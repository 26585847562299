import { useState } from 'react';
import { teams as teamsData } from '../../../data/teams/teams.js';
import { ReactComponent as Helmet } from './svgs/helmet.svg';
import { ReactComponent as Jersey } from './svgs/jersey.svg';
import { ReactComponent as Stripes } from './svgs/stripes.svg';

export default function JerseyBox (props: {
	team: string;
	position: string;
	stat: string;
	name: string;
}): React.JSX.Element {
	const [teams] = useState(teamsData);

	const getJerseyFill = (): string | undefined => {
		return teams.find(({ team }) => team === props.team)?.primary;
	};

	const getStripesFill = (): string | undefined => {
		return teams.find(({ team }) => team === props.team)?.secondary;
	};

	const getHelmetFill = (): string | undefined => {
		return teams.find(({ team }) => team === props.team)?.helmet;
	};

	const findOutline = (): string => {
		const result = teams.find(({ team }) => team === props.team);
		if (!result) return '';
		let outline = `calc(3px * var(--sizemultiplier)) ${result.secondary}`;
		if (props.team === 'NYJ' || props.team === 'NYJ2' || props.team === 'PIT2' || props.team === 'SF3') {
			outline = 'calc(3px * var(--sizemultiplier)) #000000';
		}
		if (props.team.includes('ARI')) {
			outline = 'calc(3px * var(--sizemultiplier)) #869397';
		}
		if (props.team.includes('BAL')) {
			outline = 'calc(3px * var(--sizemultiplier)) #9E7C0C';
		}
		if (props.team === 'DET') {
			outline = 'calc(3px * var(--sizemultiplier)) #656E7C';
		}
		if (props.team === 'DET2') {
			outline = 'calc(3px * var(--sizemultiplier)) #B0B7BC';
		}
		if (props.team === 'JAX') {
			outline = 'calc(3px * var(--sizemultiplier)) #01A2B4';
		}
		if (props.team.includes('NO')) {
			outline = 'calc(3px * var(--sizemultiplier)) #e8ddc5';
		}
		if (props.team === 'GB' || props.team === 'IND' || props.team === 'MIN' || props.team === 'NYG' || props.team === 'PIT' || props.team === 'SF' || props.team === 'LAC3' || props.team === 'HOU3' || props.team === 'WSH') {
			outline = 'calc(2px * var(--sizemultiplier)) #ffffff';
		}
		if (props.team === 'WSH3') {
			outline = 'calc(2px * var(--sizemultiplier)) #773141';
		}
		if (props.team === 'BUF' || props.team === 'CAR' || props.team.includes('CHI') || props.team === 'MIA') {
			outline = `calc(4px * var(--sizemultiplier)) ${result.secondary}`;
		}
		if (props.team.includes('CLE') || props.team === 'LAR' || props.team.includes('ATL') || props.team.includes('DAL')) {
			outline = '';
		}
		if (props.team === 'DAL' || props.team === 'DAL3') {
			outline = `calc(2px * var(--sizemultiplier)) ${result.primary}`;
		}
		if (props.team === 'NE') {
			outline = 'calc(2px * var(--sizemultiplier)) #B0B7BC';
		}
		if (props.team === 'NE2' || props.team === 'TEN3') {
			outline = 'calc(2px * var(--sizemultiplier)) #FFFFFF';
		}
		if (props.team.includes('TB')) {
			outline = 'calc(2px * var(--sizemultiplier)) #FF7900';
		}
		if (props.team === 'BUF3') {
			outline = 'calc(4px * var(--sizemultiplier)) #00338D';
		}
		if (props.team === 'DEN2') {
			outline = 'calc(2px * var(--sizemultiplier)) #FB4F14';
		}
		if (props.team === 'HOU2') {
			outline = 'calc(2px * var(--sizemultiplier)) #A71930';
		}
		if (props.team === 'MIA2') {
			outline = 'calc(2px * var(--sizemultiplier)) #FC4C02';
		}
		if (props.team === 'NO3' || props.team === 'PHI2') {
			outline = 'calc(2px * var(--sizemultiplier)) #000000';
		}
		if (props.team === 'SEA2' || props.team === 'SEA3') {
			outline = 'calc(2px * var(--sizemultiplier)) #69BE28';
		}
		if (props.team === 'WSH2') {
			outline = 'calc(2px * var(--sizemultiplier)) #FFB612';
		}

		return outline;
	};

	const findShadow = (): string => {
		const result = teams.find(({ team }) => team === props.team);
		if (!result) return '';
		let shadow = '';
		if (props.team === 'NYJ' || props.team.includes('BUF') || props.team === 'SF3') {
			shadow = 'calc(3px * var(--sizemultiplier)) calc(3px * var(--sizemultiplier)) 0px #000000';
		}
		if (props.team === 'PHI') {
			shadow = 'calc(5px * var(--sizemultiplier)) calc(5px * var(--sizemultiplier)) 0px #000000';
		}
		if (props.team.includes('BAL')) {
			shadow = `calc(5px * var(--sizemultiplier)) calc(5px * var(--sizemultiplier)) 0px ${result.secondary}`;
		}
		if (props.team.includes('placeholder')) {
			shadow = `calc(3px * var(--sizemultiplier)) calc(3px * var(--sizemultiplier)) 0px ${result.secondary}`;
		}
		if (props.team === 'DAL') {
			shadow = 'calc(2px * var(--sizemultiplier)) calc(2px * var(--sizemultiplier)) 0 #ffffff, calc(-2px * var(--sizemultiplier)) calc(-2px * var(--sizemultiplier)) 0 #ffffff, calc(2px * var(--sizemultiplier)) calc(2px * var(--sizemultiplier)) 0 #ffffff, calc(2px * var(--sizemultiplier)) calc(2px * var(--sizemultiplier)) 0 #ffffff';
		}
		if (props.team === 'DAL3') {
			shadow = 'calc(2px * var(--sizemultiplier)) calc(2px * var(--sizemultiplier)) 0 #041E42, calc(-2px * var(--sizemultiplier)) calc(-2px * var(--sizemultiplier)) 0 #041E42, calc(2px * var(--sizemultiplier)) calc(-2px * var(--sizemultiplier)) 0 #041E42, calc(-2px * var(--sizemultiplier)) calc(2px * var(--sizemultiplier)) 0 #041E42';
		}
		if (props.team === 'NE') {
			shadow = 'calc(2px * var(--sizemultiplier)) calc(2px * var(--sizemultiplier)) 0 #C60C30, calc(-2px * var(--sizemultiplier)) calc(-2px * var(--sizemultiplier)) 0 #C60C30, calc(2px * var(--sizemultiplier)) calc(-2px * var(--sizemultiplier)) 0 #C60C30, calc(-2px * var(--sizemultiplier)) calc(2px * var(--sizemultiplier)) 0 #C60C30';
		}
		if (props.team === 'NE2') {
			shadow = 'calc(2px * var(--sizemultiplier)) calc(2px * var(--sizemultiplier)) 0 #C60C30, calc(-2px * var(--sizemultiplier)) calc(-2px * var(--sizemultiplier)) 0 #C60C30, calc(2px * var(--sizemultiplier)) calc(-2px * var(--sizemultiplier)) 0 #C60C30, calc(-2px * var(--sizemultiplier)) calc(2px * var(--sizemultiplier)) 0 #C60C30';
		}
		if (props.team.includes('ATL')) {
			shadow = `calc(3px * var(--sizemultiplier)) 0px 0 ${result.secondary}`;
		}
		if (props.team === 'ATL2') {
			shadow = 'calc(3px * var(--sizemultiplier)) 0px 0 #A71930';
		}
		if (props.team.includes('TB')) {
			shadow = 'calc(4px * var(--sizemultiplier)) calc(4px * var(--sizemultiplier)) 0 #34302B, calc(-4px * var(--sizemultiplier)) calc(-4px * var(--sizemultiplier)) 0 #34302B, calc(4px * var(--sizemultiplier)) calc(-4px * var(--sizemultiplier)) 0 #34302B, calc(-4px * var(--sizemultiplier)) calc(4px * var(--sizemultiplier)) 0 #34302B';
		}
		return shadow;
	};

	const findColor = (): string => {
		const result = teams.find(({ team }) => team === props.team);
		if (!result) return '';
		let color = '#ffffff';
		if (props.team === 'CLE3' || props.team === 'DET' || props.team.includes('NO') || props.team === 'LV' || props.team.includes('2') || props.team === 'SF3' || props.team === 'LAR' || props.team === 'GB3' || props.team === 'HOU3' || props.team === 'WSH' || props.team === 'WSH3') {
			color = result.secondary;
		}
		if (props.team === 'SEA') {
			color = '#A5ACAF';
		}
		if (props.team === 'SEA3') {
			color = '#002244';
		}
		if (props.team === 'BAL2') {
			color = '#241773';
		}
		if (props.team === 'BUF2') {
			color = '#00338D';
		}
		if (props.team === 'CAR2' || props.team === 'CIN2' || props.team === 'PIT2') {
			color = '#000000';
		}
		if (props.team === 'CHI2') {
			color = '#0B162A';
		}
		if (props.team === 'CLE2') {
			color = '#311D00';
		}
		if (props.team === 'KC2') {
			color = '#E31837';
		}
		if (props.team === 'LAC2') {
			color = '#0080C6';
		}
		if (props.team === 'LAC3') {
			color = '#FFC20E';
		}
		if (props.team === 'LAR3') {
			color = '#C1A972';
		}
		if (props.team === 'MIN3') {
			color = '#FFC62F';
		}
		if (props.team === 'PIT3') {
			color = '#FFB612';
		}
		if (props.team === 'TEN2' || props.team === 'TEN3') {
			color = '#0C2340';
		}
		if (props.team === 'DAL3') {
			color = '#041E42';
		}
		if (props.team === 'ARI3') {
			color = '#990133';
		}
		return color;
	};

	const findLogo = (): string | undefined => {
		return teams.find(({ team }) => team === props.team)?.logo;
	};

	const findWordmark = (): string | undefined => {
		return teams.find(({ team }) => team === props.team)?.wordmark;
	};

	return (
		<div className={'team ' + props.team}>
			<span className='jerseySpan'>
				<p className='Pos'>{props.position}</p>
				<Helmet
					className='helmet'
					fill={getHelmetFill()}
				/>
				<Jersey
					className='jersey'
					fill={getJerseyFill()}
				/>
				<Stripes
					className='stripes'
					fill={getStripesFill()}
				/>
				<img
					className='wordmark'
					src={findWordmark()}
					alt='wordmark'
				/>
				<img
					className='Jerseylogo'
					src={findLogo()}
					alt='teamlogo'
				/>
			</span>
			<p
				className='Jerseynum'
				style={{ textShadow: `${findShadow()}`, WebkitTextStroke: `${findOutline()}`, color: `${findColor()}` }}>
				{props.stat}
			</p>
			<p className='Player'>{props.name}</p>
		</div>
	);
}
