import Modal from 'react-modal';
import useModalStore from '../../stores/modal.store';

export default function ImageModal(props) {
	const { toggleModal } = useModalStore();

	return (
		<Modal
			isOpen={true}
			onRequestClose={() => toggleModal('image')}
			shouldCloseOnOverlayClick={true}
			shouldCloseOnEsc={true}>
			<div className='modal modal-fit'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>Download Image</h5>
							<span
								className='close-modal'
								onClick={() => toggleModal('image')}>
								X
							</span>
						</div>
						<div
							className='modal-body'
							id='image-modal-body'>
							<p>Normal download failed. Right click to save image</p>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}
