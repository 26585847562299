import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import useUserStore from '../stores/user.store';
import useSettingsStore from '../stores/settings.store';
import { baseSettings } from '../data/settings/baseSettings';
import usePreferencesStore from '../stores/preferences.store';

export default function Lock(props) {
	const { lockedSettings, setLockedSettings } = useUserStore();
	const { updateSetting, updateSettings } = useSettingsStore();
	const { darkMode } = usePreferencesStore();

	const toggleLockedSetting = (setting) => {
		let lockedSettingsCopy = [...lockedSettings];
		const index = lockedSettingsCopy.indexOf(setting);
		if (index >= 0) {
			lockedSettingsCopy.splice(index, 1);
			setLockedSettings(lockedSettingsCopy);
		} else {
			lockedSettingsCopy.push(setting);
			setLockedSettings(lockedSettingsCopy);
		}
		localStorage.setItem('lockedSettings', JSON.stringify(lockedSettingsCopy));
	};

	const resetSetting = (setting) => {
		if (!lockedSettings.includes(setting)) {
			if (setting === 'boxMargin') {
				updateSettings({
					boxMarginTop: 0,
					boxMarginRight: 0,
					boxMarginBottom: 0,
					boxMarginLeft: 0
				});
			} else if (setting === 'subtitleMargin') {
				updateSettings({
					subtitleMarginTop: -7,
					subtitleMarginRight: 0,
					subtitleMarginBottom: 0,
					subtitleMarginLeft: 0
				});
			} else if (setting === 'titleMargin') {
				updateSettings({
					titleMarginTop: 0,
					titleMarginRight: 0,
					titleMarginBottom: 0,
					titleMarginLeft: 0
				});
			} else if (setting === 'subtitlePadding') {
				updateSettings({
					subtitlePaddingTop: 0,
					subtitlePaddingRight: 0,
					subtitlePaddingBottom: 0,
					subtitlePaddingLeft: 0
				});
			} else if (setting === 'titlePadding') {
				updateSettings({
					titlePaddingTop: 0,
					titlePaddingRight: 0,
					titlePaddingBottom: 0,
					titlePaddingLeft: 0
				});
			} else if (setting === 'thirdTitleMargin') {
				updateSettings({
					thirdTitleMarginTop: 0,
					thirdTitleMarginRight: 0,
					thirdTitleMarginBottom: 0,
					thirdTitleMarginLeft: 0
				});
			} else if (setting === 'thirdTitlePadding') {
				updateSettings({
					thirdTitlePaddingTop: 0,
					thirdTitlePaddingRight: 0,
					thirdTitlePaddingBottom: 0,
					thirdTitlePaddingLeft: 0
				});
			} else {
				updateSetting(setting, baseSettings[setting]);
			}
		}
	}

	return (
		<span className='flexRow'>
			{props.title && <h5>{props.title}</h5>}
			<Tippy content={lockedSettings.includes(props.setting) ? `Unlock ${props.title ?? props.tooltip ?? ''}` : `Lock ${props.title ?? props.tooltip ?? ''}`}>
				<span
					className={!props.tooltip ? '' : lockedSettings.includes(props.setting) ? 'blackBackground2' : 'blackBackground'}
					onClick={() => toggleLockedSetting(props.setting)}>
					<i className={lockedSettings.includes(props.setting) ? `fa-solid fa-lock` : `fa-solid fa-lock-open`}></i>
				</span>
			</Tippy>
			{!props.hideReset && (<Tippy content={`Reset ${props.title ?? props.tooltip ?? ''}`}>
				<span
					className={'blackBackground'}
					style={{marginLeft: '5px'}}
					onClick={() => resetSetting(props.setting)}>
					<i className='fa-solid fa-rotate-left' style={{color: lockedSettings.includes(props.setting) ? 'gray' : darkMode ? 'white' : 'black'}}></i>
				</span>
			</Tippy>)}
		</span>
	);
}
