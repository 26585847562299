import {useState} from 'react';
import {Link} from 'react-router-dom';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.css';

export default function Abbreviations() {
	const [nhl] = useState([
		'ANA - DUCKS',
		'BOS - BRUINS',
		'BUF - SABRES',
		'CAR - HURRICANES',
		'CGY - FLAMES',
		'CHI - BLACKHAWKS',
		'CBJ - BLUE JACKETS',
		'COL - AVALANCHE',
		'DAL - STARS',
		'DET - RED WINGS',
		'EDM - OILERS',
		'FLA - PANTHERS',
		'LA or LAK - KINGS',
		'MIN - WILD',
		'MTL - CANADIENS',
		'NSH - PREDATORS',
		'NJ or NJD - DEVILS',
		'NYI - ISLANDERS',
		'NYR - RANGERS',
		'OTT - SENATORS',
		'PHI - FLYERS',
		'PIT - PENGUINS',
		'SEA - KRAKEN ',
		'STL - BLUES',
		'SJ or SJS - SHARKS',
		'TB or TBL - LIGHTNING',
		'TOR - MAPLE LEAFS',
		'UTAH - HOCKEY CLUB',
		'VAN - CANUCKS',
		'VGS or VGK - KNIGHTS',
		'WPG - JETS',
		'WSH - CAPITALS',
	]);
	const [nba] = useState([
		'ATL - HAWKS',
		'BOS - CELTICS',
		'BRK or BKN - NETS',
		'CHA or CHO - HORNETS',
		'CHI - BULLS',
		'CLE - CAVALIERS',
		'DAL - MAVERICKS',
		'DEN - NUGGETS',
		'DET - PISTONS',
		'GS or GSW - WARRIORS',
		'HOU - ROCKETS',
		'IND - PACERS',
		'LAC - CLIPPERS',
		'LAL - LAKERS',
		'MEM - GRIZZLIES',
		'MIA - HEAT',
		'MIL - BUCKS',
		'MIN - TIMBERWOLVES',
		'NO or NOP - PELICANS',
		'NY or NYK - KNICKS',
		'OKC - THUNDER',
		'ORL - MAGIC',
		'PHI - 76ERS',
		'PHO or PHX - SUNS',
		'POR - TRAIL BLAZERS',
		'SAC - KINGS',
		'SA or SAS - SPURS',
		'TOR - RAPTORS',
		'UTA or UTAH - JAZZ',
		'WAS or WSH - WIZARDS',
	]);
	const [nfl] = useState([
		'ARI - CARDINALS',
		'ATL - FALCONS',
		'BAL - RAVENS',
		'BUF - BILLS',
		'CAR - PANTHERS',
		'CHI - BEARS',
		'CIN - BENGALS',
		'CLE - BROWNS',
		'DAL - COWBOYS',
		'DEN - BRONCOS',
		'DET - LIONS',
		'GB or GNB - PACKERS',
		'HOU - TEXANS',
		'IND - COLTS',
		'JAC or JAX - JAGUARS',
		'KC or KAN - CHIEFS',
		'LAC - CHARGERS',
		'LAR - RAMS',
		'MIA - DOLPHINS',
		'MIN - VIKINGS',
		'NE or NWE - PATRIOTS',
		'NO or NOR - SAINTS',
		'NYG - GIANTS',
		'NYJ - JETS',
		'LV or LVR - RAIDERS',
		'PHI - EAGLES',
		'PIT - STEELERS',
		'SEA - SEAHAWKS',
		'SF or SFO - 49ERS',
		'TB or TAM - BUCCANEERS',
		'TEN - TITANS',
		'WAS or WSH - COMMANDERS',
	]);
	const [mlb] = useState([
		'ARI - DIAMONDBACKS',
		'ATL - BRAVES',
		'BAL - ORIOLES',
		'BOS - RED SOX',
		'CHC - CUBS',
		'CHW - WHITE SOX',
		'CIN - REDS',
		'CLE - GUARDIANS',
		'COL - ROCKIES',
		'DET - TIGERS',
		'HOU - ASTROS',
		'KC or KCR - ROYALS',
		'LAA - ANGELS',
		'LAD - DODGERS',
		'MIA - MARLINS',
		'MIL - BREWERS',
		'MIN - TWINS',
		'NYM - METS',
		'NYY - YANKEES',
		'OAK - ATHLETICS',
		'PHI - PHILLIES',
		'PIT - PIRATES',
		'SD or SDP - PADRES',
		'SEA - MARINERS',
		'SF or SFG - GIANTS',
		'STL - CARDINALS',
		'TB or TBR - RAYS',
		'TEX - RANGERS',
		'TOR - BLUE JAYS',
		'WAS or WSH - NATIONALS',
	]);
	const [ncaa] = useState([
		'Abilene Christian - ACU',
		'Air Force - AFA',
		'Akron - AKR',
		'Alabama - ALA',
		'Alabama A&M - AAMU',
		'Alabama State - ALST',
		'Albany - ALB',
		'Alcorn State - ALCN',
		'American - AMER',
		'Appalachian State - APP',
		'Arizona - ARIZ',
		'Arizona State - ASU',
		'Arkansas - ARK',
		'Arkansas State - ARST',
		'Arkansas-Pine Bluff - UAPB',
		'Army - ARMY',
		'Auburn - AUB',
		'Austin Peay - APSU',
		'Ball State - BALL',
		'Baylor - BAY',
		'Bellarmine - BELL',
		'Belmont - BEL',
		'Bethune-Cookman - BCU',
		'Binghamton - BING',
		'Boise State - BSU',
		'Boston - BU',
		'Boston College - BC',
		'Bowling Green - BGSU',
		'Bradley - BRAD',
		'Brigham Young - BYU',
		'Brown - BRWN',
		'Bryant - BRY',
		'Bucknell - BUCK',
		'Buffalo - BUFF',
		'Butler - BUT',
		'Cal - CAL',
		'Cal Poly - CP',
		'California Baptist - CBU',
		'Campbell - CAM',
		'Canisius - CAN',
		'Central Arkansas - UCA',
		'Central Connecticut - CCSU',
		'Central Michigan - CMU',
		'Charleston   - COFC',
		'Charleston Southern - CHSO',
		'Charlotte - CHAR',
		'Charlotte - CLT',
		'Chattanooga - UTC',
		'Chicago State - CHIC',
		'Cincinnati - CIN',
		'Clemson - CLEM',
		'Cleveland State - CLEV',
		'Coastal Carolina - CCAR',
		'Coastal Carolina - CCU',
		'Colgate - COLG',
		'Colorado - COLO',
		'Colorado State - CSU',
		'Columbia - CLMB',
		'Coppin State - COPP',
		'Cornell - COR',
		'Creighton - CREI',
		'CSU Bakersfield - CSUB',
		'CSU Fullerton - CSUF',
		'CSU Northridge - CSUN',
		'Dartmouth - DART',
		'Davidson - DAV',
		'Dayton - DAY',
		'Delaware - DEL',
		'Delaware State - DSU',
		'Denver - DEN',
		'DePaul - DEP',
		'Detroit Mercy - DET',
		'Drake - DRKE',
		'Drexel - DREX',
		'Duke - DUKE',
		'Duquesne - DUQ',
		'Utah Tech - UTU',
		'East Carolina - ECU',
		'East Tennessee State - ETSU',
		'Eastern Illinois - EIU',
		'Eastern Kentucky - EKU',
		'Eastern Michigan - EMU',
		'Eastern Washington - EWU',
		'Elon - ELON',
		'Evansville - EVAN',
		'Fairfield - FAIR',
		'Fairleigh Dickinson - FDU',
		'Florida - FLA',
		'Florida A&M - FAMU',
		'Florida Atlantic - FAU',
		'Florida Gulf Coast - FGCU',
		'Florida International University - FIU',
		'Florida State - FSU',
		'Fordham - FOR',
		'Fresno State - FRES',
		'Furman - FUR',
		'Gardner-Webb - GWEB',
		'George Mason - GMU',
		'George Washington - GW',
		'Georgetown - GTWN',
		'Georgia - UGA',
		'Georgia Southern - GASO',
		'Georgia State - GAST',
		'Georgia Tech - GT',
		'Gonzaga - GONZ',
		'Grambling State - GRAM',
		'Grand Canyon - GCU',
		'Green Bay - GB',
		'Hampton - HAMP',
		'Hartford - HART',
		'Harvard - HARV',
		'Hawaii - HAW',
		'High Point - HP',
		'Hofstra - HOF',
		'Holy Cross - HC',
		'Houston - HOU',
		'Houston Christian - HCU',
		'Howard - HOW',
		'Idaho - IDHO',
		'Idaho State - IDST',
		'Illinois - ILL',
		'Illinois State - ILST',
		'Incarnate Word - UIW',
		'Indiana - IND',
		'Indiana - IU',
		'Indiana State - INST',
		'Indiana University–Purdue University Indianapolis - IUPU',
		'Iona - IONA',
		'Iowa - IOWA',
		'Iowa State - ISU',
		'Jackson State - JKST',
		'Jacksonville   - JAX',
		'Jacksonville State - JVST',
		'James Madison - JMU',
		'Kansas - KU',
		'Kansas State - KSU',
		'Kennesaw State - KENN',
		'Kent State - KENT',
		'Kentucky - UK',
		'La Salle - LAS',
		'Lafayette - LAF',
		'Lamar - LAM',
		'Lehigh - LEH',
		'Liberty - LIB',
		'Lipscomb - LIP',
		'Little Rock - UALR',
		'Long Beach State - LBSU',
		'Long Island University - LIU',
		'Longwood - LONG',
		'Louisiana Lafayette - UL',
		'Louisiana Lafayette - ULL',
		'Louisiana Monroe - ULM',
		'Louisiana State University - LSU',
		'Louisiana Tech - LAT',
		'Louisiana Tech - LT',
		'Louisville - LOU',
		'Loyola (MD) - L-MD',
		'Loyola Chicago - LUC',
		'Loyola Marymount - LMU',
		'Maine - ME',
		'Manhattan - MAN',
		'Marist - MRST',
		'Marquette - MARQ',
		'Marshall - MRSH',
		'Maryland - MD',
		'Maryland - UMD',
		'Maryland-Eastern Shore - UMES',
		'McNeese State - MCNS',
		'Memphis - MEM',
		'Mercer - MER',
		'Merrimack - MRMK',
		'Miami - MIAMI',
		'Miami (FL) - MIA',
		'Miami (OH) - M-OH',
		'Michigan - MICH',
		'Michigan State - MSU',
		'Middle Tennessee - MTSU',
		'Milwaukee - MILW',
		'Minnesota - MINN',
		'Mississippi State - MSST',
		'Mississippi Valley State - MVSU',
		'Missouri   - MIZ',
		'Missouri State - MOST',
		'Monmouth - MONM',
		'Montana - MONT',
		'Montana State - MTST',
		'Morehead State - MORE',
		'Morgan State - MORG',
		"Mount St. Mary's - MSM",
		'Murray State - MUR',
		'Navy - NAVY',
		'NC Central - NCCU',
		'NC State - NCST',
		'Nebraska - NEB',
		'Nevada - NEV',
		'New Hampshire - UNH',
		'New Jersey Institute of Technology - NJIT',
		'New Mexico - UNM',
		'New Mexico State - NMSU',
		'New Orleans - UNO',
		'Niagara - NIAG',
		'Nicholls State - NICH',
		'Norfolk State - NORF',
		'North Alabama - UNA',
		'North Carolina - UNC',
		'North Carolina A&T - NCAT',
		'North Dakota - UND',
		'North Dakota State - NDSU',
		'North Florida - UNF',
		'North Texas - UNT',
		'Northeastern - NE',
		'Northern Arizona - NAU',
		'Northern Colorado - UNCO',
		'Northern Illinois - NIU',
		'Northern Iowa - UNI',
		'Northern Kentucky - NKU',
		'Northwestern - NW',
		'Northwestern State - NWST',
		'Notre Dame - ND',
		'Oakland - OAK',
		'Ohio - OHIO',
		'Ohio State - OSU',
		'Oklahoma - OU',
		'Oklahoma   - OKLA',
		'Oklahoma State - OKST',
		'Old Dominion - ODU',
		'Ole Miss - MISS',
		'Omaha - OMA',
		'Oral Roberts - ORU',
		'Oregon   - ORE',
		'Oregon State - ORST',
		'Pacific - PAC',
		'Penn State - PSU',
		'Pepperdine - PEPP',
		'Pittsburgh - PITT',
		'Portland   - PORT',
		'Portland State - PRST',
		'Prairie View A&M - PV',
		'Presbyterian - PRES',
		'Princeton - PRIN',
		'Providence - PROV',
		'Purdue - PUR',
		'Purdue Fort Wayne - PFW',
		'Quinnipiac - QUIN',
		'Radford - RAD',
		'Rhode Island - URI',
		'Rice - RICE',
		'Richmond - RICH',
		'Rider - RID',
		'Robert Morris (PA) - RMU',
		'Rutgers - RUTG',
		'Sacramento State - SAC',
		'Sacred Heart - SHU',
		"Saint Joseph's - JOES",
		'Saint Louis - SLU',
		"Saint Mary's - SMC",
		"Saint Peter's - SPU",
		'Sam Houston State - SHSU',
		'Samford - SAM',
		'San Diego - USD',
		'San Diego State - SDSU',
		'San Francisco - SF',
		'San José State - SJSU',
		'Santa Clara - SCU',
		'Seattle U - SEA',
		'Seton Hall - HALL',
		'Siena - SIE',
		'SIU Edwardsville - SIUE',
		'South Alabama - USA',
		'South Carolina - SC',
		'South Carolina State - SCST',
		'South Carolina Upstate - SCUP',
		'South Dakota - SDAK',
		'South Dakota State - SDST',
		'South Florida - USF',
		'Southeast Missouri - SEMO',
		'Southeastern Louisiana - SELA',
		'Southern Illinois - SIU',
		'Southern Methodist University - SMU',
		'Southern Miss - USM',
		'Southern University - SOU',
		'Southern Utah - SUU',
		'St. Bonaventure - SBU',
		'St. Francis (BKN) - SFBK',
		'St. Francis (PA) - SFPA',
		"St. John's - SJU",
		'Stanford - STAN',
		'Stephen F. Austin - SFA',
		'Stetson - STET',
		'Stony Brook - STBK',
		'Syracuse - SYR',
		'Tarleton - TAR',
		'Temple - TEM',
		'Tennessee - TENN',
		'Tennessee State - TNST',
		'Tennessee Tech - TNTC',
		'Tennessee-Martin - UTM',
		'Texas - TEX',
		'Texas A&M - TAMU',
		'Texas A&M-CC - AMCC',
		'Texas Christian - TCU',
		'Texas Southern - TXSO',
		'Texas State - TXST',
		'Texas Tech - TTU',
		'The Citadel - CIT',
		'Toledo - TOL',
		'Towson - TOW',
		'Troy - TROY',
		'Tulane - TUL',
		'Tulane - TULN',
		'Tulsa - TLSA',
		'UC Davis - UCD',
		'UC Irvine - UCI',
		'UC Riverside - UCR',
		'UC San Diego - UCSD',
		'UC Santa Barbara - UCSB',
		'UConn - CONN',
		'UM Kansas City - UMKC',
		'UMASS - MASS',
		'UMass - UMASS',
		'UMASS Lowell - UML',
		'UNC Asheville - UNCA',
		'UNC Greensboro - UNCG',
		'UNC Wilmington - UNCW',
		'University of Alabama at Birmingham - UAB',
		'University of California Los Angeles - UCLA',
		'University of Central Florida - UCF',
		'University of Illinois at Chicago - UIC',
		'University of Maryland Baltimore County - UMBC',
		'University of Nevada Las Vegas - UNLV',
		'University of Pennsylvania - PENN',
		'University of Southern California - USC',
		'University of Texas at El Paso - UTEP',
		'University of Texas at San Antonio - UTSA',
		'UT Arlington - UTA',
		'UT Rio Grande Valley - UTRGV',
		'Utah - UTAH',
		'Utah State - USU',
		'Utah Valley - UVU',
		'Valparaiso - VAL',
		'Vanderbilt - VAN',
		'Vermont - UVM',
		'Villanova - VILL',
		'Virginia - UVA',
		'Virginia Commonwealth - VCU',
		'Virginia Military - VMI',
		'Virginia Tech - VT',
		'Wagner - WAG',
		'Wake Forest - WAKE',
		'Washington   - WASH',
		'Washington State - WSU',
		'Weber State - WEB',
		'West Virginia - WVU',
		'Western Carolina - WCU',
		'Western Illinois - WIU',
		'Western Kentucky - WKU',
		'Western Michigan - WMU',
		'Wichita State - WICH',
		'William & Mary - WM',
		'Winthrop - WIN',
		'Wisconsin - WIS',
		'Wisconsin - WISC',
		'Wofford - WOF',
		'Wright State - WRST',
		'Wyoming - WYO',
		'Xavier - XAV',
		'Yale - YALE',
		'Youngstown State - YSU',
		'Lindenwood - LIN',
		'Queens University - QUOC',
		'Southern Indiana - USI',
		'Stonehill - STO',
		'East Texas A&M - ETAM',
		'Le Moyne - LEM',
		'West Georgia - UWG',
		'Mercyhurst - MERC'
	]);
	const [soccer] = useState([
		'Ajax Amsterdam - AJA',
		'Alavés - ALV',
		'Arsenal - ARS',
		'Atalanta - ATA',
		'Athletic Club - ATH',
		'Atletico Madrid - ATL',
		'Atlanta United FC - ATLU',
		'Austin FC - ATX',
		'FC Augsburg - AUG',
		'Aston Villa - AVL',
		'Barcelona - BAR',
		'Benevento - BEN',
		'Real Betis - BET',
		'Brighton & Hove Albion - BHA',
		'Bologna - BOL',
		'Club Brugge - BRUG',
		'Burnley - BUR',
		'Cádiz - CAD',
		'Cagliari - CAG',
		'Celta Vigo - CEL',
		'Chelsea - CHE',
		'Chicago Fire FC - CHI',
		'FC Cincinnati - CIN',
		'Columbus Crew SC - CLB',
		'FC Cologne - COL',
		'Colorado Rapids - COLO',
		'Crotone - CRO',
		'Crystal Palace - CRY',
		'FC Dallas - DAL',
		'DC United - DC',
		'Borussia Dortmund - DOR',
		'Arminia Bielefeld - DSC',
		'Eibar - EIB',
		'Eintracht Frankfurt - EINF',
		'Elche - ELC',
		'Everton - EVE',
		'Krasnodar - FCK',
		'FC Union Berlin - FCUB',
		'Ferencvaros - FERE',
		'Fiorentina - FIO',
		'SC Freiburg - FREI',
		'Fulham - FUL',
		'Granada - GCF',
		'Genoa - GEN',
		'Getafe - GET',
		'Hertha Berlin - HERT',
		'Tsg Hoffenheim - HOF',
		'Houston Dynamo FC - HOU',
		'Huesca - HUE',
		'Istanbul Basaksehir - IBFK',
		'Internazionale - INT',
		'Juventus - JUV',
		'Dynamo Kiev - KIEV',
		'La Galaxy - LA',
		'Los Angeles FC - LAFC',
		'Lazio - LAZ',
		'Leeds United - LEE',
		'Leicester City - LEI',
		'Rb Leipzig - LEIP',
		'Levante - LEV',
		'Bayer Leverkusen - LEVE',
		'Liverpool - LIV',
		'Lokomotiv Moscow - LMO',
		'Real Madrid - MAD',
		'Mainz - MAIN',
		'Manchester United - MAN',
		'Marseille - MAR',
		'Inter Miami CF - MIA',
		'Midtjylland - MIDT',
		'AC Milan - MIL',
		'Minnesota United FC - MIN',
		'Manchester City - MNC',
		'Borussia Monchengladbach - MON',
		'CF Montréal - MTL',
		'Bayern Munich - MUN',
		'Napoli - NAP',
		'New England Revolution - NE',
		'Newcastle United - NEW',
		'Nashville SC - NSH',
		'New York Red Bulls - NY',
		'New York City FC - NYC',
		'Olympiakos - OLY',
		'Orlando City SC - ORL',
		'Osasuna - OSA',
		'Parma - PAR',
		'Philadelphia Union - PHI',
		'Porto - POR',
		'Portland Timbers - PORT',
		'Paris Saint-Germain - PSG',
		'Stade Rennes - RENN',
		'AS Roma - ROMA',
		'Real Salt Lake - RSL',
		'Real Sociedad - RSO',
		'Salzburg - SALZ',
		'Sampdoria - SAMP',
		'Sassuolo - SAS',
		'Schalke 04 - SCHA',
		'Seattle Sounders FC - SEA',
		'Sevilla FC - SEV',
		'Shakhtar Donetsk - SHK',
		'Sheffield United - SHU',
		'San Jose Earthquakes - SJ',
		'Sporting Kansas City - SKC',
		'Southampton - SOU',
		'Spezia - SPEZ',
		'Vfb Stuttgart - STU',
		'Torino - TOR',
		'Toronto FC - TORO',
		'Tottenham Hotspur - TOT',
		'Udinese - UDN',
		'Valencia - VAL',
		'Real Valladolid - VALL',
		'Vancouver Whitecaps - VAN',
		'Hellas Verona - VER',
		'Villarreal - VILL',
		'West Bromwich Albion - WBA',
		'Werder Bremen - WER',
		'West Ham United - WHU',
		'Wolverhampton Wanderers - WOL',
		'Vfb Stuttgart - WOLF',
		'Zenit St Petersburg - ZEN',
		'Lecce - LEC',
		'Empoli - EMP',
		'Monza - MONZ',
		'Frosinone - FROS',
		'Salernitana - SAL',
		'Brentford - BRE',
		'Girona - GIR',
		'Las Palmas - PALM',
		'Rayo Vallecano - RAY',
		'Mallorca - MAL',
		'Almeria - ALM',
	]);
	const [filteredncaa, setFilteredNcaa] = useState(ncaa);
	const [filteredmlb, setFilteredMlb] = useState(mlb);
	const [filterednfl, setFilteredNfl] = useState(nfl);
	const [filterednba, setFilteredNba] = useState(nba);
	const [filterednhl, setFilteredNhl] = useState(nhl);
	const [filteredsoccer, setFilteredSoccer] = useState(soccer);

	const filternhl = (e) => {
		const filtered = nhl.filter((team) => {
			return team.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1;
		});
		setFilteredNhl(filtered);
	};

	const filternba = (e) => {
		const filtered = nba.filter((team) => {
			return team.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1;
		});
		setFilteredNba(filtered);
	};

	const filternfl = (e) => {
		const filtered = nfl.filter((team) => {
			return team.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1;
		});
		setFilteredNfl(filtered);
	};

	const filtermlb = (e) => {
		const filtered = mlb.filter((team) => {
			return team.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1;
		});
		setFilteredMlb(filtered);
	};

	const filterncaa = (e) => {
		const filtered = ncaa.filter((team) => {
			return team.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1;
		});
		setFilteredNcaa(filtered);
	};

	const filtersoccer = (e) => {
		const filtered = soccer.filter((team) => {
			return team.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1;
		});
		setFilteredSoccer(filtered);
	};

	const selectAbbrs = (e) => {
		let hide = document.querySelectorAll('.card');
		// eslint-disable-next-line
		Array.from(hide).map((el) => {
			el.classList.add('hide');
		});
		const select = document.getElementById('abbrselect');
		if (select) {
			const filter = select.value;
			const element = document.getElementById(filter);
			element.classList.remove('hide');
		}
	};

	return (
		<div className='abbreviationPage'>
			<h1>Abbreviations</h1>
			<h6>
				<Link to='/dashboard'>Return to Dashboard</Link>
			</h6>
			<p>For the four major leagues, you can use either the city abbreviation (i.e. ARI) or team name (i.e. Cardinals). For NCAA and Soccer, you can only use the abbreviation (i.e. ALA)</p>
			<p>Select a Sport:</p>
			<div className='abbreviations'>
				<select
					name='auto1'
					id='abbrselect'
					onChange={selectAbbrs}>
					<option value='ncaa'>NCAA</option>
					<option value='nfl'>NFL</option>
					<option value='nba'>NBA</option>
					<option value='mlb'>MLB</option>
					<option value='nhl'>NHL</option>
					<option value='soccer'>SOCCER</option>
				</select>
				<div
					className='nflabbrs card mb-4 rounded-3 hide'
					id='nfl'>
					<h4 className='card-header py-3'>NFL</h4>
					<div className='p-1 bg-light rounded rounded-pill shadow-sm mb-4 abbrfilter'>
						<div className='input-group'>
							<input
								type='search'
								placeholder='Search'
								onChange={filternfl}
								className='form-control border-0 bg-light'
							/>
							<div className='input-group-append'>
								<i className='fa fa-search'></i>
							</div>
						</div>
					</div>
					<ul>
						{filterednfl.map((item, i) => {
							return <li key={i}>{item}</li>;
						})}
					</ul>
				</div>
				<div
					className='nbaabbrs card mb-4 rounded-3 hide'
					id='nba'>
					<h4 className='card-header py-3'>NBA</h4>
					<div className='p-1 bg-light rounded rounded-pill shadow-sm mb-4 abbrfilter'>
						<div className='input-group'>
							<input
								type='search'
								placeholder='Search'
								onChange={filternba}
								className='form-control border-0 bg-light'
							/>
							<div className='input-group-append'>
								<i className='fa fa-search'></i>
							</div>
						</div>
					</div>
					<ul>
						{filterednba.map((item, i) => {
							return <li key={i}>{item}</li>;
						})}
					</ul>
				</div>
				<div
					className='mlbabbrs card mb-4 rounded-3 hide'
					id='mlb'>
					<h4 className='card-header py-3'>MLB</h4>
					<div className='p-1 bg-light rounded rounded-pill shadow-sm mb-4 abbrfilter'>
						<div className='input-group'>
							<input
								type='search'
								placeholder='Search'
								onChange={filtermlb}
								className='form-control border-0 bg-light'
							/>
							<div className='input-group-append'>
								<i className='fa fa-search'></i>
							</div>
						</div>
					</div>
					<ul>
						{filteredmlb.map((item, i) => {
							return <li key={i}>{item}</li>;
						})}
					</ul>
				</div>
				<div
					className='nhlabbrs card mb-4 rounded-3 hide'
					id='nhl'>
					<h4 className='card-header py-3'>NHL</h4>
					<div className='p-1 bg-light rounded rounded-pill shadow-sm mb-4 abbrfilter'>
						<div className='input-group'>
							<input
								type='search'
								placeholder='Search'
								onChange={filternhl}
								className='form-control border-0 bg-light'
							/>
							<div className='input-group-append'>
								<i className='fa fa-search'></i>
							</div>
						</div>
					</div>
					<ul>
						{filterednhl.map((item, i) => {
							return <li key={i}>{item}</li>;
						})}
					</ul>
				</div>
				<div
					className='ncaaabbrs card mb-4 rounded-3'
					id='ncaa'>
					<h4 className='card-header py-3'>NCAA</h4>
					<div className='p-1 bg-light rounded rounded-pill shadow-sm mb-4 abbrfilter'>
						<div className='input-group'>
							<input
								type='search'
								placeholder='Search'
								onChange={filterncaa}
								className='form-control border-0 bg-light'
							/>
							<div className='input-group-append'>
								<i className='fa fa-search'></i>
							</div>
						</div>
					</div>
					<ul>
						{filteredncaa.map((item, i) => {
							return <li key={i}>{item}</li>;
						})}
					</ul>
				</div>
				<div
					className='soccerabbrs card mb-4 rounded-3 hide'
					id='soccer'>
					<h4 className='card-header py-3'>Soccer</h4>
					<div className='p-1 bg-light rounded rounded-pill shadow-sm mb-4 abbrfilter'>
						<div className='input-group'>
							<input
								type='search'
								placeholder='Search'
								onChange={filtersoccer}
								className='form-control border-0 bg-light'
							/>
							<div className='input-group-append'>
								<i className='fa fa-search'></i>
							</div>
						</div>
					</div>
					<ul>
						{filteredsoccer.map((item, i) => {
							return <li key={i}>{item}</li>;
						})}
					</ul>
				</div>
			</div>
		</div>
	);
}
