export const gridSettings = {
	type: 'grid',
	headerSize: 20,
	nameSize: 30,
	statSize: 30,
	logoOrder: 0,
	logoOpacity: 100,
	logoSize: 78,
	headerSpacing: 0,
	nameMargin: 3,
	headerFont: 'roboto bold',
	nameFont: 'roboto bold',
	statFont: 'roboto bold',
	headerColor: '#000000',
	headerFontColor: '#ffffff',
	watermark: '@FAN\nTASY\nFTBL',
	watermarkFont: 'exo',
	watermarkSize: 28,
	watermarkWidth: 82,
	statMargin: -15,
	watermarkColor: '#ffffff',
	nameShadow: 0,
	borderColor: '#ffffff',
	nameSpacing: 0,
	statSpacing: -1,
	statShadow: 0,
	logoPosition: 292,
	overlap: false,
	statMargin2: 0,
	lineHeight: 0.9,
	nameBackground: 'primary',
	statBackground: 'primary',
	nameColor: '#ffffff',
	statColor: '#ffffff',
	imageHorizontalNews: 0,
	imageVerticalNews: 0,
	imageZoomNews: 100,
	headerSizeNews: 20,
	headerFontNews: 'roboto condensed',
	headerMarginNews: 0,
	headerSpacingNews: 0,
	headerColorNews: '#ffffff',
	headerFontColorNews: '#000000',
	logoOpacityNews: 100,
	nameColorNews: '#ffffff',
	logoSizeNews: 500,
	orientation: 'leftright',
	orientationNews: 'leftright',
	orientationMatchup: 'topbottom',
	orientationHighlight: 'leftright',
	orientationLineup: 'leftright',
	borderRadius: 13,
	scoreMargin: 5,
	paneSplit: [50, 50],
	paneSplitNews: [50, 50],
	paneSplitMatchup: [80, 20],
	paneSplitHighlight: [50, 50],
	paneSplitLineup: [50, 50],
	duplicate: true,
	logoShadow: 0,
	nameMargin2: 10,
	postWidth: 653,
	postHeight: 653,
	postWidthNews: 653,
	postHeightNews: 653,
	tierFont: 'roboto condensed',
	tierColor: '#ffffff',
	tierSize: 75,
	duplicateLogoSize: 466,
	duplicateLogoPosition: 0,
	duplicateLogoOpacity: 10,
	gridMargin: 3,
	logoFlip: true,
	scoreMinHeight: 100,
	videoDirection: 'fromright',
	videoReverse: false,
	headerOutlineColor: '#ffffff',
	nameOutlineColor: '#ffffff',
	statOutlineColor: '#ffffff',
	headerOutlineSize: 0,
	nameOutlineSize: 0,
	statOutlineSize: 0,
	headerTransform: 'none',
	nameTransform: 'none',
	statTransform: 'none',
	nameShadowColor: '#000000',
	statShadowColor: '#000000',
	tierTitle: 'left',
	pyramid: false,
	newsStyle: {
		value: 'style-1',
		label: 'Style 1',
	},
	postBackground: null,
	titleHeight: 10,
	subtitleFontSize: 10,
	subtitleSpacing: 0,
	subtitleFont: 'roboto condensed',
	subtitleFontColor: '#ffffff',
	subtitleOutlineColor: '#ffffff',
	subtitleOutlineSize: 0,
	subtitleTransform: 'none',
	backgroundColor: 'single',
	postColumns: 1,
	gridColumns: null,
	tierSplit: 8,
	fivelessBreak: 8,
	helmets: false,
	rankSize: 16,
	rankFont: 'roboto condensed',
	rankFontColor: '#ffffff',
	rankMargin: 0,
	rankSpacing: 0,
	rankOutlineSize: 2,
	rankOutlineColor: '#000000',
	logoSizeGrid: 72,
	logoSizeTier: 100,
	pointsSize: 20,
	pointsFont: 'roboto condensed',
	pointsFontColor: '#ffffff',
	pointsMargin: 0,
	pointsMargin2: 0,
	pointsSpacing: -1.2,
	pointsOutlineSize: 0,
	pointsOutlineColor: '#000000',
	matchupPointsBackground: '#00000000',
	pointsBackground: '#000000',
	playerHeight: 1,
	statLineHeight: 1,
	indexAxis: 'y',
	chartType: {
		value: 'bar',
		label: 'Bar Chart',
	},
	labelSize: 14,
	labelColor: '#ffffff',
	labelFont: 'roboto condensed',
	chartBorderColor: '#ffffff',
	chartBorderWidth: 0.5,
	chartLogoSize: 75,
	playerMargin: 0,
	lighterLogos: false,
	texture: 'grainy',
	textureSize: 5,
	textureOpacity: 8,
	matchupRankBackground: '#00000000',
	rankBackground: 'primary',
	teamBackground: 'gradient',
	actualHeaderColor: '#00000000',
	rankWidth: 100,
	rankMargin2: 0,
	boxRankOutlineSize: 0,
	borderSize: 0,
	headerBorderSize: 2,
	headerBorderColor: '#ffffff',
	boxImageBackground: 'none',
	logoDropShadow: 0,
	footerHeight: 30,
	footerSpacing: 0,
	footerMargin: 0,
	footerFont: 'roboto condensed',
	footerSize: 20,
	footerFontColor: '#ffffff',
	footerBackground: '#000000',
	footerOutlineSize: 0,
	footerOutlineColor: '#000000',
	hasFooter: false,
	footerText: '',
	footerAlignment: 'center',
	tierBackground: '#00000000',
	statHighlight: false,
	topHighlight: '#0eff00',
	bottomHighlight: '#ff0000',
	subtitleSizeNews: 20,
	subtitleMarginNews: 20,
	subtitleSpacingNews: 0,
	subtitleFontNews: 'roboto condensed',
	matchupStyle: {
		value: 'style-1',
		label: 'Style 1',
	},
	imageShadowColor: '#000000',
	imageShadowSize: 20,
	descriptionSize: 25,
	descriptionMargin: 0,
	descriptionSpacing: 0,
	descriptionFont: 'roboto condensed',
	descriptionFontColor: '#ffffff',
	descriptionOutlineColor: '#000000',
	descriptionOutlineSize: 2,
	descriptionTransform: 'none',
	comparisonRadius: 10,
	comparisonMargin: 20,
	comparisonBorderSize: 2,
	comparisonBorderColor: '#ffffff',
	labelsSize: 20,
	labelsMargin: 6,
	labelsSpacing: 0,
	labelsFont: 'roboto condensed',
	labelsFontColor: '#ffffff',
	labelsBackground: '#000000',
	labelsTransform: 'none',
	boxGlowWidth: 0,
	boxGlowColor: '#ffffff',
	lineHeightNews: 0,
	lineHeightTextNews: 1.3,
	imageTitleColor: '#ffffff',
	backgroundText: 'STATS',
	imageLinesColor: 'secondary',
	badgeLineHeightNews: 0,
	titleBackground: 'secondary',
	textBackground: 'secondary',
	teamContainerBackground: 'primary',
	teamBackgroundLeft: 'secondary',
	imageStyle: {
		value: 'style-1',
		label: 'Style 1',
	},
	logoVerticalMargin: -12,
	logoHorizontalMargin: 0,
	logoVerticalMarginNews: 0,
	logoHorizontalMarginNews: 0,
	line1size: 100,
	line2size: 65,
	line3size: 100,
	line1sizestat: 100,
	line2sizestat: 100,
	line3sizestat: 100,
	nameAlignment: 'fill',
	statAlignment: 'fill',
	unevenTiers: false,
	footerPadding: 0,
	playerOrder: 3,
	rankInside: true,
	rankMarginReal: 10,
	rankOutsideMargin: 0,
	rankGlowWidth: 0,
	rankGlowColor: '#ffffff',
	rankBorderRadius: 5,
	postStatBackground: 'none',
	postStatPadding: 0,
	postStatHeight: 100,
	postStatBorderRadius: 0,
	gradientDirection: '270deg',
	gradientType: 'linear-gradient',
	gradientStops: [
		{
			color: 'secondary',
			percentage: 0,
		},
		{
			color: 'mid',
			percentage: 32,
		},
		{
			color: 'primary',
			percentage: 100,
		},
	],
	gradientDirectionNews: '0deg',
	gradientTypeNews: 'linear-gradient',
	gradientStopsNews: [
		{
			color: 'secondary',
			percentage: 0,
		},
		{
			color: 'mid',
			percentage: 50,
		},
		{
			color: 'primary',
			percentage: 100,
		},
	],
	backgroundGradientDirectionDeg: '180deg',
	backgroundGradientType: 'linear-gradient',
	backgroundGradientStops: [
		{
			color: '#013369',
			percentage: 0,
		},
		{
			color: '#D50A0A',
			percentage: 100,
		},
	],
	leftBackgroundGradientDirection: '180deg',
	leftBackgroundGradientType: 'linear-gradient',
	leftBackgroundGradientStops: [
		{
			color: 'primary',
			percentage: 0,
		},
		{
			color: 'mid',
			percentage: 50,
		},
		{
			color: 'secondary',
			percentage: 100,
		},
	],
	nonHeadshot: false,
	playerAspectRatio: 1.3,
	logoSizeBracket: 100,
	bracketMinimized: false,
	bracketOneSided: false,
	boxMarginTop: 0,
	boxMarginRight: 0,
	boxMarginBottom: 0,
	boxMarginLeft: 0,
	subtitleBackground: '#00000000',
	subtitleMarginTop: -7,
	subtitleMarginRight: 0,
	subtitleMarginBottom: 0,
	subtitleMarginLeft: 0,
	subtitlePaddingTop: 0,
	subtitlePaddingRight: 0,
	subtitlePaddingBottom: 0,
	subtitlePaddingLeft: 0,
	subtitleAlignment: 'center',
	subtitleAlignmentNews: 'left',
	titleBackgroundColor: '#00000000',
	titleMarginTop: -1,
	titleMarginRight: 0,
	titleMarginBottom: 0,
	titleMarginLeft: 0,
	titlePaddingTop: 0,
	titlePaddingRight: 0,
	titlePaddingBottom: 0,
	titlePaddingLeft: 0,
	headerAlignment: 'center',
	headerAlignmentNews: 'left',
	thirdTitleFontSize: 10,
	thirdTitleSpacing: 0,
	thirdTitleFont: 'roboto condensed',
	thirdTitleFontColor: '#ffffff',
	thirdTitleOutlineColor: '#ffffff',
	thirdTitleOutlineSize: 0,
	thirdTitleTransform: 'none',
	thirdTitleBackground: '#00000000',
	thirdTitleMarginTop: 0,
	thirdTitleMarginRight: 0,
	thirdTitleMarginBottom: 0,
	thirdTitleMarginLeft: 0,
	thirdTitlePaddingTop: 0,
	thirdTitlePaddingRight: 0,
	thirdTitlePaddingBottom: 0,
	thirdTitlePaddingLeft: 0,
	thirdTitleAlignment: 'center',
	headerTextGradientDirection: '180deg',
	headerTextGradientType: 'linear-gradient',
	headerTextGradientStops: [
		{
			color: '#FFFFFF',
			percentage: 0,
		},
		{
			color: '#5d5d5d',
			percentage: 100,
		},
	],
	subtitleTextGradientDirection: '180deg',
	subtitleTextGradientType: 'linear-gradient',
	subtitleTextGradientStops: [
		{
			color: '#FFFFFF',
			percentage: 0,
		},
		{
			color: '#5d5d5d',
			percentage: 100,
		},
	],
	thirdTitleTextGradientDirection: '180deg',
	thirdTitleTextGradientType: 'linear-gradient',
	thirdTitleTextGradientStops: [
		{
			color: '#FFFFFF',
			percentage: 0,
		},
		{
			color: '#5d5d5d',
			percentage: 100,
		},
	],
};
