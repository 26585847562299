export const sportOptions = [
	{value: 'nfl', label: 'NFL'},
	{value: 'nba', label: 'NBA'},
	{value: 'mlb', label: 'MLB'},
	{value: 'ncaa', label: 'NCAA'},
	{value: 'nhl', label: 'NHL'},
	{value: 'soccer', label: 'Soccer'},
	{value: 'f1', label: 'F1'},
	{value: 'wnba', label: 'WNBA'},
	{value: 'afl', label: 'AFL'},
];