import { createRoot } from 'react-dom/client';
import './index.css';
import RoutesPage from './Routes';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ErrorBoundary } from 'react-error-boundary';
import usePostStore from './stores/posts/base-post.store';
import useBracketStore from './stores/posts/bracket.store';
import useChartStore from './stores/posts/chart.store';
import useComparisonStore from './stores/posts/comparison.store';
import useTierStore from './stores/posts/tier.store';
import useWithImageStore from './stores/posts/with-image.store';

const root = createRoot(document.getElementById('root'));
// i really dont want to add this but if the page is going to crash anyway then this
// will be easier to see on fullstory so I can fix the things
function ErrorFallback({ error, resetErrorBoundary }) {
	const {reset: resetPost} = usePostStore();
    const { reset: resetBracket } = useBracketStore();
	const { reset: resetChart } = useChartStore();
	const { reset: resetComparison } = useComparisonStore();
	const { reset: resetTier } = useTierStore();
	const { reset: resetWithImage } = useWithImageStore();

	const reset = () => {
		resetPost();
		resetBracket();
		resetChart();
		resetComparison();
		resetTier();
		resetWithImage();
		resetErrorBoundary();
	}
	return (
		<div role="alert">
			<p>Something went wrong:</p>
			<pre style={{ color: 'red' }}>{error.message}</pre>
			<button id='majorError' onClick={reset}>Try again</button>
		</div>
	)
}

root.render(
	<GoogleOAuthProvider clientId="109165687997-8ao5b2s83npaknkf0a1vs9qevu5b2kd5.apps.googleusercontent.com">
		<ErrorBoundary FallbackComponent={ErrorFallback}>
			<RoutesPage />
		</ErrorBoundary>
	</GoogleOAuthProvider>);
