import Modal from 'react-modal';
import TeamEdit from '../TeamEdit';
import {useState} from 'react';
import BoxPreview from '../BoxPreview';
import useModalStore from '../../stores/modal.store';
import useUserStore from '../../stores/user.store';

export default function OverridesModal(props) {
	const [teamValue, setTeamValue] = useState(null);
	const { toggleModal } = useModalStore();
	const { teamOverrides } = useUserStore();
	return (
		<Modal
			isOpen={true}
			onRequestClose={() => toggleModal('overrides')}>
			<div className='modal'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>Manage Overrides</h5>
							<span
								className='close-modal'
								onClick={() => toggleModal('overrides')}>
								X
							</span>
						</div>
						<div className='modal-body'>
							<div className='overrideButtons'>
								{teamOverrides.map((override, i) => {
									if (!override.isGlobal) return <span key={i}></span>;
									return (
										<button
											key={i}
											className='newButton'
											onClick={() => setTeamValue({team: override.abbreviation, sport: override.sport})}>
											{override.sport + '-' + override.abbreviation}
										</button>
									);
								})}
							</div>
							{teamValue !== null && (
								<BoxPreview
									teamObj={props.getTeamObj(teamValue.team, teamValue.sport)}
								/>
							)}
							{teamValue !== null && (
								<TeamEdit
									user={props.user}
									teamValue={teamValue}
									getTeamObj={props.getTeamObj}
									getTeam={props.getTeam}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}
