import axios from 'axios';
import {useState} from 'react';
import Modal from 'react-modal';
import {toast} from 'react-toastify';
import useModalStore from '../../stores/modal.store';
import useGlobalStore from '../../stores/global.store';

export default function CancelModal(props) {
	const [reason, setReason] = useState('');
	const { toggleModal } = useModalStore();
	const { debug } = useGlobalStore();

	const cancelPlan = (e) => {
		try {
			e.preventDefault();
			axios
				.post(`/users/cancel`, {})
				.then((response) => {
					if (response.status === 204) {
						toast.success('Subscription cancelled', {autoClose: 3000});
						toggleModal('cancel');
						axios
							.put(`/users/userCancelled/${props.user._id}`, {
								isActive: false,
								reason,
							})
							.then((response) => {
								localStorage.setItem('token', response.data.data.token);
								props.updateUser(response.data.data.token);
							})
							.catch((error) => {
								axios.post(`/users/sendRequest`, {
									email: props.user.email,
									subject: 'Cancellation Error',
									message: JSON.stringify(error),
								});
							});
					}
				})
				.catch((error) => {
					if (debug) {
						console.log(error);
					}
					toast.error('Something went wrong. Please reach out to greg@statlists.com', {autoClose: 5000});
					toggleModal('cancel');
					axios.post(`/users/sendRequest`, {
						email: props.user.email,
						subject: 'Cancellation Error',
						message: JSON.stringify(error),
					});
				});
		} catch (e) {
			if (debug) {
				console.log(e);
			}
		}
	};

	return (
		<Modal
			isOpen={true}
			onRequestClose={() => toggleModal('cancel')}>
			<div className='modal'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>Cancel Subscription</h5>
							<span
								className='close-modal'
								onClick={() => toggleModal('cancel')}>
								X
							</span>
						</div>
						<div className='modal-body'>
							<p>
								Are you sure you want to cancel? Please note that you will <strong>lose access immediately.</strong>
							</p>
							<form onSubmit={cancelPlan}>
								<div className='form-group'>
									<label className='form-label'>Reason for cancellation:</label>
									<textarea
										className='form-control'
										required
										maxLength='128'
										onChange={(e) => {
											setReason(e.target.value);
										}}
									/>
								</div>
								<div className='form-check'>
									<input
										type='checkbox'
										required
										className='form-check-input'
										id='confirmcheck'
									/>
									<label
										className='form-check-label'
										htmlFor='confirmcheck'>
										By checking this box, you acknowledge that you will lose access immediately.
									</label>
								</div>
								<div className='modal-footer'>
									<button
										type='submit'
										className='btn btn-primary secondary'>
										Confirm Cancellation
									</button>
									<button
										type='button'
										onClick={() => toggleModal('cancel')}
										className='btn btn-primary'
										data-dismiss='modal'>
										Nevermind
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}
