import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import { quick1 } from '../../data/settings/quick1';
import { quick2 } from '../../data/settings/quick2';
import { quick3 } from '../../data/settings/quick3';
import { quick4 } from '../../data/settings/quick4';
import { quick5 } from '../../data/settings/quick5';
import { quick6 } from '../../data/settings/quick6';
import { quick7 } from '../../data/settings/quick7';
import { quick8 } from '../../data/settings/quick8';
import { quick9 } from '../../data/settings/quick9';
import useSettingsStore from '../../stores/settings.store';
import useModalStore from '../../stores/modal.store';
import usePostStore from '../../stores/posts/base-post.store';
import useUserStore from '../../stores/user.store';

export default function QuickSettingsModal(props) {
	const [isNew, setIsNew] = useState(false);
	const { settings } = useSettingsStore();
	const { toggleModal } = useModalStore();
	const { setRanks } = usePostStore();
	const { allSettings, setAllSettings } = useUserStore();

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const welcome = urlParams.get('welcome');
		if (welcome) {
			setIsNew(true);
			window.history.replaceState(null, '', window.location.pathname);
		}
	}, []);

	const selectSettings = async (quick) => {
		let newSettings = [];
		if (quick === 'quick1') newSettings = JSON.parse(JSON.stringify(quick1));
		else if (quick === 'quick2') newSettings = JSON.parse(JSON.stringify(quick2));
		else if (quick === 'quick3') newSettings = JSON.parse(JSON.stringify(quick3));
		else if (quick === 'quick4') newSettings = JSON.parse(JSON.stringify(quick4));
		else if (quick === 'quick5') newSettings = JSON.parse(JSON.stringify(quick5));
		else if (quick === 'quick6') newSettings = JSON.parse(JSON.stringify(quick6));
		else if (quick === 'quick7') newSettings = JSON.parse(JSON.stringify(quick7));
		else if (quick === 'quick8') newSettings = JSON.parse(JSON.stringify(quick8));
		else if (quick === 'quick9') newSettings = JSON.parse(JSON.stringify(quick9));
		newSettings.type = settings.type;
		await props.setSettings(newSettings);
		toggleModal('quick-settings');
		if (quick === 'quick2' || quick === 'quick4' || quick === 'quick6' || quick === 'quick8') {
			setRanks([]);
		} else {
			props.orderList();
		}
		if (isNew) {
			// save settings as default
			axios
				.post(`/settings/saveSettings`, {
					email: props.user.email,
					user_id: props.user._id,
					settings: newSettings,
					title: null,
				})
				.then((response) => {
					setAllSettings([...allSettings, response.data[0]]);
				});
		}
	};

	return (
		<Modal
			isOpen={true}
			onRequestClose={() => toggleModal('quick-settings')}>
			<div className='modal quick-settings'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>{isNew ? 'Welcome to Stat Lists!' : 'Quick Settings'}</h5>
							<span
								className='close-modal'
								onClick={() => toggleModal('quick-settings')}>
								X
							</span>
						</div>
						<div className='modal-body'>
							{isNew ? 'To start, select a style that you like (this can always be modified later).' : 'Select a style:'}
							<div className='heroImages'>
								<img
									className='heroImage'
									src='/quick1.jpeg'
									alt='quick1'
									onClick={() => selectSettings('quick1')}
								/>
								<img
									className='heroImage'
									src='/quick2.jpeg'
									alt='quick2'
									onClick={() => selectSettings('quick2')}
								/>
								<img
									className='heroImage'
									src='/quick3.jpeg'
									alt='quick3'
									onClick={() => selectSettings('quick3')}
								/>
								<img
									className='heroImage'
									src='/quick4.jpeg'
									alt='quick4'
									onClick={() => selectSettings('quick4')}
								/>
								<img
									className='heroImage'
									src='/quick5.jpeg'
									alt='quick5'
									onClick={() => selectSettings('quick5')}
								/>
								<img
									className='heroImage'
									src='/quick6.jpeg'
									alt='quick6'
									onClick={() => selectSettings('quick6')}
								/>
								<img
									className='heroImage'
									src='/quick7.jpeg'
									alt='quick7'
									onClick={() => selectSettings('quick7')}
								/>
								<img
									className='heroImage'
									src='/quick8.jpeg'
									alt='quick8'
									onClick={() => selectSettings('quick8')}
								/>
								<img
									className='heroImage'
									src='/quick9.jpeg'
									alt='quick9'
									onClick={() => selectSettings('quick9')}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}
