import {useEffect, useState} from 'react';
import Select from 'react-select';
import {Popover} from 'react-tiny-popover';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import {fontOptions} from '../data/styles/fontOptions';
import {settingsStyles} from '../data/styles/settingsStyles';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {settingsStylesDark} from '../data/styles/settingsStylesDark';
import { ChromePicker } from './editors/ChromePicker';
import usePreferencesStore from '../stores/preferences.store';
import usePostStore from '../stores/posts/base-post.store';
import useGlobalStore from '../stores/global.store';

export default function FloatingRichEditor(props) {
	const [popovers, setPopovers] = useState([]);
	const [selectedOption, setSelectedOption] = useState({value: 'roboto condensed', label: 'Roboto Condensed', className: 'robotocondensed'});
	const { darkMode } = usePreferencesStore();
	const { floatingTexts, setFloatingTexts } = usePostStore();
	const { openEditors } = useGlobalStore();

	useEffect(() => {
		setSelectedOption(fontOptions.find((opt) => opt.value === floatingTexts[openEditors].font));
	}, [floatingTexts]);

	const duplicateTextBox = () => {
		let copy = {...floatingTexts[openEditors]};
		copy.yval = copy.yval + 50;
		let floatingTextsCopy = [...floatingTexts];
		floatingTextsCopy.push(copy);
		setFloatingTexts(floatingTextsCopy);
	};

	const deleteTextBox = () => {
		let floatingTextsCopy = [...floatingTexts];
		floatingTextsCopy.splice(openEditors, 1);
		setFloatingTexts(floatingTextsCopy);
	};

	const togglePopover = (e, popover) => {
		let name = e ? e.target.getAttribute('data-popover') : popover;
		if (!popovers.includes(name)) {
			let popoversCopy = [...popovers];
			popoversCopy.push(name);
			setPopovers(popoversCopy);
		} else {
			let popoversCopy = [...popovers];
			const index = popoversCopy.indexOf(name);
			if (index >= 0) {
				popoversCopy.splice(index, 1);
				setPopovers(popoversCopy);
			}
		}
	};

	const colorChange = (color, property) => {
		let floatingTextsCopy = [...floatingTexts];
		floatingTextsCopy[openEditors][property] = color;
		setFloatingTexts(floatingTextsCopy);
	};

	const fontChange = (selectedOption, property) => {
		let floatingTextsCopy = [...floatingTexts];
		floatingTextsCopy[openEditors][property] = selectedOption.value;
		setFloatingTexts(floatingTextsCopy);
		setSelectedOption(selectedOption);
	};

	const sliderChange = (value, property) => {
		let floatingTextsCopy = [...floatingTexts];
		floatingTextsCopy[openEditors][property] = value;
		setFloatingTexts(floatingTextsCopy);
	};

	return (
		<div className='floating-rich-editor'>
			<span className='editor-dropdown'>
				<Select
					value={selectedOption}
					onChange={(selectedOption) => fontChange(selectedOption, `font`)}
					options={fontOptions}
					styles={darkMode ? settingsStylesDark : settingsStyles}
				/>
			</span>
			<span className='editor-input'>
				<Tippy content={<span>Decrease Font Size</span>}>
				<span
					className='subtract-one'
					onClick={() => {
						let floatingTextsCopy = [...floatingTexts];
						floatingTextsCopy[openEditors].fontSize = Number(floatingTextsCopy[openEditors].fontSize) - 1;
						setFloatingTexts(floatingTextsCopy);
					}}>
					-
				</span>
				</Tippy>
				<Tippy content={<span>Font Size</span>}>
				<input
					type='text'
					value={floatingTexts[openEditors].fontSize}
					onChange={(e) => {
						let floatingTextsCopy = [...floatingTexts];
						floatingTextsCopy[openEditors].fontSize = Number(e.target.value);
						setFloatingTexts(floatingTextsCopy);
					}}></input>
				</Tippy>
				<Tippy content={<span>Increase Font Size</span>}>
				<span
					className='add-one'
					onClick={() => {
						let floatingTextsCopy = [...floatingTexts];
						floatingTextsCopy[openEditors].fontSize = Number(floatingTextsCopy[openEditors].fontSize) + 1;
						setFloatingTexts(floatingTextsCopy);
					}}>
					+
				</span>
				</Tippy>
			</span>
			<Tippy content={<span>Text Color</span>}>
			<span className='editor-button'>
				<Popover
					isOpen={popovers.includes('popover2')}
					reposition={true}
					containerStyle={{'z-index': '1000'}}
					onClickOutside={() => togglePopover(null, 'popover2')}
					positions={['bottom', 'top', 'right', 'left']}
					content={
						<div className='popover-content color-picker'>
							<ChromePicker
								color={floatingTexts[openEditors].fontColor}
								onChange={(color) => colorChange(color, 'fontColor')}
							/>
						</div>
					}>
					<span
						className='colorbox-editor'
						data-popover='popover2'
						onClick={togglePopover}>
						<span className='colorbox-letter'>A</span>
						<span className='rainbow-bar'></span>
					</span>
				</Popover>
			</span>
			</Tippy>
			<Tippy content={<span>Spacing</span>}>
			<span className='editor-button'>
				<Popover
					isOpen={popovers.includes('popover1')}
					reposition={true}
					containerStyle={{'z-index': '1000'}}
					onClickOutside={() => togglePopover(null, 'popover1')}
					positions={['bottom', 'top', 'right', 'left']}
					content={
						<div className='popover-content'>
							<span>
								<h5>Letter Spacing</h5>
								<Slider
									railStyle={{backgroundColor: 'lightgray'}}
									trackStyle={{backgroundColor: '#00a199'}}
									handleStyle={{borderColor: '#00a199'}}
									value={floatingTexts[openEditors].letterSpacing}
									onChange={(value) => sliderChange(value, `letterSpacing`)}
									min={-5}
									max={5}
								/>
							</span>
						</div>
					}>
					<span
						className='spacing-editor'
						data-popover='popover1'
						onClick={togglePopover}>
						<i className='fas fa-arrow-down-up-across-line no-click'></i>
					</span>
				</Popover>
			</span>
			</Tippy>
			<span>
				<Popover
					isOpen={popovers.includes('popover3')}
					reposition={true}
					containerStyle={{'z-index': '1000'}}
					onClickOutside={() => togglePopover(null, 'popover3')}
					positions={['bottom', 'top', 'right', 'left']}
					content={
						<div className='popover-content color-picker'>
							<h5>Text Shadow</h5>
							<Slider
								railStyle={{backgroundColor: 'lightgray'}}
								trackStyle={{backgroundColor: '#00a199'}}
								handleStyle={{borderColor: '#00a199'}}
								value={floatingTexts[openEditors].textShadow}
								onChange={(value) => sliderChange(value, `textShadow`)}
								min={-6}
								max={6}
							/>
							<ChromePicker
								color={floatingTexts[openEditors].shadowColor}
								onChange={(color) => colorChange(color, `shadowColor`)}
							/>
						</div>
					}>
					<Tippy content={<span>Text Shadow</span>}>
					<span
						className='editor-shadow editor-button'
						data-popover='popover3'
						onClick={togglePopover}>
						A
					</span>
					</Tippy>
				</Popover>
			</span>
			<span>
				<Tippy content={<span>Duplicate Text Box</span>}>
				<span
					className='editor-duplicate editor-button'
					onClick={duplicateTextBox}>
					<i className='fas fa-copy'></i>
				</span>
				</Tippy>
			</span>
			<span>
				<Tippy content={<span>Delete Text Box</span>}>
				<span
					className='editor-delete editor-button'
					onClick={deleteTextBox}>
					<i className='fa fa-trash'></i>
				</span>
				</Tippy>
			</span>
		</div>
	);
}
