
import { useState } from 'react';
import { uploadJpeg } from '../../../firebase/firebase';
import useWithImageStore from '../../../stores/posts/with-image.store';
import {toast} from 'react-toastify';

export default function FileUpload(props) {
    const [selectedFile, setSelectedFile] = useState(null);
	const [uploading, setUploading] = useState(false);
    const { setNewsImage } = useWithImageStore();

	const checkFileSize = (event) => {
		let file = event.target.files[0];
		if (file && file.size > 2097152) {
			toast.error('File is over 2 MB.  Please try a different file.');
			event.target.value = null;
			return false;
		}
		return true;
	};

	const fileSelected = (e) => {
		if (e.target.files[0] === undefined) {
			setSelectedFile(null);
		} else if (checkFileSize(e)) {
			setSelectedFile(e.target.files[0]);
		}
	};

	const imageSubmit = (e) => {
		e.preventDefault();
		setUploading(true);
		if (selectedFile) {
			const fileName = `/images/main-${props.user._id}${selectedFile.name}`;
			uploadJpeg(selectedFile, fileName)
				.then((url) => {
					setNewsImage(url);
					toast.success('Uploaded Successfully!', {autoClose: 1000});
				})
				.catch((error) => {
					toast.error('Upload failed. Please try again.');
					console.log(error);
				})
				.finally(() => {
					setUploading(false);
					setSelectedFile(null);
				});
		} else {
			toast.error('Please select a file first');
			setUploading(false);
		}
	};

    return (
        <div className='file-box'>
            <form onSubmit={imageSubmit}>
                <span className='uploadInfo'>Enter a link to an external image above or upload an image here.</span>
                <input
                    type='file'
                    className='file-input'
                    accept='image/*'
                    onChange={fileSelected}
                />
                {selectedFile && (
                    <input
                        id='upload-button'
                        type='submit'
                        value='Upload'
                        disabled={uploading}
                        className='upload-button newButton'
                    />
                )}
            </form>
        </div>
    );
}
