import axios from 'axios';
import {useState} from 'react';
import Modal from 'react-modal';
import {toast} from 'react-toastify';
import useModalStore from '../../stores/modal.store';
import useGlobalStore from '../../stores/global.store';
import useUserStore from '../../stores/user.store';

export default function DeleteTeamModal(props) {
	const { toggleModal } = useModalStore();
	const { pendingDelete, debug } = useGlobalStore();
	const { customTeams, setCustomTeams } = useUserStore();

	const deleteTeam = () => {
		const id = pendingDelete.id;
		try {
			axios.delete(`/custom/deleteCustomTeam/${id}`).then((response) => {
				if (response.status === 200) {
					const filteredTeams = customTeams.filter((teams) => teams._id !== id);
					setCustomTeams(filteredTeams);
					toggleModal('delete-team');
					toast.error('Team Deleted!', {autoClose: 3000});
				}
			});
		} catch (e) {
			if (debug) {
				console.log(e);
			}
		}
	};

	return (
		<Modal
			isOpen={true}
			onRequestClose={() => toggleModal('delete-team')}
			shouldCloseOnOverlayClick={true}
			shouldCloseOnEsc={true}>
			<div className='modal'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>Delete Custom Team</h5>
							<span
								className='close-modal'
								onClick={() => toggleModal('delete-team')}>
								X
							</span>
						</div>
						<div className='modal-body'>
							<p>Are you sure you want to delete {pendingDelete.abbreviation}?</p>
						</div>
						<div className='modal-footer'>
							<button
								type='button'
								onClick={() => toggleModal('delete-team')}
								className='btn btn-primary secondary'
								data-dismiss='modal'>
								Cancel
							</button>
							<button
								type='submit'
								onClick={deleteTeam}
								className='btn btn-primary'>
								Delete
							</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}
