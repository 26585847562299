import axios from 'axios';
import {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {toast} from 'react-toastify';
import useModalStore from '../../stores/modal.store';
import useUserStore from '../../stores/user.store';
import useGlobalStore from '../../stores/global.store';

export default function RenameModal(props) {
	const [name, setName] = useState('');
	const { toggleModal } = useModalStore();
	const { savedPosts, setSavedPosts } = useUserStore();
	const { renamePost, setRenamePost } = useGlobalStore();

	useEffect(() => {
		let title = renamePost.title ? renamePost.title : renamePost.newstitle ? renamePost.newstitle + ' - ' + renamePost.newsteam : 'Untitled';
		if (renamePost.settings.type === 'matchupimage') {
			title = `${renamePost.team.join(' - ')} Matchup`;
		}
		if (renamePost.savedTitle) {
			title = renamePost.savedTitle;
		}
		setName(title);
	}, []);

	const save = (e) => {
		e.preventDefault();
		toggleModal('rename');
		axios
			.put(`/posts/renamePost/${renamePost._id}`, {
				savedTitle: name
			})
			.then((response) => {
				if (response.status === 200) {
					toast.success('Post Updated!', {autoClose: 1500});
				}
				let postsCopy = [...savedPosts];
				let existingPost = postsCopy.find((post) => {
					return post._id === renamePost._id;
				});
				existingPost.savedTitle = name;
				setSavedPosts(postsCopy);
				setRenamePost({});
			});
	};

	const close = () => {
		setRenamePost({});
		toggleModal('rename');
	}

	return (
		<Modal
			isOpen={true}
			onRequestClose={() => close()}>
			<div className='modal'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>Rename Post</h5>
							<span
								className='close-modal'
								onClick={() => close()}>
								X
							</span>
						</div>
						<div className='modal-body'>
							<form onSubmit={save}>
								<div className='form-group'>
									<label className='form-label'>Title</label>
									<div className='input-group'>
										<input
											type='text'
											required
											maxLength='25'
											autoFocus
											className='form-control'
											value={name}
											onChange={(e) => {
												setName(e.target.value);
											}}
										/>
									</div>
								</div>
								<button
									type='submit'
									className='btn btn-primary btn-block'>
									Save
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}
