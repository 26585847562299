const mlbplayerstats = [
	{
		name: 'batting',
		displayName: 'Own Batting',
		labels: ['GP', 'AB', 'R', 'H', 'AVG', '2B', '3B', 'HR', 'RBI', 'TB', 'BB', 'SO', 'SB', 'OBP', 'SLG', 'OPS', 'WAR'],
		names: ['gamesPlayed', 'atBats', 'runs', 'hits', 'avg', 'doubles', 'triples', 'homeRuns', 'RBIs', 'totalBases', 'walks', 'strikeouts', 'stolenBases', 'onBasePct', 'slugAvg', 'OPS', 'WARBR'],
		displayNames: [
			'Games Played',
			'At Bats',
			'Runs',
			'Hits',
			'Batting Average',
			'Doubles',
			'Triples',
			'Home Runs',
			'Runs Batted In',
			'Total Bases',
			'Walks',
			'Strikeouts',
			'Stolen Bases',
			'On Base Percentage',
			'Slugging Percentage',
			'OBP Pct + SLG Pct',
			'Wins Above Replacement',
		],
		descriptions: [
			'Games Played',
			'The number of times a batter comes up to bat not counting walks and sacrifices.',
			'The number of runs scored.',
			'The number of hits.',
			'The average number of times the batter reached successfully on base due to a hit: hits / atBats',
			'The number of times that a batter reaches second base as the result of a hit without an error being committed.',
			'The number of hits that result in the batter reaching 3rd base without the aid of an error.',
			'The number of hits that allowed the batter to round the bases and score without the aid of an error of the defense.',
			"The number of runs scored by a batter's play.",
			'The number of bases calculated as 1 for a 1B, 2 for a 2B, 3 for a 3B and 4 for a HR.',
			'The number of times a batter reaches base on four balls',
			'The number of times a player strikes out.',
			'The number of bases stolen.',
			'The ratio of the number of times the batter reached base per plate appearnce: (hits + walks + hbp / plateAppearances)',
			'Slugging percentage is a metric that is meant to measuring how many bases the hitter safely advances to after making contact with the ball: (totalBases / atBats)',
			'The average calculated by adding up on-base percentage and slugging percentage.',
			'Wins Above Replacement.',
		],
	},
	{
		name: 'pitching',
		displayName: 'Own Pitching',
		labels: ['GP', 'GS', 'QS', 'ERA', 'W', 'L', 'SV', 'HLD', 'IP', 'H', 'ER', 'HR', 'BB', 'K', 'K/9', 'WHIP', 'WAR'],
		names: ['gamesPlayed', 'gamesStarted', 'qualityStarts', 'ERA', 'wins', 'losses', 'saves', 'holds', 'innings', 'hits', 'earnedRuns', 'homeRuns', 'walks', 'strikeouts', 'strikeoutsPerNineInnings', 'WHIP', 'WARBR'],
		displayNames: [
			'Games Played',
			'Games Started',
			'Quality Starts',
			'Earned Run Average',
			'Wins',
			'Losses',
			'Saves',
			'Holds',
			'Innings pitched',
			'Hits',
			'Earned runs',
			'Home Runs',
			'Walks',
			'Strikeouts',
			'Strikeouts Per 9 Innings',
			'Walks Plus Hits Per Inning Pitched',
			'Wins Above Replacement',
		],
		descriptions: [
			'Games Played',
			'The number of games started by a pitcher.',
			'The number of quality starts for a pitcher.',
			'The average number of runs a pitcher yields per 9 innings thrown',
			'The number of times the pitcher was attributed with a win',
			'The number of losses for a pitcher.',
			'The number of times a pitcher enters and finishes the game without surrendering the lead in a save opportunity.',
			'The number of times a pitcher enters the game with the lead, gets at least an out and leaves the game wihtout having given up the lead.',
			'The number of innings pitched.',
			'The number of hits surrendered by a pitcher.',
			'The number of earned runs surrendered by a pitcher.',
			'The number of times a pitcher has allowed a home run.',
			'The number of times a pitcher surrenders a base on balls.',
			'The number of times the pitcher struck out a batter.',
			'The average number of strikeouts for a pitcher per 9 innings.',
			'The number representing walks plus hits divided by innings pitched.',
			'Wins Above Replacement.',
		],
	},
	{
		name: 'fielding',
		displayName: 'Own Fielding',
		labels: ['GP', 'GS', 'FIP', 'TC', 'PO', 'A', 'FP', 'E', 'DP', 'RF', 'DWAR', 'PB', 'SBA', 'CS', 'CS%'],
		names: [
			'gamesPlayed',
			'gamesStarted',
			'fullInningsPlayed',
			'totalChances',
			'putouts',
			'assists',
			'fieldingPct',
			'errors',
			'doublePlays',
			'rangeFactor',
			'defWARBR',
			'passedBalls',
			'catcherStolenBasesAllowed',
			'catcherCaughtStealing',
			'catcherCaughtStealingPct',
		],
		displayNames: [
			'Games Played',
			'Games Started',
			'Full Innings',
			'Total Chances',
			'Putouts',
			'Assists',
			'Fielding Percentage',
			'Errors',
			'Double Plays',
			'Range Factor',
			'Defensive Wins Above Replacement',
			'Passed Balls',
			'Stolen Bases Allowed',
			'Caught Stealing',
			'Caught Stealing Percentage',
		],
		descriptions: [
			'Games Played',
			'The number of games started by a fielder.',
			'The number of fully completed innings by a fielder.',
			'The number of chances a fielder has to make an out calculated by assists plus putouts plus errors.',
			'The number of times the fielder tags or forces the out of a baserunner.',
			'The number of times a fielder is involved in an out when the fielder is not the putouter.',
			'The percentage of plays made by a fielder given the total number of chances.',
			'The number of times a fielder fails to make the play they are expected to make.',
			'The number of double plays a fielder has been a part of as either the assister or putouter.',
			'The estimation of how much field a fielder can cover.',
			'Defensive Wins Above Replacement.',
			'The number of times the ball is dropped or passes by the catcher.',
			'The number of stolen bases that a catcher allows.',
			'The number of times a catcher throws a baserunner trying to steal a base.',
			'The percentage of baserunners caught by the catcher given the total number of steal attempts.',
		],
	},
];

const nflplayerstats = [
	{
		name: 'general',
		displayName: 'Own General',
		labels: ['GP', 'FF', 'FR', 'FTD'],
		names: ['gamesPlayed', 'fumblesForced', 'fumblesRecovered', 'fumblesTouchdowns'],
		displayNames: ['Games Played', 'Forced Fumbles', 'Fumbles Recovered', 'Fumbles Touchdowns'],
		descriptions: ['Games Played', 'The total number of forced fumbles.', 'The number of fumbles recovered.', 'The number of times a fumbles is recovered and returned for a touchdown.'],
	},
	{
		name: 'passing',
		displayName: 'Own Passing',
		labels: ['CMP', 'ATT', 'CMP%', 'YDS', 'AVG', 'YDS/G', 'LNG', 'TD', 'INT', 'SACK', 'SYL', 'QBR', 'RTG', 'QBR'],
		names: ['completions', 'passingAttempts', 'completionPct', 'passingYards', 'yardsPerPassAttempt', 'passingYardsPerGame', 'longPassing', 'passingTouchdowns', 'interceptions', 'sacks', 'sackYardsLost', 'QBR', 'QBRating', 'adjQBR'],
		displayNames: [
			'Completions',
			'Passing Attempts',
			'Completion Percentage',
			'Passing Yards',
			'Yards Per Pass Attempt',
			'Passing Yards Per Game',
			'Longest Pass',
			'Passing Touchdowns',
			'Interceptions',
			'Total Sacks',
			'Sack Yards Lost',
			'Total QBR',
			'Passer Rating',
			'Adjusted QBR',
		],
		descriptions: [
			'The times a player completes a pass to another player who is eligible to catch a pass.',
			'The number of times a pass is attempted.',
			'The percentage of completed passes.',
			'The total passing yards.',
			'The average number yards per pass attempt.',
			'The number of passing yards per game.',
			'The longest pass play completed in terms of yards.',
			'The total number of passing touchdowns.',
			'The number of passes thrown that were intercepted by the opposing team.',
			'The total number of sacks.',
			'The amount of yards lost due to sacks.',
			'Total Quarterback Rating, which values quarterback on all play types on a 0-to-100 scale.',
			'Passer Rating',
			'Adjusted Quarterback Rating.',
		],
	},
	{
		name: 'rushing',
		displayName: 'Own Rushing',
		labels: ['ATT', 'YDS', 'AVG', 'LNG', 'BIG', 'TD', 'YDS/G', 'FUM', 'LST', 'FD'],
		names: ['rushingAttempts', 'rushingYards', 'yardsPerRushAttempt', 'longRushing', 'rushingBigPlays', 'rushingTouchdowns', 'rushingYardsPerGame', 'rushingFumbles', 'rushingFumblesLost', 'rushingFirstDowns'],
		displayNames: ['Rushing Attempts', 'Rushing Yards', 'Yards Per Rush Attempt', 'Long Rushing', '20+ Yard Rushing Plays', 'Rushing Touchdowns', 'Rushing Yards Per Game', 'Rushing Fumbles', 'Rushing Fumbles Lost', 'Rushing 1st downs'],
		descriptions: [
			'The total number of attempted rushes.',
			'The total rushing yards.',
			'The average number of yards per rush attempt.',
			'The amount of yards for the longest run.',
			'The number of rushing big plays gained.',
			'The total number of rushing touchdowns.',
			'The average number of rushing yards per game.',
			'The number of times there is a run and then the ball is fumbled.',
			'The number of times there is a run and then the ball is fumbled.',
			'The number of times a first down is picked up by a run.',
		],
	},
	{
		name: 'receiving',
		displayName: 'Own Receiving',
		labels: ['REC', 'TGTS', 'YDS', 'AVG', 'TD', 'LNG', 'BIG', 'YDS/G', 'FUM', 'LST', 'YAC', 'FD'],
		names: [
			'receptions',
			'receivingTargets',
			'receivingYards',
			'yardsPerReception',
			'receivingTouchdowns',
			'longReception',
			'receivingBigPlays',
			'receivingYardsPerGame',
			'receivingFumbles',
			'receivingFumblesLost',
			'receivingYardsAfterCatch',
			'receivingFirstDowns',
		],
		displayNames: [
			'Receptions',
			'Receiving Targets',
			'Receiving Yards',
			'Yards Per Reception',
			'Receiving Touchdowns',
			'Long Reception',
			'20+ Yard Receiving Plays',
			'Receiving Yards Per Game',
			'Receiving Fumbles',
			'Receiving Fumbles Lost',
			'Receiving Yards After Catch',
			'Receiving First Downs',
		],
		descriptions: [
			'The total number of receptions.',
			'The number of times a receiver is thrown to.',
			'The total receiving yards.',
			'The average number of yards per reception.',
			'The total number of receiving touchdowns.',
			'The amount of yards for the longest reception.',
			'The number of receiving big plays gained.',
			'The average number of receiving yards per game.',
			'The number of times a reception is made and then the ball is fumbled.',
			'The number of times a reception is made and the balled is fumbled and recovered by the opposing team.',
			'The number of yards gained after the catch has been made.',
			'The number of times a first down is picked up by a reception.',
		],
	},
	{
		name: 'defensive',
		displayName: 'Own Defensive',
		labels: ['SOLO', 'AST', 'TOT', 'SACK', 'SCKYDS', 'TFL', 'PD', 'LNG'],
		names: ['soloTackles', 'assistTackles', 'totalTackles', 'sacks', 'sackYards', 'tacklesForLoss', 'passesDefended', 'longInterception'],
		displayNames: ['Solo Tackles', 'Assist Tackles', 'Total Tackles', 'Sacks', 'Sack Yards', 'Tackles For Loss', 'Passes Defended', 'Long Interception'],
		descriptions: [
			'The number of times a tackle was made unassisted.',
			'The number of assists on tackles.',
			'The total number of tackles.',
			'The total number of sacks.',
			'The number of yards lost from sacks.',
			'The number of tackles that result in a loss of yardage.',
			'The total number of passes defended.',
			'The amount of yards of the longest interception return.',
		],
	},
	{
		name: 'defensiveinterceptions',
		displayName: 'Own Defensive Interceptions',
		labels: ['INT', 'YDS', 'TD'],
		names: ['interceptions', 'interceptionYards', 'interceptionTouchdowns'],
		displayNames: ['Interceptions', 'Interception Yards', 'Interception Touchdowns'],
		descriptions: ['The total number of interceptions.', 'The number of yards gained after an interception.', 'The number of times an interception is returned for a touchdown.'],
	},
	{
		name: 'scoring',
		displayName: 'Own Scoring',
		labels: ['RUSH', 'REC', 'RET', 'TD', 'FG', 'PAT', '2PT', 'PTS', 'TP/G'],
		names: ['rushingTouchdowns', 'receivingTouchdowns', 'returnTouchdowns', 'totalTouchdowns', 'fieldGoals', 'kickExtraPoints', 'totalTwoPointConvs', 'totalPoints', 'totalPointsPerGame'],
		displayNames: ['Rushing Touchdowns', 'Receiving Touchdowns', 'Return Touchdowns', 'Total Touchdowns', 'Field Goals', 'Kick Extra Points', 'Total Two Point Conversions', 'Total Points', 'Total Points Per Game'],
		descriptions: [
			'The total number of rushing touchdowns.',
			'The total number of receiving touchdowns.',
			'The total number of return touchdowns.',
			'The number of touchdowns scored in total.',
			'The number of field goals made.',
			'The number of extra points made.',
			'The number of times a 2-point conversion is successful.',
			'The number of total points scored.',
			'The number of points scored per game.',
		],
	},
	{
		name: 'returning',
		displayName: 'Own Returning',
		labels: ['ATT', 'YDS', 'AVG', 'LNG', 'TD', 'ATT', 'YDS', 'AVG', 'LNG', 'TD', 'FC'],
		names: ['kickReturns', 'kickReturnYards', 'yardsPerKickReturn', 'longKickReturn', 'kickReturnTouchdowns', 'puntReturns', 'puntReturnYards', 'yardsPerPuntReturn', 'longPuntReturn', 'puntReturnTouchdowns', 'puntReturnFairCatches'],
		displayNames: [
			'Kick Returns',
			'Kick Return Yards',
			'Yards Per Kick Return',
			'Long Kick Return',
			'Kick Return Touchdowns',
			'Punt Returns',
			'Punt Return Yards',
			'Yards Per Punt Return',
			'Long Punt Return',
			'Punt Return Touchdowns',
			'Punt Return Fair Catches',
		],
		descriptions: [
			'The number of kick returns.',
			'The total yardage of kick returns.',
			'The average number of yards per kick return.',
			'The longest kick return in yards.',
			'The number of kick return touchdowns.',
			'The number of punt returns.',
			'The total yardage of punt returns.',
			'The average number of yards per punt return.',
			'The longest punt return in yards.',
			'The number of punt return touchdowns.',
			'The number of punt return fair catches.',
		],
	},
	{
		name: 'kicking',
		displayName: 'Own Kicking',
		labels: ['FGM', 'FGA', 'FG%', 'LNG', 'FGM 1-19', 'FGM 20-29', 'FGM 30-39', 'FGM 40-49', '50+', 'FGA 1-19', 'FGA 20-29', 'FGA 30-39', 'FGA 40-49', 'FGA 50+', 'XPM', 'XPA', 'XP%'],
		names: [
			'fieldGoalsMade',
			'fieldGoalAttempts',
			'fieldGoalPct',
			'longFieldGoalMade',
			'fieldGoalsMade1_19',
			'fieldGoalsMade20_29',
			'fieldGoalsMade30_39',
			'fieldGoalsMade40_49',
			'fieldGoalsMade50',
			'fieldGoalAttempts1_19',
			'fieldGoalAttempts20_29',
			'fieldGoalAttempts30_39',
			'fieldGoalAttempts40_49',
			'fieldGoalAttempts50',
			'extraPointsMade',
			'extraPointAttempts',
			'extraPointPct',
		],
		displayNames: [
			'Field Goal Made',
			'Field Goal Attempts',
			'Field Goal Percentage',
			'Long Field Goal Made',
			'Field Goals Made 1-19',
			'Field Goals Made 20-29',
			'Field Goals Made 30-39',
			'Field Goals Made 40-49',
			'Field Goals Made 50+',
			'Field Goal Attempts 1-19',
			'Field Goal Attempts 20-29',
			'Field Goal Attempts 30-39',
			'Field Goal Attempts 40-49',
			'Field Goal Attempts 50+',
			'Extra Points Made',
			'Extra Point Attempts',
			'Extra Point Percentage',
		],
		descriptions: [
			'The number of times a field goal is made.',
			'The number of times a field goal is attempted.',
			'The percentage of field goals attempted that are made.',
			'The distance of the longest field goal made.',
			'The number of field goals made from 1-19 yards.',
			'The number of field goals made from 20-29 yards.',
			'The number of field goals made from 30-39 yards.',
			'The number of field goals made from 40-49 yards.',
			'The number of field goals made from 50+ yards.',
			'The number of field goals attempted from 1-19 yards.',
			'The number of field goals attempted from 20-29 yards.',
			'The number of field goals attempted from 30-39 yards.',
			'The number of field goals attempted from 40-49 yards.',
			'The number of field goals attempted from 50+ yards.',
			'The number of extra point attempts made.',
			'The number of extra point attempts.',
			'The percentage of extra point attempts that are converted.',
		],
	},
	{
		name: 'punting',
		displayName: 'Own Punting',
		labels: ['PUNTS', 'YDS', 'LNG', 'AVG', 'NET', 'PBLK', 'IN20', 'TB', 'FC', 'ATT', 'YDS', 'AVG'],
		names: ['punts', 'puntYards', 'longPunt', 'grossAvgPuntYards', 'netAvgPuntYards', 'puntsBlocked', 'puntsInside20', 'touchbacks', 'fairCatches', 'puntReturns', 'puntReturnYards', 'avgPuntReturnYards'],
		displayNames: ['Punts', 'Punt Yards', 'Long Punt', 'Gross Average Punt Yards', 'Net Average Punt Yards', 'Punts Blocked', 'Punts Inside 20', 'Touchbacks', 'Fair Catches', 'Punt Returns', 'Punt Return Yards', 'Average Punt Return Yards'],
		descriptions: [
			'The number of punts taken.',
			'The total amount of yardage of punts.',
			'The distance of the longest punt.',
			'The average gross number of yards per punt.',
			'The average net number of yards per punt.',
			'The number of times a punt was blocked.',
			'The number of times a punt is downed inside the 20.',
			'The number of kickoffs that result in touchbacks.',
			'The number of fair catches.',
			'The number of times a punt was returned.',
			'The total amount of yardage of punt returns.',
			'The average number of yards per punt return.',
		],
	},
];

const nbaplayerstats = [
	{
		name: 'general',
		displayName: 'Own General',
		labels: ['GP', 'MIN', 'PF', 'FLAG', 'TECH', 'EJECT', 'DD2', 'TD3', 'PER', 'MIN', 'REB', 'PF', 'REB'],
		names: ['gamesPlayed', 'avgMinutes', 'avgFouls', 'flagrantFouls', 'technicalFouls', 'ejections', 'doubleDouble', 'tripleDouble', 'PER', 'minutes', 'rebounds', 'fouls', 'avgRebounds'],
		displayNames: ['Games Played', 'Minutes Per Game', 'Fouls Per Game', 'Flagrant Fouls', 'Technical Fouls', 'Ejections', 'Double Double', 'Triple Double', 'Player Efficiency Rating', 'Minutes', 'Rebounds', 'Fouls', 'Rebounds Per Game'],
		descriptions: [
			'Games Played',
			'The average number of minutes per game.',
			'The average fouls committed per game.',
			'The number of fouls that the officials thought were unnecessary or excessive.',
			'The number of times an player or coach was called for a technical foul (unsportsmanlike conduct or violations).',
			'The number of times a player or coach is removed from the game as a result of a serious offense.',
			'The number of times double digit values were accumulated in 2 of the following categories: points, rebounds, assists, steals, and blocked shots',
			'The number of times double digit values were accumulated in 3 of the following categories: points, rebounds, assists, steals, and blocked shots',
			"A numerical value for each of a player's accomplishments per-minute and is pace-adjusted for the team they play on. The league average in PER to 15.00 every season.",
			'The total number of minutes played.',
			'The total number of rebounds (offensive and defensive).',
			'The number of times a player had illegal contact with the opponent.',
			'The average rebounds per game.',
		],
	},
	{
		name: 'offensive',
		displayName: 'Own Offensive',
		labels: ['PTS', 'FGM', 'FGA', 'FG%', '3PM', '3PA', '3P%', 'FTM', 'FTA', 'FT%', 'AST', 'TO', 'PTS', 'FGM', 'FGA', '3PM', '3PA', 'FTM', 'FTA', 'AST', 'TO'],
		names: [
			'avgPoints',
			'avgFieldGoalsMade',
			'avgFieldGoalsAttempted',
			'fieldGoalPct',
			'avgThreePointFieldGoalsMade',
			'avgThreePointFieldGoalsAttempted',
			'threePointFieldGoalPct',
			'avgFreeThrowsMade',
			'avgFreeThrowsAttempted',
			'freeThrowPct',
			'avgAssists',
			'avgTurnovers',
			'points',
			'fieldGoalsMade',
			'fieldGoalsAttempted',
			'threePointFieldGoalsMade',
			'threePointFieldGoalsAttempted',
			'freeThrowsMade',
			'freeThrowsAttempted',
			'assists',
			'turnovers',
		],
		displayNames: [
			'Points Per Game',
			'Average Field Goals Made',
			'Average Field Goals Attempted',
			'Field Goal Percentage',
			'Average 3-Point Field Goals Made',
			'Average 3-Point Field Goals Attempted',
			'3-Point Field Goal Percentage',
			'Average Free Throws Made',
			'Average Free Throws Attempted',
			'Free Throw Percentage',
			'Assists Per Game',
			'Turnovers Per Game',
			'Points',
			'Field Goals Made',
			'Field Goals Attempted',
			'3-Point Field Goals Made',
			'3-Point Field Goals Attempted',
			'Free Throws Made',
			'Free Throws Attempted',
			'Assists',
			'Turnovers',
		],
		descriptions: [
			'The average number of points scored per game.',
			'The average field goals made per game.',
			'The average field goals attempted per game.',
			'The ratio of field goals made to field goals attempted: FGM / FGA',
			'The average three point field goals made per game.',
			'The average three point field goals attempted per game.',
			'The ratio of 3pt field goals made to 3pt field goals attempted: 3PM / 3PA.',
			'The average free throw shots made per game.',
			'The average free throw shots attempted per game.',
			'The ratio of free throws made to free throws attempted: FTM / FTA',
			'The average assists per game.',
			'The average turnovers committed per game.',
			'The number of points scored.',
			'The number of times a 2pt field goal was made.',
			'The number of times a 2pt field goal was attempted.',
			'The number of times a 3pt field goal was made.',
			'The number of times a 3pt field goal was attempted.',
			'The number of times a free throw was made.',
			'The number of times a free throw was attempted.',
			'The number of times a player who passes the ball to a teammate in a way that leads to a score by field goal, meaning that he or she was "assisting" in the basket. There is some judgment involved in deciding whether a passer should be credited with an assist.',
			'The number of times a player loses possession to the other team.',
		],
	},
	{
		name: 'defensive',
		displayName: 'Own Defensive',
		labels: ['STL', 'BLK', 'STL', 'BLK'],
		names: ['avgSteals', 'avgBlocks', 'steals', 'blocks'],
		displayNames: ['Steals Per Game', 'Blocks Per Game', 'Steals', 'Blocks'],
		descriptions: [
			'The average steals per game.',
			'The average blocks per game.',
			'The number of times a defensive player forced a turnover by intercepting or deflecting a pass or a dribble of an offensive player.',
			'Short for blocked shot, number of times when a defensive player legally deflects a field goal attempt from an offensive player.',
		],
	},
];

const nhlplayerstats = [
	{
		name: 'general',
		displayName: 'Own General',
		labels: ['GP', '+/-', 'TOI/G', 'SHFT', 'PROD', 'WINS', 'L', 'TOI'],
		names: ['games', 'plusMinus', 'timeOnIcePerGame', 'shifts', 'production', 'wins', 'losses', 'timeOnIce'],
		displayNames: ['Games Played', 'Plus/Minus Rating', 'Time On Ice Per Game', 'Shifts', 'Production', 'Wins', 'Losses', 'Time On Ice'],
		descriptions: ['Total games played.', 'Plus/Minus Rating', 'Total time on ice per game.', 'Total shifts.', 'Total production.', 'Wins', 'Losses', 'Total time on ice.'],
	},
	{
		name: 'offensive',
		displayName: 'Own Offensive',
		labels: ['G', 'A', 'PTS', 'PPG', 'PPA', 'S', 'SPCT', 'GWG', 'FW', 'FL', 'FO%', 'SOA', 'SOG', 'SOPCT'],
		names: ['goals', 'assists', 'points', 'powerPlayGoals', 'powerPlayAssists', 'shotsTotal', 'shootingPct', 'gameWinningGoals', 'faceoffsWon', 'faceoffsLost', 'faceoffPercent', 'shootoutAttempts', 'shootoutGoals', 'shootoutShotPct'],
		displayNames: [
			'Goals',
			'Assists',
			'Points',
			'Power Play Goals',
			'Power Play Assists',
			'Shots',
			'Shooting Percentage',
			'Game-Winning Goals',
			'Faceoffs Won',
			'Faceoffs Lost',
			'Faceoff Win Percent',
			'Shootout Attempts',
			'Shootout Goals',
			'Shootout Shot Percentage',
		],
		descriptions: [
			'Total goals scored.',
			'Total goal assists.',
			'Points',
			'Total power play goals.',
			'Total power play assists.',
			'Total shots.',
			'Percentage of shots scored.',
			'Total game-winning goals',
			'Total faceoffs won.',
			'Total faceoffs lost.',
			'Percentage of faceoffs won.',
			'Total shootout attempts.',
			'Total shootout goals.',
			'Percentage of shootout goals made.',
		],
	},
	{
		name: 'defensive',
		displayName: 'Own Defensive',
		labels: ['OTL', 'GA/G', 'SA', 'GA', 'SV', 'SV%', 'SOSA', 'SOS', 'SOSPCT', 'SO'],
		names: ['overtimeLosses', 'avgGoalsAgainst', 'shotsAgainst', 'goalsAgainst', 'saves', 'savePct', 'shootoutShotsAgainst', 'shootoutSaves', 'shootoutSavePct', 'shutouts'],
		displayNames: ['Overtime Losses', 'Goals Against per Game', 'Shots Against', 'Goals Against', 'Saves', 'Save Percentage', 'Shootout Shots Against', 'Shootout Saves', 'Shootout Save Percentage', 'Shutouts'],
		descriptions: [
			'Total games lost in overtime.',
			'Average goals against per game.',
			'Total shots against.',
			'Total goals against.',
			'Total saves.',
			'Percentage of shots saved.',
			'Total shootout shots against.',
			'Total shootout saves.',
			'Percentage of shootout shots saved.',
			'Total shutouts.',
		],
	},
	{
		name: 'penalties',
		displayName: 'Own Penalties',
		labels: ['PIM'],
		names: ['penaltyMinutes'],
		displayNames: ['Penalty Minutes'],
		descriptions: ['Total penalty minutes.'],
	},
];

const cfbplayerstats = [
	{
		name: 'general',
		displayName: 'Own General',
		labels: ['GP', 'FF', 'FR', 'FTD'],
		names: ['gamesPlayed', 'fumblesForced', 'fumblesRecovered', 'fumblesTouchdowns'],
		displayNames: ['Games Played', 'Forced Fumbles', 'Fumbles Recovered', 'Fumbles Touchdowns'],
		descriptions: ['Games Played', 'The total number of forced fumbles.', 'The number of fumbles recovered.', 'The number of times a fumbles is recovered and returned for a touchdown.'],
	},
	{
		name: 'passing',
		displayName: 'Own Passing',
		labels: ['CMP', 'ATT', 'CMP%', 'YDS', 'AVG', 'YDS/G', 'LNG', 'TD', 'INT', 'SACK', 'SYL', 'QBR', 'RTG', 'QBR'],
		names: ['completions', 'passingAttempts', 'completionPct', 'passingYards', 'yardsPerPassAttempt', 'passingYardsPerGame', 'longPassing', 'passingTouchdowns', 'interceptions', 'sacks', 'sackYardsLost', 'QBR', 'QBRating', 'adjQBR'],
		displayNames: [
			'Completions',
			'Passing Attempts',
			'Completion Percentage',
			'Passing Yards',
			'Yards Per Pass Attempt',
			'Passing Yards Per Game',
			'Longest Pass',
			'Passing Touchdowns',
			'Interceptions',
			'Total Sacks',
			'Sack Yards Lost',
			'Total QBR',
			'Passer Rating',
			'Adjusted QBR',
		],
		descriptions: [
			'The times a player completes a pass to another player who is eligible to catch a pass.',
			'The number of times a pass is attempted.',
			'The percentage of completed passes.',
			'The total passing yards.',
			'The average number yards per pass attempt.',
			'The number of passing yards per game.',
			'The longest pass play completed in terms of yards.',
			'The total number of passing touchdowns.',
			'The number of passes thrown that were intercepted by the opposing team.',
			'The total number of sacks.',
			'The amount of yards lost due to sacks.',
			'Total Quarterback Rating, which values quarterback on all play types on a 0-to-100 scale.',
			'Passer Rating',
			'Adjusted Quarterback Rating.',
		],
	},
	{
		name: 'rushing',
		displayName: 'Own Rushing',
		labels: ['ATT', 'YDS', 'AVG', 'LNG', 'BIG', 'TD', 'YDS/G', 'FUM', 'LST', 'FD'],
		names: ['rushingAttempts', 'rushingYards', 'yardsPerRushAttempt', 'longRushing', 'rushingBigPlays', 'rushingTouchdowns', 'rushingYardsPerGame', 'rushingFumbles', 'rushingFumblesLost', 'rushingFirstDowns'],
		displayNames: ['Rushing Attempts', 'Rushing Yards', 'Yards Per Rush Attempt', 'Long Rushing', '20+ Yard Rushing Plays', 'Rushing Touchdowns', 'Rushing Yards Per Game', 'Rushing Fumbles', 'Rushing Fumbles Lost', 'Rushing 1st downs'],
		descriptions: [
			'The total number of attempted rushes.',
			'The total rushing yards.',
			'The average number of yards per rush attempt.',
			'The amount of yards for the longest run.',
			'The number of rushing big plays gained.',
			'The total number of rushing touchdowns.',
			'The average number of rushing yards per game.',
			'The number of times there is a run and then the ball is fumbled.',
			'The number of times there is a run and then the ball is fumbled.',
			'The number of times a first down is picked up by a run.',
		],
	},
	{
		name: 'receiving',
		displayName: 'Own Receiving',
		labels: ['REC', 'TGTS', 'YDS', 'AVG', 'TD', 'LNG', 'BIG', 'YDS/G', 'FUM', 'LST', 'YAC', 'FD'],
		names: [
			'receptions',
			'receivingTargets',
			'receivingYards',
			'yardsPerReception',
			'receivingTouchdowns',
			'longReception',
			'receivingBigPlays',
			'receivingYardsPerGame',
			'receivingFumbles',
			'receivingFumblesLost',
			'receivingYardsAfterCatch',
			'receivingFirstDowns',
		],
		displayNames: [
			'Receptions',
			'Receiving Targets',
			'Receiving Yards',
			'Yards Per Reception',
			'Receiving Touchdowns',
			'Long Reception',
			'20+ Yard Receiving Plays',
			'Receiving Yards Per Game',
			'Receiving Fumbles',
			'Receiving Fumbles Lost',
			'Receiving Yards After Catch',
			'Receiving First Downs',
		],
		descriptions: [
			'The total number of receptions.',
			'The number of times a receiver is thrown to.',
			'The total receiving yards.',
			'The average number of yards per reception.',
			'The total number of receiving touchdowns.',
			'The amount of yards for the longest reception.',
			'The number of receiving big plays gained.',
			'The average number of receiving yards per game.',
			'The number of times a reception is made and then the ball is fumbled.',
			'The number of times a reception is made and the balled is fumbled and recovered by the opposing team.',
			'The number of yards gained after the catch has been made.',
			'The number of times a first down is picked up by a reception.',
		],
	},
	{
		name: 'defensive',
		displayName: 'Own Defensive',
		labels: ['SOLO', 'AST', 'TOT', 'SACK', 'SCKYDS', 'TFL', 'PD', 'LNG'],
		names: ['soloTackles', 'assistTackles', 'totalTackles', 'sacks', 'sackYards', 'tacklesForLoss', 'passesDefended', 'longInterception'],
		displayNames: ['Solo Tackles', 'Assist Tackles', 'Total Tackles', 'Sacks', 'Sack Yards', 'Tackles For Loss', 'Passes Defended', 'Long Interception'],
		descriptions: [
			'The number of times a tackle was made unassisted.',
			'The number of assists on tackles.',
			'The total number of tackles.',
			'The total number of sacks.',
			'The number of yards lost from sacks.',
			'The number of tackles that result in a loss of yardage.',
			'The total number of passes defended.',
			'The amount of yards of the longest interception return.',
		],
	},
	{
		name: 'defensiveinterceptions',
		displayName: 'Own Defensive Interceptions',
		labels: ['INT', 'YDS', 'TD'],
		names: ['interceptions', 'interceptionYards', 'interceptionTouchdowns'],
		displayNames: ['Interceptions', 'Interception Yards', 'Interception Touchdowns'],
		descriptions: ['The total number of interceptions.', 'The number of yards gained after an interception.', 'The number of times an interception is returned for a touchdown.'],
	},
	{
		name: 'scoring',
		displayName: 'Own Scoring',
		labels: ['RUSH', 'REC', 'RET', 'TD', 'FG', 'PAT', '2PT', 'PTS', 'TP/G'],
		names: ['rushingTouchdowns', 'receivingTouchdowns', 'returnTouchdowns', 'totalTouchdowns', 'fieldGoals', 'kickExtraPoints', 'totalTwoPointConvs', 'totalPoints', 'totalPointsPerGame'],
		displayNames: ['Rushing Touchdowns', 'Receiving Touchdowns', 'Return Touchdowns', 'Total Touchdowns', 'Field Goals', 'Kick Extra Points', 'Total Two Point Conversions', 'Total Points', 'Total Points Per Game'],
		descriptions: [
			'The total number of rushing touchdowns.',
			'The total number of receiving touchdowns.',
			'The total number of return touchdowns.',
			'The number of touchdowns scored in total.',
			'The number of field goals made.',
			'The number of extra points made.',
			'The number of times a 2-point conversion is successful.',
			'The number of total points scored.',
			'The number of points scored per game.',
		],
	},
	{
		name: 'returning',
		displayName: 'Own Returning',
		labels: ['ATT', 'YDS', 'AVG', 'LNG', 'TD', 'ATT', 'YDS', 'AVG', 'LNG', 'TD', 'FC'],
		names: ['kickReturns', 'kickReturnYards', 'yardsPerKickReturn', 'longKickReturn', 'kickReturnTouchdowns', 'puntReturns', 'puntReturnYards', 'yardsPerPuntReturn', 'longPuntReturn', 'puntReturnTouchdowns', 'puntReturnFairCatches'],
		displayNames: [
			'Kick Returns',
			'Kick Return Yards',
			'Yards Per Kick Return',
			'Long Kick Return',
			'Kick Return Touchdowns',
			'Punt Returns',
			'Punt Return Yards',
			'Yards Per Punt Return',
			'Long Punt Return',
			'Punt Return Touchdowns',
			'Punt Return Fair Catches',
		],
		descriptions: [
			'The number of kick returns.',
			'The total yardage of kick returns.',
			'The average number of yards per kick return.',
			'The longest kick return in yards.',
			'The number of kick return touchdowns.',
			'The number of punt returns.',
			'The total yardage of punt returns.',
			'The average number of yards per punt return.',
			'The longest punt return in yards.',
			'The number of punt return touchdowns.',
			'The number of punt return fair catches.',
		],
	},
	{
		name: 'kicking',
		displayName: 'Own Kicking',
		labels: ['FGM', 'FGA', 'FG%', 'LNG', 'FGM 1-19', 'FGM 20-29', 'FGM 30-39', 'FGM 40-49', '50+', 'FGA 1-19', 'FGA 20-29', 'FGA 30-39', 'FGA 40-49', 'FGA 50+', 'XPM', 'XPA', 'XP%'],
		names: [
			'fieldGoalsMade',
			'fieldGoalAttempts',
			'fieldGoalPct',
			'longFieldGoalMade',
			'fieldGoalsMade1_19',
			'fieldGoalsMade20_29',
			'fieldGoalsMade30_39',
			'fieldGoalsMade40_49',
			'fieldGoalsMade50',
			'fieldGoalAttempts1_19',
			'fieldGoalAttempts20_29',
			'fieldGoalAttempts30_39',
			'fieldGoalAttempts40_49',
			'fieldGoalAttempts50',
			'extraPointsMade',
			'extraPointAttempts',
			'extraPointPct',
		],
		displayNames: [
			'Field Goal Made',
			'Field Goal Attempts',
			'Field Goal Percentage',
			'Long Field Goal Made',
			'Field Goals Made 1-19',
			'Field Goals Made 20-29',
			'Field Goals Made 30-39',
			'Field Goals Made 40-49',
			'Field Goals Made 50+',
			'Field Goal Attempts 1-19',
			'Field Goal Attempts 20-29',
			'Field Goal Attempts 30-39',
			'Field Goal Attempts 40-49',
			'Field Goal Attempts 50+',
			'Extra Points Made',
			'Extra Point Attempts',
			'Extra Point Percentage',
		],
		descriptions: [
			'The number of times a field goal is made.',
			'The number of times a field goal is attempted.',
			'The percentage of field goals attempted that are made.',
			'The distance of the longest field goal made.',
			'The number of field goals made from 1-19 yards.',
			'The number of field goals made from 20-29 yards.',
			'The number of field goals made from 30-39 yards.',
			'The number of field goals made from 40-49 yards.',
			'The number of field goals made from 50+ yards.',
			'The number of field goals attempted from 1-19 yards.',
			'The number of field goals attempted from 20-29 yards.',
			'The number of field goals attempted from 30-39 yards.',
			'The number of field goals attempted from 40-49 yards.',
			'The number of field goals attempted from 50+ yards.',
			'The number of extra point attempts made.',
			'The number of extra point attempts.',
			'The percentage of extra point attempts that are converted.',
		],
	},
	{
		name: 'punting',
		displayName: 'Own Punting',
		labels: ['PUNTS', 'YDS', 'LNG', 'AVG', 'NET', 'PBLK', 'IN20', 'TB', 'FC', 'ATT', 'YDS', 'AVG'],
		names: ['punts', 'puntYards', 'longPunt', 'grossAvgPuntYards', 'netAvgPuntYards', 'puntsBlocked', 'puntsInside20', 'touchbacks', 'fairCatches', 'puntReturns', 'puntReturnYards', 'avgPuntReturnYards'],
		displayNames: ['Punts', 'Punt Yards', 'Long Punt', 'Gross Average Punt Yards', 'Net Average Punt Yards', 'Punts Blocked', 'Punts Inside 20', 'Touchbacks', 'Fair Catches', 'Punt Returns', 'Punt Return Yards', 'Average Punt Return Yards'],
		descriptions: [
			'The number of punts taken.',
			'The total amount of yardage of punts.',
			'The distance of the longest punt.',
			'The average gross number of yards per punt.',
			'The average net number of yards per punt.',
			'The number of times a punt was blocked.',
			'The number of times a punt is downed inside the 20.',
			'The number of kickoffs that result in touchbacks.',
			'The number of fair catches.',
			'The number of times a punt was returned.',
			'The total amount of yardage of punt returns.',
			'The average number of yards per punt return.',
		],
	},
];

const cbbplayerstats = [
	{
		name: 'general',
		displayName: 'Own General',
		labels: ['GP', 'MIN', 'PF', 'FLAG', 'TECH', 'EJECT', 'DD2', 'TD3', 'PER', 'MIN', 'REB', 'PF', 'REB'],
		names: ['gamesPlayed', 'avgMinutes', 'avgFouls', 'flagrantFouls', 'technicalFouls', 'ejections', 'doubleDouble', 'tripleDouble', 'PER', 'minutes', 'rebounds', 'fouls', 'avgRebounds'],
		displayNames: ['Games Played', 'Minutes Per Game', 'Fouls Per Game', 'Flagrant Fouls', 'Technical Fouls', 'Ejections', 'Double Double', 'Triple Double', 'Player Efficiency Rating', 'Minutes', 'Rebounds', 'Fouls', 'Rebounds Per Game'],
		descriptions: [
			'Games Played',
			'The average number of minutes per game.',
			'The average fouls committed per game.',
			'The number of fouls that the officials thought were unnecessary or excessive.',
			'The number of times an player or coach was called for a technical foul (unsportsmanlike conduct or violations).',
			'The number of times a player or coach is removed from the game as a result of a serious offense.',
			'The number of times double digit values were accumulated in 2 of the following categories: points, rebounds, assists, steals, and blocked shots',
			'The number of times double digit values were accumulated in 3 of the following categories: points, rebounds, assists, steals, and blocked shots',
			"A numerical value for each of a player's accomplishments per-minute and is pace-adjusted for the team they play on. The league average in PER to 15.00 every season.",
			'The total number of minutes played.',
			'The total number of rebounds (offensive and defensive).',
			'The number of times a player had illegal contact with the opponent.',
			'The average rebounds per game.',
		],
	},
	{
		name: 'offensive',
		displayName: 'Own Offensive',
		labels: ['PTS', 'FGM', 'FGA', 'FG%', '3PM', '3PA', '3P%', 'FTM', 'FTA', 'FT%', 'AST', 'TO', 'PTS', 'FGM', 'FGA', '3PM', '3PA', 'FTM', 'FTA', 'AST', 'TO'],
		names: [
			'avgPoints',
			'avgFieldGoalsMade',
			'avgFieldGoalsAttempted',
			'fieldGoalPct',
			'avgThreePointFieldGoalsMade',
			'avgThreePointFieldGoalsAttempted',
			'threePointFieldGoalPct',
			'avgFreeThrowsMade',
			'avgFreeThrowsAttempted',
			'freeThrowPct',
			'avgAssists',
			'avgTurnovers',
			'points',
			'fieldGoalsMade',
			'fieldGoalsAttempted',
			'threePointFieldGoalsMade',
			'threePointFieldGoalsAttempted',
			'freeThrowsMade',
			'freeThrowsAttempted',
			'assists',
			'turnovers',
		],
		displayNames: [
			'Points Per Game',
			'Average Field Goals Made',
			'Average Field Goals Attempted',
			'Field Goal Percentage',
			'Average 3-Point Field Goals Made',
			'Average 3-Point Field Goals Attempted',
			'3-Point Field Goal Percentage',
			'Average Free Throws Made',
			'Average Free Throws Attempted',
			'Free Throw Percentage',
			'Assists Per Game',
			'Turnovers Per Game',
			'Points',
			'Field Goals Made',
			'Field Goals Attempted',
			'3-Point Field Goals Made',
			'3-Point Field Goals Attempted',
			'Free Throws Made',
			'Free Throws Attempted',
			'Assists',
			'Turnovers',
		],
		descriptions: [
			'The average number of points scored per game.',
			'The average field goals made per game.',
			'The average field goals attempted per game.',
			'The ratio of field goals made to field goals attempted: FGM / FGA',
			'The average three point field goals made per game.',
			'The average three point field goals attempted per game.',
			'The ratio of 3pt field goals made to 3pt field goals attempted: 3PM / 3PA.',
			'The average free throw shots made per game.',
			'The average free throw shots attempted per game.',
			'The ratio of free throws made to free throws attempted: FTM / FTA',
			'The average assists per game.',
			'The average turnovers committed per game.',
			'The number of points scored.',
			'The number of times a 2pt field goal was made.',
			'The number of times a 2pt field goal was attempted.',
			'The number of times a 3pt field goal was made.',
			'The number of times a 3pt field goal was attempted.',
			'The number of times a free throw was made.',
			'The number of times a free throw was attempted.',
			'The number of times a player who passes the ball to a teammate in a way that leads to a score by field goal, meaning that he or she was "assisting" in the basket. There is some judgment involved in deciding whether a passer should be credited with an assist.',
			'The number of times a player loses possession to the other team.',
		],
	},
	{
		name: 'defensive',
		displayName: 'Own Defensive',
		labels: ['STL', 'BLK', 'STL', 'BLK'],
		names: ['avgSteals', 'avgBlocks', 'steals', 'blocks'],
		displayNames: ['Steals Per Game', 'Blocks Per Game', 'Steals', 'Blocks'],
		descriptions: [
			'The average steals per game.',
			'The average blocks per game.',
			'The number of times a defensive player forced a turnover by intercepting or deflecting a pass or a dribble of an offensive player.',
			'Short for blocked shot, number of times when a defensive player legally deflects a field goal attempt from an offensive player.',
		],
	},
];

const nflteamstats = [
	{
		name: 'general',
		labels: ['GP', 'FR', 'LST'],
		names: ['gamesPlayed', 'fumblesRecovered', 'fumblesLost'],
		displayNames: ['Games Played', 'Fumbles Recovered', 'Fumbles Lost'],
		descriptions: ['Games Played', 'The number of fumbles recovered.', 'The number of times a fumble is recovered by the opposing team'],
	},
	{
		name: 'passing',
		labels: ['TYDS', 'YDS/G', 'NYDS', 'NYDS/G', 'PTS', 'TP', 'CMP', 'YDS', 'YDS/G', 'ATT', 'CMP%', 'NTYDS', 'NTYDS/G', 'NYDS/PA', 'AVG', 'LNG', 'TD', 'INT', 'SACK', 'SYL', 'RTG'],
		names: [
			'totalYards',
			'yardsPerGame',
			'netPassingYards',
			'netPassingYardsPerGame',
			'totalPoints',
			'totalPointsPerGame',
			'completions',
			'passingYards',
			'passingYardsPerGame',
			'passingAttempts',
			'completionPct',
			'netTotalYards',
			'netYardsPerGame',
			'netYardsPerPassAttempt',
			'yardsPerPassAttempt',
			'longPassing',
			'passingTouchdowns',
			'interceptions',
			'sacks',
			'sackYardsLost',
			'QBRating',
		],
		displayNames: [
			'Total Yards',
			'Yards Per Game',
			'Net Passing Yards',
			'Net Passing Yards Per Game',
			'Total Points',
			'Total Points Per Game',
			'Completions',
			'Passing Yards',
			'Passing Yards Per Game',
			'Passing Attempts',
			'Completion Percentage',
			'Net Total Yards',
			'Net Yards Per Game',
			'Net Yards Per Pass Attempt',
			'Yards Per Pass Attempt',
			'Longest Pass',
			'Passing Touchdowns',
			'Interceptions',
			'Total Sacks',
			'Sack Yards Lost',
			'Passer Rating',
		],
		descriptions: [
			'The number of yards accumulated in total.',
			'The average number of yards per game.',
			'The amount of net passing yards.',
			'The amount of net passing yards per game.',
			'The number of total points scored.',
			'The number of points scored per game.',
			'The times a player completes a pass to another player who is eligible to catch a pass.',
			'The total passing yards.',
			'The number of passing yards per game.',
			'The number of times a pass is attempted.',
			'The percentage of completed passes.',
			'The amount of net total yards.',
			'The amount of net total yards per game.',
			'The average number net yards (taking sack yardage into account) per pass attempt.',
			'The average number yards per pass attempt.',
			'The longest pass play completed in terms of yards.',
			'The total number of passing touchdowns.',
			'The number of passes thrown that were intercepted by the opposing team.',
			'The total number of sacks.',
			'The amount of yards lost due to sacks.',
			'Passer Rating',
		],
	},
	{
		name: 'rushing',
		labels: ['YDS', 'YDS/G', 'ATT', 'YDS', 'AVG', 'YDS/G', 'LNG', 'TD', 'FUM', 'LST'],
		names: ['rushingYards', 'rushingYardsPerGame', 'rushingAttempts', 'rushingYards', 'yardsPerRushAttempt', 'rushingYardsPerGame', 'longRushing', 'rushingTouchdowns', 'rushingFumbles', 'rushingFumblesLost'],
		displayNames: ['Rushing Yards', 'Rushing Yards Per Game', 'Rushing Attempts', 'Rushing Yards', 'Yards Per Rush Attempt', 'Rushing Yards Per Game', 'Long Rushing', 'Rushing Touchdowns', 'Rushing Fumbles', 'Rushing Fumbles Lost'],
		descriptions: [
			'The total rushing yards.',
			'The average number of rushing yards per game.',
			'The total number of attempted rushes.',
			'The total rushing yards.',
			'The average number of yards per rush attempt.',
			'The average number of rushing yards per game.',
			'The amount of yards for the longest run.',
			'The total number of rushing touchdowns.',
			'The number of times there is a run and then the ball is fumbled.',
			'The number of times there is a run and then the ball is fumbled.',
		],
	},
	{
		name: 'receiving',
		labels: ['REC', 'YDS', 'AVG', 'YDS/G', 'LNG', 'TD', 'FUM', 'LST', 'YDS/G'],
		names: ['receptions', 'receivingYards', 'yardsPerReception', 'yardsPerGame', 'longReception', 'receivingTouchdowns', 'receivingFumbles', 'receivingFumblesLost', 'receivingYardsPerGame'],
		displayNames: ['Receptions', 'Receiving Yards', 'Yards Per Reception', 'Yards Per Game', 'Long Reception', 'Receiving Touchdowns', 'Receiving Fumbles', 'Receiving Fumbles Lost', 'Receiving Yards Per Game'],
		descriptions: [
			'The total number of receptions.',
			'The total receiving yards.',
			'The average number of yards per reception.',
			'The average number of yards per game.',
			'The amount of yards for the longest reception.',
			'The total number of receiving touchdowns.',
			'The number of times a reception is made and then the ball is fumbled.',
			'The number of times a reception is made and the balled is fumbled and recovered by the opposing team.',
			'The average number of receiving yards per game.',
		],
	},
	{
		name: 'miscellaneous',
		labels: ['CMP', 'FDR', 'FDP', 'FDPEN', '3RDC', '3RDA', '3RDC%', '4THC', 'FDA', '4THC%', 'TPEN', 'TPY', 'DIFF', 'TT', 'TGV'],
		names: [
			'firstDowns',
			'firstDownsRushing',
			'firstDownsPassing',
			'firstDownsPenalty',
			'thirdDownConvs',
			'thirdDownAttempts',
			'thirdDownConvPct',
			'fourthDownConvs',
			'fourthDownAttempts',
			'fourthDownConvPct',
			'totalPenalties',
			'totalPenaltyYards',
			'turnOverDifferential',
			'totalTakeaways',
			'totalGiveaways',
		],
		displayNames: [
			'Total 1st downs',
			'Rushing 1st downs',
			'Passing 1st downs',
			'1st downs by penalty',
			'Third Down Conversions',
			'Third Down Attempts',
			'3rd down %',
			'Fourth Down Conversions',
			'First Downs Attempts',
			'4th down %',
			'Total Penalties',
			'Total Penalty Yards',
			'Turnover Ratio',
			'Total Takeaways',
			'Total Giveaways',
		],
		descriptions: [
			'The the number of first downs.',
			'The number of times a rush results in a first down.',
			'The number of times a pass results in a first down.',
			'The number of times a penalty results in a first down.',
			'The number of 3rd down conversions.',
			'The number of 3rd down attempts.',
			'The percentage of 3rd downs that are converted.',
			'The number of times a fourth down is converted.',
			'The number of attempts at getting a first down.',
			'The percentage of fourth down attempts that are converted.',
			'The total number of total penalties.',
			'The total number of penalty yards.',
			'Difference between take-aways and give-aways',
			'The total number of takeaways.',
			'The total number of giveaways.',
		],
	},
	{
		name: 'returning',
		labels: ['ATT', 'YDS', 'AVG', 'LNG', 'TD', 'ATT', 'YDS', 'AVG', 'LNG', 'TD', 'FC'],
		names: ['kickReturns', 'kickReturnYards', 'yardsPerKickReturn', 'longKickReturn', 'kickReturnTouchdowns', 'puntReturns', 'puntReturnYards', 'yardsPerPuntReturn', 'longPuntReturn', 'puntReturnTouchdowns', 'puntReturnFairCatches'],
		displayNames: [
			'Kick Returns',
			'Kick Return Yards',
			'Yards Per Kick Return',
			'Long Kick Return',
			'Kick Return Touchdowns',
			'Punt Returns',
			'Punt Return Yards',
			'Yards Per Punt Return',
			'Long Punt Return',
			'Punt Return Touchdowns',
			'Punt Return Fair Catches',
		],
		descriptions: [
			'The number of kick returns.',
			'The total yardage of kick returns.',
			'The average number of yards per kick return.',
			'The longest kick return in yards.',
			'The number of kick return touchdowns.',
			'The number of punt returns.',
			'The total yardage of punt returns.',
			'The average number of yards per punt return.',
			'The longest punt return in yards.',
			'The number of punt return touchdowns.',
			'The number of punt return fair catches.',
		],
	},
	{
		name: 'kicking',
		labels: ['FGM', 'FGA', 'FG%', 'LNG', 'FGM 1-19', 'FGM 20-29', 'FGM 30-39', 'FGM 40-49', '50+', 'FGA 1-19', 'FGA 20-29', 'FGA 30-39', 'FGA 40-49', 'FGA 50+', 'XPM', 'XPA', 'XP%'],
		names: [
			'fieldGoalsMade',
			'fieldGoalAttempts',
			'fieldGoalPct',
			'longFieldGoalMade',
			'fieldGoalsMade1_19',
			'fieldGoalsMade20_29',
			'fieldGoalsMade30_39',
			'fieldGoalsMade40_49',
			'fieldGoalsMade50',
			'fieldGoalAttempts1_19',
			'fieldGoalAttempts20_29',
			'fieldGoalAttempts30_39',
			'fieldGoalAttempts40_49',
			'fieldGoalAttempts50',
			'extraPointsMade',
			'extraPointAttempts',
			'extraPointPct',
		],
		displayNames: [
			'Field Goal Made',
			'Field Goal Attempts',
			'Field Goal Percentage',
			'Long Field Goal Made',
			'Field Goals Made 1-19',
			'Field Goals Made 20-29',
			'Field Goals Made 30-39',
			'Field Goals Made 40-49',
			'Field Goals Made 50+',
			'Field Goal Attempts 1-19',
			'Field Goal Attempts 20-29',
			'Field Goal Attempts 30-39',
			'Field Goal Attempts 40-49',
			'Field Goal Attempts 50+',
			'Extra Points Made',
			'Extra Point Attempts',
			'Extra Point Percentage',
		],
		descriptions: [
			'The number of times a field goal is made.',
			'The number of times a field goal is attempted.',
			'The percentage of field goals attempted that are made.',
			'The distance of the longest field goal made.',
			'The number of field goals made from 1-19 yards.',
			'The number of field goals made from 20-29 yards.',
			'The number of field goals made from 30-39 yards.',
			'The number of field goals made from 40-49 yards.',
			'The number of field goals made from 50+ yards.',
			'The number of field goals attempted from 1-19 yards.',
			'The number of field goals attempted from 20-29 yards.',
			'The number of field goals attempted from 30-39 yards.',
			'The number of field goals attempted from 40-49 yards.',
			'The number of field goals attempted from 50+ yards.',
			'The number of extra point attempts made.',
			'The number of extra point attempts.',
			'The percentage of extra point attempts that are converted.',
		],
	},
	{
		name: 'punting',
		labels: ['PUNTS', 'YDS', 'LNG', 'AVG', 'NET', 'PBLK', 'IN20', 'TB', 'FC', 'ATT', 'YDS', 'AVG'],
		names: ['punts', 'puntYards', 'longPunt', 'grossAvgPuntYards', 'netAvgPuntYards', 'puntsBlocked', 'puntsInside20', 'touchbacks', 'fairCatches', 'puntReturns', 'puntReturnYards', 'avgPuntReturnYards'],
		displayNames: ['Punts', 'Punt Yards', 'Long Punt', 'Gross Average Punt Yards', 'Net Average Punt Yards', 'Punts Blocked', 'Punts Inside 20', 'Touchbacks', 'Fair Catches', 'Punt Returns', 'Punt Return Yards', 'Average Punt Return Yards'],
		descriptions: [
			'The number of punts taken.',
			'The total amount of yardage of punts.',
			'The distance of the longest punt.',
			'The average gross number of yards per punt.',
			'The average net number of yards per punt.',
			'The number of times a punt was blocked.',
			'The number of times a punt is downed inside the 20.',
			'The number of kickoffs that result in touchbacks.',
			'The number of fair catches.',
			'The number of times a punt was returned.',
			'The total amount of yardage of punt returns.',
			'The average number of yards per punt return.',
		],
	},
	{
		name: 'defensiveinterceptions',
		labels: ['INT'],
		names: ['interceptions'],
		displayNames: ['Interceptions'],
		descriptions: ['The total number of interceptions.'],
	},
];

const nbateamstats = [
	{
		name: 'general',
		labels: ['REB', 'REB', 'REB', 'AST/TO', 'TECH', 'REB', 'AST/TO', 'GP', 'PF', 'PF'],
		names: ['totalRebounds', 'avgRebounds', 'avgRebounds', 'teamAssistTurnoverRatio', 'technicalFouls', 'rebounds', 'assistTurnoverRatio', 'gamesPlayed', 'avgFouls', 'fouls'],
		displayNames: ['Rebounds', 'Rebounds Per Game', 'Rebounds Per Game', 'Assist To Turnover Ratio', 'Technical Fouls', 'Rebounds', 'Assist To Turnover Ratio', 'Games Played', 'Fouls Per Game', 'Fouls'],
		descriptions: [
			'The total number of rebounds for a team or player',
			'The average rebounds per game.',
			'The average rebounds per game.',
			'The number of assists per turnover for a team',
			'The number of times an player or coach was called for a technical foul (unsportsmanlike conduct or violations).',
			'The total number of rebounds (offensive and defensive).',
			'The average number of assists a player or team records per turnover',
			'Games Played',
			'The average fouls committed per game.',
			'The number of times a player had illegal contact with the opponent.',
		],
	},
	{
		name: 'defensive',
		labels: ['DR', 'DR', 'STL', 'BLK', 'BLK', 'STL'],
		names: ['defensiveRebounds', 'avgDefensiveRebounds', 'avgSteals', 'avgBlocks', 'blocks', 'steals'],
		displayNames: ['Defensive Rebounds', 'Defensive Rebounds Per Game', 'Steals Per Game', 'Blocks Per Game', 'Blocks', 'Steals'],
		descriptions: [
			'The number of times when the defense obtains the possession of the ball after a missed shot by the offense.',
			'The average defensive rebounds per game.',
			'The average steals per game.',
			'The average blocks per game.',
			'Short for blocked shot, number of times when a defensive player legally deflects a field goal attempt from an offensive player.',
			'The number of times a defensive player forced a turnover by intercepting or deflecting a pass or a dribble of an offensive player.',
		],
	},
	{
		name: 'offensive',
		labels: ['PTS', 'FG%', '3P%', 'FT%', 'TO', 'TO', 'FGM', 'FGA', '3PM', '3PA', 'FTM', 'FTA', 'OR', 'AST', 'PTS', 'FGM', 'FGA', '3PM', '3PA', 'FTM', 'FTA', 'OR', 'AST', 'OR%', 'SC-EFF', 'SH-EFF', '3P%'],
		names: [
			'avgPoints',
			'fieldGoalPct',
			'threePointFieldGoalPct',
			'freeThrowPct',
			'avgTurnovers',
			'turnovers',
			'avgFieldGoalsMade',
			'avgFieldGoalsAttempted',
			'avgThreePointFieldGoalsMade',
			'avgThreePointFieldGoalsAttempted',
			'avgFreeThrowsMade',
			'avgFreeThrowsAttempted',
			'avgOffensiveRebounds',
			'avgAssists',
			'points',
			'fieldGoalsMade',
			'fieldGoalsAttempted',
			'threePointFieldGoalsMade',
			'threePointFieldGoalsAttempted',
			'freeThrowsMade',
			'freeThrowsAttempted',
			'offensiveRebounds',
			'assists',
			'offensiveReboundPct',
			'scoringEfficiency',
			'shootingEfficiency',
			'threePointPct',
		],
		displayNames: [
			'Points Per Game',
			'Field Goal Percentage',
			'3-Point Field Goal Percentage',
			'Free Throw Percentage',
			'Turnovers Per Game',
			'Turnovers',
			'Average Field Goals Made',
			'Average Field Goals Attempted',
			'Average 3-Point Field Goals Made',
			'Average 3-Point Field Goals Attempted',
			'Average Free Throws Made',
			'Average Free Throws Attempted',
			'Offensive Rebounds Per Game',
			'Assists Per Game',
			'Points',
			'Field Goals Made',
			'Field Goals Attempted',
			'3-Point Field Goals Made',
			'3-Point Field Goals Attempted',
			'Free Throws Made',
			'Free Throws Attempted',
			'Offensive Rebounds',
			'Assists',
			'Offensive Rebound Percentage',
			'Scoring Efficiency',
			'Shooting Efficiency',
			'3-Point Field Goal Percentage',
		],
		descriptions: [
			'The average number of points scored per game.',
			'The ratio of field goals made to field goals attempted: FGM / FGA',
			'The ratio of 3pt field goals made to 3pt field goals attempted: 3PM / 3PA.',
			'The ratio of free throws made to free throws attempted: FTM / FTA',
			'The average turnovers committed per game.',
			'The number of times a player loses possession to the other team.',
			'The average field goals made per game.',
			'The average field goals attempted per game.',
			'The average three point field goals made per game.',
			'The average three point field goals attempted per game.',
			'The average free throw shots made per game.',
			'The average free throw shots attempted per game.',
			'The average offensive rebounds per game.',
			'The average assists per game.',
			'The number of points scored.',
			'The number of times a 2pt field goal was made.',
			'The number of times a 2pt field goal was attempted.',
			'The number of times a 3pt field goal was made.',
			'The number of times a 3pt field goal was attempted.',
			'The number of times a free throw was made.',
			'The number of times a free throw was attempted.',
			'The number of times when the offense obtains the possession of the ball after a missed shot.',
			'The number of times a player who passes the ball to a teammate in a way that leads to a score by field goal, meaning that he or she was "assisting" in the basket. There is some judgment involved in deciding whether a passer should be credited with an assist.',
			'The percentage of the number of times they obtain the possession of the ball after a missed shot.',
			'The efficiency with which a team or player scores the basketball.',
			'The efficiency with which a team or player shoots the basketball.',
			'The ratio of 3pt field goals made to 3pt field goals attempted: 3PM / 3PA.',
		],
	},
	{
		name: 'differential',
		labels: [
			'PTSDiff',
			'FGMDiff',
			'FGADiff',
			'3PMDiff',
			'3PADiff',
			'FTMDiff',
			'FTADiff',
			'TODiff',
			'REBDiff',
			'PTSDiff',
			'FGM',
			'FGADiff',
			'3PMDiff',
			'3PADiff',
			'FTMDiff',
			'FTADiff',
			'TODiff',
			'REBDiff',
			'FT%Diff',
			'SC-EFFDiff',
			'SH-EFFDiff',
			'FG%',
			'3P%Diff',
			'ORDiff',
			'DRDiff',
			'ASTDiff',
			'STLDiff',
			'BLKDiff',
			'PFDiff',
		],
		names: [
			'avgPointsDifferential',
			'avgFieldGoalsMadeDifferential',
			'avgFieldGoalsAttemptedDifferential',
			'avgThreePointFieldGoalsMadeDifferential',
			'avgThreePointFieldGoalsAttemptedDifferential',
			'avgFreeThrowsMadeDifferential',
			'avgFreeThrowsAttemptedDifferential',
			'avgTurnoversDifferential',
			'avgReboundsDifferential',
			'pointsDifferential',
			'fieldGoalsMadeDifferential',
			'fieldGoalsAttemptedDifferential',
			'threePointFieldGoalsMadeDifferential',
			'threePointFieldGoalsAttemptedDifferential',
			'freeThrowsMadeDifferential',
			'freeThrowsAttemptedDifferential',
			'turnoversDifferential',
			'reboundsDifferential',
			'freeThrowPctDifferential',
			'scoringEfficiencyDifferential',
			'shootingEfficiencyDifferential',
			'fieldGoalPctDifferential',
			'threePointFieldGoalPctDifferential',
			'avgOffensiveReboundsDifferential',
			'avgDefensiveReboundsDifferential',
			'avgAssistsDifferential',
			'avgStealsDifferential',
			'avgBlocksDifferential',
			'avgFoulsDifferential',
		],
		displayNames: [
			'Points Per Game Differential',
			'Average Field Goals Made Differential',
			'Average Field Goals Attempted Differential',
			'Average 3-Point Field Goals Made Differential',
			'Average 3-Point Field Goals Attempted Differential',
			'Average Free Throws Made Differential',
			'Average Free Throws Attempted Differential',
			'Turnovers Per Game Differential',
			'Rebounds Per Game Differential',
			'Points Differential',
			'Field Goals Made Differential',
			'Field Goals Attempted Differential',
			'3-Point Field Goals Made Differential',
			'3-Point Field Goals Attempted Differential',
			'Free Throws Made Differential',
			'Free Throws Attempted Differential',
			'Turnovers Differential',
			'Rebounds Differential',
			'Free Throw Percentage Differential',
			'Scoring Efficiency Differential',
			'Shooting Efficiency Differential',
			'Field Goal Percentage Differential',
			'3-Point Field Goal Percentage Differential',
			'Offensive Rebounds Per Game Differential',
			'Defensive Rebounds Per Game Differential',
			'Assists Per Game Differential',
			'Steals Per Game Differential',
			'Blocks Per Game Differential',
			'Fouls Per Game Differential',
		],
		descriptions: [
			'The average number of points scored per game. Differential',
			'The average field goals made per game. Differential',
			'The average field goals attempted per game. Differential',
			'The average three point field goals made per game. Differential',
			'The average three point field goals attempted per game. Differential',
			'The average free throw shots made per game. Differential',
			'The average free throw shots attempted per game. Differential',
			'The average turnovers committed per game. Differential',
			'The average rebounds per game. Differential',
			'The number of points scored. Differential',
			'The number of times a 2pt field goal was made. Differential',
			'The number of times a 2pt field goal was attempted. Differential',
			'The number of times a 3pt field goal was made. Differential',
			'The number of times a 3pt field goal was attempted. Differential',
			'The number of times a free throw was made. Differential',
			'The number of times a free throw was attempted. Differential',
			'The number of times a player loses possession to the other team. Differential',
			'The total number of rebounds (offensive and defensive). Differential',
			'The ratio of free throws made to free throws attempted: FTM / FTA Differential',
			'The efficiency with which a team or player scores the basketball. Differential',
			'The efficiency with which a team or player shoots the basketball. Differential',
			'The ratio of field goals made to field goals attempted: FGM / FGA Differential',
			'The ratio of 3pt field goals made to 3pt field goals attempted: 3PM / 3PA. Differential',
			'The average offensive rebounds per game. Differential',
			'The average defensive rebounds per game. Differential',
			'The average assists per game. Differential',
			'The average steals per game. Differential',
			'The average blocks per game. Differential',
			'The average fouls committed per game. Differential',
		],
	},
];

const mlbteamstats = [
	{
		name: 'batting',
		labels: ['GP', 'AB', 'R', 'H', '2B', '3B', 'HR', 'RBI', 'TB', 'BB', 'SO', 'SB', 'AVG', 'OBP', 'SLG', 'OPS'],
		names: ['gamesPlayed', 'atBats', 'runs', 'hits', 'doubles', 'triples', 'homeRuns', 'RBIs', 'totalBases', 'walks', 'strikeouts', 'stolenBases', 'avg', 'onBasePct', 'slugAvg', 'OPS'],
		displayNames: [
			'Games Played',
			'At Bats',
			'Runs',
			'Hits',
			'Doubles',
			'Triples',
			'Home Runs',
			'Runs Batted In',
			'Total Bases',
			'Walks',
			'Strikeouts',
			'Stolen Bases',
			'Batting Average',
			'On Base Percentage',
			'Slugging Percentage',
			'OBP Pct + SLG Pct',
		],
		descriptions: [
			'Games Played',
			'The number of times a batter comes up to bat not counting walks and sacrifices.',
			'The number of runs scored.',
			'The number of hits.',
			'The number of times that a batter reaches second base as the result of a hit without an error being committed.',
			'The number of hits that result in the batter reaching 3rd base without the aid of an error.',
			'The number of hits that allowed the batter to round the bases and score without the aid of an error of the defense.',
			"The number of runs scored by a batter's play.",
			'The number of bases calculated as 1 for a 1B, 2 for a 2B, 3 for a 3B and 4 for a HR.',
			'The number of times a batter reaches base on four balls',
			'The number of times a player strikes out.',
			'The number of bases stolen.',
			'The average number of times the batter reached successfully on base due to a hit: hits / atBats',
			'The ratio of the number of times the batter reached base per plate appearnce: (hits + walks + hbp / plateAppearances)',
			'Slugging percentage is a metric that is meant to measuring how many bases the hitter safely advances to after making contact with the ball: (totalBases / atBats)',
			'The average calculated by adding up on-base percentage and slugging percentage.',
		],
	},
	{
		name: 'pitching',
		labels: ['GP', 'W', 'L', 'ERA', 'SV', 'CG', 'SHO', 'QS', 'IP', 'H', 'ER', 'HR', 'BB', 'K', 'OBA', 'WHIP'],
		names: ['gamesPlayed', 'wins', 'losses', 'ERA', 'saves', 'completeGames', 'shutouts', 'qualityStarts', 'innings', 'hits', 'earnedRuns', 'homeRuns', 'walks', 'strikeouts', 'opponentAvg', 'WHIP'],
		displayNames: [
			'Games Played',
			'Wins',
			'Losses',
			'Earned Run Average',
			'Saves',
			'Complete Games',
			'Shutouts',
			'Quality Starts',
			'Innings pitched',
			'Hits',
			'Earned runs',
			'Home Runs',
			'Walks',
			'Strikeouts',
			'Opponent Batting Average',
			'Walks Plus Hits Per Inning Pitched',
		],
		descriptions: [
			'Games Played',
			'The number of times the pitcher was attributed with a win',
			'The number of losses for a pitcher.',
			'The average number of runs a pitcher yields per 9 innings thrown',
			'The number of times a pitcher enters and finishes the game without surrendering the lead in a save opportunity.',
			'The number of times that pitcher starts and finishes a game.',
			'The number of times that a pitcher allows zero runs.',
			'The number of quality starts for a pitcher.',
			'The number of innings pitched.',
			'The number of hits surrendered by a pitcher.',
			'The number of earned runs surrendered by a pitcher.',
			'The number of times a pitcher has allowed a home run.',
			'The number of times a pitcher surrenders a base on balls.',
			'The number of times the pitcher struck out a batter.',
			"The opponent's batting average for a pitcher.",
			'The number representing walks plus hits divided by innings pitched.',
		],
	},
	{
		name: 'fielding',
		labels: ['GP', 'E', 'FP', 'TC', 'PO', 'A'],
		names: ['gamesPlayed', 'errors', 'fieldingPct', 'totalChances', 'putouts', 'assists'],
		displayNames: ['Games Played', 'Errors', 'Fielding Percentage', 'Total Chances', 'Putouts', 'Assists'],
		descriptions: [
			'Games Played',
			'The number of times a fielder fails to make the play they are expected to make.',
			'The percentage of plays made by a fielder given the total number of chances.',
			'The number of chances a fielder has to make an out calculated by assists plus putouts plus errors.',
			'The number of times the fielder tags or forces the out of a baserunner.',
			'The number of times a fielder is involved in an out when the fielder is not the putouter.',
		],
	},
];

const nhlteamstats = [
	{
		name: 'general',
		labels: ['GP', 'WINS', 'L'],
		names: ['games', 'wins', 'losses'],
		displayNames: ['Games Played', 'Wins', 'Losses'],
		descriptions: ['Total games played.', 'Wins', 'Losses'],
	},
	{
		name: 'offensive',
		labels: ['GF/G', 'G', 'A', 'PTS', 'PPG', 'PPO', 'PCT', 'SHG', 'S', 'SPCT', 'SOA', 'SOG', 'SOPCT'],
		names: ['avgGoals', 'goals', 'assists', 'points', 'powerPlayGoals', 'powerPlayOpportunities', 'powerPlayPct', 'shortHandedGoals', 'shotsTotal', 'shootingPct', 'shootoutAttempts', 'shootoutGoals', 'shootoutShotPct'],
		displayNames: [
			'Goals For per Game',
			'Goals',
			'Assists',
			'Points',
			'Power Play Goals',
			'Power Play Opportunities',
			'Power Play Percentage',
			'Short Handed Goals',
			'Shots',
			'Shooting Percentage',
			'Shootout Attempts',
			'Shootout Goals',
			'Shootout Shot Percentage',
		],
		descriptions: [
			'Average goals scored per game.',
			'Total goals scored.',
			'Total goal assists.',
			'Points',
			'Total power play goals.',
			'Total power play opportunities.',
			'Percentage of power play scored on.',
			'Total short handed goals.',
			'Total shots.',
			'Percentage of shots scored.',
			'Total shootout attempts.',
			'Total shootout goals.',
			'Percentage of shootout goals made.',
		],
	},
	{
		name: 'defensive',
		labels: ['PCT', 'GA/G', 'OTL', 'SA', 'GA', 'SV', 'SV%', 'SOSA', 'SOS', 'SOSPCT', 'SO'],
		names: ['penaltyKillPct', 'avgGoalsAgainst', 'overtimeLosses', 'shotsAgainst', 'goalsAgainst', 'saves', 'savePct', 'shootoutShotsAgainst', 'shootoutSaves', 'shootoutSavePct', 'shutouts'],
		displayNames: ['Penalty Kill Percentage', 'Goals Against per Game', 'Overtime Losses', 'Shots Against', 'Goals Against', 'Saves', 'Save Percentage', 'Shootout Shots Against', 'Shootout Saves', 'Shootout Save Percentage', 'Shutouts'],
		descriptions: [
			'Percentage of penalties killed.',
			'Average goals against per game.',
			'Total games lost in overtime.',
			'Total shots against.',
			'Total goals against.',
			'Total saves.',
			'Percentage of shots saved.',
			'Total shootout shots against.',
			'Total shootout saves.',
			'Percentage of shootout shots saved.',
			'Total shutouts.',
		],
	},
	{
		name: 'penalties',
		labels: ['PIM'],
		names: ['penaltyMinutes'],
		displayNames: ['Penalty Minutes'],
		descriptions: ['Total penalty minutes.'],
	},
];

const cfbteamstats = [
	{
		name: 'general',
		labels: ['GP', 'FR'],
		names: ['gamesPlayed', 'fumblesRecovered'],
		displayNames: ['Games Played', 'Fumbles Recovered'],
		descriptions: ['Games Played', 'The number of fumbles recovered.'],
	},
	{
		name: 'passing',
		labels: ['AVG', 'TYDS', 'YDS', 'YDS/G', 'PTS', 'TP', 'CMP', 'ATT', 'CMP%', 'YDS', 'LNG', 'TD', 'INT', 'SACK', 'SYL', 'RTG', 'YDS/G', 'YDS/G'],
		names: [
			'yardsPerPassAttempt',
			'totalYards',
			'passingYards',
			'passingYardsPerGame',
			'totalPoints',
			'totalPointsPerGame',
			'completions',
			'passingAttempts',
			'completionPct',
			'passingYards',
			'longPassing',
			'passingTouchdowns',
			'interceptions',
			'sacks',
			'sackYardsLost',
			'QBRating',
			'yardsPerGame',
			'passingYardsPerGame',
		],
		displayNames: [
			'Yards Per Pass Attempt',
			'Total Yards',
			'Passing Yards',
			'Passing Yards Per Game',
			'Total Points',
			'Total Points Per Game',
			'Completions',
			'Passing Attempts',
			'Completion Percentage',
			'Passing Yards',
			'Longest Pass',
			'Passing Touchdowns',
			'Interceptions',
			'Total Sacks',
			'Sack Yards Lost',
			'Passer Rating',
			'Yards Per Game',
			'Passing Yards Per Game',
		],
		descriptions: [
			'The average number yards per pass attempt.',
			'The number of yards accumulated in total.',
			'The total passing yards.',
			'The number of passing yards per game.',
			'The number of total points scored.',
			'The number of points scored per game.',
			'The times a player completes a pass to another player who is eligible to catch a pass.',
			'The number of times a pass is attempted.',
			'The percentage of completed passes.',
			'The total passing yards.',
			'The longest pass play completed in terms of yards.',
			'The total number of passing touchdowns.',
			'The number of passes thrown that were intercepted by the opposing team.',
			'The total number of sacks.',
			'The amount of yards lost due to sacks.',
			'Passer Rating',
			'The average number of yards per game.',
			'The number of passing yards per game.',
		],
	},
	{
		name: 'rushing',
		labels: ['YDS/G', 'YDS', 'AVG', 'YDS/G', 'ATT', 'YDS', 'LNG', 'FUM', 'TD'],
		names: ['rushingYardsPerGame', 'rushingYards', 'yardsPerRushAttempt', 'rushingYardsPerGame', 'rushingAttempts', 'rushingYards', 'longRushing', 'rushingFumbles', 'rushingTouchdowns'],
		displayNames: ['Rushing Yards Per Game', 'Rushing Yards', 'Yards Per Rush Attempt', 'Rushing Yards Per Game', 'Rushing Attempts', 'Rushing Yards', 'Long Rushing', 'Rushing Fumbles', 'Rushing Touchdowns'],
		descriptions: [
			'The average number of rushing yards per game.',
			'The total rushing yards.',
			'The average number of yards per rush attempt.',
			'The average number of rushing yards per game.',
			'The total number of attempted rushes.',
			'The total rushing yards.',
			'The amount of yards for the longest run.',
			'The number of times there is a run and then the ball is fumbled.',
			'The total number of rushing touchdowns.',
		],
	},
	{
		name: 'receiving',
		labels: ['REC', 'YDS', 'YDS/G', 'AVG', 'LNG', 'TD'],
		names: ['receptions', 'receivingYards', 'receivingYardsPerGame', 'yardsPerReception', 'longReception', 'receivingTouchdowns'],
		displayNames: ['Receptions', 'Receiving Yards', 'Receiving Yards Per Game', 'Yards Per Reception', 'Long Reception', 'Receiving Touchdowns'],
		descriptions: [
			'The total number of receptions.',
			'The total receiving yards.',
			'The average number of receiving yards per game.',
			'The average number of yards per reception.',
			'The amount of yards for the longest reception.',
			'The total number of receiving touchdowns.',
		],
	},
	{
		name: 'kicking',
		labels: ['FGA', 'FGM', 'FG%', 'FGM 1-19', 'FGM 20-29', 'FGM 30-39', 'FGM 40-49', '50+', 'FGA 1-19', 'FGA 20-29', 'FGA 30-39', 'FGA 40-49', 'FGA 50+', 'XPM', 'XPA', 'XP%', 'LNG'],
		names: [
			'fieldGoalAttempts',
			'fieldGoalsMade',
			'fieldGoalPct',
			'fieldGoalsMade1_19',
			'fieldGoalsMade20_29',
			'fieldGoalsMade30_39',
			'fieldGoalsMade40_49',
			'fieldGoalsMade50',
			'fieldGoalAttempts1_19',
			'fieldGoalAttempts20_29',
			'fieldGoalAttempts30_39',
			'fieldGoalAttempts40_49',
			'fieldGoalAttempts50',
			'extraPointsMade',
			'extraPointAttempts',
			'extraPointPct',
			'longFieldGoalMade',
		],
		displayNames: [
			'Field Goal Attempts',
			'Field Goal Made',
			'Field Goal Percentage',
			'Field Goals Made 1-19',
			'Field Goals Made 20-29',
			'Field Goals Made 30-39',
			'Field Goals Made 40-49',
			'Field Goals Made 50+',
			'Field Goal Attempts 1-19',
			'Field Goal Attempts 20-29',
			'Field Goal Attempts 30-39',
			'Field Goal Attempts 40-49',
			'Field Goal Attempts 50+',
			'Extra Points Made',
			'Extra Point Attempts',
			'Extra Point Percentage',
			'Long Field Goal Made',
		],
		descriptions: [
			'The number of times a field goal is attempted.',
			'The number of times a field goal is made.',
			'The percentage of field goals attempted that are made.',
			'The number of field goals made from 1-19 yards.',
			'The number of field goals made from 20-29 yards.',
			'The number of field goals made from 30-39 yards.',
			'The number of field goals made from 40-49 yards.',
			'The number of field goals made from 50+ yards.',
			'The number of field goals attempted from 1-19 yards.',
			'The number of field goals attempted from 20-29 yards.',
			'The number of field goals attempted from 30-39 yards.',
			'The number of field goals attempted from 40-49 yards.',
			'The number of field goals attempted from 50+ yards.',
			'The number of extra point attempts made.',
			'The number of extra point attempts.',
			'The percentage of extra point attempts that are converted.',
			'The distance of the longest field goal made.',
		],
	},
	{
		name: 'returning',
		labels: ['ATT', 'YDS', 'AVG', 'LNG', 'TD', 'ATT', 'YDS', 'AVG', 'LNG', 'TD'],
		names: ['kickReturns', 'kickReturnYards', 'yardsPerKickReturn', 'longKickReturn', 'kickReturnTouchdowns', 'puntReturns', 'puntReturnYards', 'yardsPerPuntReturn', 'longPuntReturn', 'puntReturnTouchdowns'],
		displayNames: ['Kick Returns', 'Kick Return Yards', 'Yards Per Kick Return', 'Long Kick Return', 'Kick Return Touchdowns', 'Punt Returns', 'Punt Return Yards', 'Yards Per Punt Return', 'Long Punt Return', 'Punt Return Touchdowns'],
		descriptions: [
			'The number of kick returns.',
			'The total yardage of kick returns.',
			'The average number of yards per kick return.',
			'The longest kick return in yards.',
			'The number of kick return touchdowns.',
			'The number of punt returns.',
			'The total yardage of punt returns.',
			'The average number of yards per punt return.',
			'The longest punt return in yards.',
			'The number of punt return touchdowns.',
		],
	},
	{
		name: 'punting',
		labels: ['PUNTS', 'YDS', 'LNG', 'AVG', 'NET'],
		names: ['punts', 'puntYards', 'longPunt', 'grossAvgPuntYards', 'netAvgPuntYards'],
		displayNames: ['Punts', 'Punt Yards', 'Long Punt', 'Gross Average Punt Yards', 'Net Average Punt Yards'],
		descriptions: ['The number of punts taken.', 'The total amount of yardage of punts.', 'The distance of the longest punt.', 'The average gross number of yards per punt.', 'The average net number of yards per punt.'],
	},
	{
		name: 'miscellaneous',
		labels: ['3RDC', '3RDA', '4THC', '4THC%', 'TPEN', 'TPY', 'FDA', '3RDC%', 'CMP', 'FDPEN', 'FDR', 'FDP'],
		names: [
			'thirdDownConvs',
			'thirdDownAttempts',
			'fourthDownConvs',
			'fourthDownConvPct',
			'totalPenalties',
			'totalPenaltyYards',
			'fourthDownAttempts',
			'thirdDownConvPct',
			'firstDowns',
			'firstDownsPenalty',
			'firstDownsRushing',
			'firstDownsPassing',
		],
		displayNames: [
			'Third Down Conversions',
			'Third Down Attempts',
			'Fourth Down Conversions',
			'4th down %',
			'Total Penalties',
			'Total Penalty Yards',
			'First Downs Attempts',
			'3rd down %',
			'Total 1st downs',
			'1st downs by penalty',
			'Rushing 1st downs',
			'Passing 1st downs',
		],
		descriptions: [
			'The number of 3rd down conversions.',
			'The number of 3rd down attempts.',
			'The number of times a fourth down is converted.',
			'The percentage of fourth down attempts that are converted.',
			'The total number of total penalties.',
			'The total number of penalty yards.',
			'The number of attempts at getting a first down.',
			'The percentage of 3rd downs that are converted.',
			'The the number of first downs.',
			'The number of times a penalty results in a first down.',
			'The number of times a rush results in a first down.',
			'The number of times a pass results in a first down.',
		],
	},
];

const cbbteamstats = [
	{
		name: 'general',
		labels: ['REB', 'REB', 'REB', 'AST/TO', 'TECH', 'REB', 'AST/TO', 'GP', 'PF', 'PF'],
		names: ['totalRebounds', 'avgRebounds', 'avgRebounds', 'teamAssistTurnoverRatio', 'technicalFouls', 'rebounds', 'assistTurnoverRatio', 'gamesPlayed', 'avgFouls', 'fouls'],
		displayNames: ['Rebounds', 'Rebounds Per Game', 'Rebounds Per Game', 'Assist To Turnover Ratio', 'Technical Fouls', 'Rebounds', 'Assist To Turnover Ratio', 'Games Played', 'Fouls Per Game', 'Fouls'],
		descriptions: [
			'The total number of rebounds for a team or player',
			'The average rebounds per game.',
			'The average rebounds per game.',
			'The number of assists per turnover for a team',
			'The number of times an player or coach was called for a technical foul (unsportsmanlike conduct or violations).',
			'The total number of rebounds (offensive and defensive).',
			'The average number of assists a player or team records per turnover',
			'Games Played',
			'The average fouls committed per game.',
			'The number of times a player had illegal contact with the opponent.',
		],
	},
	{
		name: 'defensive',
		labels: ['DR', 'DR', 'STL', 'BLK', 'BLK', 'STL'],
		names: ['defensiveRebounds', 'avgDefensiveRebounds', 'avgSteals', 'avgBlocks', 'blocks', 'steals'],
		displayNames: ['Defensive Rebounds', 'Defensive Rebounds Per Game', 'Steals Per Game', 'Blocks Per Game', 'Blocks', 'Steals'],
		descriptions: [
			'The number of times when the defense obtains the possession of the ball after a missed shot by the offense.',
			'The average defensive rebounds per game.',
			'The average steals per game.',
			'The average blocks per game.',
			'Short for blocked shot, number of times when a defensive player legally deflects a field goal attempt from an offensive player.',
			'The number of times a defensive player forced a turnover by intercepting or deflecting a pass or a dribble of an offensive player.',
		],
	},
	{
		name: 'offensive',
		labels: ['PTS', 'FG%', '3P%', 'FT%', 'TO', 'TO', 'FGM', 'FGA', '3PM', '3PA', 'FTM', 'FTA', 'OR', 'AST', 'PTS', 'FGM', 'FGA', '3PM', '3PA', 'FTM', 'FTA', 'OR', 'AST', 'OR%', 'SC-EFF', 'SH-EFF', '3P%'],
		names: [
			'avgPoints',
			'fieldGoalPct',
			'threePointFieldGoalPct',
			'freeThrowPct',
			'avgTurnovers',
			'turnovers',
			'avgFieldGoalsMade',
			'avgFieldGoalsAttempted',
			'avgThreePointFieldGoalsMade',
			'avgThreePointFieldGoalsAttempted',
			'avgFreeThrowsMade',
			'avgFreeThrowsAttempted',
			'avgOffensiveRebounds',
			'avgAssists',
			'points',
			'fieldGoalsMade',
			'fieldGoalsAttempted',
			'threePointFieldGoalsMade',
			'threePointFieldGoalsAttempted',
			'freeThrowsMade',
			'freeThrowsAttempted',
			'offensiveRebounds',
			'assists',
			'offensiveReboundPct',
			'scoringEfficiency',
			'shootingEfficiency',
			'threePointPct',
		],
		displayNames: [
			'Points Per Game',
			'Field Goal Percentage',
			'3-Point Field Goal Percentage',
			'Free Throw Percentage',
			'Turnovers Per Game',
			'Turnovers',
			'Average Field Goals Made',
			'Average Field Goals Attempted',
			'Average 3-Point Field Goals Made',
			'Average 3-Point Field Goals Attempted',
			'Average Free Throws Made',
			'Average Free Throws Attempted',
			'Offensive Rebounds Per Game',
			'Assists Per Game',
			'Points',
			'Field Goals Made',
			'Field Goals Attempted',
			'3-Point Field Goals Made',
			'3-Point Field Goals Attempted',
			'Free Throws Made',
			'Free Throws Attempted',
			'Offensive Rebounds',
			'Assists',
			'Offensive Rebound Percentage',
			'Scoring Efficiency',
			'Shooting Efficiency',
			'3-Point Field Goal Percentage',
		],
		descriptions: [
			'The average number of points scored per game.',
			'The ratio of field goals made to field goals attempted: FGM / FGA',
			'The ratio of 3pt field goals made to 3pt field goals attempted: 3PM / 3PA.',
			'The ratio of free throws made to free throws attempted: FTM / FTA',
			'The average turnovers committed per game.',
			'The number of times a player loses possession to the other team.',
			'The average field goals made per game.',
			'The average field goals attempted per game.',
			'The average three point field goals made per game.',
			'The average three point field goals attempted per game.',
			'The average free throw shots made per game.',
			'The average free throw shots attempted per game.',
			'The average offensive rebounds per game.',
			'The average assists per game.',
			'The number of points scored.',
			'The number of times a 2pt field goal was made.',
			'The number of times a 2pt field goal was attempted.',
			'The number of times a 3pt field goal was made.',
			'The number of times a 3pt field goal was attempted.',
			'The number of times a free throw was made.',
			'The number of times a free throw was attempted.',
			'The number of times when the offense obtains the possession of the ball after a missed shot.',
			'The number of times a player who passes the ball to a teammate in a way that leads to a score by field goal, meaning that he or she was "assisting" in the basket. There is some judgment involved in deciding whether a passer should be credited with an assist.',
			'The percentage of the number of times they obtain the possession of the ball after a missed shot.',
			'The efficiency with which a team or player scores the basketball.',
			'The efficiency with which a team or player shoots the basketball.',
			'The ratio of 3pt field goals made to 3pt field goals attempted: 3PM / 3PA.',
		],
	},
	{
		name: 'differential',
		labels: [
			'PTSDiff',
			'FGMDiff',
			'FGADiff',
			'3PMDiff',
			'3PADiff',
			'FTMDiff',
			'FTADiff',
			'TODiff',
			'REBDiff',
			'PTSDiff',
			'FGM',
			'FGADiff',
			'3PMDiff',
			'3PADiff',
			'FTMDiff',
			'FTADiff',
			'TODiff',
			'REBDiff',
			'FT%Diff',
			'SC-EFFDiff',
			'SH-EFFDiff',
			'FG%',
			'3P%Diff',
			'ORDiff',
			'DRDiff',
			'ASTDiff',
			'STLDiff',
			'BLKDiff',
			'PFDiff',
		],
		names: [
			'avgPointsDifferential',
			'avgFieldGoalsMadeDifferential',
			'avgFieldGoalsAttemptedDifferential',
			'avgThreePointFieldGoalsMadeDifferential',
			'avgThreePointFieldGoalsAttemptedDifferential',
			'avgFreeThrowsMadeDifferential',
			'avgFreeThrowsAttemptedDifferential',
			'avgTurnoversDifferential',
			'avgReboundsDifferential',
			'pointsDifferential',
			'fieldGoalsMadeDifferential',
			'fieldGoalsAttemptedDifferential',
			'threePointFieldGoalsMadeDifferential',
			'threePointFieldGoalsAttemptedDifferential',
			'freeThrowsMadeDifferential',
			'freeThrowsAttemptedDifferential',
			'turnoversDifferential',
			'reboundsDifferential',
			'freeThrowPctDifferential',
			'scoringEfficiencyDifferential',
			'shootingEfficiencyDifferential',
			'fieldGoalPctDifferential',
			'threePointFieldGoalPctDifferential',
			'avgOffensiveReboundsDifferential',
			'avgDefensiveReboundsDifferential',
			'avgAssistsDifferential',
			'avgStealsDifferential',
			'avgBlocksDifferential',
			'avgFoulsDifferential',
		],
		displayNames: [
			'Points Per Game Differential',
			'Average Field Goals Made Differential',
			'Average Field Goals Attempted Differential',
			'Average 3-Point Field Goals Made Differential',
			'Average 3-Point Field Goals Attempted Differential',
			'Average Free Throws Made Differential',
			'Average Free Throws Attempted Differential',
			'Turnovers Per Game Differential',
			'Rebounds Per Game Differential',
			'Points Differential',
			'Field Goals Made Differential',
			'Field Goals Attempted Differential',
			'3-Point Field Goals Made Differential',
			'3-Point Field Goals Attempted Differential',
			'Free Throws Made Differential',
			'Free Throws Attempted Differential',
			'Turnovers Differential',
			'Rebounds Differential',
			'Free Throw Percentage Differential',
			'Scoring Efficiency Differential',
			'Shooting Efficiency Differential',
			'Field Goal Percentage Differential',
			'3-Point Field Goal Percentage Differential',
			'Offensive Rebounds Per Game Differential',
			'Defensive Rebounds Per Game Differential',
			'Assists Per Game Differential',
			'Steals Per Game Differential',
			'Blocks Per Game Differential',
			'Fouls Per Game Differential',
		],
		descriptions: [
			'The average number of points scored per game. Differential',
			'The average field goals made per game. Differential',
			'The average field goals attempted per game. Differential',
			'The average three point field goals made per game. Differential',
			'The average three point field goals attempted per game. Differential',
			'The average free throw shots made per game. Differential',
			'The average free throw shots attempted per game. Differential',
			'The average turnovers committed per game. Differential',
			'The average rebounds per game. Differential',
			'The number of points scored. Differential',
			'The number of times a 2pt field goal was made. Differential',
			'The number of times a 2pt field goal was attempted. Differential',
			'The number of times a 3pt field goal was made. Differential',
			'The number of times a 3pt field goal was attempted. Differential',
			'The number of times a free throw was made. Differential',
			'The number of times a free throw was attempted. Differential',
			'The number of times a player loses possession to the other team. Differential',
			'The total number of rebounds (offensive and defensive). Differential',
			'The ratio of free throws made to free throws attempted: FTM / FTA Differential',
			'The efficiency with which a team or player scores the basketball. Differential',
			'The efficiency with which a team or player shoots the basketball. Differential',
			'The ratio of field goals made to field goals attempted: FGM / FGA Differential',
			'The ratio of 3pt field goals made to 3pt field goals attempted: 3PM / 3PA. Differential',
			'The average offensive rebounds per game. Differential',
			'The average defensive rebounds per game. Differential',
			'The average assists per game. Differential',
			'The average steals per game. Differential',
			'The average blocks per game. Differential',
			'The average fouls committed per game. Differential',
		],
	},
];

module.exports = {
	mlbplayerstats,
	nflplayerstats,
	nbaplayerstats,
	nhlplayerstats,
	cfbplayerstats,
	cbbplayerstats,
	nflteamstats,
	nbateamstats,
	mlbteamstats,
	nhlteamstats,
	cfbteamstats,
	cbbteamstats,
};
