import {useState} from 'react';
import Modal from 'react-modal';
import {toast} from 'react-toastify';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import {uploadJpeg} from '../../firebase/firebase';
import useSettingsStore from '../../stores/settings.store';
import useModalStore from '../../stores/modal.store';
import useGlobalStore from '../../stores/global.store';

export default function BackgroundModal(props) {
	const [selectedImage, setSelectedImage] = useState(null);
	const [url, setUrl] = useState('');
	const [urlValid, setValidity] = useState(true);
	const [page, setPage] = useState(1);
	const [backgroundImages] = useState([
		'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background1.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
		'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background2.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
		'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background3.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
		'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background4.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
		'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background5.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
		'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background6.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
		'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background7.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
		'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background8.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
		'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background9.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
		'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background10.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
		'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background11.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
		'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background12.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
		'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background13.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
		'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background14.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
		'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background15.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
		'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background16.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
		'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background17.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
		'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background18.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
		'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background19.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
		'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background20.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
		'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background21.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
		'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background22.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
		'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background23.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
		'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background24.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
		'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background25.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
		'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background26.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
		'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background27.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
	]);
	const { updateSetting } = useSettingsStore();
	const { toggleModal } = useModalStore();
	const { debug } = useGlobalStore();

	const setPageFn = (e) => {
		setPage(Number(e.target.getAttribute('data-page-num')));
	};

	const checkFileSize = (event) => {
		let file = event.target.files[0];
		if (file && file.size > 2097152) {
			toast.error('File is over 2 MB.  Please try a different file.');
			event.target.value = null;
			return false;
		}
		return true;
	};

	const backgroundSelected = (e) => {
		if (e.target.files[0] === undefined) {
			setSelectedImage(null);
		} else if (checkFileSize(e)) {
			setSelectedImage(e.target.files[0]);
		}
	};

	const backgroundSubmit = (e) => {
		e.preventDefault();
		if (selectedImage) {
			const title = `/images/background-${props.user._id}${selectedImage.name}`;
			uploadJpeg(selectedImage, title)
				.then((returnedUrl) => {
					toast.success('Uploaded Successfully!', {autoClose: 1000});
					updateSetting('postBackground', returnedUrl);
					toggleModal('background');
				})
				.catch((error) => {
					toast.error('Upload failed. Please try again.');
					if (debug) {
						console.log(error);
					}
					setSelectedImage(null);
				});
		}
	};

	const selectBackground = (e) => {
		updateSetting('postBackground', e.target.currentSrc);
		toggleModal('background');
	};

	const validateImageUrl = async (imageUrl) => {
		const imageExtensionRegex = /\.(jpeg|jpg|gif|png|webp|bmp)$/i;

		if (!imageUrl.match(imageExtensionRegex)) {
			return false;
		}

		try {
			const response = await fetch(imageUrl, { method: 'HEAD' });
			if (!response.ok) {
				return false;
			}

			const contentType = response.headers.get('content-type');
			if (!contentType || !contentType.startsWith('image/')) {
				return false;
			}

			return true;
		} catch (error) {
			return false;
		}
	};

	let pages = [];
	let totalPages = Math.ceil(backgroundImages.length / 9);
	for (var i = 1; i < totalPages + 1; i++) {
		pages.push(
			<span
				key={i}
				className={`${page === i ? 'page-selected' : 'page-not-selected'}`}
				data-page-num={i}
				onClick={setPageFn}>
				{i}
			</span>
		);
	}
	let imageSlice = backgroundImages.slice(page * 9 - 9, page * 9);
	return (
		<Modal
			isOpen={true}
			onRequestClose={() => toggleModal('background')}
			shouldCloseOnOverlayClick={true}
			shouldCloseOnEsc={true}>
			<div className='modal backgroundModal'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>Upload Background Image</h5>
							<span
								className='close-modal'
								onClick={() => toggleModal('background')}>
								X
							</span>
						</div>
						<div className='modal-body'>
							<h6>Select a background image:</h6>
							<div className='heroImages'>
								{imageSlice.map((image, i) => {
									return (
										<img
											key={i}
											className='heroImage'
											src={image}
											alt='backgroundimage'
											onClick={selectBackground}
										/>
									);
								})}
							</div>
							<span className='pages'>Page: {pages}</span>
							<h5 className='withLine'>
								<span>OR</span>
							</h5>
							<h6>
								Image URL{' '}
								<Tippy content={<span>Link to background image (preferably at least 1080px x 1080px)</span>}>
									<i className='fa fa-question-circle'></i>
								</Tippy>
							</h6>
							<textarea
								className='form-control'
								required
								rows='1'
								cols='45'
								style={{resize: 'none'}}
								value={url}
								onChange={(e) => {
									setUrl(e.target.value);
								}}
								onBlur={() => {
									validateImageUrl(url).then((isValid) => {
										if (isValid) {
											setValidity(true);
										} else {
											setValidity(false);
										}
									});
								}}
							/>
							{!urlValid&& <span className='form-error'>Please enter a valid URL (Some websites do not allow linking outside of their site, in which case you may need to upload the image instead)</span>}
							{url && (
								<button
									type='button'
									disabled={!urlValid}
									onClick={() => {
										updateSetting('postBackground', url);
										toggleModal('background');
									}}
									className='btn btn-primary save-url'>
									Save
								</button>
							)}
							<h6 style={{marginTop: '10px'}}>Or Upload a Background</h6>
							<div className='file-box'>
								<form onSubmit={backgroundSubmit}>
									<span className='uploadInfo'>Upload a background image here.</span>
									<input
										type='file'
										className='file-input'
										accept='image/*'
										onChange={backgroundSelected}
									/>
									{selectedImage && (
										<input
											id='upload-button'
											type='submit'
											value='Upload'
											className='upload-button newButton'
										/>
									)}
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}
