import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { aflTeams, f1Teams, mlbTeams, nbaTeams, ncaaTeams, nflTeams, nhlTeams, soccerTeams, wnbaTeams } from '../../data/teams/variables';
import Graphic from '../../components/Graphic';
import useSettingsStore from '../../stores/settings.store';
import { useEventListener, useIsomorphicLayoutEffect } from 'usehooks-ts';
import useGlobalStore from '../../stores/global.store';
import usePostStore from '../../stores/posts/base-post.store';
import useUserStore from '../../stores/user.store';
import useBracketStore from '../../stores/posts/bracket.store';
import useTierStore from '../../stores/posts/tier.store';
import useWithImageStore from '../../stores/posts/with-image.store';
import useComparisonStore from '../../stores/posts/comparison.store';
import useChartStore from '../../stores/posts/chart.store';
import { posts } from '../../data/prefill/homedemo';
import './Home.css';
import { quick1 } from '../../data/settings/quick1';
import { quick2 } from '../../data/settings/quick2';
import { quick3 } from '../../data/settings/quick3';
import { quick4 } from '../../data/settings/quick4';
import { quick5 } from '../../data/settings/quick5';
import { quick6 } from '../../data/settings/quick6';
import { quick7 } from '../../data/settings/quick7';
import { quick8 } from '../../data/settings/quick8';
import { quick9 } from '../../data/settings/quick9';
const NUMERIC_REGEXP = /[-]{0,1}[\d]*[.]{0,1}[\d]+/g;

// eslint-disable-next-line no-extend-native
Array.prototype.sample = function () {
	return this[Math.floor(Math.random() * this.length)];
};

export default function Home() {
	const [selectedType, setSelectedType] = useState('');
	const [selectedPostId, setSelectedPostId] = useState('');
	const [savedPosts, setSavedPosts] = useState([]);
	const [user] = useState({ plan: 'all', sport: 'all' });
	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0,
	});
	const { setSizeMultiplier, draggables: savedDraggables, setDraggables, setHasDraggables, setShowImage, debug, setForceGraphicUpdate } = useGlobalStore();
	const { settings, updateSetting, updateSettings, replaceSettings } = useSettingsStore();
	const { setRound, reset: resetBracket } = useBracketStore();
	const { setTiers, reset: resetTier } = useTierStore();
	const { sport, setSport, teamOverrides, setTeamOverrides } = useUserStore();
	const { reset: resetWithImage, setNewsTitle, setNewsSubtitle, setNewsImage, setNewsTeam, setNewsImageWidth, setNewsImageHeight, newsimagewidth, newsimageheight } = useWithImageStore();
	const { reset: resetComparison, setLeftName, setRightName, setLeftTeam, setRightTeam, setLeftImage, setRightImage, setLeftStats, setRightStats, setStatLabels } = useComparisonStore();
	const { reset: resetPost, setTitle, setSubtitle, setThirdTitle, setName, setTeam, setStat, setPoints, setImage, setRanks, setFloatingTexts, setNameOverrides, setStatOverrides, setNameHighlights, setStatHighlights, setDescription, setTeamSplits } = usePostStore();
	const { setxLabel, setyLabel, reset: resetChart } = useChartStore();

	// componentDidMount
	useEffect(() => {
		setSavedPosts(posts);
		resizePost();
		setSport('nfl');

		return () => {
			// componentWillUnmount
			replaceSettings({});
		};
	}, []);

	// componentDidUpdate
	useEffect(() => {
		updateGraphic();
	});

	// component Unmount
	useEffect( () => () => {
		reset();
		setSport('');
	}, [] );

	useEffect(() => {
		if (savedPosts.length) {
			loadPost(savedPosts[0]._id);
		}
	}, [savedPosts])

	const handleSize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	useEventListener('resize', handleSize);

	useIsomorphicLayoutEffect(() => {
		handleSize();
	}, []);

	useEffect(() => {
		resizePost();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [windowSize]);

	const reset = () => {
		resetPost();
		resetBracket();
		resetChart();
		resetComparison();
		resetTier();
		resetWithImage();
		resetDraggables();
	};

	const updateGraphic = () => {
		try {
			let root = document.documentElement;
			
			if (settings.type.includes('post')) {
				// get widest stat element
				let statEls = document.querySelectorAll('.spanstat');
				let widestEl = 0;
				Array.from(statEls).forEach((statEl) => {
					if (statEl.offsetWidth > widestEl) widestEl = statEl.offsetWidth;
				});
				root.style.setProperty('--boxstatminwidth', widestEl + 'px');

				// get player image width
				const playerImage = document.querySelector('.playerimage');
				if (playerImage) {
					root.style.setProperty('--playerimagewidth', playerImage.offsetWidth + 'px');
				} else {
					root.style.setProperty('--playerimagewidth', '0px');
				}

				// get widest image element
				let teamImageEls = document.querySelectorAll('.boximagecontainer');
				let widestTeamImage = 0;
				Array.from(teamImageEls).forEach((imageEl) => {
					if (imageEl.offsetWidth > widestTeamImage) widestTeamImage = imageEl.offsetWidth;
				});
				if (settings.logoOrder === -2 && !settings.overlap) {
					if (settings.playerOrder < -1) {
						root.style.setProperty('--teamimagewidth', widestTeamImage + playerImage.offsetWidth + 'px');
					} else {
						root.style.setProperty('--teamimagewidth', widestTeamImage + 'px');
					}
				} else {
					if (settings.playerOrder < -1) {
						root.style.setProperty('--teamimagewidth', playerImage.offsetWidth + 'px');
					} else {
						root.style.setProperty('--teamimagewidth', '0px');
					}
				}
			} else if (settings.type === 'tier') {
				root.style.setProperty('--pyramidboxwidth', '800px');
				// get smallest box width
				const boxes = document.querySelectorAll('.box');
				let smallestEl = settings.postWidth;
				Array.from(boxes).forEach((box) => {
					if (box.offsetWidth < smallestEl) smallestEl = box.offsetWidth;
				});
				root.style.setProperty('--pyramidboxwidth', smallestEl + 'px');
			} else if (settings.type === 'news') {
				const banner = document.querySelector('.newsTitle');
				if (banner) {
					root.style.setProperty('--barheight', banner.offsetHeight + 'px');
				}
			}
		} catch (e) {
			if (debug) {
				console.log(e);
			}
		}
	};

	const resizePost = () => {
		let root = document.documentElement;
		let newSettings = {};
		// minus sidebar minus padding
		let docWidth = document.documentElement.clientWidth - 290;
		if (document.documentElement.clientWidth <= 950) {
			// minus padding
			docWidth = document.documentElement.clientWidth - 40;
		}
		// minus title minus padding
		let docHeight = document.documentElement.clientHeight - 108;
		docWidth = Math.min(Math.min(docWidth, docHeight), 1080);		
		const reverseDimensions = settings.postHeight / settings.postWidth;
		newSettings.postWidth = docWidth;
		newSettings.postHeight = docWidth * reverseDimensions;
		const reverseDimensionsNews = settings.postHeightNews / settings.postWidthNews;
		newSettings.postWidthNews = docWidth;
		newSettings.postHeightNews = docWidth * reverseDimensionsNews;
		setSizeMultiplier(docWidth / 800);
		updateSettings(newSettings);
		root.style.setProperty('--sizemultiplier', docWidth / 800);
		moveDraggables();
	};

	const resetDraggables = () => {
		setDraggables([]);
		setHasDraggables(false);
		const allDraggables = document.querySelectorAll('.react-draggable');
		const draggables = Array.from(allDraggables).filter(draggable => {
			return !draggable.closest('.floatingText');
		});
		draggables.forEach((draggable, i) => {
			draggable.style.transform = `translate(0px, 0px)`;
			draggable.style.width = `auto`;
			draggable.style.height = `auto`;
		});
		saveDraggables();
	};

	const saveDraggables = () => {
		const returnVal = [];
		const draggables = document.querySelectorAll('.react-draggable');
		draggables.forEach((draggable, i) => {
			let obj = {};
			const transform = draggable.style.transform.split(',');
			let width = draggable.closest('.drag-container').offsetWidth;
			obj.boxWidth = width;
			obj.xvalue = transform[0].match(NUMERIC_REGEXP)[0];
			obj.yvalue = transform[1].match(NUMERIC_REGEXP)[0];
			obj.width = draggable.style.width.replace('px', '');
			obj.height = draggable.style.height.replace('px', '');
			// if image has been moved
			if ((obj.xvalue > 0 || obj.yvalue > 0 || obj.width !== 'auto' || obj.height !== 'auto') && !draggable.closest('.floatingText.drag-container')) {
				setHasDraggables(true);
			}
			returnVal.push(obj);
		});
		setDraggables(returnVal);
	};

	const moveDraggables = (passedIn) => {
		let draggableInfo = passedIn || savedDraggables;
		if (draggableInfo) {
			const draggables = document.querySelectorAll('.react-draggable');
			draggables.forEach((draggable, i) => {
			if (draggableInfo[i]) {
				const currentBoxWidth = draggable.closest('.drag-container').offsetWidth;
				const boxWidth = draggableInfo[i].boxWidth;
				const ratio = currentBoxWidth / boxWidth;
				const xvalue = draggableInfo[i].xvalue;
				const yvalue = draggableInfo[i].yvalue;
				const width = draggableInfo[i].width;
				const height = draggableInfo[i].height;
				const newx = xvalue * ratio;
				const newy = yvalue * ratio;
				draggable.style.transform = `translate(${newx}px, ${newy}px)`;
				if (width && !isNaN(width)) {
					const newwidth = width * ratio;
					draggable.style.width = `${newwidth}px`;
				} else {
					draggable.style.width = 'auto';
				}
				if (height && !isNaN(height)) {
					const newheight = height * ratio;
					draggable.style.height = `${newheight}px`;
				} else {
					draggable.style.height = 'auto';
				}
				// if image has been moved
				if ((xvalue > 0 || yvalue > 0 || width !== 'auto' || height !== 'auto') && !draggable.closest('.floatingText.drag-container')) {
					setHasDraggables(true);
				}
			}
			});
		}
	};

	const adjustImage = () => {
		let height = document.getElementById('newsLeft').offsetHeight;
		let width = document.getElementById('newsLeft').offsetWidth;
		if (Math.abs(settings.imageVerticalNews) > Math.abs(height + newsimageheight * -1)) {
			let newHeight = height + newsimageheight * -1;
			updateSetting('imageVerticalNews', newHeight);
		}
		if (newsimagewidth - width < Math.abs(settings.imageHorizontalNews)) {
			if (settings.imageHorizontalNews < 0) {
				let newWidth = width + newsimagewidth * -1;
				updateSetting('imageHorizontalNews', newWidth);
			} else if (settings.imageHorizontalNews > 0) {
				let newWidth = newsimagewidth - width;
				updateSetting('imageHorizontalNews', newWidth);
			}
		}
	};

	const setImageDimensions = () => {
		if (document.getElementById('newsImage')) {
			setNewsImageWidth(document.getElementById('newsImage').offsetWidth);
			setNewsImageHeight(document.getElementById('newsImage').offsetHeight);
		}
	};

	const resetImage = (settings) => {
		if (settings.type === 'matchupimage') {
			if (settings.orientationMatchup) {
				setShowImage(false);
				if (settings.orientationMatchup.includes('top') && document.getElementById('newsLeft')) {
					document.getElementById('newsLeft').style.width = null;
				}
				if (settings.orientationMatchup.includes('top') && document.getElementById('newsRight')) {
					document.getElementById('newsRight').style.width = null;
				}
				if (settings.orientationMatchup.includes('left') && document.getElementById('newsLeft')) {
					document.getElementById('newsLeft').style.height = null;
				}
				if (settings.orientationMatchup.includes('left') && document.getElementById('newsRight')) {
					document.getElementById('newsRight').style.height = null;
				}
				let root = document.documentElement;
				root.style.setProperty('--panesplitmatchupleft', `80`);
				root.style.setProperty('--panesplitmatchupright', `20`);
				setShowImage(true);
			}
		} else if (settings.type === 'news') {
			if (settings.orientationNews) {
				setShowImage(false);
				if (settings.orientationNews.includes('top') && document.getElementById('newsLeft')) {
					document.getElementById('newsLeft').style.width = null;
				}
				if (settings.orientationNews.includes('top') && document.getElementById('newsRight')) {
					document.getElementById('newsRight').style.width = null;
				}
				if (settings.orientationNews.includes('left') && document.getElementById('newsLeft')) {
					document.getElementById('newsLeft').style.height = null;
				}
				if (settings.orientationNews.includes('left') && document.getElementById('newsRight')) {
					document.getElementById('newsRight').style.height = null;
				}
				let root = document.documentElement;
				root.style.setProperty('--panesplitnewsleft', `50`);
				root.style.setProperty('--panesplitnewsright', `50`);
				setShowImage(true);
			}
		} else if (settings.type === 'highlightimage') {
			if (settings.orientationHighlight) {
				setShowImage(false);
				if (settings.orientationHighlight.includes('top') && document.getElementById('newsLeft')) {
					document.getElementById('newsLeft').style.width = null;
				}
				if (settings.orientationHighlight.includes('top') && document.getElementById('newsRight')) {
					document.getElementById('newsRight').style.width = null;
				}
				if (settings.orientationHighlight.includes('left') && document.getElementById('newsLeft')) {
					document.getElementById('newsLeft').style.height = null;
				}
				if (settings.orientationHighlight.includes('left') && document.getElementById('newsRight')) {
					document.getElementById('newsRight').style.height = null;
				}
				let root = document.documentElement;
				root.style.setProperty('--panesplithighlightleft', `50`);
				root.style.setProperty('--panesplithighlightright', `50`);
				setShowImage(true);
			}
		} else if (settings.type === 'lineupimage') {
			if (settings.orientationLineup) {
				setShowImage(false);
				if (settings.orientationLineup.includes('top') && document.getElementById('newsLeft')) {
					document.getElementById('newsLeft').style.width = null;
				}
				if (settings.orientationLineup.includes('top') && document.getElementById('newsRight')) {
					document.getElementById('newsRight').style.width = null;
				}
				if (settings.orientationLineup.includes('left') && document.getElementById('newsLeft')) {
					document.getElementById('newsLeft').style.height = null;
				}
				if (settings.orientationLineup.includes('left') && document.getElementById('newsRight')) {
					document.getElementById('newsRight').style.height = null;
				}
				let root = document.documentElement;
				root.style.setProperty('--panesplitlineupleft', `50`);
				root.style.setProperty('--panesplitlineupright', `50`);
				setShowImage(true);
			}
		} else if (settings.type.includes('image')) {
			if (settings.orientation) {
				setShowImage(false);
				if (settings.orientation.includes('top') && document.getElementById('newsLeft')) {
					document.getElementById('newsLeft').style.width = null;
				}
				if (settings.orientation.includes('top') && document.getElementById('newsRight')) {
					document.getElementById('newsRight').style.width = null;
				}
				if (settings.orientation.includes('left') && document.getElementById('newsLeft')) {
					document.getElementById('newsLeft').style.height = null;
				}
				if (settings.orientation.includes('left') && document.getElementById('newsRight')) {
					document.getElementById('newsRight').style.height = null;
				}
				let root = document.documentElement;
				root.style.setProperty('--panesplitleft', `50`);
				root.style.setProperty('--panesplitright', `50`);
				setShowImage(true);
			}
		}
	};

	const getTeam = (t) => {
		t = t.toUpperCase().trim();
		if (t.includes('/')) {
			let team1 = t.split('/')[0];
			let team2 = t.split('/')[1];
			let sport1 = sport;
			let sport2 = sport;
			if (user.plan === 'all' && team1.includes('*')) {
				sport1 = team1.split('*')[0].toLowerCase();
				team1 = team1.split('*')[1];
			}
			if (user.plan === 'all' && team2.includes('*')) {
				sport2 = team2.split('*')[0].toLowerCase();
				team2 = team2.split('*')[1];
			}
			return {sport1, team1, sport2, team2};
		} else if (user.plan === 'all' && t.includes('*')) {
			let s = t.split('*')[0].toLowerCase();
			t = t.split('*')[1];
			return {team: t, sport: s};
		} else {
			return {team: t, sport};
		}
	};

	const normalizeTeamName = (name) => {
		if (name && sport === 'ncaa' && name.includes(' STATE')) {
			name = name.replace(' STATE', ' ST')
		}
		return name?.normalize('NFD').replace(/\p{Diacritic}/gu, '').replace(/[^a-zA-Z0-9&-]/g, '').replace('2','');
	}

	const getTeamObj = (team, sport) => {
		let teams = [];
		if (!team) return {};
		if (sport === 'nhl') teams = JSON.parse(JSON.stringify(nhlTeams));
		else if (sport === 'nba') teams = JSON.parse(JSON.stringify(nbaTeams));
		else if (sport === 'nfl') teams = JSON.parse(JSON.stringify(nflTeams));
		else if (sport === 'ncaa') teams = JSON.parse(JSON.stringify(ncaaTeams));
		else if (sport === 'mlb') teams = JSON.parse(JSON.stringify(mlbTeams));
		else if (sport === 'soccer') teams = JSON.parse(JSON.stringify(soccerTeams));
		else if (sport === 'f1') teams = JSON.parse(JSON.stringify(f1Teams));
		else if (sport === 'wnba') teams = JSON.parse(JSON.stringify(wnbaTeams));
		else if (sport === 'afl') teams = JSON.parse(JSON.stringify(aflTeams));
		const teamToFind = normalizeTeamName(team);
		let returnTeam = {};
		const foundTeam = teams.find((obj) => {
			return obj.abbreviation === teamToFind || normalizeTeamName(obj.full) === teamToFind || obj.abbreviation2 === teamToFind || normalizeTeamName(obj.full2) === teamToFind || obj.abbreviation3 === teamToFind;
		});
		if (foundTeam) {
			Object.assign(returnTeam, foundTeam);
			returnTeam.logo = returnTeam.logos[0];
		}
		let overridesToUse = teamOverrides;
		let override = overridesToUse.filter((obj) => {
			return obj.sport === sport && (obj.abbreviation === teamToFind || normalizeTeamName(obj.full) === teamToFind || obj.abbreviation2 === teamToFind || normalizeTeamName(obj.full2) === teamToFind || obj.abbreviation3 === teamToFind);
		});
		if (override && override.length > 1) {
			let localOverride = override.find((obj) => {
				return !obj.isGlobal;
			});
			if (localOverride) {
				if (!localOverride.logo) localOverride.logo = localOverride.logos[0];
				Object.assign(returnTeam, localOverride);
			}
		} else if (override && override.length === 1) {
			if (!override[0].logo) override[0].logo = override[0].logos[0];
			Object.assign(returnTeam, override[0]);
		}
		if (team?.substring(team.length - 1) === '2' && returnTeam) {
			const oldPrimary = returnTeam.primary;
			const oldSecondary = returnTeam.secondary;
			returnTeam = {...returnTeam};
			returnTeam.primary = oldSecondary;
			returnTeam.secondary = oldPrimary;
		}
		if (settings.helmets && foundTeam?.helmet) {
			returnTeam.logo = foundTeam.helmet;
		}
		if (settings.lighterLogos && sport !== 'afl' && returnTeam.logo) {
			returnTeam.logo = returnTeam.logo.replace('/500/', '/500-dark/');
		}
		return returnTeam;
	};

	const changeType = (type) => {
		resetDraggables();
		setSelectedType(type);
		updateSetting('type', type);
		const filteredPosts = savedPosts.filter((post) => post.settings.type.includes(type));
		loadPost(filteredPosts[0]._id);
	}

	const loadPost = async (id) => {
		setSelectedPostId(id);
		let post = savedPosts.find((post) => post._id === id);
		if (post.settings.type === 'postimage' || post.settings.type === 'gridimage') {
			if (!post.settings.paneSplit) {
				post.settings.paneSplit = post.settings.paneSplitNews;
			}
			if (!post.settings.orientation) {
				post.settings.orientation = post.settings.orientationNews;
			}
		}
		if (post.settings.type === 'news' || post.settings.type === 'highlightimage' || post.settings.type === 'lineupimage') {
			resetImage(post.settings);
			replaceSettings(post.settings);
			setNewsTitle(post.newstitle);
			setNewsSubtitle(post.newssubtitle);
			setNewsImage(post.newsimage);
			setNewsTeam(post.newsteam);
			setSport(post.sport);
			setTeamSplits(post.teamsplits);
			setFloatingTexts(post.floatingTexts ? post.floatingTexts : []);
			if (post.settings.type === 'lineupimage') {
				setName(post.name);
				setStat(post.stat);
			}
		} else if (post.settings.type === 'comparison') {
			resetImage(post.settings);
			replaceSettings(post.settings);
			setTitle(post.title);
			setSubtitle(post.subtitle);
			setLeftName(post.leftName);
			setRightName(post.rightName);
			setLeftTeam(post.leftTeam);
			setRightTeam(post.rightTeam);
			setLeftImage(post.leftImage);
			setRightImage(post.rightImage);
			setLeftStats(post.leftStats);
			setRightStats(post.rightStats);
			setStatLabels(post.statLabels);
			setSport(post.sport);
			setTeamSplits(post.teamsplits);
			setFloatingTexts(post.floatingTexts);
		} else {
			replaceSettings(post.settings);
			setTitle(post.title);
			setSubtitle(post.subtitle);
			setThirdTitle(post.thirdTitle);
			setTeam(post.team);
			setName(post.name);
			setStat(post.stat);
			setPoints(post.points ? post.points : []);
			setImage(post.image);
			setSport(post.sport);
			setTeamSplits(post.teamsplits ? post.teamsplits : []);
			setRanks(post.ranks ? post.ranks : []);
			setFloatingTexts(post.floatingTexts ? post.floatingTexts : []);
			setxLabel(post.xLabel ? post.xLabel : '');
			setyLabel(post.yLabel ? post.yLabel : '');
			setNameOverrides(post.nameOverrides ? post.nameOverrides : []);
			setStatOverrides(post.statOverrides ? post.statOverrides : []);
			setNameHighlights(post.nameHighlights ? post.nameHighlights : []);
			setStatHighlights(post.statHighlights ? post.statHighlights : []);
			setDescription(post.description ? post.description : '');
			if (post.settings.type === 'bracket') {
				setRound('round32', post.round32);
				setRound('round16',post.round16);
				setRound('round12',post.round12);
				setRound('round8',post.round8);
				setRound('round4',post.round4);
				setRound('round2',post.round2);
				setRound('round1',post.round1);
			} else if (post.settings.type === 'tier') {
				if (post.tiers) {
					setTiers(post.tiers);
				} else {
					setTiers({
						0: 1,
						1: 2,
						2: 3,
						3: 4,
						4: 5,
						5: 6,
						6: 7,
						7: 8,
						8: 9,
						9: 10
					});
				}
			}
			if (post.settings.type.includes('image')) {
				resetImage(post.settings);
				setNewsImage(post.newsimage);
			}
		}
		if (post.settings.type.includes('post') || post.settings.type.includes('grid') || post.settings.type === 'bracket' || post.settings.type === 'scores') {
			if (post.team.includes('#')) {
				let filteredTeam = post.team.filter((value) => value !== '#');
				setTeam(filteredTeam);
			}
		}
		setTeamOverrides(post.teamoverrides || []);
		if (post.draggables && post.draggables.length) {
			setDraggables(post.draggables);
			moveDraggables(post.draggables);
		} else {
			resetDraggables();
		}
		window.dispatchEvent(new Event('resize'));
		// force update to get text fit to work properly
		setForceGraphicUpdate();
	};

	const noop = () => {}

	return (
		<div className='HomeBackground'>
			<Helmet>
				<title>Stat Lists - Sports graphics made easy</title>
			</Helmet>
			<div className='Home'>
				<div className='homeHero'>
					<h1>Easily create sports graphics in minutes.</h1>
					<h2>Simply type in or copy and paste a list to create a stunning graphic with ease. No graphic design skills required.</h2>
					<Link to='/register'>
						<button className='newButton'>Start Free Trial</button>
					</Link>
				</div>
				<div className='homedemo'>
					<h2 className='hometitle' style={{color: 'white', marginBottom: '20px'}}>Try it out!</h2>
					<div className='graphicContainer'>
						{selectedType === '' && (<div className='graphicSidebar'>
							<span>Select a format:</span>
							<button className={settings.type.includes('post') ? 'selected' : ''} onClick={() => changeType('post')}>List</button>
							<button className={settings.type === 'news' ? 'selected' : ''} onClick={() => changeType('news')}>News/Quote</button>
							<button className={settings.type.includes('grid') ? 'selected' : ''} onClick={() => changeType('grid')}>Grid</button>
							<button className={settings.type === 'scores' ? 'selected' : ''} onClick={() => changeType('scores')}>Scores</button>
							<button className={settings.type === 'bracket' ? 'selected' : ''} onClick={() => changeType('bracket')}>Bracket</button>
							<button className={settings.type === 'tier' ? 'selected' : ''} onClick={() => changeType('tier')}>Tier</button>
							<button className={settings.type === 'matchupimage' ? 'selected' : ''} onClick={() => changeType('matchupimage')}>Matchup</button>
							<button className={settings.type === 'highlightimage' ? 'selected' : ''} onClick={() => changeType('highlightimage')}>Highlight</button>
							<button className={settings.type === 'chart' ? 'selected' : ''} onClick={() => changeType('chart')}>Chart</button>
						</div>)}
						{selectedType !== '' && (<div className='graphicSidebar'>
							<button className='backBtn' onClick={() => setSelectedType('')}>
								<i className="fa-solid fa-arrow-left"></i>
							</button>
							<span>Select an example:</span>
							{savedPosts.filter((post) => post.settings.type.includes(selectedType)).map((post, i) => {
								return <button key={i} className={selectedPostId === post._id  ? 'selected' : ''} onClick={() => loadPost(post._id)}>{post.title.toLowerCase().replace('nfl', 'NFL').replace('nba', 'NBA').replace('mlb', 'MLB').replace('nhl', 'NHL')}</button>
							})}
						</div>)}
						<Graphic
							user={user}
							homePage={true}
							getTeam={getTeam}
							getTeamObj={getTeamObj}
							originalSport={noop}
							adjustImage={adjustImage}
							setImageDimensions={setImageDimensions}
						/>
					</div>
				</div>
				<h2 className='hometitle'>Why Stat Lists?</h2>
				<ul className='moreReasons'>
					<li>Create lists or grids anywhere from Top 5 to Top 50</li>
					<li>Create tiered lists with up to 10 tiers</li>
					<li>Create brackets for 4, 8, 16, 32, or 64 teams</li>
					<li>Create graphics for breaking news or quotes</li>
					<li>Create scoreboards for a whole league or just a single matchup</li>
					<li>Create bar or scatter charts</li>
					<li>Over 100 different customizable settings</li>
					<li>Download with the click of a button</li>
					<li>Try it out for free with a 7-day free trial</li>
					<li>Easy cancellation</li>
				</ul>
				<hr className='homeline' />
				<div className='homepay'>
					<h2 className='stepsTitle'>Pricing</h2>
					<div className='row row-cols-1 row-cols-md-2 mb-2 text-center card-container'>
						<div className='col'>
							<div className='card mb-4 rounded-3 shadow-sm'>
								<div className='card-header py-3'>
									<h4 className='my-0 fw-normal'>Single Sport</h4>
								</div>
								<div className='card-body'>
									<h3 className='card-title pricing-card-title'>
										$8
										<small className='text-muted fw-light'>/mo</small>
									</h3>
									<ul className='list-unstyled mt-3 mb-4'>
										<li>Create and download an unlimited number of graphics for a single sport (NFL, NBA, MLB, NHL, NCAA, Soccer, WNBA, AFL, or F1). Includes a one-week free trial.</li>
									</ul>
									<Link to='/register'>
										<button className='newButton'>Sign Up</button>
									</Link>
								</div>
							</div>
						</div>
						<div className='col'>
							<div className='card mb-4 rounded-3 shadow-sm'>
								<div className='card-header py-3'>
									<h4 className='my-0 fw-normal'>All Sports</h4>
								</div>
								<div className='card-body'>
									<h3 className='card-title pricing-card-title'>
										$15
										<small className='text-muted fw-light'>/mo</small>
									</h3>
									<ul className='list-unstyled mt-3 mb-4'>
										<li>Create and download an unlimited number of graphics for all leagues. (NFL, NBA, MLB, NHL, NCAA, Soccer, WNBA, AFL, or F1). Includes a one-week free trial.</li>
									</ul>
									<Link to='/register'>
										<button className='newButton'>Sign Up</button>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
