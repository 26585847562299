export const passingStats = [
	{
	  label: "Passing Yards",
	  value: "nflplayerstats/passing-passingyards",
	},
	{
	  label: "Passing Attempts",
	  value: "nflplayerstats/passing-passingattempts",
	},
	{
	  label: "Completions",
	  value: "nflplayerstats/passing-completions",
	},
	{
	  label: "Completion Percentage",
	  value: "nflplayerstats/passing-completionpct",
	},
	{
	  label: "Yards Per Pass Attempt",
	  value: "nflplayerstats/passing-yardsperpassattempt",
	},
	{
	  label: "Passing Yards Per Game",
	  value: "nflplayerstats/passing-passingyardspergame",
	},
	{
	  label: "Longest Pass",
	  value: "nflplayerstats/passing-longpassing",
	},
	{
	  label: "Passing Touchdowns",
	  value: "nflplayerstats/passing-passingtouchdowns",
	},
	{
	  label: "Interceptions",
	  value: "nflplayerstats/passing-interceptions",
	},
	{
	  label: "Total Sacks",
	  value: "nflplayerstats/passing-sacks",
	},
	{
	  label: "Sack Yards Lost",
	  value: "nflplayerstats/passing-sackyardslost",
	},
	{
	  label: "Total QBR",
	  value: "nflplayerstats/passing-qbr",
	},
	{
	  label: "Passer Rating",
	  value: "nflplayerstats/passing-qbrating",
	},
	{
	  label: "Adjusted QBR",
	  value: "nflplayerstats/passing-adjqbr",
	},
  ];

  export const rushingStats = [
	{
	  label: "Rushing Yards",
	  value: "nflplayerstats/rushing-rushingyards",
	},
	{
	  label: "Rushing Attempts",
	  value: "nflplayerstats/rushing-rushingattempts",
	},
	{
	  label: "Yards Per Rush Attempt",
	  value: "nflplayerstats/rushing-yardsperrushattempt",
	},
	{
	  label: "Long Rushing",
	  value: "nflplayerstats/rushing-longrushing",
	},
	{
	  label: "20+ Yard Rushing Plays",
	  value: "nflplayerstats/rushing-rushingbigplays",
	},
	{
	  label: "Rushing Touchdowns",
	  value: "nflplayerstats/rushing-rushingtouchdowns",
	},
	{
	  label: "Rushing Yards Per Game",
	  value: "nflplayerstats/rushing-rushingyardspergame",
	},
	{
	  label: "Rushing Fumbles",
	  value: "nflplayerstats/rushing-rushingfumbles",
	},
	{
	  label: "Rushing Fumbles Lost",
	  value: "nflplayerstats/rushing-rushingfumbleslost",
	},
	{
	  label: "Rushing 1st downs",
	  value: "nflplayerstats/rushing-rushingfirstdowns",
	},
  ];

  export const receivingStats = [
	{
	  label: "Receptions",
	  value: "nflplayerstats/receiving-receptions",
	},
	{
	  label: "Receiving Targets",
	  value: "nflplayerstats/receiving-receivingtargets",
	},
	{
	  label: "Receiving Yards",
	  value: "nflplayerstats/receiving-receivingyards",
	},
	{
	  label: "Yards Per Reception",
	  value: "nflplayerstats/receiving-yardsperreception",
	},
	{
	  label: "Receiving Touchdowns",
	  value: "nflplayerstats/receiving-receivingtouchdowns",
	},
	{
	  label: "Long Reception",
	  value: "nflplayerstats/receiving-longreception",
	},
	{
	  label: "20+ Yard Receiving Plays",
	  value: "nflplayerstats/receiving-receivingbigplays",
	},
	{
	  label: "Receiving Yards Per Game",
	  value: "nflplayerstats/receiving-receivingyardspergame",
	},
	{
	  label: "Receiving Fumbles",
	  value: "nflplayerstats/receiving-receivingfumbles",
	},
	{
	  label: "Receiving Fumbles Lost",
	  value: "nflplayerstats/receiving-receivingfumbleslost",
	},
	{
	  label: "Receiving Yards After Catch",
	  value: "nflplayerstats/receiving-receivingyardsaftercatch",
	},
	{
	  label: "Receiving First Downs",
	  value: "nflplayerstats/receiving-receivingfirstdowns",
	},
  ];

  export const defensiveStats = [
	{
	  label: "Solo Tackles",
	  value: "nflplayerstats/defensive-solotackles",
	},
	{
	  label: "Assist Tackles",
	  value: "nflplayerstats/defensive-assisttackles",
	},
	{
	  label: "Total Tackles",
	  value: "nflplayerstats/defensive-totaltackles",
	},
	{
	  label: "Sacks",
	  value: "nflplayerstats/defensive-sacks",
	},
	{
	  label: "Sack Yards",
	  value: "nflplayerstats/defensive-sackyards",
	},
	{
	  label: "Tackles For Loss",
	  value: "nflplayerstats/defensive-tacklesforloss",
	},
	{
	  label: "Passes Defended",
	  value: "nflplayerstats/defensive-passesdefended",
	},
	{
	  label: "Long Interception",
	  value: "nflplayerstats/defensive-longinterception",
	},
	{
	  label: "Interceptions",
	  value: "nflplayerstats/defensiveinterceptions-interceptions",
	},
	{
	  label: "Interception Yards",
	  value: "nflplayerstats/defensiveinterceptions-interceptionyards",
	},
	{
	  label: "Interception Touchdowns",
	  value: "nflplayerstats/defensiveinterceptions-interceptiontouchdowns",
	},
	{
	  label: "Forced Fumbles",
	  value: "nflplayerstats/general-fumblesforced",
	},
	{
	  label: "Fumbles Recovered",
	  value: "nflplayerstats/general-fumblesrecovered",
	},
	{
	  label: "Fumbles Touchdowns",
	  value: "nflplayerstats/general-fumblestouchdowns",
	},
  ];

  export const specialStats = [
	{
	  label: "Kick Returns",
	  value: "nflplayerstats/returning-kickreturns",
	},
	{
	  label: "Kick Return Yards",
	  value: "nflplayerstats/returning-kickreturnyards",
	},
	{
	  label: "Yards Per Kick Return",
	  value: "nflplayerstats/returning-yardsperkickreturn",
	},
	{
	  label: "Long Kick Return",
	  value: "nflplayerstats/returning-longkickreturn",
	},
	{
	  label: "Kick Return Touchdowns",
	  value: "nflplayerstats/returning-kickreturntouchdowns",
	},
	{
	  label: "Punt Returns",
	  value: "nflplayerstats/returning-puntreturns",
	},
	{
	  label: "Punt Return Yards",
	  value: "nflplayerstats/returning-puntreturnyards",
	},
	{
	  label: "Yards Per Punt Return",
	  value: "nflplayerstats/returning-yardsperpuntreturn",
	},
	{
	  label: "Long Punt Return",
	  value: "nflplayerstats/returning-longpuntreturn",
	},
	{
	  label: "Punt Return Touchdowns",
	  value: "nflplayerstats/returning-puntreturntouchdowns",
	},
	{
	  label: "Punt Return Fair Catches",
	  value: "nflplayerstats/returning-puntreturnfaircatches",
	},
  ];

  export const kickingStats = [
	{
	  label: "Field Goal Made",
	  value: "nflplayerstats/kicking-fieldgoalsmade",
	},
	{
	  label: "Field Goal Attempts",
	  value: "nflplayerstats/kicking-fieldgoalattempts",
	},
	{
	  label: "Field Goal Percentage",
	  value: "nflplayerstats/kicking-fieldgoalpct",
	},
	{
	  label: "Long Field Goal Made",
	  value: "nflplayerstats/kicking-longfieldgoalmade",
	},
	{
	  label: "Field Goals Made 1-19",
	  value: "nflplayerstats/kicking-fieldgoalsmade1_19",
	},
	{
	  label: "Field Goals Made 20-29",
	  value: "nflplayerstats/kicking-fieldgoalsmade20_29",
	},
	{
	  label: "Field Goals Made 30-39",
	  value: "nflplayerstats/kicking-fieldgoalsmade30_39",
	},
	{
	  label: "Field Goals Made 40-49",
	  value: "nflplayerstats/kicking-fieldgoalsmade40_49",
	},
	{
	  label: "Field Goals Made 50+",
	  value: "nflplayerstats/kicking-fieldgoalsmade50",
	},
	{
	  label: "Field Goal Attempts 1-19",
	  value: "nflplayerstats/kicking-fieldgoalattempts1_19",
	},
	{
	  label: "Field Goal Attempts 20-29",
	  value: "nflplayerstats/kicking-fieldgoalattempts20_29",
	},
	{
	  label: "Field Goal Attempts 30-39",
	  value: "nflplayerstats/kicking-fieldgoalattempts30_39",
	},
	{
	  label: "Field Goal Attempts 40-49",
	  value: "nflplayerstats/kicking-fieldgoalattempts40_49",
	},
	{
	  label: "Field Goal Attempts 50+",
	  value: "nflplayerstats/kicking-fieldgoalattempts50",
	},
	{
	  label: "Extra Points Made",
	  value: "nflplayerstats/kicking-extrapointsmade",
	},
	{
	  label: "Extra Point Attempts",
	  value: "nflplayerstats/kicking-extrapointattempts",
	},
	{
	  label: "Extra Point Percentage",
	  value: "nflplayerstats/kicking-extrapointpct",
	},
  ];

  export const puntingStats = [
	{ label: "Punts", value: "nflplayerstats/punting-punts" },
	{
	  label: "Punt Yards",
	  value: "nflplayerstats/punting-puntyards",
	},
	{
	  label: "Long Punt",
	  value: "nflplayerstats/punting-longpunt",
	},
	{
	  label: "Gross Average Punt Yards",
	  value: "nflplayerstats/punting-grossavgpuntyards",
	},
	{
	  label: "Net Average Punt Yards",
	  value: "nflplayerstats/punting-netavgpuntyards",
	},
	{
	  label: "Punts Blocked",
	  value: "nflplayerstats/punting-puntsblocked",
	},
	{
	  label: "Punts Inside 20",
	  value: "nflplayerstats/punting-puntsinside20",
	},
	{
	  label: "Touchbacks",
	  value: "nflplayerstats/punting-touchbacks",
	},
	{
	  label: "Fair Catches",
	  value: "nflplayerstats/punting-faircatches",
	},
  ];

//   2024 season, update each year
  export const byeWeeks = {
	5: "Lions, Chargers, Eagles, Titans",
	6: "Chiefs, Rams, Dolphins, Vikings",
	7: "Bears, Cowboys",
	9: "Steelers, 49ers",
	10: "Browns, Packers, Raiders, Seahawks",
	11: "Cardinals, Panthers, Giants, Buccaneers",
	12: "Falcons, Bills, Bengals, Jaguars, Saints, Jets",
	14: "Ravens, Broncos, Texans, Colts, Patriots, Commanders",
  };