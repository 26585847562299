import { create } from 'zustand';

interface ModalStore {
    openModals: string[];
    toggleModal: (name: string) => void;
    isModalOpen: (name: string) => boolean;
    clearModals: () => void;
}

const useModalStore = create<ModalStore>((set, get) => ({
    openModals: [],
    toggleModal: (name: string) => {
        set((state) => {
            const modalsCopy = [...state.openModals];
            const modalIndex = modalsCopy.indexOf(name);

            if (modalIndex >= 0) {
                modalsCopy.splice(modalIndex, 1);
            } else {
                modalsCopy.push(name);
            }

            return { openModals: modalsCopy };
        });
    },
    isModalOpen: (name: string) => {
        const { openModals } = get();
        return openModals.includes(name);
    },
    clearModals: () => {
        set(() => ({ openModals: [] }));
    }
}));

export default useModalStore;
