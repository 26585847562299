import React from 'react';
import useUserStore from '../../stores/user.store';
import useSettingsStore from '../../stores/settings.store';

interface SpacingEditorProps {
	settingName: string;
}

export const SpacingEditor: React.FC<SpacingEditorProps> = ({ settingName }) => {
	const { lockedSettings } = useUserStore();
	const { settings, updateSetting, updateSettings } = useSettingsStore();

	const handleChange = (value: number): void => {
		const newSettings: any = {};
		newSettings[`${settingName}Top`] = Math.max(0, Number(settings[settingName + 'Top']) + value);
		newSettings[`${settingName}Right`] = Math.max(0, Number(settings[settingName + 'Right']) + value);
		newSettings[`${settingName}Bottom`] = Math.max(0, Number(settings[settingName + 'Bottom']) + value);
		newSettings[`${settingName}Left`] = Math.max(0, Number(settings[settingName + 'Left']) + value);
		updateSettings(newSettings);
	};

	return (
		<div className='all-spacing-editor'>
			<div className='spacing-editor'>
				<span className='spacing-editor-top'>
					<input
					disabled={lockedSettings.includes(settingName)}
					type='number'
					value={settings[settingName + 'Top']}
					onChange={(e) => {
						updateSetting(settingName + 'Top', Number(e.target.value));
					}}></input>
				</span>
				<span className='spacing-editor-middle'>
					<input
					disabled={lockedSettings.includes(settingName)}
					type='number'
					value={settings[settingName + 'Left']}
					onChange={(e) => {
						updateSetting(settingName + 'Left', Number(e.target.value));
					}}></input>
					<input
					disabled={lockedSettings.includes(settingName)}
					type='number'
					value={settings[settingName + 'Right']}
					onChange={(e) => {
						updateSetting(settingName + 'Right', Number(e.target.value));
					}}></input>
				</span>
				<span className='spacing-editor-bottom'>
					<input
					disabled={lockedSettings.includes(settingName)}
					type='number'
					value={settings[settingName + 'Bottom']}
					onChange={(e) => {
						updateSetting(settingName + 'Bottom', Number(e.target.value));
					}}></input>
				</span>
			</div>
			<span className='all-buttons'>
				<button
					type='button'
					className='editor-button'
					onClick={() => handleChange(1)}>
					<i className="fa-solid fa-caret-up"></i>
				</button>
				<button
					type='button'
					className='editor-button'
					onClick={() => handleChange(-1)}>
					<i className="fa-solid fa-caret-down"></i>
				</button>
			</span>
		</div>
	);
};
