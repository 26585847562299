import { useRef } from 'react';
import { Textfit } from '@gmurph91/react-textfit';
import { colord, extend } from 'colord';
import mixPlugin from 'colord/plugins/mix';
import useSettingsStore from '../../stores/settings.store';
import usePostStore from '../../stores/posts/base-post.store';
import useGlobalStore from '../../stores/global.store';
import useUserStore from '../../stores/user.store';
extend([mixPlugin]);

export default function PostFormat(props) {
	const ref = useRef();
	const { settings } = useSettingsStore();
	const {
		team,
		name,
		stat,
		points,
		ranks,
		image,
		opponents,
		teamsplits,
		combined,
		nflRanks,
		proRanks,
		espnRanks,
		oppRanks
	} = usePostStore();
	const { individuallyEdit, sizeMultiplier, setEditTeam } = useGlobalStore();
	const { sport } = useUserStore();
	const { thirdTitle } = usePostStore();

	const hasBoxMargin = () => {
		return settings.boxMarginTop > 0 || settings.boxMarginRight > 0 || settings.boxMarginBottom > 0 || settings.boxMarginLeft > 0;
	}

	const getOppRankColor = (rank) => {
		let nonOrdinal = rank.replace(/[^0-9]/g, '');
		if (nonOrdinal < 10) {
			return {color: '#FF0000'};
		} else if (nonOrdinal > 22) {
			return {color: '#008000'};
		}
		return {color: '#000000'};
	};

	let maplength = team.length;
	if (maplength >= 1 && maplength < 5) {
		maplength = 5;
	} else if (maplength >= 100) {
		maplength = 100;
	}
	return Array(maplength)
		.fill(0)
		.map((item, i) => {
			let teamValues = team[i] ? props.getTeam(team[i]) : '';
			let t = teamValues.team ? teamValues.team : null;
			let s = teamValues.sport ? teamValues.sport : sport;
			let team1 = teamValues.team1 ? teamValues.team1 : null;
			let team2 = teamValues.team2 ? teamValues.team2 : null;
			let sport1 = teamValues.sport1 ? teamValues.sport1 : null;
			let sport2 = teamValues.sport2 ? teamValues.sport2 : null;
			let img = image[i];
			let imgsrc;
			if (img) {
				imgsrc = props.getImg(img);
			}
			let footerHeight = settings.hasFooter ? settings.footerHeight : 0;
			let titleHeight = settings.titleHeight / 100;
			if (thirdTitle) titleHeight = (settings.titleHeight + 4) / 100;
			footerHeight = footerHeight * sizeMultiplier;
			let height = (settings.postHeight * (1 - titleHeight) - footerHeight) / maplength;
			if (combined) {
				height = (settings.postHeight * (1 - titleHeight) - footerHeight - 36 * sizeMultiplier) / maplength;
			}
			let imageWidth = height / 25 + 'em';
			let nameFont = height / 30 + 'em';
			let statFont = height / 30 + 'em';
			let numColumns = 1;
			const hasRank = ranks[0] ? true : false;
			let width = hasRank && !settings.rankInside ? 
				`calc(var(--postwidth) - calc(var(--boxmarginleft) * var(--sizemultiplier)) - calc(var(--boxmarginright) * var(--sizemultiplier)) - calc(var(--rankwidth) * var(--sizemultiplier)) - calc(var(--rankmarginreal) * var(--sizemultiplier)))`
				: `calc(var(--postwidth) - calc(var(--boxmarginleft) * var(--sizemultiplier)) - calc(var(--boxmarginright) * var(--sizemultiplier)))`;
			if ((maplength > 75 && !settings.postColumns) || settings.postColumns === 4) {
				numColumns = 4;
				height = (settings.postHeight * (1 - titleHeight) - footerHeight) / Math.round(maplength / 4);
				imageWidth = height / 60 + 'em';
				nameFont = height / 45 + 'em';
				statFont = height / 45 + 'em';
				width = hasRank && !settings.rankInside ? 
					`calc(calc(var(--postwidth) / 4) - calc(var(--boxmarginleft) * var(--sizemultiplier)) - calc(var(--boxmarginright) * var(--sizemultiplier)) - calc(var(--rankwidth) * var(--sizemultiplier)) - calc(var(--rankmarginreal) * var(--sizemultiplier)))`
					: `calc(calc(var(--postwidth) / 4) - calc(var(--boxmarginleft) * var(--sizemultiplier)) - calc(var(--boxmarginright) * var(--sizemultiplier)))`;
			} else if ((maplength > 50 && !settings.postColumns) || settings.postColumns === 3) {
				numColumns = 3;
				height = (settings.postHeight * (1 - titleHeight) - footerHeight) / Math.ceil(maplength / 3);
				imageWidth = height / 50 + 'em';
				nameFont = height / 40 + 'em';
				statFont = height / 40 + 'em';
				width = hasRank && !settings.rankInside ? 
					`calc(calc(var(--postwidth) / 3) - calc(var(--boxmarginleft) * var(--sizemultiplier)) - calc(var(--boxmarginright) * var(--sizemultiplier)) - calc(var(--rankwidth) * var(--sizemultiplier)) - calc(var(--rankmarginreal) * var(--sizemultiplier)))`
					: `calc(calc(var(--postwidth) / 3) - calc(var(--boxmarginleft) * var(--sizemultiplier)) - calc(var(--boxmarginright) * var(--sizemultiplier)))`;
			} else if ((maplength > 20 && !settings.postColumns) || settings.postColumns === 2) {
				numColumns = 2;
				height = (settings.postHeight * (1 - titleHeight) - footerHeight) / Math.round(maplength / 2);
				imageWidth = height / 40 + 'em';
				nameFont = height / 35 + 'em';
				statFont = height / 35 + 'em';
				width = hasRank && !settings.rankInside ? 
					`calc(calc(var(--postwidth) / 2) - calc(var(--boxmarginleft) * var(--sizemultiplier)) - calc(var(--boxmarginright) * var(--sizemultiplier)) - calc(var(--rankwidth) * var(--sizemultiplier)) - calc(var(--rankmarginreal) * var(--sizemultiplier)))`
					: `calc(calc(var(--postwidth) / 2) - calc(var(--boxmarginleft) * var(--sizemultiplier)) - calc(var(--boxmarginright) * var(--sizemultiplier)))`;
			}
			if (settings.type === 'postimage' && (settings.orientation === 'topbottom' || settings.orientation === 'bottomtop')) {
				if ((maplength > 75 && !settings.postColumns) || settings.postColumns === 4) {
					height = height - (10 * sizeMultiplier) / Math.round(maplength / 4);
				} else if ((maplength > 50 && !settings.postColumns) || settings.postColumns === 3) {
					height = height - (10 * sizeMultiplier) / Math.ceil(maplength / 3);
				} else if ((maplength > 20 && !settings.postColumns) || settings.postColumns === 2) {
					height = height - (10 * sizeMultiplier) / Math.round(maplength / 2);
				} else {
					height = height - (10 * sizeMultiplier) / maplength;
				}
			}
			if (settings.type === 'postimage' && (settings.orientation === 'leftright' || settings.orientation === 'rightleft')) {
				const rightSideWidth = document.getElementById('newsRight') ? document.getElementById('newsRight').offsetWidth : 400;
				if ((maplength > 75 && !settings.postColumns) || settings.postColumns === 4) {
					width = hasRank && !settings.rankInside ? 
						`calc(calc(${rightSideWidth}px / 4) - calc(var(--boxmarginleft) * var(--sizemultiplier)) - calc(var(--boxmarginright) * var(--sizemultiplier)) - calc(var(--rankwidth) * var(--sizemultiplier)) - calc(var(--rankmarginreal) * var(--sizemultiplier)))`
						: `calc(calc(${rightSideWidth}px / 4) - calc(var(--boxmarginleft) * var(--sizemultiplier)) - calc(var(--boxmarginright) * var(--sizemultiplier)))`;
				} else if ((maplength > 50 && !settings.postColumns) || settings.postColumns === 3) {
					width = hasRank && !settings.rankInside ? 
						`calc(calc(${rightSideWidth}px / 3) - calc(var(--boxmarginleft) * var(--sizemultiplier)) - calc(var(--boxmarginright) * var(--sizemultiplier)) - calc(var(--rankwidth) * var(--sizemultiplier)) - calc(var(--rankmarginreal) * var(--sizemultiplier)))`
						: `calc(calc(${rightSideWidth}px / 3) - calc(var(--boxmarginleft) * var(--sizemultiplier)) - calc(var(--boxmarginright) * var(--sizemultiplier)))`;
				} else if ((maplength > 20 && !settings.postColumns) || settings.postColumns === 2) {
					width = hasRank && !settings.rankInside ? 
						`calc(calc(${rightSideWidth}px / 2) - calc(var(--boxmarginleft) * var(--sizemultiplier)) - calc(var(--boxmarginright) * var(--sizemultiplier)) - calc(var(--rankwidth) * var(--sizemultiplier)) - calc(var(--rankmarginreal) * var(--sizemultiplier)))`
						: `calc(calc(${rightSideWidth}px / 2) - calc(var(--boxmarginleft) * var(--sizemultiplier)) - calc(var(--boxmarginright) * var(--sizemultiplier)))`;
				} else {
					width = hasRank && !settings.rankInside ? 
						`calc(${rightSideWidth}px - calc(var(--boxmarginleft) * var(--sizemultiplier)) - calc(var(--boxmarginright) * var(--sizemultiplier)) - calc(var(--rankwidth) * var(--sizemultiplier)) - calc(var(--rankmarginreal) * var(--sizemultiplier)))`
						: `calc(${rightSideWidth}px - calc(var(--boxmarginleft) * var(--sizemultiplier)) - calc(var(--boxmarginright) * var(--sizemultiplier)))`;
				}
			}
			let playerHeight = height * 1.4 * settings.playerHeight;
			if (settings.type.includes('image')) {
				if (settings.orientation === 'topbottom' || settings.orientation === 'bottomtop') {
					height = (height * settings.paneSplit[1]) / 100;
				}
				imageWidth = Number(imageWidth.replace('em', '')) / 2 + 'em';
				nameFont = Number(nameFont.replace('em', '')) / 2 + 'em';
				statFont = Number(statFont.replace('em', '')) / 2 + 'em';
				playerHeight = height * settings.playerHeight;
			}
			if (isNaN(height)) {
				height = 100;
			}
			height = height - (settings.boxMarginTop * sizeMultiplier) - (settings.boxMarginBottom * sizeMultiplier);
			let boximage, boxbackground;
			let teamObj, teamObj1, teamObj2;
			let boxImageBack = 'none';
			if (t) {
				teamObj = props.getTeamObj(t, s);
				if (teamObj) {
					boximage = (
						<div
							id={`image${i}`}
							className={`boximage`}
							style={{ backgroundImage: teamObj.logo, minWidth: imageWidth, height: height }}></div>
					);
					boxbackground = props.getBoxBackground(teamObj);
					if (settings.boxImageBackground !== 'none') {
						boxImageBack = props.findColor(settings.boxImageBackground, teamObj);
					}
				} else {
					boxbackground = 'transparent';
				}
			}
			let oppBox;
			if (opponents[i]) {
				let opp = opponents[i];
				let oppObj = props.getTeamObj(opp.replace('@', ''), s);
				if (oppObj) {
					const mid = colord(oppObj.secondary).mix(oppObj.primary).toHex();
					oppBox = (
						<div
							className='opponent'
							id={`opponent${i}`}
							style={{ background: `linear-gradient(to bottom, ${oppObj.primary} 0%, ${mid} 50%, ${oppObj.secondary} 100%)` }}>
							<span className='oppAt textglow'>{opp.includes('@') ? '@' : ''}</span>
							<span
								className={`oppImage`}
								style={{ backgroundImage: oppObj?.logo }}></span>
						</div>
					);
				}
			}
			if (team1 && team2) {
				teamObj1 = props.getTeamObj(team1, sport1);
				teamObj2 = props.getTeamObj(team2, sport2);
				let originalWidth = imageWidth;
				let existingSplit = teamsplits.find((split) => split.team === team[i].toUpperCase());
				if (existingSplit?.splitDirection.includes('half')) {
					imageWidth = Number(imageWidth.replace('em', '')) / 2 + 'em';
				}
				boximage = (
					<span
						className='boximagesplit'
						data-team={team[i]}
						style={{ width: originalWidth }}
						onClick={props.flipClip}>
						<div
							className={`boximage no-click ${existingSplit?.splitDirection ? existingSplit?.splitDirection.split('/')[0] : 'cliptopleft'}`}
							style={{ backgroundImage: teamObj1?.logo, minWidth: imageWidth, height: height, marginRight: '0px' }}></div>
						<div
							className={`boximage no-click ${existingSplit?.splitDirection ? existingSplit?.splitDirection.split('/')[1] : 'clipbottomright'}`}
							style={{ backgroundImage: teamObj2?.logo, minWidth: imageWidth, height: height, marginLeft: existingSplit?.splitDirection.includes('half') ? '' : `calc(${imageWidth} * -1)` }}></div>
					</span>
				);
				boxbackground = props.getTwoTeamBoxBackground(teamObj1, teamObj2, existingSplit);
			}
			let splitter = /\s+/;
			if (name[i] && String(name[i]).includes('##')) {
				splitter = '##';
			}
			const boxBorderColor = props.findColor(settings.borderColor, teamObj);
			const boxGlowColor = props.findColor(settings.boxGlowColor, teamObj);
			const rankGlowColor = props.findColor(settings.rankGlowColor, teamObj);
			let statBackground = props.findColor(settings.postStatBackground, teamObj || teamObj1);
			if (maplength < settings.fivelessBreak) {
				statBackground = 'none';
			}
			return (
				<span
					className={`boxwrap${hasRank && settings.rankInside ? ' hasRank' : ''}${(hasBoxMargin() || settings.borderRadius > 5) && settings.borderSize > 2 ? ' withOutline' : ''}${individuallyEdit ? ' editing' : ''}`}
					key={i}>
					{individuallyEdit && (teamObj || teamObj1 || teamObj2) && (
						<span
							className={`edit-box left`}
							onClick={(e) => {
								e.stopPropagation();
								setEditTeam(i);
							}}>
							<i className='fas fa-pencil-alt no-click'></i>
						</span>
					)}
					{hasRank && !settings.rankInside && (
						<span
							style={{
								background: props.findColor(settings.rankBackground, teamObj),
								height: `calc(${height}px + calc(var(--rankoutsidemargin) * var(--sizemultiplier)))`,
								outline: ((hasBoxMargin() || settings.borderRadius > 5) && settings.borderSize > 2) ? `calc(calc(var(--bordersize) * var(--sizemultiplier)) - 2px) solid ${boxBorderColor}` : '',
								boxShadow: settings.rankInside ? `0px 0px ${settings.boxGlowWidth}px ${settings.boxGlowWidth / 5}px ${boxGlowColor}` : `0px 0px ${settings.rankGlowWidth}px ${settings.rankGlowWidth / 5}px ${rankGlowColor}`
							}}
							className={`boxrank ${settings.rankInside ? 'rankInside' : 'rankOutside'}`}
							contentEditable={props.homePage}
							suppressContentEditableWarning={true}
							onClick={() => props.selectSettings('rank')}>
							<span style={{zIndex: '2'}}>{ranks[i]}</span>
						</span>
					)}
					<div
						id={`box${i}`}
						style={{
							height,
							background: boxbackground,
							width,
							outline: ((hasBoxMargin()|| settings.borderRadius > 5) && settings.borderSize > 2) ? `calc(calc(var(--bordersize) * var(--sizemultiplier)) - 2px) solid ${boxBorderColor}` : '',
							boxShadow: `0px 0px ${settings.boxGlowWidth}px ${settings.boxGlowWidth / 5}px ${boxGlowColor}`
						}}
						className={
							'box drag-container' + (numColumns > 1 && !hasBoxMargin() && settings.borderRadius <= 5 && i < Math.round(maplength / numColumns) * (numColumns - 1) ? ' boxborderright' : '')
						}>
						{hasRank && settings.rankInside && (
							<span
								style={{
									order: '-3',
									zIndex: '3',
									background: props.findColor(settings.rankBackground, teamObj)
								}}
								className={`boxrank rankInside`}
								contentEditable={props.homePage}
								suppressContentEditableWarning={true}
								onClick={() => props.selectSettings('rank')}>
								<span style={{zIndex: '2'}}>{ranks[i]}</span>
							</span>
						)}
						{!name[i] && (
							<div
								id={`boxname${i}`}
								className={`boxname ${t}` + (settings.nameShadow === 0 ? ' no-shadow' : '')}></div>
						)}
						{name[i] && maplength < settings.fivelessBreak && (
							<div
								id={`boxname${i}`}
								className={`boxname ${t} flexstart` + (settings.nameShadow === 0 ? ' no-shadow' : '')}
								style={props.getNameStyle(i, nameFont, name[i])}
								onClick={(e) => {
									props.openInlineEditor(e.target, 'name', i);
									props.selectSettings('name');
								}}>
								<span
									id={`spanname${i}`}
									className={'fiveless'}
									contentEditable={props.homePage}
									suppressContentEditableWarning={true}
									style={{ fontSize: (settings.nameSize - 24) * sizeMultiplier + height / 1.75 + 'px' }}>
									{name[i].split(splitter).length <= 1 ? 
										name[i] : 
										(
											<>
												<span>{name[i].split(splitter)[0]}</span>
												<span>{name[i].split(splitter).slice(1).join(' ')}</span>
											</>
										)
									}
								</span>
							</div>
						)}
						{name[i] && name[i].length && (!String(name[i]).includes(',') || String(name[i]).includes('##')) && maplength >= settings.fivelessBreak && (
							<div
								id={`boxname${i}`}
								className={`boxname ${t}` + (settings.nameShadow === 0 ? ' no-shadow' : '')}
								style={name[i].includes('##') ? { height: '100%' } : {}}
								onClick={(e) => {
									props.openInlineEditor(e.target, 'name', i);
									props.selectSettings('name');
								}}>
								<span
									id={`spanname${i}`}
									className={name[i].includes('##') ? ' multi-line-name' : ''}
									contentEditable={props.homePage}
									suppressContentEditableWarning={true}
									style={props.getNameStyle(i, nameFont, name[i])}>
									{name[i].includes('##') ?  name[i].split('##').map((part, i) => (
										<span key={i} className='multi-line-span' style={{ fontSize: `${settings[`line${i + 1}size`]}%` }}>
											{part}
										</span>
									)) : name[i]}
								</span>
							</div>
						)}
						{name[i] && String(name[i]).includes(',') && !String(name[i]).includes('##') && maplength >= settings.fivelessBreak && (
							<div
								id={`boxname${i}`}
								data-edit={i}
								className={`boxname ${t}` + (maplength > 20 ? '' : ' wrap') + (settings.nameShadow === 0 ? ' no-shadow' : '')}>
								<Textfit
									ref={ref}
									max={Number(nameFont.replace('em', '')) * settings.nameSize}
									className='textfit'
									mode={maplength > 20 ? 'single' : 'multi'}
									onReady={(fontSize) => {
										const textFitElement = ref.current;
										if (typeof textFitElement !== 'undefined' && textFitElement !== null) {
											if (fontSize === Number(nameFont.replace('em', '')) * settings.nameSize) {
												textFitElement.props.style.whiteSpace = `pre`;
											}
										}
									}}
									style={{ height: height, maxHeight: height, width: '100%', padding: '.5%' }}>
									{name[i]}
								</Textfit>
							</div>
						)}
						{settings.texture && (
							<div
								className={'boxtexture ' + settings.texture}
								onClick={() => props.selectSettings('team')}></div>
						)}
						{t !== 'BLANK' && settings.logoOpacity !== 0 && (
							<div
								id={`boximage${i}`}
								style={{ background: boxImageBack, marginRight: (settings.logoOrder === 4 || settings.boxImageBackground !== 'none') ? '0' : 'calc(10px * var(--sizemultiplier))' }}
								className={settings.overlap ? `boximagecontainer movelogo postyimg` : 'boximagecontainer postyimg' + (settings.boxImageBackground !== 'none' ? ' withBackground' : '')}
								onClick={() => props.selectSettings('team')}>
								{boximage}
							</div>
						)}
						{settings.duplicate && (
							<div
								id={`2boximage${i}`}
								className={`boximagecontainer2`}>
								{teamObj?.duplicate ? (
									<div
										id={`image${i}`}
										className={`boximage`}
										style={{ backgroundImage: teamObj?.duplicate, minWidth: imageWidth, height: height }}></div>
								) : (
									boximage
								)}
							</div>
						)}
						{props.user.is_admin && combined && (
							<div className='combinedRanks'>
								<span className='combinedRank'>{nflRanks[i]}</span>
								<span className='combinedRank'>{espnRanks[i]}</span>
								<span className='combinedRank'>{proRanks[i]}</span>
								{oppBox}
								<span
									className='oppRank ordinal'
									style={getOppRankColor(oppRanks[i])}>
									{oppRanks[i]}
								</span>
							</div>
						)}
						<div
							id={`boxstat${i}`}
							className={'boxstat' + (maplength < settings.fivelessBreak ? ' nomin' : '') + (settings.statShadow === 0 ? ' no-shadow' : '')}
							style={stat[i] && (String(stat[i]).includes('##') || statBackground !== 'none') ? { height: '100%', marginTop: 'unset', marginRight: `calc(var(--statmargin2) * -1 * var(--sizemultiplier))` } : {}}
							onClick={(e) => {
								props.openInlineEditor(e.target, 'stat', i);
								props.selectSettings('stat');
							}}>
							<span style={{background: statBackground}} className={statBackground === 'none' ? 'spanstatbackground transparent' : 'spanstatbackground nottransparent'}>
								<span
									id={`spanstat${i}`}
									contentEditable={props.homePage}
									suppressContentEditableWarning={true}
									className={'spanstat' + (maplength < settings.fivelessBreak ? ' fiveless' : '') + (String(stat[i]).includes('##') ? ' multi-line-stat' : '')}
									style={props.getStatStyle(i, stat[i], statFont, maplength < settings.fivelessBreak, height)}>
									{stat[i] && String(stat[i]).includes('##') ?  stat[i].split('##').map((part, i) => (
										<span key={i} className='multi-line-span' style={{ fontSize: `${settings[`line${i + 1}sizestat`]}%` }}>
											{part}
										</span>
									)) : stat[i]}
								</span>
							</span>
						</div>
						{imgsrc && imgsrc[0] && playerHeight && (
							<img
								src={imgsrc[0]}
								onError={({ currentTarget }) => {
									currentTarget.onerror = null;
									currentTarget.src = '/transparent.png';
									currentTarget.classList.add('player-image-min-width');
								}}
								onLoad={({ currentTarget }) => {
									currentTarget.onload = null;
									if (!currentTarget.src.includes('transparent.png')) currentTarget.classList.remove('player-image-min-width');
								}}
								alt=''
								style={{ height: settings.nonHeadshot ? '100%' : playerHeight }}
								className={`playerimage ${settings.nonHeadshot ? 'equalWidth' : ''}`}
								onClick={() => props.selectSettings('player')}></img>
						)}
						{imgsrc && imgsrc[1] && playerHeight && (
							<img
								src={imgsrc[1]}
								alt=''
								style={{ height: playerHeight, marginLeft: playerHeight * -0.75 }}
								className='playerimage secondimage'></img>
						)}
						{imgsrc && imgsrc[2] && playerHeight && (
							<img
								src={imgsrc[2]}
								alt=''
								style={{ height: playerHeight, marginLeft: playerHeight * -0.75 }}
								className='playerimage thirdimage'></img>
						)}
						{!img && t !== 'BLANK' && <div className='playerimagespacer'></div>}
						{points[i] && maplength < settings.fivelessBreak && (
							<div
								className={`extrainfoouter`}
								style={{ width: Math.min(playerHeight, settings.postHeight * 0.1875) * 1.376 }}
								onClick={() => props.selectSettings('points')}>
								<div
									className={`extrainfo`}
									style={{ fontSize: (settings.pointsSize - 24) * sizeMultiplier + height / 3.5 + 'px' }}>
									{points[i]}
								</div>
							</div>
						)}
						{(t || team1) && !hasBoxMargin() && settings.borderRadius <= 5 && <div className={`boxborder`} style={{
							background: boxBorderColor,
						}}></div>}
					</div>
				</span>
			);
		});
}
