import axios from 'axios';
import {useState} from 'react';
import Modal from 'react-modal';
import {toast} from 'react-toastify';
import useModalStore from '../../stores/modal.store';
import useSettingsStore from '../../stores/settings.store';
import useUserStore from '../../stores/user.store';

export default function SettingsModal(props) {
	const [settingsTitle, setSettingsTitle] = useState('');
	const { settings } = useSettingsStore();
	const { toggleModal } = useModalStore();
	const { allSettings, setAllSettings } = useUserStore();

	const saveSettings = (e) => {
		e.preventDefault();
		toggleModal('settings');
		axios
			.post(`/settings/saveSettings`, {
				email: props.user.email,
				user_id: props.user._id,
				settings,
				title: settingsTitle,
			})
			.then((response) => {
				if (response.status === 200) {
					toast.success('Settings Saved!', {autoClose: 3000});
				}
				setSettingsTitle('');
				setAllSettings([...allSettings, response.data[0]]);
			});
	};

	return (
		<Modal
			isOpen={true}
			onRequestClose={() => toggleModal('settings')}>
			<div className='modal'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>Save New Settings</h5>
							<span
								className='close-modal'
								onClick={() => toggleModal('settings')}>
								X
							</span>
						</div>
						<div className='modal-body'>
							<form onSubmit={saveSettings}>
								<div className='form-group'>
									<label className='form-label'>Title</label>
									<div className='input-group'>
										<input
											type='text'
											required
											maxLength='25'
											autoFocus
											className='form-control'
											onChange={(e) => {
												setSettingsTitle(e.target.value);
											}}
										/>
									</div>
								</div>
								<button
									type='submit'
									className='btn btn-primary btn-block'>
									Submit
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}
