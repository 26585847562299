import { useEffect, useState } from 'react';
import useSettingsStore from '../../../stores/settings.store';
import { Textfit } from '@gmurph91/react-textfit';
import useGlobalStore from '../../../stores/global.store';

export default function Footer (props: { selectSettings: (arg0: string) => void; user: any }): React.JSX.Element {
    const [reloadKey, setReloadKey] = useState(0);
	const { forceGraphicUpdate, sizeMultiplier } = useGlobalStore();
	const { settings } = useSettingsStore();

    useEffect(() => {
		setReloadKey(prevKey => prevKey + 1);
	}, [forceGraphicUpdate]);

	const alignment = settings.footerAlignment;
	const style: any = { justifyContent: alignment };
	if (settings.type.includes('grid') && settings.gridColumns) {
		style.gridColumnEnd = String(Number(settings.gridColumns) + 1);
	}
	return (
		<div
			className='graphic-footer'
			id='graphicFooter'
			style={style}
			onClick={() => props.selectSettings('footer')}>
			{alignment === 'space-between' && (
				<span
					className='footer-text'
					style={{ transform: 'translateX(-50%)' }}>
					{settings.footerText}
				</span>
			)}
			{props.user.is_admin
            ? (<span className='footer-text textfit'>
					<Textfit mode="single" max={settings.footerSize * sizeMultiplier} key={reloadKey}>
						{settings.footerText}
					</Textfit>
				</span>
			)
            : (
				<span className='footer-text'>{settings.footerText}</span>
			)}
			{alignment === 'space-between' && (
				<span
					className='footer-text'
					style={{ transform: 'translateX(50%)' }}>
					{settings.footerText}
				</span>
			)}
		</div>
	);
}
