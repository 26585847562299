import React from 'react';
import { HexAlphaColorPicker, HexColorInput } from 'react-colorful';
import { colord } from 'colord';
import { useDebouncyFn } from 'use-debouncy';

interface ChromePickerProps {
  color: string;
  onChange: (color: string) => void;
  presetColors?: string[];
}

export const ChromePicker: React.FC<ChromePickerProps> = ({
  color,
  onChange,
  presetColors
}) => {
  const handleChange = useDebouncyFn(
    (color: string) => onChange(color),
    100
  );

  return (
    <div className='picker'>
      <HexAlphaColorPicker
        color={color}
        onChange={handleChange}
      />
      <HexColorInput
        color={color}
        onChange={handleChange}
        alpha
        prefixed
      />

      {presetColors && presetColors.length > 0 && (
        <div className='picker__swatches'>
          {presetColors.map((presetColor) => (
            <button
              key={presetColor}
              className='picker__swatch'
              style={{ background: presetColor }}
              onClick={() => handleChange(presetColor)}
            />
          ))}
        </div>
      )}
      {colord(color).alpha() === 0 && (
        <span className='note'>Please note color is currently transparent, change transparency slider if needed</span>
      )}
    </div>
  );
};
