import useGlobalStore from '../../../stores/global.store';
import useWithImageStore from '../../../stores/posts/with-image.store';
import useSettingsStore from '../../../stores/settings.store';
import useUserStore from '../../../stores/user.store';
import {colord, extend} from 'colord';
import mixPlugin from 'colord/plugins/mix';
extend([mixPlugin]);

export default function HighlightFormat(props) {
	const { settings } = useSettingsStore();
	const { newstitle, newssubtitle, newsteam } = useWithImageStore();
	const { individuallyEdit, setEditTeam } = useGlobalStore();
	const { sport } = useUserStore();

	const t = newsteam.toUpperCase();
	const teamObj = props.getTeamObj(t, sport);
	const boxbackground = props.getBoxBackground(teamObj);
	return (
		<div
			className={`newsRight highlightImage ${settings.imageStyle?.value === 'style-3' ? 'hasImage3' : ''}`}
			style={{ background: boxbackground }}>
			{settings.texture && <div className={'boxtexture ' + settings.texture}></div>}
			{individuallyEdit && teamObj && settings.imageStyle?.value !== 'style-3' && (
				<span
					className={`edit-box left`}
					onClick={(e) => {
						e.stopPropagation();
						setEditTeam(0);
					}}>
					<i className='fas fa-pencil-alt no-click'></i>
				</span>
			)}
			{newstitle &&
				(settings.newsStyle?.value === 'style-4' ? (
					<div
						className='highlightTitle'
						onClick={() => props.selectSettings('header')}>
						<span
							className='textBoxTop'
							contentEditable={props.homePage}
							suppressContentEditableWarning={true}>
							{newstitle.split(/\s+/)[0]}
						</span>
						<span
							className='textBoxBottom'
							style={{ background: props.findColor(settings.titleBackground, teamObj) }}
							contentEditable={props.homePage}
							suppressContentEditableWarning={true}>
							<span>{newstitle.replace(newstitle.split(/\s+/)[0], '').trim()}</span>
						</span>
					</div>
				) : (
					<div
						className='highlightTitle'
						contentEditable={props.homePage}
						suppressContentEditableWarning={true}
						onClick={() => props.selectSettings('header')}>
						{newstitle}
					</div>
				))}
			{settings.imageStyle?.value !== 'style-3' && (
				<div
					id={`image1000-news`}
					onClick={(e) => {
						props.selectSettings('team');
					}}
					className='newsTeam'
					style={{ backgroundImage: teamObj?.logo }}></div>
			)}

			<span className={newstitle ? 'textBoxes' : 'textBoxes noTitle'}>
				{newssubtitle.split(/\r?\n/).map((text, i) => {
					return (
						<span
							className='textBox'
							key={i}
							onClick={(e) => {
								props.selectSettings('subtitle');
							}}>
							<span
								className='textBoxTop'
								contentEditable={props.homePage}
								suppressContentEditableWarning={true}>
								{text.split(/\s+/)[0]}
							</span>
							<span
								className='textBoxBottom'
								style={settings.newsStyle?.value === 'style-4' ? { background: props.findColor(settings.textBackground, teamObj) } : {}}
								contentEditable={props.homePage}
								suppressContentEditableWarning={true}>
								<span>{text.replace(text.split(/\s+/)[0], '').trim()}</span>
							</span>
						</span>
					);
				})}
			</span>
			{settings.newsStyle?.value === 'style-1' && (
				<span className='news-lines'>
					<span className='news-line-1'></span>
					<span className='news-line-2'></span>
					<span className='news-line-3'></span>
					<span className='news-line-4'></span>
					<span className='news-line-5'></span>
				</span>
			)}
			{settings.newsStyle?.value === 'style-4' && (
				<div className='lines-div' style={props.getLinesSvg()}></div>
			)}
		</div>
	);
}
