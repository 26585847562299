import axios from 'axios';
import {useState} from 'react';
import Modal from 'react-modal';
import {toast} from 'react-toastify';
import {deleteFile} from '../../firebase/firebase';
import useModalStore from '../../stores/modal.store';
import useGlobalStore from '../../stores/global.store';
import useUserStore from '../../stores/user.store';

export default function DeletePostModal(props) {
	const { toggleModal } = useModalStore();
	const { pendingDelete, debug } = useGlobalStore();
	const { savedPosts, setSavedPosts } = useUserStore();

	const deletePost = () => {
		try {
			const ids = pendingDelete.selected ? pendingDelete.selected.join(',') : pendingDelete.item._id
			axios.delete(`/posts/deletePost/${ids}`).then((response) => {
				if (response.status === 200) {
					const filteredSavedPosts = savedPosts.filter((post) => !ids.includes(post._id));
					setSavedPosts(filteredSavedPosts);
					if (pendingDelete.item) {
						const item = pendingDelete.item;
						if (item.newsimage && item.newsimage.includes('firebase')) {
							// if no other posts re-use image then delete it
							const count = savedPosts.filter((obj) => obj.newsimage === item.newsimage).length;
							if (count === 0) {
								deleteFile(item.newsimage);
							}
						}
					}
					toggleModal('delete-post');
					toast.error(pendingDelete.selected ? 'Posts Deleted!' : 'Post Deleted!', {autoClose: 1500});
				}
			});
		} catch (e) {
			if (debug) {
				console.log(e);
			}
		}
	};

	const multiDelete = pendingDelete.selectedTitles?.length > 0;
	let title;
	if (!multiDelete) {
		let post = pendingDelete.item;
		title = post.title ? post.title : post.newstitle ? post.newstitle + ' - ' + post.newsteam : 'Untitled';
		if (post.settings.type === 'matchupimage') {
			title = `${post.team.join(' - ')} Matchup`;
		}
		if (post.savedTitle) {
			title = post.savedTitle;
		}
	}
	return (
		<Modal
			isOpen={true}
			onRequestClose={() => toggleModal('delete-post')}
			shouldCloseOnOverlayClick={true}
			shouldCloseOnEsc={true}>
			<div
				className='modal'
				onClick={(e) => props.closeModal(e, 'delete-post')}>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>{multiDelete ? 'Delete Posts' : 'Delete Post'}</h5>
							<span
								className='close-modal'
								onClick={() => toggleModal('delete-post')}>
								X
							</span>
						</div>
						<div className='modal-body'>
							{!multiDelete && (<p>Are you sure you want to delete {title}?</p>)}
							{multiDelete && (<span><p>Are you sure you want to delete the following posts?</p>
								<ul>
									{pendingDelete.selectedTitles.map((title, i) => {
										return <li key={i}>{title}</li>
									})}
								</ul>
							</span>)}
							<p>This cannot be undone.</p>
						</div>
						<div className='modal-footer'>
							<button
								type='button'
								onClick={() => toggleModal('delete-post')}
								className='btn btn-primary secondary'
								data-dismiss='modal'>
								Cancel
							</button>
							<button
								type='submit'
								onClick={deletePost}
								className='btn btn-primary'>
								Delete
							</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}
