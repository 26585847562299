export const nflPrefill = {
	team: ['MIA', 'DET', 'DAL', 'BUF', 'SF', 'KC', 'GB', 'HOU', 'TB', 'JAX'],
	name: ['Tua Tagovailoa', 'Jared Goff', 'Dak Prescott', 'Josh Allen', 'Brock Purdy', 'Patrick Mahomes', 'Jordan Love', 'C.J. Stroud', 'Baker Mayfield', 'Trevor Lawrence'],
	image: ['4241479', '3046779', '2577417', '3918298', '4361741', '3139477', '4036378', '4432577', '3052587', '4360310'],
};

export const nbaPrefill = {
	team: ['GSW', 'MIL', 'DEN', 'PHO', 'DAL', 'MIL', 'PHO', 'NYK', 'BOS', 'NOP'],
	name: ['Stephen Curry', 'Damian Lillard', 'Nikola Jokic', 'Bradley Beal', 'Luka Doncic', 'Giannis Antetokounmpo', 'Devin Booker', 'Julius Randle', 'Jayson Tatum', 'Zion Williamson'],
	image: ['3975', '6606', '3112335', '6580', '3945274', '3032977', '3136193', '3064514', '4065648', '4395628'],
};

export const mlbPrefill = {
	team: ['MIL', 'TEX', 'LAD', 'MIL', 'PHI', 'TOR', 'SEA', 'LAD', 'CHC', 'ATL'],
	name: ['Corbin Burnes', 'Max Scherzer', 'Walker Buehler', 'Brandon Woodruff', 'Zack Wheeler', 'Kevin Gausman', 'Robbie Ray', 'Julio Urias', 'Marcus Stroman', 'Max Fried'],
	image: ['39878', '28976', '39251', '37515', '31267', '32667', '32175', '33223', '32815', '32685'],
};

export const ncaaPrefill = {
	team: ['COLO', 'USC', 'WASH', 'ND', 'SC', 'OU', 'UGA', 'ORE', 'UNC', 'UMD'],
	name: ['Shedeur Sanders', 'Caleb Williams', 'Michael Penix Jr.', 'Sam Hartman', 'Spencer Rattler', 'Dillon Gabriel', 'Carson Beck', 'Bo Nix', 'Drake Maye', 'Taulia Tagovailoa'],
	image: ['4432762', '4431611', '4360423', '4361994', '4426339', '4427238', '4430841', '4426338', '4431452', '4567097'],
};

export const nhlPrefill = {
	team: ['EDM', 'EDM', 'BOS', 'TOR', 'TOR', 'COL', 'CHI', 'COL', 'WPG', 'PIT'],
	name: ['Connor McDavid', 'Leon Draisaitl', 'Brad Marchand', 'Mitch Marner', 'Auston Matthews', 'Mikko Rantanen', 'Patrick Kane', 'Nathan MacKinnon', 'Mark Scheifele', 'Sidney Crosby'],
	image: ['3895074', '3114727', '3852', '3899937', '4024123', '3899938', '3735', '3041969', '2562632', '3114'],
};

export const soccerPrefill = {
	team: ['TOT', 'LIV', 'MAN', 'TOT', 'LEE', 'EVE', 'ARS', 'LEI', 'MNC', 'AVL'],
	name: ['Harry Kane', 'Mohamed Salah', 'Bruno Fernandes', 'Son Heung-Min', 'Patrick Bamford', 'Dominic Calvert-Lewin', 'Alexandre Lacazette', 'Jamie Vardy', 'Ilkay Gündogan', 'Ollie Watkins'],
	image: []
};

export const f1Prefill = {
	team: ['RED', 'FER', 'MER', 'ALPN', 'MCL', 'ALF', 'HAAS', 'ALPH', 'AST', 'WIL'],
	name: ['Red Bull Racing', 'Ferrari', 'Mercedes-AMG', 'Alpine', 'McLaren', 'Alfa Romeo', 'Haas', 'AlphaTauri', 'Aston Martin', 'Williams'],
	image: []
};

export const wnbaPrefill = {
	team: ['SEA', 'LV', 'PHX', 'DAL', 'LV', 'IND', 'LA', 'NY', 'PHX', 'SEA'],
	name: ['Breanna Stewart', 'Kelsey Plum', 'Skylar Diggins-Smith', 'Arike Ogunbowale', "A'ja Wilson", 'Kelsey Mitchell', 'Nneka Ogwumike', 'Sabrina Ionescu', 'Diana Taurasi', 'Jewell Loyd'],
	image: []
};

export const aflPrefill = {
	team: ['GEEL', 'GEEL', 'CARL', 'RICH', 'MELB', 'BL', 'GEEL', 'ESS', 'SYD', 'STK'],
	name: ['Tom Hawkins', 'Jeremy Cameron', 'Charlie Curnow', 'Tom J. Lynch', 'Bayley Fritsch', 'Charlie Cameron', 'Tyson Stengle', 'Peter Wright', 'Lance Franklin', 'Max King'],
	image: [
		'https://s.afl.com.au/staticfile/AFL%20Tenant/AFL/Players/ChampIDImages/XLarge2022/261510.png',
		'https://s.afl.com.au/staticfile/AFL%20Tenant/AFL/Players/ChampIDImages/XLarge2022/293845.png',
		'https://s.afl.com.au/staticfile/AFL%20Tenant/AFL/Players/ChampIDImages/XLarge2022/996731.png',
		'https://s.afl.com.au/staticfile/AFL%20Tenant/AFL/Players/ChampIDImages/XLarge2022/293813.png',
		'https://s.afl.com.au/staticfile/AFL%20Tenant/AFL/Players/ChampIDImages/XLarge2022/1001438.png',
		'https://s.afl.com.au/staticfile/AFL%20Tenant/AFL/Players/ChampIDImages/XLarge2022/990609.png',
		'https://s.afl.com.au/staticfile/AFL%20Tenant/AFL/Players/ChampIDImages/XLarge2022/997230.png',
		'https://s.afl.com.au/staticfile/AFL%20Tenant/AFL/Players/ChampIDImages/XLarge2022/298289.png',
		'https://s.afl.com.au/staticfile/AFL%20Tenant/AFL/Players/ChampIDImages/XLarge2022/240399.png',
		'https://s.afl.com.au/staticfile/AFL%20Tenant/AFL/Players/ChampIDImages/XLarge2022/1006143.png',
	],
};
