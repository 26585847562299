import { create } from 'zustand';

interface UserStore {
    sport: string;
    allSettings: any;
    selectedSettings: string;
    lockedSettings: any;
    customTeams: any;
    requestedChange: boolean;
    savedPosts: any;
    folders: any;
    teamOverrides: any;
    currentPost: any;
    fetchedPosts: boolean;
    setSport: (newValue: string) => void;
    setAllSettings: (newValue: any) => void;
    setSelectedSettings: (newValue: string) => void;
    setLockedSettings: (newValue: any) => void;
    setCustomTeams: (newValue: any) => void;
    setRequestedChange: (newValue: boolean) => void;
    setSavedPosts: (newValue: any) => void;
    setFolders: (newValue: any) => void;
    setTeamOverrides: (newValue: any) => void;
    setCurrentPost: (newValue: any) => void;
    setFetchedPosts: (newValue: boolean) => void;
}

const useUserStore = create<UserStore>((set) => ({
    sport: '',
    allSettings: [],
    selectedSettings: '',
    lockedSettings: [],
    customTeams: [],
    requestedChange: false,
    savedPosts: [],
    folders: [],
    teamOverrides: [],
    currentPost: {},
    fetchedPosts: false,
    setSport: (newValue) => set({ sport: newValue }),
    setAllSettings: (newValue) => set({ allSettings: newValue }),
    setSelectedSettings: (newValue) => set({ selectedSettings: newValue }),
    setLockedSettings: (newValue) => set({ lockedSettings: newValue }),
    setCustomTeams: (newValue) => set({ customTeams: newValue }),
    setRequestedChange: (newValue) => set({ requestedChange: newValue }),
    setSavedPosts: (newValue) => set({ savedPosts: newValue }),
    setFolders: (newValue) => set({ folders: newValue }),
    setTeamOverrides: (newValue) => set({ teamOverrides: newValue }),
    setCurrentPost: (newValue) => set({ currentPost: newValue }),
    setFetchedPosts: (newValue) => set({ fetchedPosts: newValue })
}));

export default useUserStore;
