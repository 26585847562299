export const passingStatsNCAA = [
	{
	  label: "Passing Yards",
	  value: "cfbplayerstats/passing-passingyards",
	},
	{
	  label: "Completions",
	  value: "cfbplayerstats/passing-completions",
	},
	{
	  label: "Passing Attempts",
	  value: "cfbplayerstats/passing-passingattempts",
	},
	{
	  label: "Completion Percentage",
	  value: "cfbplayerstats/passing-completionpct",
	},
	{
	  label: "Yards Per Pass Attempt",
	  value: "cfbplayerstats/passing-yardsperpassattempt",
	},
	{
	  label: "Longest Pass",
	  value: "cfbplayerstats/passing-longpassing",
	},
	{
	  label: "Passing Touchdowns",
	  value: "cfbplayerstats/passing-passingtouchdowns",
	},
	{
	  label: "Interceptions",
	  value: "cfbplayerstats/passing-interceptions",
	},
	{
	  label: "Total Sacks",
	  value: "cfbplayerstats/passing-sacks",
	},
	{
	  label: "Sack Yards Lost",
	  value: "cfbplayerstats/passing-sackyardslost",
	},
	{
	  label: "Passer Rating",
	  value: "cfbplayerstats/passing-qbrating",
	},
  ];

  export const rushingStatsNCAA = [
	{
	  label: "Rushing Yards",
	  value: "cfbplayerstats/rushing-rushingyards",
	},
	{
	  label: "Rushing Attempts",
	  value: "cfbplayerstats/rushing-rushingattempts",
	},
	{
	  label: "Yards Per Rush Attempt",
	  value: "cfbplayerstats/rushing-yardsperrushattempt",
	},
	{
	  label: "Long Rushing",
	  value: "cfbplayerstats/rushing-longrushing",
	},
	{
	  label: "20+ Yard Rushing Plays",
	  value: "cfbplayerstats/rushing-rushingbigplays",
	},
	{
	  label: "Rushing Touchdowns",
	  value: "cfbplayerstats/rushing-rushingtouchdowns",
	},
	{
	  label: "Rushing Yards Per Game",
	  value: "cfbplayerstats/rushing-rushingyardspergame",
	},
	{
	  label: "Rushing Fumbles",
	  value: "cfbplayerstats/rushing-rushingfumbles",
	},
	{
	  label: "Rushing Fumbles Lost",
	  value: "cfbplayerstats/rushing-rushingfumbleslost",
	},
	{
	  label: "Rushing 1st downs",
	  value: "cfbplayerstats/rushing-rushingfirstdowns",
	},
  ];

  export const receivingStatsNCAA = [
	{
	  label: "Receptions",
	  value: "cfbplayerstats/receiving-receptions",
	},
	{
	  label: "Receiving Targets",
	  value: "cfbplayerstats/receiving-receivingtargets",
	},
	{
	  label: "Receiving Yards",
	  value: "cfbplayerstats/receiving-receivingyards",
	},
	{
	  label: "Yards Per Reception",
	  value: "cfbplayerstats/receiving-yardsperreception",
	},
	{
	  label: "Receiving Touchdowns",
	  value: "cfbplayerstats/receiving-receivingtouchdowns",
	},
	{
	  label: "Long Reception",
	  value: "cfbplayerstats/receiving-longreception",
	},
	{
	  label: "20+ Yard Receiving Plays",
	  value: "cfbplayerstats/receiving-receivingbigplays",
	},
	{
	  label: "Receiving Yards Per Game",
	  value: "cfbplayerstats/receiving-receivingyardspergame",
	},
	{
	  label: "Receiving Fumbles",
	  value: "cfbplayerstats/receiving-receivingfumbles",
	},
	{
	  label: "Receiving Fumbles Lost",
	  value: "cfbplayerstats/receiving-receivingfumbleslost",
	},
	{
	  label: "Receiving Yards After Catch",
	  value: "cfbplayerstats/receiving-receivingyardsaftercatch",
	},
	{
	  label: "Receiving First Downs",
	  value: "cfbplayerstats/receiving-receivingfirstdowns",
	},
  ];

  export const defensiveStatsNCAA = [
	{
	  label: "Solo Tackles",
	  value: "cfbplayerstats/defensive-solotackles",
	},
	{
	  label: "Assist Tackles",
	  value: "cfbplayerstats/defensive-assisttackles",
	},
	{
	  label: "Total Tackles",
	  value: "cfbplayerstats/defensive-totaltackles",
	},
	{
	  label: "Sacks",
	  value: "cfbplayerstats/defensive-sacks",
	},
	{
	  label: "Sack Yards",
	  value: "cfbplayerstats/defensive-sackyards",
	},
	{
	  label: "Tackles For Loss",
	  value: "cfbplayerstats/defensive-tacklesforloss",
	},
	{
	  label: "Passes Defended",
	  value: "cfbplayerstats/defensive-passesdefended",
	},
	{
	  label: "Long Interception",
	  value: "cfbplayerstats/defensive-longinterception",
	},
	{
	  label: "Interceptions",
	  value: "cfbplayerstats/defensiveinterceptions-interceptions",
	},
	{
	  label: "Interception Yards",
	  value: "cfbplayerstats/defensiveinterceptions-interceptionyards",
	},
	{
	  label: "Interception Touchdowns",
	  value: "cfbplayerstats/defensiveinterceptions-interceptiontouchdowns",
	},
	{
	  label: "Forced Fumbles",
	  value: "cfbplayerstats/general-fumblesforced",
	},
	{
	  label: "Fumbles Recovered",
	  value: "cfbplayerstats/general-fumblesrecovered",
	},
	{
	  label: "Fumbles Touchdowns",
	  value: "cfbplayerstats/general-fumblestouchdowns",
	},
  ];