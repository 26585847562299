import {useEffect, useMemo, useState} from 'react';
import {ToastContainer, toast} from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.css';
import {mlbplayerstats, nflplayerstats, nbaplayerstats, nhlplayerstats, cfbplayerstats, cbbplayerstats, nflteamstats, nbateamstats, mlbteamstats, nhlteamstats, cfbteamstats, cbbteamstats} from '../../data/stats/stats';
import Select from 'react-select';
import {settingsStyles} from '../../data/styles/settingsStyles';
import VirtualTable from '../../components/VirtualTable';
import {settingsStylesDark} from '../../data/styles/settingsStylesDark';
import axios from 'axios';
import usePreferencesStore from '../../stores/preferences.store';
const statorder = ['mlbplayerstats', 'nflplayerstats', 'nbaplayerstats', 'nhlplayerstats', 'cfbplayerstats', 'cbbplayerstats', 'nflteamstats', 'nbateamstats', 'mlbteamstats', 'nhlteamstats', 'cfbteamstats', 'cbbteamstats'];
const statarray = [mlbplayerstats, nflplayerstats, nbaplayerstats, nhlplayerstats, cfbplayerstats, cbbplayerstats, nflteamstats, nbateamstats, mlbteamstats, nhlteamstats, cfbteamstats, cbbteamstats];

export default function Stats(props) {
	const [stats, setStats] = useState([]);
	const [filteredStats, setFilteredStats] = useState([]);
	const [uniqueStats, setUniqueStats] = useState([]);
	const [sanitizedStats, setSanitizedStats] = useState([]);
	const [positions, setPositions] = useState([]);
	const [sports] = useState(['NFL', 'NBA', 'MLB', 'NHL', 'CFB', 'CBB']);
	const [selectedSport, setSelectedSport] = useState('NFL');
	const [selectedPosition, setSelectedPosition] = useState(null);
	const [subCategory, setSubCategory] = useState('player');
	const [sortAsc, setSortAsc] = useState(true);
	const [loading, setLoading] = useState(true);
	const { darkMode } = usePreferencesStore();

	useEffect(() => {
		setSelectedSport('NFL');
	}, []);

	useMemo(() => {
		try {
			setLoading(true);
			const collection = `${selectedSport.toLowerCase()}${subCategory}stats`;
			axios.get(`/stats/mystats/${collection}`).then((response) => {
				if (response.data) {
					const stats = response.data;
					stats.forEach((stat) => {
						delete stat._id;
					});
					const positions = [...new Set(stats.map((player) => player.position))];
					let positionsArray = [];
					positions.forEach((pos) => {
						positionsArray.push({label: pos, value: pos});
					});
					let uniqueStats = Object.keys(
						stats.reduce((result, obj) => {
							return Object.assign(result, obj);
						}, {})
					);
					let sanitizedStats = {
						player_id: 'ID',
						player: 'NAME',
						name: 'NAME',
						position: 'POS',
						team: 'TEAM',
						'general-gamesplayed': 'GP',
						team_games_played: 'TEAM GP',
						updated_at: 'UPDATED',
					};
					const statindex = statorder.findIndex((stat) => stat === collection);
					statarray[statindex].forEach((cat) => {
						cat.names.forEach((name, i) => {
							sanitizedStats[`${cat.name}-${name.toLowerCase()}`] = cat.labels[i];
						});
					});
					uniqueStats = uniqueStats.filter((stat) => sanitizedStats[stat]);
					uniqueStats.sort((a, b) => Object.keys(sanitizedStats).indexOf(a) - Object.keys(sanitizedStats).indexOf(b));
					setStats(stats);
					setFilteredStats(stats);
					setUniqueStats(uniqueStats);
					setPositions(positionsArray);
					setSanitizedStats(sanitizedStats);
					setLoading(false);
				}
			});
		} catch (e) {
			console.log(e);
			toast.error('something went wrong, please reload the page');
		}
	}, [selectedSport, subCategory]);

	const sortTable = (sortVal) => {
		filteredStats.sort((a, b) => {
			if (!a.hasOwnProperty(sortVal)) {
				return 1;
			}
			if (!b.hasOwnProperty(sortVal)) {
				return -1;
			}
			if (isNaN(a[sortVal]) || isNaN(b[sortVal])) {
				return sortAsc ? a[sortVal].localeCompare(b[sortVal]) : b[sortVal].localeCompare(a[sortVal]);
			}
			return sortAsc ? Number(a[sortVal]) - Number(b[sortVal]) : Number(b[sortVal]) - Number(a[sortVal]);
		});
		setFilteredStats(filteredStats);
		setSortAsc(!sortAsc);
	};

	const changePosition = async (selectedPosition) => {
		let filteredStats = stats.filter((stat) => {
			return stat.position === selectedPosition.value;
		});
		let uniqueStats = Object.keys(
			filteredStats.reduce((result, obj) => {
				return Object.assign(result, obj);
			}, {})
		);
		let sanitizedStats = {
			player_id: 'ID',
			player: 'NAME',
			name: 'NAME',
			position: 'POS',
			team: 'TEAM',
			'general-gamesplayed': 'GP',
			team_games_played: 'TEAM GP',
			updated_at: 'UPDATED',
		};
		const collection = `${selectedSport.toLowerCase()}${subCategory}stats`;
		const statindex = statorder.findIndex((stat) => stat === collection);
		statarray[statindex].forEach((cat) => {
			cat.names.forEach((name, i) => {
				sanitizedStats[`${cat.name}-${name.toLowerCase()}`] = cat.labels[i];
			});
		});
		uniqueStats = uniqueStats.filter((stat) => sanitizedStats[stat]);
		uniqueStats.sort((a, b) => Object.keys(sanitizedStats).indexOf(a) - Object.keys(sanitizedStats).indexOf(b));
		setSelectedPosition(selectedPosition);
		setFilteredStats(filteredStats);
		setUniqueStats(uniqueStats);
		setSanitizedStats(sanitizedStats);
	};

	return (
		<div className='AdminPage Stats'>
			<ToastContainer />
			<div className='main'>
				<div className='stat-category'>
					{sports.map((sport, i) => {
						return (
							<div
								key={i}
								className={`sport-btn ${selectedSport === sport ? 'selected' : ''}`}
								onClick={() => setSelectedSport(sport)}>
								{sport}
							</div>
						);
					})}
				</div>
				<div className='stat-subcategory'>
					<div
						className={`sub-btn ${subCategory === 'player' ? 'selected' : ''}`}
						onClick={() => setSubCategory('player')}>
						Player
					</div>
					<div
						className={`sub-btn ${subCategory === 'team' ? 'selected' : ''}`}
						onClick={() => setSubCategory('team')}>
						Team
					</div>
				</div>
				<div className='stat-filters'>
					{subCategory === 'player' && (
						<Select
							value={selectedPosition}
							placeholder='Position'
							onChange={changePosition}
							options={positions}
							styles={darkMode ? settingsStylesDark : settingsStyles}
							isSearchable={false}
						/>
					)}
				</div>
				{!loading && (
					<VirtualTable
						filteredStats={filteredStats}
						uniqueStats={uniqueStats}
						sanitizedStats={sanitizedStats}
						sortTable={sortTable}
					/>
				)}
				{loading && (
					<img
						id='loadingIcon'
						src='/loading.gif'
						alt='loading'
					/>
				)}
			</div>
		</div>
	);
}
