import { useEffect, useState } from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { GoogleLogin } from '@react-oauth/google';
import usePreferencesStore from '../stores/preferences.store';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

export default function Registration() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [name, setName] = useState('');
	const [reveal, setReveal] = useState(false);
	const [referralCode, setReferralCode] = useState('');
	const [referralCodeReadOnly, setReferralCodeReadOnly] = useState(false);
	const [referralExpanded, setReferralExpanded] = useState(false);
	const { darkMode } = usePreferencesStore();
	const [passwordFocused, setPasswordFocused] = useState(false);

	useEffect(() => {
		if (searchParams.get('referral')) {
			setReferralCode(searchParams.get('referral'));
			setReferralCodeReadOnly(true);
			setReferralExpanded(true);
		}
	}, [searchParams]);

	const register = (e) => {
		e.preventDefault();
		axios
			.post(`/logins/register`, {
				email,
				password,
				name,
				referralCode
			})
			.then((response) => {
				localStorage.setItem('token', response.data.data.token);
				navigate('/dashboard');
			})
			.catch((error) => {
				if (error.data.error === 'Email already exists') {
					toast.error('An account exists with this email already.  Please sign in.');
				} else {
					toast.error('An unknown error occured.');
				}
			});
	};

	const googleLogin = (credential) => {
		axios
			.post('logins/google', {
				credential,
				referralCode
			})
			.then((response) => {
				localStorage.setItem('token', response.data.data.token);
				navigate('/dashboard');
			})
			.catch((err) => {
				toast.error('Sign up failed, please try again');
			});
	};

	return (
		<div className='login-wrapper'>
			<div className='login'>
				<ToastContainer />
				<form onSubmit={register} autoComplete='on'>
					<h3>Create an account</h3>
					<div className='form-group floating-label'>
						<input
							type='text'
							required
							className='form-control'
							autoComplete='name'
							onChange={(e) => {
								setName(e.target.value);
							}}
						/>
						<label className={'form-label' + (name && ' filled')}>Name</label>
					</div>

					<div className='form-group floating-label'>
						<input
							type='email'
							required
							autoComplete='email'
							className='form-control'
							onChange={(e) => {
								setEmail(e.target.value);
							}}
						/>
						<label className={'form-label' + (email && ' filled')}>Email address</label>
					</div>

					<div className='form-group floating-label'>
						<div className='input-group reverse'>
							<div className='input-group-append'>
								<div
									onClick={() => setReveal(!reveal)}
									className='input-group-text'>
									<i className={reveal ? 'fa fa-eye-slash' : 'fa fa-eye'}></i>
								</div>
							</div>
							<input
								type={reveal ? 'text' : 'password'}
								required
								pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}'
								className='form-control'
								autoComplete='new-password'
								onFocus={() => {
									setPasswordFocused(true);
								}}
								onBlur={() => {
									setPasswordFocused(false);
								}}
								onChange={(e) => {
									setPassword(e.target.value);
								}}
							/>
							<Tippy
								visible={passwordFocused}
								content={
									<span className='password-reqs'>
										<p>Password must contain:</p>
										<ul>
											<li>
												<i className={'far ' + (password.length > 8 ? 'fa-check-circle' : 'fa-circle-xmark')}></i>At least 8 characters
											</li>
											<li>
												<i className={'far ' + (password.match(/[A-Z]/) ? 'fa-check-circle' : 'fa-circle-xmark')}></i>At least one uppercase letter
											</li>
											<li>
												<i className={'far ' + (password.match(/[a-z]/) ? 'fa-check-circle' : 'fa-circle-xmark')}></i>At least one lowercase letter
											</li>
											<li>
												<i className={'far ' + (password.match(/[0-9]/) ? 'fa-check-circle' : 'fa-circle-xmark')}></i>At least one number
											</li>
										</ul>
									</span>
								}>
								<span className='full-width'></span>
							</Tippy>
						</div>
						<label className={'form-label' + (password && ' filled')}>Password</label>
					</div>

					{referralExpanded && <div className='form-group floating-label'>
						<input
							type='text'
							className='form-control'
							defaultValue={referralCode}
							readOnly={referralCodeReadOnly}
							onChange={(e) => {
								setReferralCode(e.target.value);
							}}
						/>
						<label className={'form-label' + (referralCode && ' filled')}>Referral Code</label>
						<span className='close-code' onClick={() => setReferralExpanded(false)} tabIndex={0}>X</span>
					</div>}

					{!referralExpanded && <div className='below-login'>
						<p className='have-code text-end'>
							<span onClick={() => setReferralExpanded(true)}>Have a referral code?</span>
						</p>
					</div>}

					<button
						type='submit'
						className='btn btn-primary btn-block'>
						Sign Up
					</button>

					<div className='or'>
						<hr />
						<span>Or</span>
					</div>

					<div className='sso'>
						<GoogleLogin
							size='medium'
							shape='pill'
							text='signup_with'
							context='signup'
							theme={darkMode ? 'filled_black' : 'outline'}
							onSuccess={(credentialResponse) => {
								googleLogin(credentialResponse.credential);
							}}
							onError={() => {
								toast.error('Sign up failed, please try again');
							}}
						/>
					</div>
					<div className='links'>
						<p className='sign-up'>
							<Link to='/login'>Already Registered? Log In</Link>
						</p>
					</div>
				</form>
			</div>
		</div>
	);
}
