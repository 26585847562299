import AddTeamModal from './modals/AddTeamModal';
import CancelModal from './modals/CancelModal';
import ChangeModal from './modals/ChangeModal';
import DeleteTeamModal from './modals/DeleteTeamModal';
import HelpModal from './modals/HelpModal';
import ImageModal from './modals/ImageModal';
import LogoModal from './modals/LogoModal';
import PasswordModal from './modals/PasswordModal';
import ProfileModal from './modals/ProfileModal';
import SettingsModal from './modals/SettingsModal';
import TimeoutModal from './modals/TimeoutModal';
import VideoModal from './modals/VideoModal';
import BackgroundModal from './modals/BackgroundModal';
import DeletePostModal from './modals/DeletePostModal';
import DeleteSettingsModal from './modals/DeleteSettingsModal';
import ReferralModal from './modals/ReferralModal';
import QuickSettingsModal from './modals/QuickSettingsModal';
import OverridesModal from './modals/OverridesModal';
import FolderModal from './modals/FolderModal';
import MoveFolderModal from './modals/MoveFolderModal';
import useModalStore from '../stores/modal.store';
import PasteTableModal from './modals/PasteTableModal';
import RenameModal from './modals/RenameModal';
import UploadModal from './modals/UploadModal';

export default function ModalManager(props) {
	const { isModalOpen, toggleModal } = useModalStore();

	const closeModal = (e, name) => {
		if (e.target.closest('.modal-dialog') === null) {
			toggleModal(name);
		}
	};

	return (
		<div>
			{isModalOpen('help') && (
				<HelpModal
					closeModal={closeModal}
					user={props.user}
				/>
			)}
			{isModalOpen('profile') && (
				<ProfileModal
					closeModal={closeModal}
					user={props.user}
					updateUser={props.updateUser}
				/>
			)}
			{isModalOpen('password') && (
				<PasswordModal
					user={props.user}
					updateUser={props.updateUser}
				/>
			)}
			{isModalOpen('cancel') && (
				<CancelModal
					user={props.user}
					updateUser={props.updateUser}
				/>
			)}
			{isModalOpen('change') && (
				<ChangeModal
					user={props.user}
				/>
			)}
			{isModalOpen('referral') && (
				<ReferralModal
					closeModal={closeModal}
					user={props.user}
					updateUser={props.updateUser}
				/>
			)}
			{isModalOpen('settings') && (
				<SettingsModal
					closeModal={closeModal}
					user={props.user}
				/>
			)}
			{isModalOpen('timeout') && (
				<TimeoutModal
					user={props.user}
					updateUser={props.updateUser}
				/>
			)}
			{isModalOpen('image') && (
				<ImageModal
					closeModal={closeModal}
				/>
			)}
			{isModalOpen('logo') && (
				<LogoModal
					closeModal={closeModal}
					user={props.user}
					updateUser={props.updateUser}
				/>
			)}
			{isModalOpen('upload') && (
				<UploadModal
					closeModal={closeModal}
					user={props.user}
				/>
			)}
			{isModalOpen('background') && (
				<BackgroundModal
					closeModal={closeModal}
					user={props.user}
				/>
			)}
			{isModalOpen('video') && (
				<VideoModal
					closeModal={closeModal}
				/>
			)}
			{isModalOpen('add-team') && (
				<AddTeamModal
					closeModal={closeModal}
					user={props.user}
				/>
			)}
			{isModalOpen('delete-team') && (
				<DeleteTeamModal
					closeModal={closeModal}
				/>
			)}
			{isModalOpen('delete-post') && (
				<DeletePostModal
					closeModal={closeModal}
				/>
			)}
			{isModalOpen('delete-settings') && (
				<DeleteSettingsModal
					closeModal={closeModal}
				/>
			)}
			{isModalOpen('quick-settings') && (
				<QuickSettingsModal
					closeModal={closeModal}
					user={props.user}
					orderList={props.orderList}
					setSettings={props.setSettings}
				/>
			)}
			{isModalOpen('overrides') && (
				<OverridesModal
					closeModal={closeModal}
					user={props.user}
					getTeamObj={props.getTeamObj}
					getTeam={props.getTeam}
				/>
			)}
			{isModalOpen('folder') && (
				<FolderModal
					closeModal={closeModal}
					user={props.user}
				/>
			)}
			{isModalOpen('move-folder') && (
				<MoveFolderModal
					closeModal={closeModal}
				/>
			)}
			{isModalOpen('paste') && (
				<PasteTableModal/>
			)}
			{isModalOpen('rename') && (
				<RenameModal/>
			)}
		</div>
	);
}
