export const textureOptions = [
	{value: null, label: 'None'},
	{value: 'grainy', label: 'Grainy'},
	{value: 'polka', label: 'Polka Dot'},
	{value: 'square', label: 'Square'},
	{value: 'triangle', label: 'Triangle'},
	{value: 'wavy', label: 'Wavy'},
	{value: 'rhombus', label: 'Rhombus'},
	{value: 'isometric', label: 'Isometric'},
	{value: 'splotchy', label: 'Splotchy'},
	{value: 'grass', label: 'Grass'},
];
