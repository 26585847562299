import {useState} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {ToastContainer, toast} from 'react-toastify';
import '../App.css';
import { jwtDecode } from "jwt-decode";

export default function StripeSuccess() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const goToDashboard = () => {
		setLoading(true);
		const token = localStorage.getItem('token');
		const decoded = jwtDecode(token);
		axios
			.get(`/users/getUser/${decoded.user._id}`)
			.then((response) => {
				localStorage.setItem('token', response.data.data.token);
				navigate('/dashboard?welcome=true');
			})
			.catch((error) => {
				console.log(error);
				toast.error('An unknown error occured. Please try again');
			});
	};

	return (
		<div className='stripe-success-page'>
			<ToastContainer />
			<i className='fa fa-check-circle'></i>
			<h1>Thanks for subscribing</h1>
			<p>After your trial, a payment to statlists.com will appear on your statement.</p>
			<button
				className='newButton'
				disabled={loading}
				onClick={goToDashboard}>
				Continue
				{loading && <i className='fa fa-spinner fa-spin'></i>}
			</button>
		</div>
	);
}
