import {useEffect, useState} from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { jwtDecode } from "jwt-decode";

export default function ResetPassword() {
	const navigate = useNavigate();
	const location = useLocation();
	const [password, setPassword] = useState('');
	const [reveal, setReveal] = useState(false);
	const [verified, setVerified] = useState(false);
	const [reset, setReset] = useState(false);
	const [used, setUsed] = useState(false);

	useEffect(() => {
		try {
			const token = location.pathname.replace('/reset/', '');
			const decoded = jwtDecode(token);
			if (decoded) {
				setVerified(true);
			}
		} catch {
			setTimeout(() => {
				navigate('/');
			});
		}
	}, [location, navigate]);

	const doReset = (e) => {
		e.preventDefault();
		const token = location.pathname.replace('/reset/', '');
		axios
			.put(`/logins/reset`, {
				token,
				password,
			})
			.then((response) => {
				if (response.status === 200) {
					toast.success('Password reset successfully! Please log in.');
					setReset(true);
				}
			})
			.catch(() => {
				toast.error('Please request a new password reset link.');
				setUsed(true);
			});
	};

	return (
		<div className='login-wrapper'>
		<div className='login'>
			<ToastContainer />
			{verified && !reset && (
				<form onSubmit={doReset} autoComplete="new-password">
					<h3>Reset password</h3>
					<div className='form-group floating-label'>
						<div className='input-group reverse'>
							<div className='input-group-append'>
								<div
									onClick={() => setReveal(!reveal)}
									className='input-group-text'>
									<i className={reveal ? 'fa fa-eye-slash' : 'fa fa-eye'}></i>
								</div>
							</div>
							<input
								type={reveal ? 'text' : 'password'}
								required
								className='form-control'
								autoComplete='new-password'
								onChange={(e) => {
									setPassword(e.target.value);
								}}
							/>
						</div>
						<label className={'form-label' + (password && ' filled')}>New password</label>
					</div>
					<button
						type='submit'
						className='btn btn-primary btn-block'>
						Reset
					</button>
				</form>
			)}
			{verified && reset && (
				<div>
					<Link to='/login'>
						<button className='btn btn-primary btn-block'>Sign in</button>
					</Link>
				</div>
			)}
			{verified && used && (
				<div>
					<Link to='/forgot'>
						<button className='btn btn-primary btn-block margin10'>Request new link</button>
					</Link>
				</div>
			)}
		</div>
		</div>
	);
}
