export const columnOptions = [
	{value: null, label: 'Default'},
	{value: 1, label: '1 Column'},
	{value: 2, label: '2 Columns'},
	{value: 3, label: '3 Columns'},
	{value: 4, label: '4 Columns'},
];

export const gridColumnOptions = [
	{value: null, label: 'Default'},
	{value: 1, label: '1 Column'},
	{value: 2, label: '2 Columns'},
	{value: 3, label: '3 Columns'},
	{value: 4, label: '4 Columns'},
	{value: 5, label: '5 Columns'},
	{value: 6, label: '6 Columns'},
	{value: 7, label: '7 Columns'},
	{value: 8, label: '8 Columns'},
	{value: 9, label: '9 Columns'},
	{value: 10, label: '10 Columns'},
];
