export const teams = [
	{
		id: 0,
		primary: '#990133',
		secondary: '#ffffff',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/ari.png',
		team: 'ARI',
		helmet: '#ffffff',
		wordmark: 'arizona.png',
	},
	{
		id: 100,
		primary: '#ffffff',
		secondary: '#990133',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/ari.png',
		team: 'ARI2',
		helmet: '#ffffff',
		wordmark: 'cardinalsred.png',
	},
	{
		id: 200,
		primary: '#000000',
		secondary: '#990133',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/ari.png',
		team: 'ARI3',
		helmet: '#ffffff',
		wordmark: 'cardinalsred.png',
	},
	{
		id: 1,
		primary: '#000000',
		secondary: '#A71930',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/atl.png',
		team: 'ATL',
		helmet: '#000000',
		wordmark: 'falconsred.png',
	},
	{
		id: 101,
		primary: '#FFFFFF',
		secondary: '#000000',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/atl.png',
		team: 'ATL2',
		helmet: '#000000',
		wordmark: 'falconsred.png',
	},
	{
		id: 201,
		primary: '#A71930',
		secondary: '#000000',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/atl.png',
		team: 'ATL3',
		helmet: '#000000',
		wordmark: 'falconswhite.png',
	},
	{
		id: 2,
		primary: '#241773',
		secondary: '#000000',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/bal.png',
		team: 'BAL',
		helmet: '#000000',
		wordmark: 'ravenswhite.png',
	},
	{
		id: 102,
		primary: '#FFFFFF',
		secondary: '#000000',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/bal.png',
		team: 'BAL2',
		helmet: '#000000',
		wordmark: 'ravensblack.png',
	},
	{
		id: 202,
		primary: '#000000',
		secondary: '#241773',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/bal.png',
		team: 'BAL3',
		helmet: '#000000',
		wordmark: 'ravenswhite.png',
	},
	{
		id: 3,
		primary: '#00338D',
		secondary: '#C60C30',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/buf.png',
		team: 'BUF',
		helmet: '#ffffff',
		wordmark: 'billswhite.png',
	},
	{
		id: 103,
		primary: '#FFFFFF',
		secondary: '#C60C30',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/buf.png',
		team: 'BUF2',
		helmet: '#ffffff',
		wordmark: 'billsblue.png',
	},
	{
		id: 203,
		primary: '#C60C30',
		secondary: '#FFFFFF',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/buf.png',
		team: 'BUF3',
		helmet: '#ffffff',
		wordmark: 'billswhite.png',
	},
	{
		id: 4,
		primary: '#000000',
		secondary: '#0085CA',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/car.png',
		team: 'CAR',
		helmet: '#BFC0BF',
		wordmark: 'pantherswhite.png',
	},
	{
		id: 104,
		primary: '#FFFFFF',
		secondary: '#0085CA',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/car.png',
		team: 'CAR2',
		helmet: '#BFC0BF',
		wordmark: 'panthersblue.png',
	},
	{
		id: 204,
		primary: '#0085CA',
		secondary: '#000000',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/car.png',
		team: 'CAR3',
		helmet: '#BFC0BF',
		wordmark: 'pantherswhite.png',
	},
	{
		id: 5,
		primary: '#0B162A',
		secondary: '#C83803',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/chi.png',
		team: 'CHI',
		helmet: '#0B162A',
		wordmark: 'bearsorange.png',
	},
	{
		id: 105,
		primary: '#FFFFFF',
		secondary: '#C83803',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/chi.png',
		team: 'CHI2',
		helmet: '#0B162A',
		wordmark: 'bearsorange.png',
	},
	{
		id: 205,
		primary: '#C83803',
		secondary: '#0B162A',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/chi.png',
		team: 'CHI3',
		helmet: '#0B162A',
		wordmark: 'bearsblue.png',
	},
	{
		id: 6,
		primary: '#000000',
		secondary: '#FB4F14',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/cin.png',
		team: 'CIN',
		helmet: '#FB4F14',
		wordmark: 'bengalswhite.png',
	},
	{
		id: 106,
		primary: '#FFFFFF',
		secondary: '#FB4F14',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/cin.png',
		team: 'CIN2',
		helmet: '#FB4F14',
		wordmark: 'bengalsblack.png',
	},
	{
		id: 206,
		primary: '#FB4F14',
		secondary: '#000000',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/cin.png',
		team: 'CIN3',
		helmet: '#FB4F14',
		wordmark: 'bengalswhite.png',
	},
	{
		id: 7,
		primary: '#311D00',
		secondary: '#FF3C00',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/cle.png',
		team: 'CLE',
		helmet: '#eb3700',
		wordmark: 'brownsorange.png',
	},
	{
		id: 107,
		primary: '#FFFFFF',
		secondary: '#FF3C00',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/cle.png',
		team: 'CLE2',
		helmet: '#eb3700',
		wordmark: 'brownsorange.png',
	},
	{
		id: 207,
		primary: '#311D00',
		secondary: '#FF3C00',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/cle.png',
		team: 'CLE3',
		helmet: '#eb3700',
		wordmark: 'brownsorange.png',
	},
	{
		id: 8,
		primary: '#041E42',
		secondary: '#869397',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/dal.png',
		team: 'DAL',
		helmet: '#869397',
		wordmark: 'cowboyswhite.png',
	},
	{
		id: 108,
		primary: '#FFFFFF',
		secondary: '#003594',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/dal.png',
		team: 'DAL2',
		helmet: '#869397',
		wordmark: 'cowboysblue.png',
	},
	{
		id: 208,
		primary: '#FFFFFF',
		secondary: '#041E42',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/dal.png',
		team: 'DAL3',
		helmet: '#869397',
		wordmark: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/Blank_button.svg/1200px-Blank_button.svg.png',
	},
	{
		id: 9,
		primary: '#FB4F14',
		secondary: '#002244',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/den.png',
		team: 'DEN',
		helmet: '#002244',
		wordmark: 'broncoswhite.png',
	},
	{
		id: 109,
		primary: '#FFFFFF',
		secondary: '#002244',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/den.png',
		team: 'DEN2',
		helmet: '#002244',
		wordmark: 'broncosblue.png',
	},
	{
		id: 209,
		primary: '#002244',
		secondary: '#FB4F14',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/den.png',
		team: 'DEN3',
		helmet: '#002244',
		wordmark: 'broncoswhite.png',
	},
	{
		id: 10,
		primary: '#0076B6',
		secondary: '#B0B7BC',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/det.png',
		team: 'DET',
		helmet: '#B0B7BC',
		wordmark: 'lionswhite.png',
	},
	{
		id: 110,
		primary: '#FFFFFF',
		secondary: '#0076B6',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/det.png',
		team: 'DET2',
		helmet: '#B0B7BC',
		wordmark: 'lionsblue.png',
	},
	{
		id: 210,
		primary: '#B0B7BC',
		secondary: '#0076B6',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/det.png',
		team: 'DET3',
		helmet: '#B0B7BC',
		wordmark: 'lionswhite.png',
	},
	{
		id: 11,
		primary: '#203731',
		secondary: '#FFB612',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/gb.png',
		team: 'GB',
		helmet: '#fdaf00',
		wordmark: 'packersyellow.png',
	},
	{
		id: 111,
		primary: '#FFFFFF',
		secondary: '#203731',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/gb.png',
		team: 'GB2',
		helmet: '#fdaf00',
		wordmark: 'packersgreen.png',
	},
	{
		id: 211,
		primary: '#203731',
		secondary: '#FFB612',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/gb.png',
		team: 'GB3',
		helmet: '#fdaf00',
		wordmark: 'packersyellow.png',
	},
	{
		id: 12,
		primary: '#03202F',
		secondary: '#A71930',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/hou.png',
		team: 'HOU',
		helmet: '#03202F',
		wordmark: 'texanswhite.png',
	},
	{
		id: 112,
		primary: '#FFFFFF',
		secondary: '#03202F',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/hou.png',
		team: 'HOU2',
		helmet: '#03202F',
		wordmark: 'texansblue.png',
	},
	{
		id: 212,
		primary: '#A71930',
		secondary: '#03202F',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/hou.png',
		team: 'HOU3',
		helmet: '#03202F',
		wordmark: 'texanswhite.png',
	},
	{
		id: 13,
		primary: '#002C5F',
		secondary: '#ffffff',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/ind.png',
		team: 'IND',
		helmet: '#ffffff',
		wordmark: 'coltswhite.png',
	},
	{
		id: 113,
		primary: '#ffffff',
		secondary: '#002C5F',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/ind.png',
		team: 'IND2',
		helmet: '#ffffff',
		wordmark: 'coltsblue.png',
	},
	{
		id: 213,
		primary: '#002C5F',
		secondary: '#ffffff',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/ind.png',
		team: 'IND3',
		helmet: '#ffffff',
		wordmark: 'coltswhite.png',
	},
	{
		id: 14,
		primary: '#006778',
		secondary: '#000000',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/jax.png',
		team: 'JAX',
		helmet: '#000000',
		wordmark: 'jaguarswhite.png',
	},
	{
		id: 114,
		primary: '#ffffff',
		secondary: '#000000',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/jax.png',
		team: 'JAX2',
		helmet: '#000000',
		wordmark: 'jaguarsblack.png',
	},
	{
		id: 214,
		primary: '#000000',
		secondary: '#006778',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/jax.png',
		team: 'JAX3',
		helmet: '#000000',
		wordmark: 'jaguarswhite.png',
	},
	{
		id: 15,
		primary: '#E31837',
		secondary: '#FFB81C',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/kc.png',
		team: 'KC',
		helmet: '#E31837',
		wordmark: 'chiefsyellow.png',
	},
	{
		id: 115,
		primary: '#FFFFFF',
		secondary: '#FFB81C',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/kc.png',
		team: 'KC2',
		helmet: '#E31837',
		wordmark: 'chiefsred.png',
	},
	{
		id: 215,
		primary: '#E31837',
		secondary: '#FFB81C',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/kc.png',
		team: 'KC3',
		helmet: '#E31837',
		wordmark: 'chiefsyellow.png',
	},
	{
		id: 16,
		primary: '#0080C6',
		secondary: '#FFC20E',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/lac.png',
		team: 'LAC',
		helmet: '#ffffff',
		wordmark: 'chargersyellow.png',
	},
	{
		id: 116,
		primary: '#FFFFFF',
		secondary: '#FFC20E',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/lac.png',
		team: 'LAC2',
		helmet: '#ffffff',
		wordmark: 'chargersblue.png',
	},
	{
		id: 216,
		primary: '#002A5E',
		secondary: '#FFC20E',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/lac.png',
		team: 'LAC3',
		helmet: '#ffffff',
		wordmark: 'chargersyellow.png',
	},
	{
		id: 17,
		primary: '#003593',
		secondary: '#FFD100',
		logo: 'https://s.yimg.com/cv/apiv2/default/nfl/20200323/500x500/rams_wbg.png',
		team: 'LAR',
		helmet: '#003593',
		wordmark: 'ramsyellow.png',
	},
	{
		id: 117,
		primary: '#FFFFFF',
		secondary: '#003593',
		logo: 'https://s.yimg.com/cv/apiv2/default/nfl/20200323/500x500/rams_wbg.png',
		team: 'LAR2',
		helmet: '#003593',
		wordmark: 'ramsblue.png',
	},
	{
		id: 217,
		primary: '#002244',
		secondary: '#C1A972',
		logo: 'https://sportslogohistory.com/wp-content/uploads/2017/12/st_louis_rams_2000-2015.png',
		team: 'LAR3',
		helmet: '#002244',
		wordmark: 'ramsolds.png',
	},
	{
		id: 18,
		primary: '#008E97',
		secondary: '#FC4C02',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/mia.png',
		team: 'MIA',
		helmet: '#ffffff',
		wordmark: 'dolphinswhite.png',
	},
	{
		id: 118,
		primary: '#FFFFFF',
		secondary: '#008E97',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/mia.png',
		team: 'MIA2',
		helmet: '#ffffff',
		wordmark: 'dolphinsblue.png',
	},
	{
		id: 218,
		primary: '#FC4C02',
		secondary: '#008E97',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/mia.png',
		team: 'MIA3',
		helmet: '#ffffff',
		wordmark: 'dolphinsblue.png',
	},
	{
		id: 19,
		primary: '#4F2683',
		secondary: '#FFC62F',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/min.png',
		team: 'MIN',
		helmet: '#4F2683',
		wordmark: 'vikingsyellow.png',
	},
	{
		id: 119,
		primary: '#FFFFFF',
		secondary: '#4F2683',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/min.png',
		team: 'MIN2',
		helmet: '#4F2683',
		wordmark: 'vikingspurple.png',
	},
	{
		id: 219,
		primary: '#4F2683',
		secondary: '#FFC62F',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/min.png',
		team: 'MIN3',
		helmet: '#4F2683',
		wordmark: 'vikingsyellow.png',
	},
	{
		id: 20,
		primary: '#002244',
		secondary: '#C60C30',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/ne.png',
		team: 'NE',
		helmet: '#B0B7BC',
		wordmark: 'patriotswhite.png',
	},
	{
		id: 120,
		primary: '#FFFFFF',
		secondary: '#002244',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/ne.png',
		team: 'NE2',
		helmet: '#B0B7BC',
		wordmark: 'patriotsblue.png',
	},
	{
		id: 220,
		primary: '#CC122C',
		secondary: '#04328C',
		logo: 'https://upload.wikimedia.org/wikipedia/en/thumb/0/0b/New_England_Patriots_logo_old.svg/1200px-New_England_Patriots_logo_old.svg.png',
		team: 'NE3',
		helmet: '#B0B7BC',
		wordmark: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/Blank_button.svg/1200px-Blank_button.svg.png',
	},
	{
		id: 21,
		primary: '#000000',
		secondary: '#D3BC8D',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/no.png',
		team: 'NO',
		helmet: '#D3BC8D',
		wordmark: 'saintstan.png',
	},
	{
		id: 121,
		primary: '#FFFFFF',
		secondary: '#000000',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/no.png',
		team: 'NO2',
		helmet: '#D3BC8D',
		wordmark: 'saintsblack.png',
	},
	{
		id: 221,
		primary: '#FFFFFF',
		secondary: '#CBA621',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/no.png',
		team: 'NO3',
		helmet: '#D3BC8D',
		wordmark: 'saintsblack.png',
	},
	{
		id: 22,
		primary: '#0B2265',
		secondary: '#0B2265',
		logo: 'https://cdn.bleacherreport.net/images/team_logos/328x328/new_york_giants.png',
		team: 'NYG',
		helmet: '#0B2265',
		wordmark: 'giantswhite.png',
	},
	{
		id: 122,
		primary: '#FFFFFF',
		secondary: '#A71930',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/nyg.png',
		team: 'NYG2',
		helmet: '#0B2265',
		wordmark: 'giantsblue.png',
	},
	{
		id: 222,
		primary: '#A71930',
		secondary: '#FFFFFF',
		logo: 'https://cdn.bleacherreport.net/images/team_logos/328x328/new_york_giants.png',
		team: 'NYG3',
		helmet: '#0B2265',
		wordmark: 'giantswhite.png',
	},
	{
		id: 23,
		primary: '#125740',
		secondary: '#ffffff',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/nyj.png',
		team: 'NYJ',
		helmet: '#125740',
		wordmark: 'jetswhite.png',
	},
	{
		id: 123,
		primary: '#ffffff',
		secondary: '#125740',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/nyj.png',
		team: 'NYJ2',
		helmet: '#125740',
		wordmark: 'jetsgreen.png',
	},
	{
		id: 223,
		primary: '#000000',
		secondary: '#125740',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/nyj.png',
		team: 'NYJ3',
		helmet: '#125740',
		wordmark: 'jetswhite.png',
	},
	{
		id: 24,
		primary: '#000000',
		secondary: '#A5ACAF',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/oak.png',
		team: 'LV',
		helmet: '#A5ACAF',
		wordmark: 'raiderssilver.png',
	},
	{
		id: 124,
		primary: '#FFFFFF',
		secondary: '#000000',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/oak.png',
		team: 'LV2',
		helmet: '#A5ACAF',
		wordmark: 'raidersblack.png',
	},
	{
		id: 224,
		primary: '#A5ACAF',
		secondary: '#000000',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/oak.png',
		team: 'LV3',
		helmet: '#A5ACAF',
		wordmark: 'raidersblack.png',
	},
	{
		id: 25,
		primary: '#004C54',
		secondary: '#A5ACAF',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/phi.png',
		team: 'PHI',
		helmet: '#004C54',
		wordmark: 'eagleswhite.png',
	},
	{
		id: 125,
		primary: '#FFFFFF',
		secondary: '#004C54',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/phi.png',
		team: 'PHI2',
		helmet: '#004C54',
		wordmark: 'eagleswhite.png',
	},
	{
		id: 225,
		primary: '#000000',
		secondary: '#004C54',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/phi.png',
		team: 'PHI3',
		helmet: '#004C54',
		wordmark: 'eagleswhite.png',
	},
	{
		id: 26,
		primary: '#000000',
		secondary: '#FFB612',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/pit.png',
		team: 'PIT',
		helmet: '#000000',
		wordmark: 'steelersyellow.png',
	},
	{
		id: 126,
		primary: '#FFFFFF',
		secondary: '#FFB612',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/pit.png',
		team: 'PIT2',
		helmet: '#000000',
		wordmark: 'steelersblack.png',
	},
	{
		id: 226,
		primary: '#000000',
		secondary: '#FFB612',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/pit.png',
		team: 'PIT3',
		helmet: '#FFB612',
		wordmark: 'steelersyellow.png',
	},
	{
		id: 27,
		primary: '#002244',
		secondary: '#69BE28',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/sea.png',
		team: 'SEA',
		helmet: '#002244',
		wordmark: 'seahawksgreen.png',
	},
	{
		id: 127,
		primary: '#FFFFFF',
		secondary: '#002244',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/sea.png',
		team: 'SEA2',
		helmet: '#002244',
		wordmark: 'seahawksblue.png',
	},
	{
		id: 227,
		primary: '#A5ACAF',
		secondary: '#002244',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/sea.png',
		team: 'SEA3',
		helmet: '#002244',
		wordmark: 'seahawksblue.png',
	},
	{
		id: 28,
		primary: '#AA0000',
		secondary: '#ffffff',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/sf.png',
		team: 'SF',
		helmet: '#B3995D',
		wordmark: 'ninerswhite.png',
	},
	{
		id: 128,
		primary: '#ffffff',
		secondary: '#AA0000',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/sf.png',
		team: 'SF2',
		helmet: '#B3995D',
		wordmark: 'ninersred.png',
	},
	{
		id: 228,
		primary: '#ffffff',
		secondary: '#AA0000',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/sf.png',
		team: 'SF3',
		helmet: '#B3995D',
		wordmark: 'ninersred.png',
	},
	{
		id: 29,
		primary: '#AB0F2D',
		secondary: '#665353',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/tb.png',
		team: 'TB',
		helmet: '#665353',
		wordmark: 'bucswhite.png',
	},
	{
		id: 129,
		primary: '#FFFFFF',
		secondary: '#AB0F2D',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/tb.png',
		team: 'TB2',
		helmet: '#665353',
		wordmark: 'bucsred.png',
	},
	{
		id: 229,
		primary: '#665353',
		secondary: '#AB0F2D',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/tb.png',
		team: 'TB3',
		helmet: '#665353',
		wordmark: 'bucsred.png',
	},
	{
		id: 30,
		primary: '#0C2340',
		secondary: '#418FDE',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/ten.png',
		team: 'TEN',
		helmet: '#0C2340',
		wordmark: 'titansblue.png',
	},
	{
		id: 130,
		primary: '#FFFFFF',
		secondary: '#418FDE',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/ten.png',
		team: 'TEN2',
		helmet: '#0C2340',
		wordmark: 'titansblue.png',
	},
	{
		id: 230,
		primary: '#418FDE',
		secondary: '#0C2340',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/ten.png',
		team: 'TEN3',
		helmet: '#0C2340',
		wordmark: 'titanswhite.png',
	},
	{
		id: 31,
		primary: '#773141',
		secondary: '#FFB612',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/wsh.png',
		team: 'WSH',
		helmet: '#773141',
		wordmark: 'washingtonyellow.png',
	},
	{
		id: 131,
		primary: '#FFFFFF',
		secondary: '#773141',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/wsh.png',
		team: 'WSH2',
		helmet: '#773141',
		wordmark: 'washingtonred.png',
	},
	{
		id: 231,
		primary: '#000000',
		secondary: '#FFB612',
		logo: 'https://a.espncdn.com/i/teamlogos/nfl/500/wsh.png',
		team: 'WSH3',
		helmet: '#000000',
		wordmark: 'washingtonyellow.png',
	},
	{
		id: 32,
		primary: '#418FDE',
		secondary: '#C8102E',
		logo: 'https://sportslogohistory.com/wp-content/uploads/2017/12/houston_oilers_1980-1996.png',
		team: 'OTI',
		helmet: '#ffffff',
		wordmark: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/Blank_button.svg/1200px-Blank_button.svg.png',
	},
];
