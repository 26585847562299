import Draggable from 'react-draggable';
import {Resizable} from 're-resizable';
import useSettingsStore from '../../stores/settings.store';
import usePostStore from '../../stores/posts/base-post.store';
import useGlobalStore from '../../stores/global.store';
import useUserStore from '../../stores/user.store';
import JerseyBox from './common/JerseyBox';
import { colord, extend } from 'colord';
import mixPlugin from 'colord/plugins/mix';
extend([mixPlugin]);

export default function GridFormat(props) {
	const { settings } = useSettingsStore();
	const { team, name, stat, image, teamsplits, nameOverrides, statOverrides, nameHighlights, statHighlights } = usePostStore();
	const { individuallyEdit, setEditTeam } = useGlobalStore();
	const { sport } = useUserStore();

	const getGridNameStyle = (i, text) => {
		let returnVal = {};
		const nameOverride = nameOverrides.find((n) => n.id === i);
		if (nameOverride) {
			returnVal.fontFamily = nameOverride.font.value.includes('bold') ? nameOverride.font.value.replaceAll(' ', '') : nameOverride.font.value;
			returnVal.fontSize = nameOverride.fontSize + 'em';
			returnVal.color = nameOverride.fontColor;
			returnVal.letterSpacing = nameOverride.letterSpacing;
		}
		const highlight = nameHighlights.find((highlight) => highlight.text.toLowerCase() === text.toLowerCase());
		if (highlight) {
			returnVal.color = highlight.color;
		}
		if (text && String(text).includes('##')) {
			returnVal.whiteSpace = 'pre';
		}
		return returnVal;
	};

	const getGridStatStyle = (i, text) => {
		let returnVal = {};
		const statOverride = statOverrides.find((n) => n.id === i);
		if (statOverride) {
			returnVal.fontFamily = statOverride.font.value.includes('bold') ? statOverride.font.value.replaceAll(' ', '') : statOverride.font.value;
			returnVal.fontSize = statOverride.fontSize + 'em';
			returnVal.color = statOverride.fontColor;
			returnVal.letterSpacing = statOverride.letterSpacing;
		}
		const highlight = statHighlights.find((highlight) => highlight.text === text);
		if (highlight) {
			returnVal.color = highlight.color;
		}
		
		if (settings.statHighlight) {
			if (Number(text) === findLargestValue(stat)) {
				returnVal.color = settings.topHighlight;
			} else if (Number(text) === findSmallestValue(stat)) {
				returnVal.color = settings.bottomHighlight;
			}
		}

		if (text && String(text).includes('##')) {
			returnVal.whiteSpace = 'pre';
		}

		return returnVal;
	};

	const findLargestValue = (arr) => {
		if (!Array.isArray(arr) || arr.length === 0) {
			return undefined; // Return undefined for invalid input
		}

		let largest = Number(arr[0]); // Initialize the largest variable with the first element

		for (let i = 1; i < arr.length; i++) {
			const currentNum = Number(arr[i]); // Convert the current string to a number

			if (!isNaN(currentNum) && currentNum > largest) {
				largest = currentNum; // Update the largest value if the current number is greater
			}
		}

		return largest;
	};

	const findSmallestValue = (arr) => {
		if (!Array.isArray(arr) || arr.length === 0) {
			return undefined; // Return undefined for invalid input
		}

		let smallest = Number(arr[0]); // Initialize the smallest variable with the first element

		for (let i = 1; i < arr.length; i++) {
			const currentNum = Number(arr[i]); // Convert the current string to a number

			if (!isNaN(currentNum) && currentNum < smallest) {
				smallest = currentNum; // Update the smallest value if the current number is smaller
			}
		}

		return smallest;
	};

	let maxhundred = team.slice(0, 100);
	return maxhundred.map((item, i) => {
		let teamValues = props.getTeam(item);
		let t = teamValues.team ? teamValues.team : null;
		let s = teamValues.sport ? teamValues.sport : sport;
		let team1 = teamValues.team1 ? teamValues.team1 : null;
		let team2 = teamValues.team2 ? teamValues.team2 : null;
		let sport1 = teamValues.sport1 ? teamValues.sport1 : null;
		let sport2 = teamValues.sport2 ? teamValues.sport2 : null;
		let imgsrc;
		if (image[i]) {
			imgsrc = props.getImg(image[i])[0];
		}
		let bottom = '';
		if (stat[i] === '' || !stat[i] || settings.statAlignment !== 'fill') {
			bottom = '0px';
		}
		let boximage, boxbackground;
		let teamObj;
		if (t) {
			teamObj = props.getTeamObj(t, s);
			if (teamObj) {
				boximage = (
					<div
						id={`image${i}`}
						className={`boximage`}
						style={{backgroundImage: teamObj?.logo, backgroundSize: settings.logoSizeGrid === 100 ? 'cover' : `${settings.logoSizeGrid}%`}}></div>
				);
				boxbackground = props.getBoxBackground(teamObj);
			} else {
				boxbackground = 'transparent';
			}
		}
		if (team1 && team2) {
			const teamObj1 = props.getTeamObj(team1, sport1);
			const teamObj2 = props.getTeamObj(team2, sport2);
			let existingSplit = teamsplits.find((split) => split.team === item.toUpperCase());
			boximage = (
				<span
					className='boximagesplit'
					data-team={item}
					onClick={props.flipClip}>
					<div
						className={`boximage no-click ${existingSplit?.splitDirection ? existingSplit?.splitDirection.split('/')[0] : 'cliptopleft'}`}
						style={{backgroundImage: teamObj1?.logo, backgroundSize: settings.logoSizeGrid === 100 ? 'cover' : `${settings.logoSizeGrid}%`}}></div>
					<div
						className={`boximage no-click ${existingSplit?.splitDirection ? existingSplit?.splitDirection.split('/')[1] : 'clipbottomright'}`}
						style={{backgroundImage: teamObj2?.logo, backgroundSize: settings.logoSizeGrid === 100 ? 'cover' : `${settings.logoSizeGrid}%`}}></div>
				</span>
			);
			boxbackground = props.getTwoTeamBoxBackground(teamObj1, teamObj2, existingSplit);
		}
		if (settings.jerseyBox) {
			const positions = ['QB', 'RB1', 'RB2', 'WR1', 'WR2', 'TE', 'FLEX', 'D/ST', 'K'];
			return (
				<div
					key={i}
					id={`box${i}`}
					className='box jerseyBox'>
					<JerseyBox
						team={team[i].toUpperCase().replace('WAS', 'WSH')}
						name={name[i]}
						stat={stat[i]}
						position={positions[i]}
					/>
				</div>
			);
		}
		const boxBorderColor = props.findColor(settings.borderColor, teamObj);
		const boxGlowColor = props.findColor(settings.boxGlowColor, teamObj);
		const topBackground = props.findColor(settings.nameBackground, teamObj);
		const bottomBackground = props.findColor(settings.statBackground, teamObj);
		return (
			<span
				className='boxwrap'
				key={i}>
				{individuallyEdit && (
					<span
						className={`edit-box left`}
						onClick={(e) => {
							e.stopPropagation();
							setEditTeam(i);
						}}>
						<i className='fas fa-pencil-alt no-click'></i>
					</span>
				)}
				<div
					id={`box${i}`}
					style={{
						background: boxbackground,
						borderColor: boxBorderColor,
						boxShadow: `0px 0px ${settings.boxGlowWidth}px ${settings.boxGlowWidth / 5}px ${boxGlowColor}, inset 0px 0px ${settings.boxGlowWidth}px ${settings.boxGlowWidth / 5}px ${boxGlowColor}`
					}}
					className={`box drag-container candrag${individuallyEdit} ${individuallyEdit ? 'editing2' : ''}`}>
					<div className={`boximagecontainer`}>{boximage}</div>
					{settings.texture && <div className={'boxtexture ' + settings.texture}></div>}
					{name[i] && (
						<div
							id={`boxname${i}`}
							className={`boxname` + (settings.nameShadow === 0 ? ' no-shadow ' : ' ') + settings.nameAlignment}
							style={{
								background: topBackground,
								border: `calc(1px * var(--sizemultiplier)) solid ${topBackground}`
							}}
							onClick={(e) => {
								props.openInlineEditor(e.target, 'name', i, 10);
								props.selectSettings('name');
							}}>
							<span
								id={`spanname${i}`}
								className='spanname'
								contentEditable={props.homePage}
								suppressContentEditableWarning={true}
								style={getGridNameStyle(i, name[i])}>
								{String(name[i]).split('##').join('\n')}
							</span>
						</div>
					)}
					{stat[i] && (
						<div
							id={`boxstat${i}`}
							className={`boxstat` + (settings.statShadow === 0 ? ' no-shadow ' : ' ') + settings.statAlignment}
							style={{
								background: bottomBackground,
								border: `calc(1px * var(--sizemultiplier)) solid ${bottomBackground}`
							}}
							onClick={(e) => {
								props.openInlineEditor(e.target, 'stat', i, 15);
								props.selectSettings('stat');
							}}>
							<span
								contentEditable={props.homePage}
								suppressContentEditableWarning={true}
								style={getGridStatStyle(i, stat[i])}>
								{String(stat[i]).split('##').join('\n')}
							</span>
						</div>
					)}
					{imgsrc && (
						<div
							className='playerImageContainer'
							style={{bottom: bottom}}>
							<Draggable disabled={!individuallyEdit} onStop={() => props.setDraggables()}>
								<Resizable
									lockAspectRatio={true}
									enable={{
										top: individuallyEdit,
										right: individuallyEdit,
										bottom: individuallyEdit,
										left: individuallyEdit,
										topRight: individuallyEdit,
										bottomRight: individuallyEdit,
										bottomLeft: individuallyEdit,
										topLeft: individuallyEdit,
									}}>
									<img
										src={imgsrc}
										draggable='false'
										alt=''
										className='playerimage'></img>
								</Resizable>
							</Draggable>
						</div>
					)}
				</div>
			</span>
		);
	});
}
