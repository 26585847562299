import { create } from 'zustand';

interface PreferencesStore {
    darkMode: boolean;
    setDarkMode: (value: boolean) => void;
}

const usePreferencesStore = create<PreferencesStore>((set) => ({
    darkMode: false,
    setDarkMode: (value: boolean) => {
        set(() => {
            localStorage.setItem('dark', String(value));

            if (value && !document.body.classList.contains('dark')) {
              document.body.classList.add('dark');
            } else if (!value && document.body.classList.contains('dark')) {
              document.body.classList.remove('dark');
            }

            return { darkMode: value };
        });
    }
}));

export default usePreferencesStore;
