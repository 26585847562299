import { create } from 'zustand';

interface TierStore {
    tiers: any;
    temptiers: string[];
    setTiers: (newValue: any) => void;
    setTemptiers: (newValue: string[]) => void;
    reset: () => void;
}

const useTierStore = create<TierStore>((set) => ({
    tiers: {
        0: 1,
        1: 2,
        2: 3,
        3: 4,
        4: 5,
        5: 6,
        6: 7,
        7: 8,
        8: 9,
        9: 10
    },
    temptiers: [],
    setTiers: (newValue) => set({ tiers: newValue }),
    setTemptiers: (newValue) => set({ temptiers: newValue }),
    reset: () => set(() => ({
        tiers: {
            0: 1,
            1: 2,
            2: 3,
            3: 4,
            4: 5,
            5: 6,
            6: 7,
            7: 8,
            8: 9,
            9: 10
        },
        temptiers: []
    }))
}));

export default useTierStore;
