export const battingStats = [
	{
		label: 'Games Played',
		value: 'mlbplayerstats/batting-gamesplayed',
	},
	{
		label: 'At Bats',
		value: 'mlbplayerstats/batting-atbats',
	},
	{label: 'Runs', value: 'mlbplayerstats/batting-runs'},
	{label: 'Hits', value: 'mlbplayerstats/batting-hits'},
	{
		label: 'Batting Average',
		value: 'mlbplayerstats/batting-avg',
	},
	{
		label: 'Doubles',
		value: 'mlbplayerstats/batting-doubles',
	},
	{
		label: 'Triples',
		value: 'mlbplayerstats/batting-triples',
	},
	{
		label: 'Home Runs',
		value: 'mlbplayerstats/batting-homeruns',
	},
	{
		label: 'Runs Batted In',
		value: 'mlbplayerstats/batting-rbis',
	},
	{
		label: 'Total Bases',
		value: 'mlbplayerstats/batting-totalbases',
	},
	{label: 'Walks', value: 'mlbplayerstats/batting-walks'},
	{
		label: 'Strikeouts',
		value: 'mlbplayerstats/batting-strikeouts',
	},
	{
		label: 'Stolen Bases',
		value: 'mlbplayerstats/batting-stolenbases',
	},
	{
		label: 'On Base Percentage',
		value: 'mlbplayerstats/batting-onbasepct',
	},
	{
		label: 'Slugging Percentage',
		value: 'mlbplayerstats/batting-slugavg',
	},
	{
		label: 'OPB Pct + SLG Pct',
		value: 'mlbplayerstats/batting-ops',
	},
	{
		label: 'Wins Above Replacement',
		value: 'mlbplayerstats/batting-warbr',
	},
];

export const pitchingStats = [
	{
		label: 'Games Played',
		value: 'mlbplayerstats/pitching-gamesplayed',
		qualify: false
	},
	{
		label: 'Games Started',
		value: 'mlbplayerstats/pitching-gamesstarted',
		qualify: false
	},
	{
		label: 'Quality Starts',
		value: 'mlbplayerstats/pitching-qualitystarts',
		qualify: false
	},
	{
		label: 'Earned Run Average',
		value: 'mlbplayerstats/pitching-era',
		direction: 'desc'
	},
	{
		label: 'Wins',
		value: 'mlbplayerstats/pitching-wins',
		qualify: false
	},
	{
		label: 'Losses',
		value: 'mlbplayerstats/pitching-losses',
		qualify: false
	},
	{
		label: 'Saves',
		value: 'mlbplayerstats/pitching-saves',
		qualify: false
	},
	{
		label: 'Holds',
		value: 'mlbplayerstats/pitching-holds',
		qualify: false
	},
	{
		label: 'Innings pitched',
		value: 'mlbplayerstats/pitching-innings',
		qualify: false
	},
	{
		label: 'Hits',
		value: 'mlbplayerstats/pitching-hits',
		qualify: false
	},
	{
		label: 'Earned runs',
		value: 'mlbplayerstats/pitching-earnedruns',
		qualify: false
	},
	{
		label: 'Home Runs',
		value: 'mlbplayerstats/pitching-homeruns',
		qualify: false
	},
	{
		label: 'Walks',
		value: 'mlbplayerstats/pitching-walks',
		qualify: false
	},
	{
		label: 'Strikeouts',
		value: 'mlbplayerstats/pitching-strikeouts',
		qualify: false
	},
	{
		label: 'Strikeouts Per 9 Innings',
		value: 'mlbplayerstats/pitching-strikeoutspernineinnings',
	},
	{
		label: 'WHIP',
		value: 'mlbplayerstats/pitching-whip',
		direction: 'desc'
	},
	{
		label: 'Wins Above Replacement',
		value: 'mlbplayerstats/pitching-warbr',
		qualify: false
	},
];

export const fieldingStats = [
	{
		label: 'Games Played',
		value: 'mlbplayerstats/fielding-gamesplayed',
	},
	{
		label: 'Games Started',
		value: 'mlbplayerstats/fielding-gamesstarted',
	},
	{
		label: 'Full Innings',
		value: 'mlbplayerstats/fielding-fullinningsplayed',
	},
	{
		label: 'Total Chances',
		value: 'mlbplayerstats/fielding-totalchances',
	},
	{
		label: 'Putouts',
		value: 'mlbplayerstats/fielding-putouts',
	},
	{
		label: 'Assists',
		value: 'mlbplayerstats/fielding-assists',
	},
	{
		label: 'Fielding Percentage',
		value: 'mlbplayerstats/fielding-fieldingpct',
	},
	{
		label: 'Errors',
		value: 'mlbplayerstats/fielding-errors',
	},
	{
		label: 'Double Plays',
		value: 'mlbplayerstats/fielding-doubleplays',
	},
	{
		label: 'Range Factor',
		value: 'mlbplayerstats/fielding-rangefactor',
	},
	{
		label: 'Defensive Wins Above Replacement',
		value: 'mlbplayerstats/fielding-defwarbr',
	},
	{
		label: 'Passed Balls',
		value: 'mlbplayerstats/fielding-passedballs',
	},
	{
		label: 'Stolen Bases Allowed',
		value: 'mlbplayerstats/fielding-catcherstolenbasesallowed',
	},
	{
		label: 'Caught Stealing',
		value: 'mlbplayerstats/fielding-catchercaughtstealing',
	},
	{
		label: 'Caught Stealing Percentage',
		value: 'mlbplayerstats/fielding-catchercaughtstealingpct',
	},
];
