import useGlobalStore from "../../stores/global.store";
import usePostStore from "../../stores/posts/base-post.store";
import useSettingsStore from "../../stores/settings.store";
import useUserStore from "../../stores/user.store";
import { colord, extend } from 'colord';
import mixPlugin from 'colord/plugins/mix';
extend([mixPlugin]);

export default function MatchupBox(props) {
	const { settings } = useSettingsStore();
	const { team, name, points, ranks } = usePostStore();
	const { individuallyEdit, setEditTeam } = useGlobalStore();
	const { sport } = useUserStore();

	let item = team[props.instance];
	if (!item) return;
	let teamValues = props.getTeam(item);
	let t = teamValues.team ? teamValues.team : null;
	let s = teamValues.sport ? teamValues.sport : sport;
	let boximage, boxbackground, teamObj;
	if (t) {
		teamObj = props.getTeamObj(t, s);
		if (teamObj) {
			boximage = (
				<div
					id={`image${props.instance}`}
					className={`boximage`}
					style={{backgroundImage: teamObj?.logo}}></div>
			);
			boxbackground = props.getBoxBackground(teamObj);
		} else {
			boxbackground = 'transparent';
		}
	}
	let evenodd = props.instance % 2 === 0 ? 'even' : 'odd';
	if (!settings.logoFlip) {
		evenodd = 'same';
	}
	let rankSide;
	if (settings.orientationMatchup.includes('top')) {
		if (settings.logoFlip) {
			rankSide = props.instance % 2 === 0 ? 'even' : 'odd';
			if (settings.logoOrder === 1) {
				rankSide = props.instance % 2 === 0 ? 'odd' : 'even';
			}
		} else {
			rankSide = 'even';
			if (settings.logoOrder === 1) {
				rankSide = 'odd';
			}
		}
	} else {
		if (settings.logoFlip) {
			rankSide = props.instance % 2 === 0 ? 'top' : 'bottom';
			if (settings.logoOrder === 1) {
				rankSide = props.instance % 2 === 0 ? 'bottom' : 'top';
			}
		} else {
			rankSide = 'top';
			if (settings.logoOrder === 1) {
				rankSide = 'bottom';
			}
		}
	}
	let boxWrapStyle = {};
	if (settings.matchupStyle?.value === 'style-3') {
		if (props.instance === 0) {
			boxWrapStyle = {boxShadow: `0px 0px calc(calc(var(--imageshadowsize) + 120px) * var(--sizemultiplier)) calc(calc(var(--imageshadowsize) + 10px) * var(--sizemultiplier)) ${teamObj?.primary}`};
		} else {
			if (settings.orientationMatchup.includes('top')) {
				boxWrapStyle = {
					boxShadow: `calc(calc(var(--imageshadowsize) + 30px)) 0px calc(calc(var(--imageshadowsize) + 120px) * var(--sizemultiplier)) calc(calc(var(--imageshadowsize) + 10px) * var(--sizemultiplier)) ${teamObj?.primary}`,
				};
			} else {
				boxWrapStyle = {
					boxShadow: `0px calc(calc(var(--imageshadowsize) + 30px) * var(--sizemultiplier)) calc(calc(var(--imageshadowsize) + 120px) * var(--sizemultiplier)) calc(calc(var(--imageshadowsize) + 10px) * var(--sizemultiplier)) ${teamObj?.primary}`,
				};
			}
		}
	}
	return (
		<span
			className='boxwrap'
			style={boxWrapStyle}
			key={props.instance}>
			{individuallyEdit && (
				<span
					className={`edit-box left`}
					onClick={(e) => {
						e.stopPropagation();
						setEditTeam(props.instance);
					}}>
					<i className='fas fa-pencil-alt no-click'></i>
				</span>
			)}
			<div
				id={`box${props.instance}`}
				style={{background: boxbackground}}
				className={`box drag-container candrag${individuallyEdit} ${evenodd}`}>
				<div
					className={`boximagecontainer`}
					style={
						settings.orientationMatchup.includes('top')
							? {width: name[props.instance] && settings.matchupStyle?.value !== 'style-4' ? '50%' : '100%'}
							: {height: name[props.instance] && settings.matchupStyle?.value !== 'style-4' ? '50%' : '100%'}
					}
					onClick={() => props.selectSettings('team')}>
					{boximage}
				</div>
				{settings.texture && (
					<div
						className={'boxtexture ' + settings.texture}
						onClick={() => props.selectSettings('team')}></div>
				)}
				{ranks[props.instance] && (
					<div
						className={`rank${rankSide}` + (settings.rankOutlineSize > 0 ? ' textglow' : '')}
						style={{
							background:props.findColor(settings.matchupRankBackground, teamObj)
						}}
						contentEditable={props.homePage}
						suppressContentEditableWarning={true}
						onClick={() => props.selectSettings('rank')}>
						{ranks[props.instance]}
					</div>
				)}
				{name[props.instance] && settings.matchupStyle?.value !== 'style-4' && (
					<div
						id={`boxname${props.instance}`}
						className={`boxname` + (settings.nameShadow === 0 ? ' no-shadow' : '')}
						onClick={() => props.selectSettings('name')}>
						<span
							id={`spanname${props.instance}`}
							contentEditable={props.homePage}
							suppressContentEditableWarning={true}
							className='spanname'>
							{name[props.instance]}
						</span>
					</div>
				)}
				{points[props.instance] && (
					<div
						className={`extrainfoouter ${rankSide}`}
						onClick={() => props.selectSettings('points')}>
						<div
							className={`extrainfo` + (settings.pointsOutlineSize > 0 ? ' textglow2' : '')}
							contentEditable={props.homePage}
							suppressContentEditableWarning={true}
							style={{
								background: props.findColor(settings.matchupPointsBackground, teamObj)
							}}>
							{points[props.instance]}
						</div>
					</div>
				)}
			</div>
		</span>
	);
}
