import { initializeApp } from "firebase/app";
import { ref, getDownloadURL, getStorage, uploadBytes, deleteObject } from "firebase/storage";

var firebaseConfig = {
    apiKey: 'AIzaSyAsJatSMojlvyfF5RAEfd5YLnBv2_bl64M',
    authDomain: "statlist-images.firebaseapp.com",
    projectId: "statlist-images",
    storageBucket: "statlist-images.appspot.com",
    messagingSenderId: "109165687997",
    appId: '1:109165687997:web:42f846ff92da01589433d4'
};

export const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);

export const uploadJpeg = (file, title) => {
    return new Promise((resolve, reject) => {
        const storageRef = ref(storage, title);
        uploadBytes(storageRef, file).then(() => {
            getDownloadURL(storageRef)
                .then((url) => {
                    resolve(url);
                })
                .catch((error) => {
                    reject(error);
                });
        }).catch((error) => {
            reject(error);
        });
    });
}

export const deleteFile = (url) => {
    return new Promise((resolve, reject) => {
        const storageRef = ref(storage, url);
        deleteObject(storageRef).then(() => resolve()).catch(() => reject());
    });
}

// export const listImages = () => {
// get all images from firebase
//     const listRef = ref(storage, 'images');
//     listAll(listRef).then((res) => {
//         res.items.forEach((itemRef) => {
//             console.log(itemRef._location.path);
//         });
//     });
// put them in sheets. get actual ones from admin/mongo
// also look at background- and logo-
// compare lists =NOT(ISERROR(MATCH(A1,Sheet1!A:A,0)))
// Then delete ones no longer used
//     const imageURLS = [
            
//     ]
//     imageURLS.forEach((url) => {
//         const storageRef = ref(storage, url);
//         deleteObject(storageRef);
//     });
// }