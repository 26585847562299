import React from 'react';
import useUserStore from '../../stores/user.store';
import useSettingsStore from '../../stores/settings.store';
import 'rc-slider/assets/index.css';
import Lock from '../Lock';
import Tippy from '@tippyjs/react';
import { ISettingsPointer, RoundSlider } from 'mz-react-round-slider';
import { GradientStop } from '../../data/settings/settings';
import { ColorMenu } from './ColorMenu';
import TooltipSlider from '../TooltipSlider';

interface GradientEditorProps {
	typeSetting: string;
	directionSetting: string;
	stopsSetting: string;
    useUnusedColor?: boolean;
    showPresets?: boolean;
}

export const GradientEditor: React.FC<GradientEditorProps> = ({ typeSetting, directionSetting, stopsSetting, useUnusedColor, showPresets }) => {
	const { lockedSettings } = useUserStore();
	const { settings, updateSetting } = useSettingsStore();

	return (
		<span>
			<span style={{ display: 'block', marginTop: '10px' }}>
				<Lock
					title='Gradient Type'
					setting={typeSetting}
					lockedSettings={lockedSettings}
				/>
				<span className='setting-radio'>
					<label>
						<input
							type='radio'
							disabled={lockedSettings.includes(typeSetting)}
							name='gradienttyperadio'
							checked={settings[typeSetting] === 'linear-gradient'}
							value='linear-gradient'
							onChange={() => updateSetting(typeSetting, 'linear-gradient')}
						/>
						Linear
					</label>
					<label>
						<input
							type='radio'
							disabled={lockedSettings.includes(typeSetting)}
							name='gradienttyperadio'
							checked={settings[typeSetting] === 'radial-gradient'}
							value='radial-gradient'
							onChange={() => updateSetting(typeSetting, 'radial-gradient')}
						/>
						Radial
					</label>
				</span>
			</span>
			<span className='edit-gradients'>
				{settings[typeSetting] === 'linear-gradient' && (
					<Lock
						title='Gradient Direction'
						setting={directionSetting}
						lockedSettings={lockedSettings}
					/>
				)}
				{settings[typeSetting] === 'linear-gradient' && (
					<span className='angleSlider'>
						<RoundSlider
							pathStartAngle={270}
							pathEndAngle={269.999}
							pointers={[{ value: Number(settings[directionSetting].replace('deg', '')) }]}
							step={5}
							min={-1}
							max={360}
							pathRadius={15}
							pointerRadius={5}
							connectionBgColor='#efefef'
							hideText={true}
							pointerBgColor={'#00a199'}
							pointerBgColorSelected={'#00a199'}
							disabled={lockedSettings.includes(directionSetting)}
							onChange={(pointer: ISettingsPointer[]) => {
								updateSetting(directionSetting, `${pointer[0].value}deg`);
							}}
						/>
						<span className='editor-input'>
							<input
								disabled={lockedSettings.includes(directionSetting)}
								type='number'
								min='0'
								max='360'
								value={settings[directionSetting].replace('deg', '')}
								onChange={(e) => {
									updateSetting(directionSetting, `${e.target.value}deg`);
								}}></input>
						</span>
						<Tippy content={<span>Rotate</span>}>
							<span
								className={`editor-button ${lockedSettings.includes(directionSetting) ? 'disabled' : ''}`}
								onClick={() => {
									if (lockedSettings.includes(directionSetting)) return;
									let newSetting: string;
									if (Number(settings[directionSetting].replace('deg', '')) < 90 || Number(settings[directionSetting].replace('deg', '')) === 360) {
										newSetting = '90deg';
									} else if (Number(settings[directionSetting].replace('deg', '')) < 180) {
										newSetting = '180deg';
									} else if (Number(settings[directionSetting].replace('deg', '')) < 270) {
										newSetting = '270deg';
									} else {
										newSetting = '360deg';
									}
									updateSetting(directionSetting, newSetting);
								}}>
								<i
									className='fa-solid fa-arrow-up'
									style={{ transform: `rotate(${settings[directionSetting]})` }}></i>
							</span>
						</Tippy>
					</span>
				)}
				<span style={{ display: 'block', marginTop: '10px' }}>
					<Lock
						title='Gradient Stops'
						setting={stopsSetting}
						lockedSettings={lockedSettings}
					/>
				</span>
				{settings[stopsSetting].map((stop: GradientStop, i: number) => {
					return (
						<span
							key={i}
							className='gradientSettings'>
							<ColorMenu
								setting={stop}
								instance={i}
								settingName={stopsSetting}
							/>
							<TooltipSlider
								disabled={lockedSettings.includes(stopsSetting)}
								value={settings[stopsSetting][i].percentage}
								onChange={(value) => {
									const gradientStopsCopy = structuredClone(settings[stopsSetting]);
									const stopToEdit = gradientStopsCopy[i];
									stopToEdit.percentage = Number(value);
									updateSetting(stopsSetting, gradientStopsCopy);
								}}
								min={0}
								max={100}
							/>
							{i !== 0 && i !== 1
                            ? (
								<button
									className='deleteStopBtn'
									disabled={lockedSettings.includes(stopsSetting)}
									onClick={(e) => {
										const gradientStopsCopy = structuredClone(settings[stopsSetting]);
										gradientStopsCopy.splice(i, 1);
										updateSetting(stopsSetting, gradientStopsCopy);
									}}>
									X
								</button>
							)
                            : (
								<span className='deleteStopSpacer'></span>
							)}
						</span>
					);
				})}
				<button
					className='add-team add-stop'
					disabled={lockedSettings.includes(stopsSetting)}
					onClick={() => {
						const gradientStopsCopy = structuredClone(settings[stopsSetting]);
						let color = '#000000';
						if (useUnusedColor) {
							const availableColors = ['primary', 'mid', 'secondary', 'primaryDarker', 'secondaryDarker', 'brighter', 'darker'];
							const unusedColor = availableColors.find((color) => {
								return !settings[stopsSetting].some((stop: GradientStop) => stop.color === color);
							});
							color = unusedColor ?? 'primary';
						}
						const percentage = Math.round((100 + Number(gradientStopsCopy[gradientStopsCopy.length - 1].percentage)) / 2);
						gradientStopsCopy.push({
							color,
							percentage
						});
						updateSetting(stopsSetting, gradientStopsCopy);
					}}>
					<i className='fa-solid fa-plus'></i> Add Stop
				</button>
				{showPresets && (
					<span>
						<h5 style={{ display: 'block', marginTop: '10px' }}>Presets</h5>
						{settings[typeSetting] === 'linear-gradient' && (
							<div className='gradientPresets'>
								<Tippy content={<span>Dark 1</span>}>
									<span
										className='editor-button gradientPresetButton'
										onClick={() => {
											updateSetting(stopsSetting, [
												{ color: '#000000', percentage: 0 },
												{ color: 'primary', percentage: 100 }
											]);
										}}>
										<span style={{ background: 'linear-gradient(90deg, #000000 0%, #00a1ff 100%)' }}></span>
									</span>
								</Tippy>
								<Tippy content={<span>Dark 2</span>}>
									<span
										className='editor-button gradientPresetButton'
										onClick={() => {
											updateSetting(stopsSetting, [
												{ color: 'primary', percentage: 0 },
												{ color: 'primary', color2: '#000000', percentage: 40, colorMix: 70 },
												{ color: 'primary', color2: '#000000', percentage: 60, colorMix: 70 },
												{ color: 'primary', percentage: 100 }
											]);
										}}>
										<span style={{ background: 'linear-gradient(90deg, #00a1ff 0%, #000000 40%, #000000 60%, #00a1ff 100%)' }}></span>
									</span>
								</Tippy>
								<Tippy content={<span>Dark 3</span>}>
									<span
										className='editor-button gradientPresetButton'
										onClick={() => {
											updateSetting(stopsSetting, [
												{ color: '#000000', percentage: 0 },
												{ color: 'darker', percentage: 100 }
											]);
										}}>
										<span style={{ background: 'linear-gradient(90deg, #000000 0%, #00a1ff 100%)' }}></span>
									</span>
								</Tippy>
								<Tippy content={<span>Light 1</span>}>
									<span
										className='editor-button gradientPresetButton'
										onClick={() => {
											updateSetting(stopsSetting, [
												{ color: '#ffffff', percentage: 0 },
												{ color: 'primary', percentage: 100 }
											]);
										}}>
										<span style={{ background: 'linear-gradient(90deg, #ffffff 0%, #00a1ff 100%)' }}></span>
									</span>
								</Tippy>
							</div>
						)}
						{settings[typeSetting] === 'radial-gradient' && (
							<div className='gradientPresets'>
								<Tippy content={<span>Dark 4</span>}>
									<span
										className='editor-button gradientPresetButton'
										onClick={() => {
											updateSetting(stopsSetting, [
												{ color: 'primary', percentage: 0 },
												{ color: 'primary', color2: '#000000', percentage: 100, colorMix: 70 }
											]);
										}}>
										<span style={{ background: 'radial-gradient(circle, #00a1ff 0%, #00000070 100%)' }}></span>
									</span>
								</Tippy>
							</div>
						)}
					</span>
				)}
			</span>
		</span>
	);
};
