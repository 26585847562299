import {useState} from 'react';
import Modal from 'react-modal';
import {toast} from 'react-toastify';
import {uploadJpeg} from '../../firebase/firebase';
import useModalStore from '../../stores/modal.store';
import usePostStore from '../../stores/posts/base-post.store';
import useGlobalStore from '../../stores/global.store';

export default function UploadModal(props) {
	const [selectedImage, setSelectedImage] = useState(null);
	const { toggleModal } = useModalStore();
	const { image, setImage } = usePostStore();
	const { debug } = useGlobalStore();

	const checkFileSize = (event) => {
		let file = event.target.files[0];
		if (file && file.size > 2097152) {
			toast.error('File is over 2 MB.  Please try a different file.');
			event.target.value = null;
			return false;
		}
		return true;
	};

	const imageSelected = (e) => {
		if (e.target.files[0] === undefined) {
			setSelectedImage(null);
		} else if (checkFileSize(e)) {
			setSelectedImage(e.target.files[0]);
		}
	};

	const imageSubmit = (e) => {
		e.preventDefault();
		if (selectedImage) {
			const title = `/images/player-image-${props.user._id}${selectedImage.name}`;
			uploadJpeg(selectedImage, title)
				.then((url) => {
					setImage([...image, url]);
					toggleModal('upload');
				})
				.catch((error) => {
					toast.error('Upload failed. Please try again.');
					if (debug) {
						console.log(error);
					}
					setSelectedImage(null);
				});
		}
	};

	return (
		<Modal
			isOpen={true}
			onRequestClose={() => toggleModal('upload')}
			shouldCloseOnOverlayClick={true}
			shouldCloseOnEsc={true}>
			<div className='modal'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>Upload Image</h5>
							<span
								className='close-modal'
								onClick={() => toggleModal('upload')}>
								X
							</span>
						</div>
						<div className='modal-body'>
							<div className='file-box'>
								<form onSubmit={imageSubmit}>
									<input
										type='file'
										className='file-input'
										accept='image/*'
										onChange={imageSelected}
									/>
									{selectedImage && (
										<input
											id='upload-button'
											type='submit'
											value='Upload'
											className='upload-button newButton'
										/>
									)}
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}
