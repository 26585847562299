import {useEffect, useState} from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleLogin } from '@react-oauth/google';
import usePreferencesStore from '../stores/preferences.store';

export default function Login() {
	const navigate = useNavigate();
	const location = useLocation();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [remember, setRemember] = useState(false);
	const [reveal, setReveal] = useState(false);
	const { darkMode } = usePreferencesStore();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (localStorage.getItem('email')) {
			setEmail(localStorage.getItem('email'));
			setRemember(true);
		}
	}, []);

	const loginToSite = (e) => {
		setLoading(true);
		e.preventDefault();
		axios
			.post(`/logins/login`, {
				email,
				password,
			})
			.then((response) => {
				if (remember) {
					localStorage.setItem('email', email);
				}
				localStorage.setItem('token', response.data.data.token);
				if (location?.state?.from?.pathname) {
					navigate(location.state.from.pathname);
				} else {
					navigate('/dashboard');
				}
			})
			.catch((err) => {
				setLoading(false);
				toast.error('Check your credentials and try again.');
			});
	};

	const rememberChange = () => {
		if (remember) {
			localStorage.removeItem('email');
		}
		setRemember(!remember);
	};

	const toggleReveal = () => {
		setReveal(!reveal);
	};

	const googleLogin = (credential) => {
		axios.post('logins/google', {
			credential,
		}).then((response) => {
			localStorage.setItem('token', response.data.data.token);
			if (location?.state?.from?.pathname) {
				navigate(location.state.from.pathname);
			} else {
				navigate('/dashboard');
			}
		}).catch((err) => {
			toast.error('Login failed, please try again');
		});
	};

	return (
		<div className='login-wrapper'>
		<div className='login'>
			<ToastContainer />
			<form onSubmit={loginToSite}>
				<h3>Sign in</h3>

				<div className='form-group floating-label'>
					<input
						type='email'
						required
						defaultValue={email}
						className='form-control'
						autoComplete='email'
						onChange={(e) => {
							setEmail(e.target.value);
						}}
					/>
					<label className={'form-label' + (email && ' filled')}>Email address</label>
				</div>

				<div className='form-group floating-label'>
					<div className='input-group reverse'>
						<div className='input-group-append'>
							<div
								onClick={toggleReveal}
								className='input-group-text'>
								<i className={reveal ? 'fa fa-eye-slash' : 'fa fa-eye'}></i>
							</div>
						</div>
						<input
							type={reveal ? 'text' : 'password'}
							required
							className='form-control'
							autoComplete='current-password'
							onChange={(e) => {
								setPassword(e.target.value);
							}}
						/>
					</div>
					<label className={'form-label' + (password && ' filled')}>Password</label>
				</div>

				<div className='below-login'>
					<div className='form-check rememberform'>
						<input
							type='checkbox'
							className='form-check-input'
							id='rememberCheck'
							checked={remember}
							onChange={rememberChange}
						/>
						<label
							className='form-check-label'
							htmlFor='rememberCheck'>
							Remember me
						</label>
					</div>

					<p className='forgot text-end'>
						<Link to='/forgot'>Forgot password?</Link>
					</p>
				</div>

				<button
					type='submit'
					disabled={loading}
					className='btn btn-primary btn-block'>
					Sign In
					{loading && <i className='fa fa-spinner fa-spin' style={{marginLeft: '10px'}}></i>}
				</button>

				<div className='or'>
					<hr/>
					<span>Or</span>
				</div>
				
				<div className='sso'>
				<GoogleLogin
					size='medium'
					shape='pill'
					theme={darkMode ? 'filled_black' : 'outline'}
					onSuccess={(credentialResponse) => {
						googleLogin(credentialResponse.credential);
					}}
					onError={() => {
						toast.error('Login failed, please try again');
					}}/>
				</div>
				<div className='links'>
					<p className='sign-up'>
						<Link to='/register'>Create account</Link>
					</p>
				</div>
			</form>
		</div>
		</div>
	);
}
