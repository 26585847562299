import { create } from 'zustand';

interface ChartStore {
    xLabel: string;
    yLabel: string;
    setxLabel: (newValue: string) => void;
    setyLabel: (newValue: string) => void;
    reset: () => void;
}

const useChartStore = create<ChartStore>((set) => ({
    xLabel: '',
    yLabel: '',
    setxLabel: (newValue) => set({ xLabel: newValue }),
    setyLabel: (newValue) => set({ yLabel: newValue }),
    reset: () => set(() => ({
        xLabel: '',
        yLabel: ''
    }))
}));

export default useChartStore;
