export const fontOptions = [
	{value: 'adidas', label: 'Adidas', className: 'adidas'},
	{value: 'american captain', label: 'American Captain', className: 'american captain'},
	{value: 'anonymous', label: 'Anonymous Pro', className: 'anonymous', mono: true},
	{value: 'anton', label: 'Anton', className: 'anton'},
	{value: 'antonio', label: 'Antonio', className: 'antonio'},
	{value: 'aurach', label: 'Aurach', className: 'aurach'},
	{value: 'beantown', label: 'Beantown', className: 'beantown'},
	{value: 'bebas neue', label: 'Bebas Neue', className: 'bebas neue', mono: true},
	{value: 'berlina', label: 'Berlina', className: 'berlina'},
	{value: 'carbon', label: 'Carbon', className: 'carbon'},
	{value: 'droid', label: 'Droid Sans', className: 'droid', mono: true},
	{value: 'edo', label: 'Edo', className: 'edo'},
	{value: 'enfatica', label: 'Enfatica', className: 'enfatica'},
	{value: 'exo', label: 'Exo', className: 'exo'},
	{value: 'graduate', label: 'Graduate', className: 'graduate', mono: true},
	{value: 'hemi', label: 'Hemi', className: 'hemi'},
	{value: 'plex', label: 'IBM Plex', className: 'plex', mono: true},
	{value: 'jersey', label: 'Jersey', className: 'jersey'},
	{value: 'league gothic', label: 'League Gothic', className: 'leaguegothic'},
	{value: 'league spartan', label: 'League Spartan', className: 'leaguespartan'},
	{value: 'lemon milk light', label: 'Lemon Milk Light', className: 'lemon milk light'},
	{value: 'lemon milk reg', label: 'Lemon Milk Reg', className: 'lemon milk reg'},
	{value: 'metropolis', label: 'Metropolis', className: 'metropolis'},
	{value: 'monofont', label: 'Mono', className: 'monofont', mono: true},
	{value: 'montserrat', label: 'Montserrat', className: 'montserrat'},
	{value: 'new athletic', label: 'New Athletic', className: 'new athletic'},
	{value: 'octin', label: 'Octin', className: 'octin'},
	{value: 'open sans', label: 'Open Sans', className: 'opensans', mono: true},
	{value: 'ostrich sans', label: 'Ostrich Sans', className: 'ostrichsans'},
	{value: 'playball', label: 'Playball', className: 'playball'},
	{value: 'pop warner', label: 'Pop Warner', className: 'popwarner'},
	{value: 'poppins', label: 'Poppins', className: 'poppins'},
	{value: 'poppinsbold', label: 'Poppins Bold', className: 'poppinsbold'},
	{value: 'poppinslight', label: 'Poppins Light', className: 'poppinslight'},
	{value: 'redwing', label: 'Redwing', className: 'redwing'},
	{value: 'rift', label: 'Rift', className: 'rift'},
	{value: 'roboto bold', label: 'Roboto Bold', className: 'robotobold', mono: true},
	{value: 'roboto condensed', label: 'Roboto Condensed', className: 'robotocondensed', mono: true},
	{value: 'robotoslab', label: 'Roboto Slab', className: 'robotoslab'},
	{value: 'soccer', label: 'Soccer', className: 'soccer'},
	{value: 'square', label: 'Square Block', className: 'square', mono: true},
	{value: 'staubach', label: 'Staubach', className: 'staubach'},
	{value: 'stadium', label: 'Stadium', className: 'stadium'},
	{value: 'taurus', label: 'Taurus', className: 'taurus', mono: true},
	{value: 'the bold one', label: 'The Bold One', className: 'the bold one'},
	{value: 'trebuchet', label: 'Trebuchet', className: 'trebuchet', mono: true},
	{value: 'true lies', label: 'True Lies', className: 'true lies'},
	{value: 'uberlin', label: 'Uberlin', className: 'uberlin'},
	{value: 'ubuntu', label: 'Ubuntu', className: 'ubuntu', mono: true},
	{value: 'uniform reg', label: 'Uniform Reg', className: 'uniform reg'},
	{value: 'uniform bold', label: 'Uniform Bold', className: 'uniform bold'},
	{value: 'uni sans', label: 'Uni Sans', className: 'unisans'},
	{value: 'viper', label: 'Viper Squadron', className: 'viper', mono: true},
];
