import axios from 'axios';
import {useState} from 'react';
import Modal from 'react-modal';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import useModalStore from '../../stores/modal.store';
import { GoogleLogin } from '@react-oauth/google';
import usePreferencesStore from '../../stores/preferences.store';
import useGlobalStore from '../../stores/global.store';

export default function TimeoutModal(props) {
	const navigate = useNavigate();
	const [reveal, setReveal] = useState(false);
	const [password, setPassword] = useState('');
	const { toggleModal, clearModals } = useModalStore();
	const { darkMode } = usePreferencesStore();
	const { debug } = useGlobalStore();

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			renewToken(e);
		}
	};

	const renewToken = async (e) => {
		try {
			e.preventDefault();
			axios
				.post(`/logins/login`, {
					email: props.user.email,
					password,
				})
				.then((response) => {
					setPassword('');
					localStorage.setItem('token', response.data.data.token);
					axios.defaults.headers.common['auth-token'] = response.data.data.token;
					props.updateUser(response.data.data.token);
					toggleModal('timeout');
				})
				.catch(() => {
					toast.error('Check your credentials and try again.');
				});
		} catch (e) {
			if (debug) console.log(e);
		}
	};

	const logout = () => {
		clearModals();
		localStorage.removeItem('token');
		navigate('/');
	};

	const googleLogin = (credential) => {
		axios.post('logins/google', {
			credential,
		}).then((response) => {
			localStorage.setItem('token', response.data.data.token);
			axios.defaults.headers.common['auth-token'] = response.data.data.token;
			props.updateUser(response.data.data.token);
			toggleModal('timeout');
		}).catch((err) => {
			toast.error('Login failed, please try again');
		});
	};

	return (
		<Modal
			isOpen={true}
			onRequestClose={logout}>
			<div className='modal'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>Your session is expired</h5>
						</div>
						<div className='modal-body'>
							<p>Please sign in.</p>
							{!props.user.hasGoogleAuth && (<form>
								<div className='form-group session-expired floating-label'>
									<input
										disabled
										className='form-control'
										defaultValue={props.user.email}
									/>
									<label className='form-label filled'>Email address</label>
								</div>
								<div className='form-group session-expired floating-label'>
									<div className='input-group reverse'>
										<div className='input-group-append'>
											<div
												onClick={() => setReveal(!reveal)}
												className='input-group-text'>
												<i className={reveal ? 'fa fa-eye-slash' : 'fa fa-eye'}></i>
											</div>
										</div>
										<input
											type={reveal ? 'text' : 'password'}
											required
											className='form-control'
											autoComplete='current-password'
											onKeyDown={handleKeyDown}
											onChange={(e) => {
												setPassword(e.target.value);
											}}
										/>
										<label className={'form-label' + (password && ' filled')}>Password</label>
									</div>
								</div>
							</form>)}
							{props.user.hasGoogleAuth && (
								<GoogleLogin
									size='medium'
									shape='pill'
									theme={darkMode ? 'filled_black' : 'outline'}
									onSuccess={(credentialResponse) => {
										googleLogin(credentialResponse.credential);
									}}
									onError={() => {
										toast.error('Login failed, please try again');
									}}/>
							)}
						</div>
						<div className='modal-footer'>
							<button
								type='button'
								onClick={logout}
								className='btn btn-primary secondary'
								data-dismiss='modal'>
								Sign out
							</button>
							{!props.user.hasGoogleAuth && (<button
								type='button'
								onClick={renewToken}
								className='btn btn-primary'>
								Sign in
							</button>)}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}
