import usePostStore from '../../../stores/posts/base-post.store';
import useSettingsStore from '../../../stores/settings.store';

export default function TitleBox (props: { user: { logo: string | undefined; }; selectSettings: (arg0: string) => void; homePage: boolean; }): React.JSX.Element {
    const { title, subtitle, thirdTitle } = usePostStore();
	const { settings } = useSettingsStore();

    const style: { gridColumnEnd?: number, height?: string } = {};
    const titleHeight = thirdTitle ? `${settings.titleHeight + 4}%` : `${settings.titleHeight}%`;
    style.height = `calc(var(--postheight) * ${titleHeight})`;
    const titleStyle: { fontSize?: number, background?: string } = {};
    const subtitleStyle: { fontSize?: number, background?: string } = {};
    const thirdTitleStyle: { fontSize?: number, background?: string } = {};
    if (settings.type.includes('image') || settings.type === 'news' || settings.type === 'highlightimage' || settings.type === 'lineupimage') {
        if (settings.orientation === 'topbottom' || settings.orientation === 'bottomtop') {
            titleStyle.fontSize = ((settings.postHeight / 800) * settings.headerSize * 3 * settings.paneSplitNews[1]) / 100;
            subtitleStyle.fontSize = ((settings.postHeight / 800) * settings.subtitleFontSize * 3 * settings.paneSplitNews[1]) / 100;
            thirdTitleStyle.fontSize = ((settings.postHeight / 800) * settings.thirdTitleFontSize * 3 * settings.paneSplitNews[1]) / 100;
        }
    }
    if (settings.headerFontColor === 'gradient') {
        let background = settings.headerTextGradientType === 'linear-gradient'
            ? `linear-gradient(${settings.headerTextGradientDirection}, `
            : 'radial-gradient(circle, ';
        settings.headerTextGradientStops.forEach((stop, i) => {
            background += `${stop.color} ${stop.percentage}%, `
        });
        background = background.slice(0, -2);
        background += ')';
        titleStyle.background = background;
    }
    if (settings.subtitleFontColor === 'gradient') {
        let background = settings.subtitleTextGradientType === 'linear-gradient'
            ? `linear-gradient(${settings.subtitleTextGradientDirection}, `
            : 'radial-gradient(circle, ';
        settings.subtitleTextGradientStops.forEach((stop, i) => {
            background += `${stop.color} ${stop.percentage}%, `
        });
        background = background.slice(0, -2);
        background += ')';
        subtitleStyle.background = background;
    }
    if (settings.thirdTitleFontColor === 'gradient') {
        let background = settings.thirdTitleTextGradientType === 'linear-gradient'
            ? `linear-gradient(${settings.thirdTitleTextGradientDirection}, `
            : 'radial-gradient(circle, ';
        settings.thirdTitleTextGradientStops.forEach((stop, i) => {
            background += `${stop.color} ${stop.percentage}%, `
        });
        background = background.slice(0, -2);
        background += ')';
        thirdTitleStyle.background = background;
    }
    if (settings.type.includes('grid') && settings.gridColumns) {
        style.gridColumnEnd = Number(settings.gridColumns) + 1;
    }
    // TODO setting for watermark logo one side
    return (
        <div
            className='titlebox'
            id='titlebox'
            style={style}>
            {props.user.logo && settings.headerAlignment !== 'left' && (
                <img
                    src={props.user.logo}
                    id='userLogo'
                    alt=''
                    className='userLogo1'></img>
            )}
            {!props.user.logo && settings.headerAlignment !== 'left' && (
                <span
                    className='watermark1'
                    onClick={() => props.selectSettings('actualheader')}>
                    {settings.watermark}
                </span>
            )}
            <span className='title-wrapper'>
                <h1
                    className={'title' + (settings.headerFontColor === 'gradient' ? ' withTextGradient' : '')}
                    contentEditable={props.homePage}
                    suppressContentEditableWarning={true}
                    style={titleStyle}
                    onClick={() => props.selectSettings('header')}>
                    {title}
                </h1>
            </span>
            <span className='subttitle-wrapper'>
                <h2
                    className={'subtitle' + (settings.subtitleFontColor === 'gradient' ? ' withTextGradient' : '')}
                    contentEditable={props.homePage}
                    suppressContentEditableWarning={true}
                    style={subtitleStyle}
                    onClick={() => props.selectSettings('subtitle')}>
                    {subtitle}
                </h2>
            </span>
            {thirdTitle && (<span className='third-title-wrapper'>
                <h2
                    className={'thirdTitle' + (settings.thirdTitleFontColor === 'gradient' ? ' withTextGradient' : '')}
                    contentEditable={props.homePage}
                    suppressContentEditableWarning={true}
                    style={thirdTitleStyle}
                    onClick={() => props.selectSettings('thirdTitle')}>
                    {thirdTitle}
                </h2>
            </span>)}
            {props.user.logo && settings.headerAlignment !== 'right' && (
                <img
                    src={props.user.logo}
                    id='userLogo'
                    alt=''
                    className='userLogo2'></img>
            )}
            {!props.user.logo && settings.headerAlignment !== 'right' && (
                <span
                    className='watermark2'
                    onClick={() => props.selectSettings('actualheader')}>
                    {settings.watermark}
                </span>
            )}
        </div>
    );
}
