import {Link} from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.css';

export default function Admin() {
	return (
		<div className='AdminLinks'>
			<span>Auth</span>
			<Link to='/dashboard'>Dashboard</Link>
			<Link to='/abbreviations'>Abbreviations</Link>
			<span>Any</span>
			<Link to='/'>Home</Link>
			<Link to='/legal'>Legal</Link>
			<Link to='/success'>Change Plan Success</Link>
			<Link to='/login'>Login</Link>
			<Link to='/register'>Register</Link>
			<Link to='/forgot'>Forgot Password</Link>
			<Link to='/reset/1'>Reset Password</Link>
			<Link to='/gamefinder'>Game Finder</Link>
			<span>Admin</span>
			<Link to='/gamepoll'>Game Poll</Link>
			<Link to='/admin/crons'>Crons</Link>
			<Link to='/admin/users'>Users</Link>
			<Link to='/admin/stats'>Stats</Link>
		</div>
	);
}
