import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import {colord, extend} from 'colord';
import mixPlugin from 'colord/plugins/mix';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';

extend([mixPlugin]);

ChartJS.register(annotationPlugin, ChartDataLabels, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement);

export default function AverageChart(props) {
	let canvas = document.createElement('canvas');
	let ctx = canvas.getContext('2d');
	let gradient = ctx.createLinearGradient(0, 0, 0, 400);
	gradient.addColorStop('0', props.teamObj.primary);
	gradient.addColorStop('.5', colord(props.teamObj.secondary).mix(props.teamObj.primary).toHex());
	gradient.addColorStop('1', props.teamObj.secondary);

	let options = {
		responsive: true,
		aspectRatio: false,
		plugins: {
			legend: false,
			title: false,
			annotation: {
				annotations: {
					line1: {
						type: 'line',
						borderColor: 'white',
						borderDash: [6, 6],
						borderDashOffset: 0,
						borderWidth: 3,
						// label: {
						// 	display: true,
						// 	content: 'Projection: ' + props.prop,
						// 	position: 'center',
						// 	xAdjust: 0,
						// 	yAdjust: 0
						// },
						scaleID: 'y',
						value: props.average,
					},
				},
			},
			datalabels: {
				color: 'white',
				anchor: 'start',
				align: 'top',
				labels: {
					title: {
						font: {
							weight: 'bold'
						}
					}
				}
			}
		},
		scales: {
			y: {
				ticks: {
					color: '#ffffff',
					padding: 10,
					font: {
						size: 24,
						weight: 700,
						family: 'roboto condensed',
					},
				},
			},
			x: {
				ticks: {
					autoSkip: false,
					color: '#ffffff',
					padding: 10,
					font: {
						size: 24,
						weight: 700,
						family: 'roboto condensed',
					},
				},
			},
		},
	};

	let data = {
		labels: props.labels,
		datasets: [
			{
				label: '',
				data: props.data,
				backgroundColor: gradient,
				borderColor: '#ffffff',
				borderWidth: 2,
			},
		],
	};

	return (
		<Bar
			options={options}
			data={data}
			style={{width: '400px', height: '400px'}}
		/>
	);
}
