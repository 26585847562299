export default function PaymentPage(props) {
	return (
		<div className='PaymentPage'>
			<stripe-pricing-table
				pricing-table-id={props.user.stripeSub ? 'prctbl_1P8qLoGVaQqQonU1edj3FVf2' : 'prctbl_1NXTPnGVaQqQonU1ZYY8oxKJ'}
				client-reference-id={props.user._id}
				customer-email={props.user.email}
				publishable-key='pk_live_51Mu5awGVaQqQonU1EprDyEHWXIsccUZKQA2QvEYgXXLLLT2etZzoe1JT2lQqTvvbHvmfhM3fNl7AskQaQBUeTu2500GJyWlpTW'></stripe-pricing-table>
		</div>
	);
}
