import {useEffect, useState} from 'react';
import {ToastContainer, toast} from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.css';
import moment from 'moment';
import axios from 'axios';

export default function Users() {
	const [users, setUsers] = useState([]);
	const [sortAsc, setSortAsc] = useState(true);

	useEffect(() => {
		getUsers();
	}, []);

	const getUsers = async () => {
		try {
			const response = await axios.get(`/admins/users`);
			if (response.data) {
				const users = response.data;
				users.forEach((user) => {
					let startDate, endDate;
					if (Number.isInteger(user.startDate)) {
						startDate = moment(new Date(0).setUTCSeconds(user.startDate)).format('M/D/YY');
					} else {
						startDate = moment(user.startDate).format('M/D/YY');
					}
					if (Number.isInteger(user.endDate)) {
						endDate = moment(new Date(0).setUTCSeconds(user.endDate)).format('M/D/YY');
					} else {
						endDate = moment(user.endDate).format('M/D/YY');
					}
					user.startDate = startDate;
					user.endDate = endDate;
					if (user.isAnnual) {
						user.monthly = 0;
						user.annually = user.plan === 'all' ? 144.27 : 76.72;
						user.total = user.annually * (moment(user.endDate).diff(moment(user.startDate), 'years') + 1);
					} else {
						user.annually = 0;
						user.monthly = user.plan === 'all' ? 13.99 : 7.23;
						user.total = user.monthly * moment(user.endDate).diff(moment(user.startDate), 'months');
					}
					user.total = Number(user.total.toFixed(2));
				});
				setUsers(users);
			}
		} catch (e) {
			toast.error(e);
		}
	};

	const sortTable = (sortVal) => {
		users.sort((a, b) => {
			const aVal = a[sortVal];
			const bVal = b[sortVal];
		
			if (sortVal === 'startDate' || sortVal === 'endDate') {
				const dateA = new Date(aVal).getTime() || 0;
				const dateB = new Date(bVal).getTime() || 0;
				return sortAsc ? dateA - dateB : dateB - dateA;
			}
		
			if (typeof aVal === 'string' && typeof bVal === 'string') {
				return sortAsc ? aVal.localeCompare(bVal) : bVal.localeCompare(aVal);
			}
		
			if (aVal == null && bVal == null) return 0;
			if (aVal == null) return sortAsc ? -1 : 1;
			if (bVal == null) return sortAsc ? 1 : -1;
		
			return sortAsc ? aVal - bVal : bVal - aVal;
		});
		setUsers(users);
		setSortAsc(!sortAsc);
	};

	return (
		<div className='AdminPage Users'>
			<ToastContainer />
			<div className='main'>
				<h3>{`Users (${users.length})`}</h3>
				<div className='table table-striped'>
					<div className='table-head'>
						<span onClick={() => sortTable('name')}>Name</span>
						<span onClick={() => sortTable('email')}>Email</span>
						<span onClick={() => sortTable('instagram')}>Social</span>
						<span onClick={() => sortTable('startDate')}>Start</span>
						<span onClick={() => sortTable('endDate')}>End</span>
						<span onClick={() => sortTable('paypalSub')}>Paypal</span>
						<span onClick={() => sortTable('stripeSub')}>Stripe</span>
						<span onClick={() => sortTable('sport')}>Plan</span>
						<span onClick={() => sortTable('post_count')}>Posts</span>
						<span onClick={() => sortTable('num_logins')}>Logins</span>
						<span onClick={() => sortTable('monthly')}>Monthly</span>
						<span onClick={() => sortTable('annually')}>Yearly</span>
						<span onClick={() => sortTable('total')}>Total</span>
					</div>
					<div className='table-body'>
						{users.map((user, i) => {
							return (
								<span
									key={i}
									className='table-row'>
									<span>{user.name}</span>
									<span>{user.email}</span>
									<span>{user.instagram}</span>
									<span>{user.startDate}</span>
									<span>{user.endDate}</span>
									<span>{user.paypalSub}</span>
									<span>{user.stripeSub}</span>
									<span>{`${user.plan === 'all' ? 'All' : user.sport.toUpperCase()} - ${user.isAnnual ? 'Annual' : 'Monthly'}`}</span>
									<span>{user.post_count}</span>
									<span>{user.num_logins}</span>
									<span>{`$${user.monthly}`}</span>
									<span>{`$${user.annually}`}</span>
									<span>{`$ ${user.total}`}</span>
								</span>
							);
						})}
						<span className='table-row total-row'>
							<span></span>
							<span></span>
							<span></span>
							<span></span>
							<span></span>
							<span></span>
							<span></span>
							<span></span>
							<span>
								{Number(
									users.reduce((acc, obj) => {
										return acc + (obj.post_count ?? 0);
									}, 0)
								)}
							</span>
							<span>
								{Number(
									users.reduce((acc, obj) => {
										return acc + (obj.num_logins ?? 0);
									}, 0)
								)}
							</span>
							<span>
								$
								{Number(
									users.reduce((acc, obj) => {
										return acc + obj.monthly;
									}, 0)
								).toFixed(2)}
							</span>
							<span>
								$
								{Number(
									users.reduce((acc, obj) => {
										return acc + obj.annually;
									}, 0)
								).toFixed(2)}
							</span>
							<span>
								$
								{Number(
									users.reduce((acc, obj) => {
										return acc + Number(obj.total);
									}, 0)
								).toFixed(2)}
							</span>
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}
