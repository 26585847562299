import { Link } from 'react-router-dom';
import SwiperCore from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import './Article.css';

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function Article(props) {
  let images = [];
  if (props.sport === 'NFL') {
    images = [
      'example1.jpeg',
      'example4.jpeg',
      'example7.jpeg',
      'example12.jpeg',
      'example15.jpeg'
    ];
  } else if (props.sport === 'NBA') {
    images = [
      'example3.jpeg',
      'example13.jpeg',
      'example14.jpeg'
    ];
  } else {
    images = [
      '/example1.jpeg',
			'/example2.jpeg',
			'/example3.jpeg',
			'/example4.jpeg',
			'/example5.jpeg',
			'/example6.jpeg',
			'/example7.jpeg',
			'/example8.jpeg',
			'/example9.jpeg',
			'/example10.jpeg',
			'/example11.jpeg',
			'/example12.jpeg',
			'/example13.jpeg',
			'/example14.jpeg',
			'/example15.jpeg'
    ];
  }

	return (
		<div className='article-container'>
			<div className='article-content'>
				<h1 className='article-title'>What is the easiest way to create {props.sport} graphics?</h1>
				<p className='article-description'>With Stat Lists, you can create {props.sport} graphics with ease.</p>
				<div className='article-carousel'>
					<Swiper
						spaceBetween={30}
						slidesPerView={1}
						navigation
						pagination={{ clickable: true }}
						autoplay={{ delay: 3000 }}>
              {images.map((image, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={image}
                    alt={`Carousel Slide ${index + 1}`}
                    className='swiper-slide-image'
                  />
                </SwiperSlide>
              ))}
					</Swiper>
				</div>
				<div className='article-body'>
					<p>In the fast-paced world of social media, captivating graphics are the key to grabbing attention and making an impact. But not everyone has the time or design skills to create stunning visuals.</p>

					<h2>Enter Stat Lists – your ultimate shortcut to creating professional {props.sport} graphics with ease.</h2>

					<p>With Stat Lists, you can effortlessly craft personalized graphics featuring your favorite teams and players, all without any design experience required.</p>

					<h2>Simple. Powerful. Persuasive.</h2>

					<p>Stat Lists simplifies the graphic creation process like never before. Just type in team and player names, and let Stat Lists handle the rest. No fuss, no hassle – just beautiful graphics in minutes.</p>

					<h2>Customize and Conquer</h2>

					<p>Stand out from the crowd with customizable options that let you tailor your graphics to perfection. From fonts to colors to layouts, make your mark with a style that's uniquely yours.</p>

					<h2>No More Headaches</h2>

					<p>Forget about complicated design software. With Stat Lists, creating eye-catching {props.sport} graphics is as easy as a few clicks. Spend less time struggling with design and more time engaging your audience.</p>

					<h2>Try it Free Today</h2>

					<p>Ready to elevate your {props.sport} social media game? Sign up for a one-week free trial of Stat Lists now. Experience the simplicity, power, and persuasive impact of Stat Lists for yourself – no strings attached.</p>

					<h2>Don't Miss Out</h2>

					<p>
						In a world where attention is everything, make sure your social media presence stands out. With Stat Lists, you have the tools to captivate your audience and make a lasting impression. Join Stat Lists today and take your {props.sport} graphics to the next level.
					</p>
				</div>
        <hr className='homeline' />
				<div className='homepay'>
					<h1 className='stepsTitle'>Subscription Options</h1>
					<div className='row row-cols-1 row-cols-md-2 mb-2 text-center'>
						<div className='col'>
							<div className='card mb-4 rounded-3 shadow-sm'>
								<div className='card-header py-3'>
									<h4 className='my-0 fw-normal'>Single Sport</h4>
								</div>
								<div className='card-body'>
									<h1 className='card-title pricing-card-title'>
										$8
										<small className='text-muted fw-light'>/mo</small>
									</h1>
									<ul className='list-unstyled mt-3 mb-4'>
										<li>Create and download an unlimited number of graphics for a single sport (NFL, NBA, MLB, NHL, NCAA, Soccer, WNBA, AFL, or F1). Includes a one-week free trial.</li>
									</ul>
									<Link to='/register'>
										<button className='newButton'>Sign Up</button>
									</Link>
								</div>
							</div>
						</div>
						<div className='col'>
							<div className='card mb-4 rounded-3 shadow-sm'>
								<div className='card-header py-3'>
									<h4 className='my-0 fw-normal'>All Sports</h4>
								</div>
								<div className='card-body'>
									<h1 className='card-title pricing-card-title'>
										$15
										<small className='text-muted fw-light'>/mo</small>
									</h1>
									<ul className='list-unstyled mt-3 mb-4'>
										<li>Create and download an unlimited number of graphics for all leagues. (NFL, NBA, MLB, NHL, NCAA, Soccer, WNBA, AFL, or F1). Includes a one-week free trial.</li>
									</ul>
									<Link to='/register'>
										<button className='newButton'>Sign Up</button>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
