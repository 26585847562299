import axios from 'axios';
import {useState} from 'react';
import Modal from 'react-modal';
import {toast} from 'react-toastify';
import useModalStore from '../../stores/modal.store';
import useGlobalStore from '../../stores/global.store';

export default function PasswordModal(props) {
	const [oldPassword, setOldPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [oldReveal, setOldReveal] = useState(false);
	const [newReveal, setNewReveal] = useState(false);
	const { toggleModal } = useModalStore();
	const { debug } = useGlobalStore();

	const changePassword = (e) => {
		e.preventDefault();
		try {
			axios
				.put(`/users/updatePassword/${props.user._id}`, {
					oldPassword,
					newPassword,
				})
				.then((response) => {
					if (response.status === 201) {
						toast.success('Updated password successfully!', {autoClose: 3000});
						localStorage.setItem('token', response.data.data.token);
						props.updateUser(response.data.data.token);
					}
					setOldPassword('');
					setNewPassword('');
					toggleModal('password');
				})
				.catch((error) => {
					if (debug) {
						console.log(error);
					}
					if (error.data.error === 'Old password is incorrect') {
						toast.error('Old password is incorrect.  Please try again', {autoClose: 3000});
					} else {
						toast.error('An unknown error occured.');
					}
				});
		} catch (e) {
			if (debug) {
				console.log(e);
			}
			toast.error('Something went wrong.  Please try again later', {autoClose: 3000});
		}
	};

	return (
		<Modal
			isOpen={true}
			onRequestClose={() => toggleModal('password')}>
			<div className='modal'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>Change Password</h5>
							<span
								className='close-modal'
								onClick={() => toggleModal('password')}>
								X
							</span>
						</div>
						<div className='modal-body'>
							<form onSubmit={changePassword}>
								<div className='form-group'>
									<label className='form-label'>Old Password</label>
									<div className='input-group reverse'>
										<div className='input-group-append'>
											<div
												onClick={() => setOldReveal(!oldReveal)}
												className='input-group-text'>
												<i className={oldReveal ? 'fa fa-eye-slash' : 'fa fa-eye'}></i>
											</div>
										</div>
										<input
											type={oldReveal ? 'text' : 'password'}
											required
											className='form-control'
											autoComplete='current-password'
											placeholder='Old password'
											onChange={(e) => {
												setOldPassword(e.target.value);
											}}
										/>
									</div>
								</div>

								<div className='form-group'>
									<label className='form-label'>New Password</label>
									<label className='sub-label'>(Must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, and one number)</label>
									<div className='input-group reverse'>
										<div className='input-group-append'>
											<div
												onClick={() => setNewReveal(!newReveal)}
												className='input-group-text'>
												<i className={newReveal ? 'fa fa-eye-slash' : 'fa fa-eye'}></i>
											</div>
										</div>
										<input
											type={newReveal ? 'text' : 'password'}
											required
											className='form-control'
											placeholder='New password'
											autoComplete='new-password'
											pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}'
											onChange={(e) => {
												setNewPassword(e.target.value);
											}}
										/>
									</div>
								</div>
								<button
									type='submit'
									className='btn btn-primary btn-block'>
									Submit
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}
