import { create } from 'zustand';

interface ComparisonStore {
    leftName: string;
    rightName: string;
    leftTeam: string;
    rightTeam: string;
    leftImage: string;
    rightImage: string;
    leftStats: string[];
    rightStats: string[];
    statLabels: string[];
    setLeftName: (newValue: string) => void;
    setRightName: (newValue: string) => void;
    setLeftTeam: (newValue: string) => void;
    setRightTeam: (newValue: string) => void;
    setLeftImage: (newValue: string) => void;
    setRightImage: (newValue: string) => void;
    setLeftStats: (newValue: string[]) => void;
    setRightStats: (newValue: string[]) => void;
    setStatLabels: (newValue: string[]) => void;
    reset: () => void;
}

const useComparisonStore = create<ComparisonStore>((set) => ({
    leftName: '',
    rightName: '',
    leftTeam: '',
    rightTeam: '',
    leftImage: '',
    rightImage: '',
    leftStats: [],
    rightStats: [],
    statLabels: [],
    setLeftName: (newValue) => set({ leftName: newValue }),
    setRightName: (newValue) => set({ rightName: newValue }),
    setLeftTeam: (newValue) => set({ leftTeam: newValue }),
    setRightTeam: (newValue) => set({ rightTeam: newValue }),
    setLeftImage: (newValue) => set({ leftImage: newValue }),
    setRightImage: (newValue) => set({ rightImage: newValue }),
    setLeftStats: (newValue) => set({ leftStats: newValue }),
    setRightStats: (newValue) => set({ rightStats: newValue }),
    setStatLabels: (newValue) => set({ statLabels: newValue }),
    reset: () => set(() => ({
        leftName: '',
        rightName: '',
        leftTeam: '',
        rightTeam: '',
        leftImage: '',
        rightImage: '',
        leftStats: [],
        rightStats: [],
        statLabels: []
    }))
}));

export default useComparisonStore;
