import {useEffect, useMemo, useState} from 'react';
import {ToastContainer, toast} from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.css';
import moment from 'moment';
import Select from 'react-select';
import {settingsStyles} from '../data/styles/settingsStyles';
import {settingsStylesDark} from '../data/styles/settingsStylesDark';
import { mlbFull, nbaFull, ncaaFull, nflFull, nhlFull } from '../data/teams/teamAbbrs';
import axios from 'axios';
import usePreferencesStore from '../stores/preferences.store';

export default function GameFinder(props) {
	const [tickets, setTickets] = useState([]);
	const [sortAsc, setSortAsc] = useState(true);
	const [sports] = useState(['NFL', 'NBA', 'MLB', 'NHL', 'CFB', 'CBB']);
	const [selectedSport, setSelectedSport] = useState('');
	const [loading, setLoading] = useState(true);
	const [teams, setTeams] = useState([]);
	const [selectedTeam, setSelectedTeam] = useState({});
	const { darkMode } = usePreferencesStore();

	useEffect(() => {
		changeSport('NBA');
	}, []);

	useMemo(() => {
		try {
			setLoading(true);
			let taxonomy;
			switch (selectedSport) {
				case 'NFL':
					taxonomy = 1020100;
					break;
				case 'NBA':
					taxonomy = 1030100;
					break;
				case 'MLB':
					taxonomy = 1010100;
					break;
				case 'NHL':
					taxonomy = 1040100;
					break;
				case 'CFB':
					taxonomy = 1020200;
					break;
				// CBB
				default:
					taxonomy = 1030200;
					break;
			}
			axios.get(`/tickets/data/taxonomies.id=${taxonomy}&performers[home_team].slug=${selectedTeam.value}&sort=datetime_utc.asc&per_page=1500`).then((response) => {
				if (response.data) {
					setTickets(response.data);
				}
				setLoading(false);
			});
		} catch (e) {
			toast.error(e);
		}
	}, [selectedSport, selectedTeam]);

	const sortTable = (sortVal) => {
		tickets.sort((a, b) => {
			if (sortVal === 'startDate' || sortVal === 'endDate') {
				if (sortAsc) {
					return new Date(a[sortVal]) - new Date(b[sortVal]);
				}
				return new Date(b[sortVal]) - new Date(a[sortVal]);
			}
			if (typeof a[sortVal] == 'string') {
				return sortAsc ? a[sortVal].localeCompare(b[sortVal]) : b[sortVal].localeCompare(a[sortVal]);
			}
			return sortAsc ? a[sortVal] - b[sortVal] : b[sortVal] - a[sortVal];
		});
		setTickets(tickets);
		setSortAsc(!sortAsc);
	};

	const sortTableSub = (obj, sortVal) => {
		tickets.sort((a, b) => {
			if (typeof a[obj][sortVal] == 'string') {
				return sortAsc ? a[obj][sortVal].localeCompare(b[obj][sortVal]) : b[obj][sortVal].localeCompare(a[obj][sortVal]);
			}
			return sortAsc ? a[obj][sortVal] - b[obj][sortVal] : b[obj][sortVal] - a[obj][sortVal];
		});
		setTickets(tickets);
		setSortAsc(!sortAsc);
	};

	const changeSport = (sport) => {
		setSelectedSport(sport);
		let teamOptions = [];
		if (sport === 'NHL') teamOptions = nhlFull;
		else if (sport === 'NBA') teamOptions = nbaFull;
		else if (sport === 'NFL') teamOptions = nflFull;
		else if (sport === 'CFB') teamOptions = ncaaFull;
		else if (sport === 'CBB') teamOptions = ncaaFull;
		else if (sport === 'MLB') teamOptions = mlbFull;
		let teamVals = teamOptions.map((opt) => {
			return {
				value: opt.replaceAll(' ', '-').toLowerCase(),
				label: opt
			}
		});
		setTeams(teamVals);
		setSelectedTeam(teamVals[0]);
	};

	return (
		<div className='AdminPage tickets'>
			<ToastContainer />
			<div className='main'>
				<div className='stat-category'>
					{sports.map((sport, i) => {
						return (
							<div
								key={i}
								className={`sport-btn ${selectedSport === sport ? 'selected' : ''}`}
								onClick={() => changeSport(sport)}>
								{sport}
							</div>
						);
					})}
				</div>
				<div className='stat-filters'>
					<Select
						value={selectedTeam}
						placeholder='Team'
						onChange={setSelectedTeam}
						options={teams}
						styles={darkMode ? settingsStylesDark : settingsStyles}
						isSearchable={false}
					/>
				</div>
				{loading && (
					<img
						id='loadingIcon'
						src='/loading.gif'
						alt='loading'
					/>
				)}
				{!loading && (
					<div className='table table-striped'>
						<div className='table-head'>
							<span onClick={() => sortTable('short_title')}>Title</span>
							<span onClick={() => sortTable('popularity')}>Popularity</span>
							<span onClick={() => sortTable('datetime_local')}>Date</span>
							<span onClick={() => sortTableSub('stats', 'highest_price')}>Highest Price</span>
							<span onClick={() => sortTableSub('stats', 'average_price')}>Average Price</span>
							<span onClick={() => sortTableSub('stats', 'median_price')}>Median Price</span>
							<span onClick={() => sortTableSub('stats', 'lowest_price')}>Lowest Price</span>
						</div>
						<div className='table-body'>
							{tickets.map((ticket, i) => {
								return (
									<span
										key={i}
										className='table-row'>
										<a
											href={ticket.url}
											target='_blank'
											rel='noreferrer'>
											{ticket.short_title}
										</a>
										<span>{ticket.popularity}</span>
										<span>{moment(ticket.datetime_local).format('M/D/YY')}</span>
										<span>{ticket.stats.highest_price}</span>
										<span>{ticket.stats.average_price}</span>
										<span>{ticket.stats.median_price}</span>
										<span>{ticket.stats.lowest_price}</span>
									</span>
								);
							})}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
