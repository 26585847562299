import {useEffect} from 'react';
import '../App.css';

export default function PaypalSuccess() {
	useEffect(() => {
		localStorage.removeItem('token');
	}, []);

	return (
		<div className='paypal-success-page'>
			<i className='fa fa-check-circle'></i>
			<h1>Plan Changed Successfully</h1>
			<p>
				Thank you, your plan has been changed successfully. Paypal will send you an email shortly containing the details of the new subscription. Additionally, you will receive an email when the changes have been made successfully to your
				account. Afterwards, you will need to log out and back in for the changes to take effect. (This may take a few minutes to complete.)
			</p>
		</div>
	);
}
