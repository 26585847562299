import useVirtual from 'react-cool-virtual';
import moment from 'moment';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

export default function VirtualTable(props) {
	const {outerRef, innerRef, items} = useVirtual({
		itemCount: props.filteredStats.length,
		itemSize: 20,
		stickyIndices: [0],
	});

	return (
		<div
			className='table'
			style={{width: '100vw', height: '100vh', overflow: 'auto'}}
			ref={outerRef}>
			<div ref={innerRef}>
				{items.map(({index, _size, isSticky}) => {
					if (isSticky) {
						return (
							<div
								key={index}
								className='table-head'
								style={{gridTemplateColumns: `100px 100px 200px repeat(${props.uniqueStats.length - 2}, 100px)`}}>
								<span>Rk</span>
								{props.uniqueStats.map((statName, i) => {
									return (
										<span key={i}>
											<Tippy content={statName}>
											<span
												onClick={() => props.sortTable(statName)}>
												{props.sanitizedStats[statName]}
											</span>
											</Tippy>
										</span>
									);
								})}
							</div>
						);
					} else {
						return (
							<span
								key={index}
								className={`table-row ${index % 2 ? 'dark' : 'light'}`}
								style={{gridTemplateColumns: `100px 100px 200px repeat(${props.uniqueStats.length - 2}, 100px)`}}>
								<span>{index}</span>
								{props.uniqueStats.map((statName, j) => {
									if (!props.filteredStats[index]) return <span key={j}></span>;
									return <span key={j}>{statName === 'updated_at' ? moment(props.filteredStats[index][statName]).format('L') : props.filteredStats[index][statName]}</span>;
								})}
							</span>
						);
					}
				})}
			</div>
		</div>
	);
}
