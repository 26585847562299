import axios from 'axios';
import { useState } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useModalStore from '../../stores/modal.store';
import useUserStore from '../../stores/user.store';
import useGlobalStore from '../../stores/global.store';

export default function ChangeModal(props) {
	const navigate = useNavigate();
	const [newSport, setNewSport] = useState(null);
	const { toggleModal } = useModalStore();
	const { setRequestedChange } = useUserStore();
	const { debug } = useGlobalStore();

	const changePlan = (e, plan_id) => {
		try {
			e.preventDefault();
			const data = { plan_id, paypalSub: props.user.paypalSub };
			axios
				.post(`users/revisePlan`, data)
				.then((response) => {
					if (response.status === 200) {
						if (!response.data.links[0].href.includes('api')) {
							window.open(response.data.links[0].href);
						} else {
							localStorage.removeItem('token');
							navigate('/success');
						}
						toggleModal('change');
						setRequestedChange(true);
						if (plan_id === 'P-0DJ60349GB0442327MDN4YGQ') {
							axios.put(`/users/newSport/${props.user._id}`, {
								newSport,
							});
						}
					}
				})
				.catch((error) => {
					if (debug) {
						console.log(error);
					}
					toast.error('Something went wrong. Please reach out to greg@statlists.com', { autoClose: 5000 });
					toggleModal('change');
					axios.post(`/users/sendRequest`, {
						email: props.user.email,
						subject: 'Upgrade/Downgrade Error',
						message: JSON.stringify(error),
					});
				});
		} catch (e) {
			if (debug) {
				console.log(e);
			}
		}
	};

	return (
		<Modal
			isOpen={true}
			onRequestClose={() => toggleModal('change')}>
			<div className='modal'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>Change Subscription</h5>
							<span
								className='close-modal'
								onClick={() => toggleModal('change')}>
								X
							</span>
						</div>
						<div className='modal-body'>
							{props.user.plan === 'single' && (
								<p>
									Click the button below to request an upgrade to the all sports plan ($15/mo). If you have a PayPal account, this will open a new tab to authorize the change with PayPal. (Please make sure pop-ups are allowed.)
									After authorizing the change, it will take a few minutes to process, and you will receive an email when it is finished. Payments will continue to process on the same day as your old plan.
								</p>
							)}
							{props.user.plan === 'all' && (
								<span>
									<p>
										Click the button below to downgrade your subscription to a single sport ($8/mo). If you have a PayPal account, this will open a new tab to authorize the change with PayPal. (Please make sure pop-ups are
										allowed.) After authorizing the change, it will take a few minutes to process, and you will receive an email when it is finished. Payments will continue to process on the same day as your old plan.
									</p>
									<p>Select a sport:</p>
									<div className='radiopay center'>
										<label>
											<input
												type='radio'
												name='radiopay'
												checked={newSport === 'nfl'}
												value='NFL'
												onChange={() => setNewSport('nfl')}
											/>
											NFL
										</label>
										<label>
											<input
												type='radio'
												name='radiopay'
												checked={newSport === 'nba'}
												value='NBA'
												onChange={() => setNewSport('nba')}
											/>
											NBA
										</label>
										<label>
											<input
												type='radio'
												name='radiopay'
												checked={newSport === 'mlb'}
												value='MLB'
												onChange={() => setNewSport('mlb')}
											/>
											MLB
										</label>
										<label>
											<input
												type='radio'
												name='radiopay'
												checked={newSport === 'ncaa'}
												value='NCAA'
												onChange={() => setNewSport('ncaa')}
											/>
											NCAA
										</label>
										<label>
											<input
												type='radio'
												name='radiopay'
												checked={newSport === 'nhl'}
												value='NHL'
												onChange={() => setNewSport('nhl')}
											/>
											NHL
										</label>
										<label>
											<input
												type='radio'
												name='radiopay'
												checked={newSport === 'soccer'}
												value='SOCCER'
												onChange={() => setNewSport('soccer')}
											/>
											Soccer
										</label>
										<label>
											<input
												type='radio'
												name='radiopay'
												checked={newSport === 'f1'}
												value='F1'
												onChange={() => setNewSport('f1')}
											/>
											F1
										</label>
										<label>
											<input
												type='radio'
												name='radiopay'
												checked={newSport === 'wnba'}
												value='WNBA'
												onChange={() => setNewSport('wnba')}
											/>
											WNBA
										</label>
										<label>
											<input
												type='radio'
												name='radiopay'
												checked={newSport === 'afl'}
												value='AFL'
												onChange={() => setNewSport('afl')}
											/>
											AFL
										</label>
									</div>
								</span>
							)}
							<div className='modal-footer'>
								{props.user.plan === 'all' && (
									<button
										type='button'
										disabled={!newSport || newSport === 'custom'}
										onClick={(e) => {
											changePlan(e, 'P-0DJ60349GB0442327MDN4YGQ');
										}}
										className='btn btn-primary'>
										Request Downgrade
									</button>
								)}
								{props.user.plan === 'single' && (
									<button
										type='button'
										onClick={(e) => {
											changePlan(e, 'P-7WF475622U428225PMDN4YUA');
										}}
										className='btn btn-primary'>
										Request Upgrade
									</button>
								)}
								<button
									type='button'
									onClick={() => toggleModal('change')}
									className='btn btn-primary secondary'
									data-dismiss='modal'>
									Nevermind
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}
