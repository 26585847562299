import {useState} from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import axios from 'axios';
import {toast} from 'react-toastify';
import {settingsStyles} from '../../data/styles/settingsStyles';
import {settingsStylesDark} from '../../data/styles/settingsStylesDark';
import usePreferencesStore from '../../stores/preferences.store';
import useModalStore from '../../stores/modal.store';
import useGlobalStore from '../../stores/global.store';
const Filter = require('bad-words');
const filter = new Filter();

export default function ReferralModal(props) {
	const [payProvider, setPayProvider] = useState({value: 'paypal', label: 'Paypal'});
	const [payInfo, setPayInfo] = useState({value: 'username', label: 'Username'});
	const [payAccount, setPayAccount] = useState('');
	const [referrerCode, setReferrerCode] = useState('');
	const [recentSignup, setRecentSignup] = useState(false);
	const { darkMode } = usePreferencesStore();
	const { toggleModal } = useModalStore();
	const { debug } = useGlobalStore();

	const submit = (e) => {
		e.preventDefault();
		if (filter.isProfane(referrerCode)) {
			toast.error('Referrer code unavailable, try something else');
			return;
		}
		try {
			axios
				.put(`/users/becomeAffiliate/${props.user._id}`, {
					paymentInfo: `${payProvider.value} - ${payInfo.value} - ${payAccount}`,
					referrerCode,
				})
				.then((response) => {
					toast.success(`Signed up successfully! You may now start referring people`);
					localStorage.setItem('token', response.data.data.token);
					props.updateUser(response.data.data.token);
					setRecentSignup(true);
				})
				.catch((error) => {
					if (debug) {
						console.log(error);
					}
					if (error.data.error === 'Referrer code already exists') {
						toast.error('Affiliate code already in use.  Please try a different one.');
					} else {
						toast.error('An unknown error occured.');
					}
				});
		} catch (error) {
			if (debug) {
				console.log(error);
			}
			toast.error('Something went wrong.  Please try again later', {autoClose: 3000});
		}
	};

	const copyCode = (e) => {
		const code = props.user.referrerCode || referrerCode;
		navigator.clipboard.writeText(code).then(
			() => {
				toast.success('Copied to clipboard!');
			},
			(err) => {
				toast.error('Could not copy to clipboard!');
			}
		);
	};

	const copyLink = (e) => {
		const code = props.user.referrerCode || referrerCode;
		navigator.clipboard.writeText(`https://statlists.com/register?referral=${code}`).then(
			() => {
				toast.success('Copied to clipboard!');
			},
			(err) => {
				toast.error('Could not copy to clipboard!');
			}
		);
	};

	return (
		<Modal
			isOpen={true}
			onRequestClose={() => toggleModal('referral')}
			shouldCloseOnOverlayClick={true}
			shouldCloseOnEsc={true}>
			<div
				className='modal referral-modal'
				onClick={(e) => props.closeModal(e, 'referral')}>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>Affiliate Program</h5>
							<span
								className='close-modal'
								onClick={() => toggleModal('referral')}>
								X
							</span>
						</div>
						{!props.user.referrerCode && !recentSignup && (
							<form onSubmit={submit}>
								<div className='modal-body affiliate-signup'>
									<p>Earn $5 for each referral that pays for a single sport plan, $10 for each referral that pays for the all sports plan!</p>
									<div className='form-group'>
										<label className='form-label'>Preferred Payment Provider</label>
										<Select
											value={payProvider}
											onChange={(selectedOption) => {
												setPayProvider(selectedOption);
											}}
											options={[
												{value: 'paypal', label: 'PayPal'},
												{value: 'venmo', label: 'Venmo'},
												{value: 'cashapp', label: 'Cash App'},
											]}
											isSearchable={false}
											styles={darkMode ? settingsStylesDark : settingsStyles}
										/>
									</div>
									<div className='form-group'>
										<label className='form-label'>Payment Information</label>
										<Select
											value={payInfo}
											onChange={(selectedOption) => {
												setPayInfo(selectedOption);
											}}
											options={[
												{value: 'username', label: 'Username'},
												{value: 'email', label: 'Email'},
												{value: 'phone', label: 'Phone Number'},
											]}
											isSearchable={false}
											styles={darkMode ? settingsStylesDark : settingsStyles}
										/>
									</div>
									{payInfo.value === 'username' && (
										<div className='form-group'>
											<label className='form-label'>{payProvider.label} Username</label>
											<div className='input-group'>
												<input
													type='text'
													required
													className='form-control'
													onChange={(e) => {
														setPayAccount(e.target.value);
													}}
												/>
											</div>
										</div>
									)}
									{payInfo.value === 'email' && (
										<div className='form-group'>
											<label className='form-label'>{payProvider.label} Email</label>
											<div className='input-group'>
												<input
													type='email'
													required
													className='form-control'
													onChange={(e) => {
														setPayAccount(e.target.value);
													}}
												/>
											</div>
										</div>
									)}
									{payInfo.value === 'phone' && (
										<div className='form-group'>
											<label className='form-label'>{payProvider.label} Phone Number</label>
											<div className='input-group'>
												<input
													type='tel'
													pattern='[0-9]{3}-[0-9]{3}-[0-9]{4}'
													required
													className='form-control'
													onChange={(e) => {
														setPayAccount(e.target.value);
													}}
												/>
											</div>
										</div>
									)}
									<div className='form-group'>
										<label className='form-label'>Affiliate Code</label>
										<label className='sub-label'>(Must contain at least 4 characters, alphanumeric characters only)</label>
										<div className='input-group'>
											<input
												type='text'
												required
												pattern='^[a-zA-Z0-9]{4,}$'
												className='form-control'
												onChange={(e) => {
													setReferrerCode(e.target.value);
												}}
											/>
										</div>
									</div>
								</div>
								<div className='modal-footer'>
									<button
										type='submit'
										className='btn btn-primary'>
										Submit
									</button>
								</div>
							</form>
						)}
						{(props.user.referrerCode || recentSignup) && (
							<div className='modal-body'>
								<p>Earn $5 for each referral that pays for a single sport plan, $10 for each referral that pays for the all sports plan!</p>
								<div className='form-group'>
									<label className='form-label'>Your Affiliate Code:</label>
									<div className='input-group reverse'>
										<div className='input-group-append'>
											<div
												onClick={copyCode}
												className='input-group-text'>
												<i className='fas fa-copy'></i>
											</div>
										</div>
										<input
											type='text'
											readOnly
											className='form-control'
											defaultValue={props.user.referrerCode || referrerCode}
										/>
									</div>
								</div>
								<div className='form-group'>
									<label className='form-label'>Your Affiliate Signup Link:</label>
									<div className='input-group reverse'>
										<div className='input-group-append'>
											<div
												onClick={copyLink}
												className='input-group-text'>
												<i className='fas fa-copy'></i>
											</div>
										</div>
										<input
											type='text'
											readOnly
											className='form-control'
											defaultValue={`https://statlists.com/register?referral=${props.user.referrerCode || referrerCode}`}
										/>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</Modal>
	);
}
