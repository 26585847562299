import {useState} from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ForgotPassword() {
	const [email, setEmail] = useState('');

	const sendEmail = (e) => {
		e.preventDefault();
		axios
			.put(`/logins/forgot`, {email})
			.then((response) => {
				if (response.status === 200) {
					toast.success('Email sent successfully!');
					setEmail('');
					e.target.reset();
				}
			})
			.catch(() => {
				toast.error('Please check your email and try again.');
			});
	};

	return (
		<div className='login-wrapper'>
		<div className='login'>
			<ToastContainer />
			<form onSubmit={sendEmail} autoComplete='on'>
				<h3>Forgot password</h3>

				<div className='form-group floating-label'>
					<input
						type='email'
						required
						className='form-control'
						autoComplete='email'
						onChange={(e) => {
							setEmail(e.target.value);
						}}
					/>
					<label className={'form-label' + (email && ' filled')}>Email address</label>
				</div>

				<button
					type='submit'
					className='btn btn-primary btn-block'>
					Reset Password
				</button>
				<div className='links'>
					<p className='sign-up'>
						<Link to='/login'>Sign In</Link>
					</p>
				</div>
			</form>
		</div>
		</div>
	);
}
