import Modal from 'react-modal';
import useModalStore from '../../stores/modal.store';

export default function VideoModal(props) {
	const { toggleModal } = useModalStore();

	return (
		<Modal
			isOpen={true}
			onRequestClose={() => toggleModal('video')}
			shouldCloseOnOverlayClick={false}
			shouldCloseOnEsc={false}>
			<div className='modal video-modal'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>Video Preview</h5>
							<span
								className='close-modal'
								onClick={() => toggleModal('video')}>
								X
							</span>
						</div>
						<div className='modal-body'>
							<div className='video-status'>
								<span id='video-status-text'></span>
								<span
									id='recording-indicator'
									style={{visibility: 'hidden'}}></span>
							</div>
							<canvas id='video-preview'></canvas>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}
