import {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {Markup} from 'interweave';
import {toast} from 'react-toastify';
import axios from 'axios';
import useModalStore from '../../stores/modal.store';
import useGlobalStore from '../../stores/global.store';

export default function HelpModal(props) {
	const [questions, setQuestions] = useState([]);
	const [filteredQuestions, setFilteredQuestions] = useState([]);
	const [faqFilter, setFaqFilter] = useState('');
	const [message, setMessage] = useState('');
	const { toggleModal } = useModalStore();
	const { debug } = useGlobalStore();

	useEffect(() => {
		let questions = [];
		if (props.user.isActive) {
			questions = [
				{question: 'What abbreviation do I use?', answer: "<p>For a list of accepted team names and abbreviations, see <a target='_blank' href='/abbreviations'>here</a>.</p>"},
				{question: 'What player ID do I use?', answer: '<p>If the player ID is not found when you click the plus button, then you will need to find it on ESPN (typically in the URL). Alternatively, you can use a URL from another site</p>'},
				{
					question: 'My post looks different on mobile',
					answer: "<p>Downloading posts on a mobile device uses a different technology than downloading on a computer.  If it doesn't look right on mobile, try downloading on a computer instead. (If you used an external player image, it may not be downloaded if that site does not allow it.)</p>",
				},
				{question: 'How do I separate tiers?', answer: "<p>To start a new tier, type in '#' on a separate line (between the list of team names).  (You can add up to 10 tiers.)</p>"},
				{question: 'How do I separate names or stats to a new line?', answer: "<p>To split names or stats to a new line, type a '##' between the text you want to separate.</p>"},
				{question: 'How do I add a subtitle?', answer: '<p>While creating a list, you can add a subtitle in between the list by typing in "subtitle" as the team name.</p>'},
				{question: "Can I flip just one team's colors?", answer: "<p>Yes, to flip just one team's colors, put a '2' after the team name (i.e. 'DEN2').</p>"},
				{question: 'How do I use a player headshot from a different league?', answer: "<p>Prefix the player ID with the league and an asterisk (i.e. 'ncaa*4360310' to use Trevor Lawrence in an NFL post).</p>"},
				{
					question: 'How do I use college basketball headshots?',
					answer: '<p>By default, NCAA headshots use college football headshots.  If you want to include a college basketball headshot, prefix the player ID with "ncaam*" (i.e. ncaam*4277951 for Luka Garza).</p>',
				},
				{
					question: 'How do I use two logos for a single player?',
					answer: "<p>If you separate the two teams with a '/' then you can use two logos (i.e. 'BRK/HOU').  Then, if you click the logos, it will rotate the separator between the two.</p>",
				},
				{question: "My post doesn't look right", answer: "<p>If your post doesn't look correct, try adding and then removing an additional team.  If that doesn't work, then try changing your settings (or reset them).</p>"},
				{question: 'What browser should I use?', answer: '<p>The recommended browser is Google Chrome.</p>'},
			];
			if (props.user.plan === 'all') {
				questions.push({
					question: 'How do I use teams from multiple leagues in the same post?',
					answer: "<p>Teams will default to the selected league (NFL, NBA, etc.).  To use a team from a different league, just prefix the team name with the league and an asterisk (i.e. 'NBA*LAL' to use Lakers while a different league is selected)</p>",
				});
				questions.push({question: 'How do I use a custom team?', answer: "<p>To use a custom team, just prefix the abbreviation with the word custom and an asterisk (i.e. 'CUSTOM*ABBR')</p>"});
			}
			if (props.user.stripeSub) {
				questions.push({question: 'How do I change my plan?', answer: '<p>For help to change your plan, please send a request below.</p>'});
			}
		}
		setQuestions(questions);
		setFilteredQuestions(questions);
	}, [props]);

	useEffect(() => {
		const filtered = questions.filter((item) => {
			return item.question.toLowerCase().includes(faqFilter.toLowerCase()) || item.answer.toLowerCase().includes(faqFilter.toLowerCase());
		});
		setFilteredQuestions(filtered);
	}, [faqFilter, questions]);

	const filterFaqs = (e) => {
		setFaqFilter(e.target.value);
	};

	const sendRequest = (e) => {
		e.preventDefault();
		toast.success('Sending request...', {autoClose: 3000});
		axios
			.post(`/users/sendRequest`, {
				email: props.user.email,
				subject: 'Feedback Request',
				message,
			})
			.then((response) => {
				if (response.status === 200) {
					toast.success('Request Sent!', {autoClose: 3000});
				} else {
					toast.error('Something went wrong. Please send your request to greg@statlists.com instead');
				}
			})
			.catch((e) => {
				if (debug) {
					console.log(e);
				}
				toast.error('Something went wrong. Please send your request to greg@statlists.com instead', {autoClose: 10000});
			});
	};

	return (
		<Modal
			isOpen={true}
			onRequestClose={() => toggleModal('help')}
			shouldCloseOnOverlayClick={true}
			shouldCloseOnEsc={true}>
			<div className='help modal' onClick={(e) => props.closeModal(e, 'help')}>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>Frequently Asked Questions</h5>
							<span
								className='close-modal'
								onClick={() => toggleModal('help')}>
								X
							</span>
						</div>
						<div className='modal-body'>
							<p>See answers to commonly asked questions below, or send a request at the bottom.</p>
							{props.user.isActive && (
								<div className='p-1 bg-light rounded rounded-pill shadow-sm mb-4 faqsearch'>
									<div className='input-group'>
										<input
											type='search'
											placeholder='Search'
											defaultValue={faqFilter}
											onChange={filterFaqs}
											className='form-control border-0 bg-light'
										/>
										<div className='input-group-append'>
											<i className='fa fa-search'></i>
										</div>
									</div>
								</div>
							)}
							{filteredQuestions.length === 0 && faqFilter && <span className='faqFilterResults'>No Results Found</span>}
							{filteredQuestions.length > 0 && (
								<div>
									{filteredQuestions.map((item, i) => {
										return (
											<div key={i}>
												<h6>{item.question}</h6>
												<Markup content={item.answer} />
											</div>
										);
									})}
								</div>
							)}
							{!props.user.isActive && (
								<span>
									<h6>What payment methods do you accept?</h6>
									<p>Debit/credit card, Apple Pay, Cash App Pay, or Google Pay</p>
								</span>
							)}
							<hr className='homeline' />
							<h5>Send a message:</h5>
							<form onSubmit={sendRequest}>
								<div className='form-group'>
									<label className='form-label'>From:</label>
									<input
										disabled
										className='form-control'
										defaultValue={props.user.email}
									/>
								</div>
								<div className='form-group'>
									<label className='form-label'>Message:</label>
									<textarea
										type='text'
										required
										className='form-control'
										placeholder='Please enter your question, feedback, or suggestion here.'
										onChange={(e) => {
											setMessage(e.target.value);
										}}
									/>
								</div>
								<button
									type='submit'
									className='btn btn-primary btn-block'>
									Submit
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}
