export const perGameStats = [
	{
	  label: "Points Per Game",
	  value: "nbaplayerstats/offensive-avgpoints",
	},
	{
	  label: "Assists Per Game",
	  value: "nbaplayerstats/offensive-avgassists",
	},
	{
	  label: "Rebounds Per Game",
	  value: "nbaplayerstats/general-avgrebounds",
	},
	{
	  label: "Steals Per Game",
	  value: "nbaplayerstats/defensive-avgsteals",
	},
	{
	  label: "Blocks Per Game",
	  value: "nbaplayerstats/defensive-avgblocks",
	},
	{
	  label: "Turnovers Per Game",
	  value: "nbaplayerstats/offensive-avgturnovers",
	},
	{
	  label: "Minutes Per Game",
	  value: "nbaplayerstats/general-avgminutes",
	},
	{
	  label: "Fouls Per Game",
	  value: "nbaplayerstats/general-avgfouls",
	},
	{
	  label: "Average Field Goals Made",
	  value: "nbaplayerstats/offensive-avgfieldgoalsmade",
	},
	{
	  label: "Average Field Goals Attempted",
	  value: "nbaplayerstats/offensive-avgfieldgoalsattempted",
	},
	{
	  label: "Average 3-Point Field Goals Made",
	  value: "nbaplayerstats/offensive-avgthreepointfieldgoalsmade",
	},
	{
	  label: "Average 3-Point Field Goals Attempted",
	  value: "nbaplayerstats/offensive-avgthreepointfieldgoalsattempted",
	},
	{
	  label: "Average Free Throws Made",
	  value: "nbaplayerstats/offensive-avgfreethrowsmade",
	},
	{
	  label: "Average Free Throws Attempted",
	  value: "nbaplayerstats/offensive-avgfreethrowsattempted",
	},
  ];

  export const totalStats = [
	{
	  label: "Points",
	  value: "nbaplayerstats/offensive-points",
	},
	{
	  label: "Assists",
	  value: "nbaplayerstats/offensive-assists",
	},
	{
	  label: "Rebounds",
	  value: "nbaplayerstats/general-rebounds",
	},
	{
	  label: "Steals",
	  value: "nbaplayerstats/defensive-steals",
	},
	{
	  label: "Blocks",
	  value: "nbaplayerstats/defensive-blocks",
	},
	{
	  label: "Turnovers",
	  value: "nbaplayerstats/offensive-turnovers",
	},
	{
	  label: "Minutes",
	  value: "nbaplayerstats/general-minutes",
	},
	{ label: "Fouls", value: "nbaplayerstats/general-fouls" },
	{
	  label: "Field Goal Percentage",
	  value: "nbaplayerstats/offensive-fieldgoalpct",
	},
	{
	  label: "Field Goals Made",
	  value: "nbaplayerstats/offensive-fieldgoalsmade",
	},
	{
	  label: "Field Goals Attempted",
	  value: "nbaplayerstats/offensive-fieldgoalsattempted",
	},
	{
	  label: "3-Point Field Goal Percentage",
	  value: "nbaplayerstats/offensive-threepointfieldgoalpct",
	},
	{
	  label: "3-Point Field Goals Made",
	  value: "nbaplayerstats/offensive-threepointfieldgoalsmade",
	},
	{
	  label: "3-Point Field Goals Attempted",
	  value: "nbaplayerstats/offensive-threepointfieldgoalsattempted",
	},
	{
	  label: "Free Throws Made",
	  value: "nbaplayerstats/offensive-freethrowsmade",
	},
	{
	  label: "Free Throws Attempted",
	  value: "nbaplayerstats/offensive-freethrowsattempted",
	},
	{
	  label: "Free Throw Percentage",
	  value: "nbaplayerstats/offensive-freethrowpct",
	},
	{
	  label: "Flagrant Fouls",
	  value: "nbaplayerstats/general-flagrantfouls",
	},
	{
	  label: "Technical Fouls",
	  value: "nbaplayerstats/general-technicalfouls",
	},
	{
	  label: "Ejections",
	  value: "nbaplayerstats/general-ejections",
	},
	{
	  label: "Double Double",
	  value: "nbaplayerstats/general-doubledouble",
	},
	{
	  label: "Triple Double",
	  value: "nbaplayerstats/general-tripledouble",
	},
	{
	  label: "Player Efficiency Rating",
	  value: "nbaplayerstats/general-per",
	},
  ];