import {useEffect, useState} from 'react';
import {nflTeams} from '../../data/teams/variables';
import {ToastContainer, toast} from 'react-toastify';
import * as htmlToImage from 'html-to-image';
import axios from 'axios';

export default function GamePoll() {
	const [matchups, setMatchups] = useState([]);

	useEffect(() => {
		axios
			.get(`/stats/nfl/allgames`)
			.then((response) => {
				if (response.data) {
					const matchups = response.data;
					setMatchups(matchups);
				}
			})
			.catch((e) => {
				console.log(e);
			});
	}, []);

	const download = async () => {
		toast.success('Downloading..', {autoClose: 1500});
		const polls = document.querySelectorAll('.pollMatchup');
		polls.forEach((poll, i) => {
			htmlToImage
				.toJpeg(poll, {height: 1280, width: 720, quality: 1})
				.then((dataUrl) => {
					let link = document.createElement('a');
					link.download = `matchup${i}.jpeg`;
					link.href = dataUrl;
					link.click();
				})
				.catch((error) => {
					console.log(error);
				});
		});
	};

	const getTeamObj = (team) => {
		let teams = JSON.parse(JSON.stringify(nflTeams));
		let returnTeam = teams.find((obj) => {
			return obj.abbreviation === team || obj.full === team || obj.abbreviation2 === team || obj.full2 === team || obj.abbreviation3 === team;
		});
		if (returnTeam) {
			returnTeam.logo = returnTeam.logos[0];
		}
		return returnTeam;
	};

	return (
		<div className='GamePoll'>
			<ToastContainer />
			<button
				className='newButton downloadImageBtn'
				onClick={download}>
				Download All
			</button>
			<div className='main'>
				{matchups.map((matchup, i) => {
					const teamObj1 = getTeamObj(matchup.awayteam);
					const teamObj2 = getTeamObj(matchup.hometeam);
					return (
						<div
							key={i}
							style={{margin: '10px'}}>
							<div className='pollMatchup'>
								<div
									className='matchupteam'
									style={{backgroundColor: teamObj1?.primary}}>
									<div
										className='matchupimage'
										style={{backgroundImage: teamObj1?.logo}}></div>
								</div>
								<div
									className='matchupteam'
									style={{backgroundColor: teamObj2?.primary}}>
									<div
										className='matchupimage'
										style={{backgroundImage: teamObj2?.logo}}></div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}
