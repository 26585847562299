import {useLocation, useNavigate} from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import usePreferencesStore from '../stores/preferences.store';
import useModalStore from '../stores/modal.store';
import ActionButtons from './ActionButtons';
import {Menu, MenuItem, MenuButton} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import useUserStore from '../stores/user.store';

export default function Header(props) {
	const navigate = useNavigate();
	const location = useLocation();
	const { darkMode, setDarkMode } = usePreferencesStore();
	const { clearModals, toggleModal } = useModalStore();
	const { setAllSettings, setSavedPosts, setCustomTeams, setTeamOverrides, setFolders } = useUserStore();

	const login = () => {
		navigate('/login');
	};

	const logout = () => {
		clearModals();
		setAllSettings([]);
		setSavedPosts([]);
		setCustomTeams([]);
		setTeamOverrides([]);
		setFolders([]);
		localStorage.removeItem('token');
		navigate('/');
	};

	const button = () => {
		const token = localStorage.getItem('token');
		if (location.pathname === '/login' || props.fromDashboard) {
			return;
		} else if (location.pathname === '/') {
			return <button onClick={login}>Login</button>;
		} else if (token && jwtDecode(token)?.exp > Date.now() / 1000) {
			return <button onClick={logout}>Logout</button>;
		} else {
			return <button onClick={login}>Login</button>;
		}
	};

	const toggleDarkmode = () => {
		setDarkMode(!darkMode);
	};

	if (location.pathname === '/dashboard' && !props.fromDashboard) {
		return null;
	}
	if (location.pathname !== '/dashboard' && props.fromDashboard) {
		return null;
	}
	
	return (
		<div className='Header'>
			<div className='leftHeader'>
				<span className='darkToggleSpan'>
					<input
						type='checkbox'
						className='darkToggle'
						id='darkToggle'
						checked={darkMode}
						onChange={toggleDarkmode}
					/>
					<label
						htmlFor='darkToggle'
						className='darkToggleLabel'>
						<i className='fas fa-moon'></i>
						<i className='fas fa-sun'></i>
						<span className='ball'></span>
					</label>
				</span>
			</div>
			<h1>Stat Lists</h1>
			<div className='rightHeader'>
				{props.fromDashboard && props.user?.isActive && (
					<ActionButtons
						moveDraggables={props.moveDraggables}
						resizePost={props.resizePost}
						updateGraphic={props.updateGraphic}/>
				)}
				{props.fromDashboard && (
					<Menu
						menuButton={
							<MenuButton>
								<i className='fa fa-solid fa-user'></i>
								<i className='fas fa-caret-down'></i>
							</MenuButton>
						}>
						<MenuItem
							onClick={() => toggleModal('profile')}>
							Profile
						</MenuItem>
						<MenuItem
							onClick={logout}>
							Log Out
						</MenuItem>
					</Menu>
				)}
				{button()}
			</div>
		</div>
	);
}
