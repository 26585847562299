import { Component, useEffect, useState } from 'react';
import TextInput from 'react-autocomplete-input';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import Modal from 'react-modal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { autoPosts } from '../../../data/autoPosts/autoPosts';
import cronstrue from 'cronstrue';
import axios from 'axios';
import './Crons.scss';
import usePreferencesStore from '../../../stores/preferences.store';

export default function Crons() {
	const [crons, setCrons] = useState([]);
	const [modalOpen, setModalOpen] = useState(false);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const [manuallyPostOpen, setManuallyPostOpen] = useState(false);
	const [sport, setSport] = useState('NFL');
	const [account, setAccount] = useState('ftblstatpage');
	const [start, setStart] = useState(moment());
	const [end, setEnd] = useState(moment());
	const [schedule, setSchedule] = useState('0 12 * * MON');
	const [caption, setCaption] = useState('');
	const [name, setName] = useState('');
	const [images, setImages] = useState([]);
	const [onHold, setOnHold] = useState(false);
	const [checkScores, setCheckScores] = useState(false);
	const [email, setEmail] = useState('');
	const [special, setSpecial] = useState('');
	const [imageText, setImageText] = useState('');
	const [imageType, setImageType] = useState('');
	const [secondaryType, setSecondaryType] = useState('');
	const [position, setPosition] = useState('');
	const [cronId, setCronId] = useState(null);
	const [pendingManualPost, setPendingManualPost] = useState(null);
	const [pendingDeleteID, setPendingDeleteID] = useState(null);
	const [titles, setTitles] = useState([]);
	const [types, setTypes] = useState([]);
	const [times, setTimes] = useState([
		'9:00',
		'9:30',
		'10:00',
		'10:30',
		'11:00',
		'11:30',
		'12:00',
		'12:30',
		'13:00',
		'13:30',
		'14:00',
		'14:30',
		'15:00',
		'15:30',
		'16:00',
		'16:30',
		'17:00',
		'17:30',
		'18:00',
		'18:30',
		'19:00',
		'19:15',
	]);
	const [days] = useState(['TIMES', 'SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']);
	const [expanded, setExpanded] = useState([]);
	const [sortAsc, setSortAsc] = useState(true);
	const [accountFilter, setAccountFilter] = useState('all');
	const { darkMode } = usePreferencesStore();
	const [isUtc, setIsUtc] = useState(false);
	const [nextCaption, setNextCaption] = useState('');
	const [nextMinutes, setNextMinutes] = useState('');
	const [nextImages, setNextImages] = useState([]);

	useEffect(() => {
		getCrons();
		let mappedTypes = autoPosts.map((a) => a.type);
		mappedTypes = mappedTypes.filter((value, index, self) => {
			return self.indexOf(value) === index;
		});
		setTitles(autoPosts.map((a) => (a.button ? a.button : a.title)));
		setTypes(mappedTypes);
	}, []);

	const reset = () => {
		setModalOpen(false);
		setSport('NFL');
		setAccount('ftblstatpage');
		setStart(moment());
		setEnd(moment());
		setSchedule('0 12 * * MON');
		setCaption('');
		setName('');
		setImages([]);
		setOnHold(false);
		setCheckScores(false);
		setEmail('');
		setSpecial('');
		setImageText('');
		setImageType('');
		setSecondaryType('');
		setPosition('');
		setCronId(null);
		setNextImages([]);
		setNextMinutes('');
		setNextCaption('');
		setIsUtc(false);
	};

	const getCrons = async () => {
		try {
			const response = await axios.get(`/admins/crons`);
			if (response.data) {
				setCrons(response.data);
				const uniqueTimes = new Set();
				response.data.forEach((cron) => {
					const [minute, hour] = cron.schedule.split(' ');
					if (hour.includes('-') || hour.includes(',') || minute.includes('-') || minute.includes(',') || minute.includes('*/')) {
						uniqueTimes.add('nightly');
					} else {
						const formattedMinute = minute.padStart(2, '0');
						const time = `${hour}:${formattedMinute}`;
						uniqueTimes.add(time);
					}
				});
				const uniqueTimesArray = Array.from(uniqueTimes).sort((a, b) => {
					if (a === 'nightly') return 1;
					if (b === 'nightly') return -1;
					const [hourA, minuteA] = a.split(':').map(Number);
					const [hourB, minuteB] = b.split(':').map(Number);
					if (hourA !== hourB) {
						return hourA - hourB;
					}
					return minuteA - minuteB;
				});
				setTimes(uniqueTimesArray);
			}
		} catch (e) {
			toast.error(e);
		}
	};

	const saveCron = (e) => {
		e.preventDefault();
		if (cronId) {
			axios
				.put(`/admins/crons/${cronId}`, {
					sport,
					account,
					start,
					end,
					schedule,
					caption,
					name,
					images,
					onHold,
					checkScores,
					email,
					special: special ? special.split(',') : null,
					timezone: isUtc ? 'Etc/Universal' : null,
					nextCaption,
					nextImages,
					nextMinutes
				})
				.then((response) => {
					if (response.status === 201) {
						toast.success('Cron Updated!', { autoClose: 3000 });
						let cronIndex = crons.findIndex((cron) => {
							return cron._id === cronId;
						});
						let cronsCopy = [...crons];
						cronsCopy.splice(cronIndex, 1, response.data);
						setCrons(cronsCopy);
						reset();
					}
				});
		} else {
			axios
				.post(`/admins/crons`, {
					sport,
					account,
					start,
					end,
					schedule,
					caption,
					name,
					images,
					onHold,
					checkScores,
					email,
					special: special ? special.split(',') : null,
					timezone: isUtc ? 'Etc/Universal' : null,
					nextCaption,
					nextImages,
					nextMinutes
				})
				.then((response) => {
					if (response.status === 200) {
						toast.success('Cron Saved!', { autoClose: 3000 });
						setCrons([...crons, response.data[0]]);
						reset();
					}
				});
		}
	};

	const updateCron = (e, cron) => {
		e.preventDefault();
		e.stopPropagation();
		axios
			.put(`/admins/crons/${cron._id}`, {
				sport: cron.sport,
				account: cron.account,
				start: cron.start,
				end: cron.end,
				schedule: cron.schedule,
				caption: cron.caption,
				name: cron.name,
				images: cron.images,
				onHold: !cron.onHold,
				checkScores: cron.checkScores,
				email: cron.email,
				special: cron.special,
				timezone: isUtc ? 'Etc/Universal' : null,
				nextCaption: cron.nextCaption,
				nextImages: cron.nextImages,
				nextMinutes: cron.nextMinutes
			})
			.then((response) => {
				if (response.status === 201) {
					toast.success('Cron Updated!', { autoClose: 3000 });
				}
				let cronIndex = crons.findIndex((cr) => {
					return cr._id === cron._id;
				});
				let cronsCopy = [...crons];
				cronsCopy.splice(cronIndex, 1, response.data);
				setCrons(cronsCopy);
			});
	};

	const addImage = (e, next) => {
		e.preventDefault();
		if (!imageText) {
			return;
		}
		let obj = {
			text: imageText,
		};
		if (imageType) {
			obj.type = imageType;
		}
		if (secondaryType) {
			obj.secondaryType = secondaryType;
		}
		if (position) {
			obj.posFilter = position;
		}
		if (next) {
			setNextImages([...nextImages, obj]);
		} else {
			setImages([...images, obj]);
		}
		setImageText('');
		setImageType('');
		setSecondaryType('');
		setPosition('');
	};

	const removeImage = (e, image) => {
		e.preventDefault();
		const index = images.indexOf(image);
		if (index > -1) {
			let imagesCopy = [...images];
			imagesCopy.splice(index, 1);
			setImages(imagesCopy);
		}
	};

	const removeNextImage = (e, image) => {
		e.preventDefault();
		const index = nextImages.indexOf(image);
		if (index > -1) {
			let imagesCopy = [...nextImages];
			imagesCopy.splice(index, 1);
			setNextImages(imagesCopy);
		}
	};

	const editCron = (e, cron) => {
		e.preventDefault();
		e.stopPropagation();
		setModalOpen(true);
		setSport(cron.sport);
		setAccount(cron.account);
		setStart(moment(cron.start));
		setEnd(moment(cron.end));
		setSchedule(cron.schedule);
		setCaption(cron.caption);
		setName(cron.name);
		setImages(cron.images ?? []);
		setOnHold(cron.onHold ?? false);
		setCheckScores(cron.checkScores ?? false);
		setEmail(cron.email);
		setSpecial(cron.special ? cron.special.join(',') : '');
		setCronId(cron._id);
		setIsUtc(cron.timezone === 'Etc/Universal');
		setNextCaption(cron.nextCaption ?? '');
		setNextImages(cron.nextImages ?? []);
		setNextMinutes(cron.nextMinutes ?? '')
	};

	const manuallyPost = () => {
		axios.post(`/admins/crons/manual`, {
			name: pendingManualPost.name,
			sport: pendingManualPost.sport,
		});
		setPendingManualPost(null);
		setManuallyPostOpen(false);
		toast.success('Requested cron manually post');
	};

	const duplicateCron = (e, cron) => {
		e.preventDefault();
		e.stopPropagation();
		setModalOpen(true);
		setSport(cron.sport);
		setAccount(cron.account);
		setStart(moment(cron.start));
		setEnd(moment(cron.end));
		setSchedule(cron.schedule);
		setCaption(cron.caption);
		setName(cron.name);
		setImages(cron.images ?? []);
		setOnHold(cron.onHold ?? false);
		setCheckScores(cron.checkScores ?? false);
		setEmail(cron.email);
		setSpecial(cron.special ? cron.special.join(',') : '');
		setCronId(null);
		setIsUtc(cron.timezone === 'Etc/Universal');
		setNextCaption(cron.nextCaption ?? '');
		setNextImages(cron.nextImages ?? []);
		setNextMinutes(cron.nextMinutes ?? '')
	};

	const deleteCron = (e) => {
		e.stopPropagation();
		try {
			axios.delete(`/admins/crons/${pendingDeleteID}`).then((response) => {
				if (response.status === 200) {
					const cronsCopy = crons.filter((cron) => cron._id !== pendingDeleteID);
					setCrons(cronsCopy);
					setDeleteOpen(false);
					setPendingDeleteID(null);
					toast.error('Cron Deleted!', { autoClose: 1500 });
				}
			});
		} catch (e) {
			console.log(e);
		}
	};

	const findCrons = (time, day) => {
		return crons.filter((cron) => {
			let brokenUp = cron.schedule.split(' ');
			let timeSplit = time.split(':');
			let hour = brokenUp[1] === '0' ? '12' : String(Number(brokenUp[1]));
			let minute = brokenUp[0] === '0' ? '00' : brokenUp[0];
			let cronDay = brokenUp[4];
			if (brokenUp[1] === '0') {
				if (cronDay === 'SUN') cronDay = 'SAT';
				else if (cronDay === 'MON') cronDay = 'SUN';
				else if (cronDay === 'TUE') cronDay = 'MON';
				else if (cronDay === 'WED') cronDay = 'TUE';
				else if (cronDay === 'THU') cronDay = 'WED';
				else if (cronDay === 'FRI') cronDay = 'THU';
				else if (cronDay === 'SAT') cronDay = 'FRI';
			}
			if (hour.includes('-') || hour.includes(',') || minute.includes('-') || minute.includes(',') || minute.includes('*/')) {
				return (accountFilter === 'all' || cron.account === accountFilter) && (cronDay === day || cronDay === '*') && time === 'nightly';
			}
			return (accountFilter === 'all' || cron.account === accountFilter) && (cronDay === day || cronDay === '*') && timeSplit[0] === hour && timeSplit[1] === minute;
		});
	};

	const toggleModal = () => {
		if (modalOpen) {
			reset();
		} else {
			setModalOpen(true);
		}
	};

	const expandRow = (e, id) => {
		if (e.target.type === 'checkbox') {
			return;
		}
		if (expanded.includes(id)) {
			const index = expanded.indexOf(id);
			let expandedCopy = [...expanded];
			expandedCopy.splice(index, 1);
			setExpanded(expandedCopy);
		} else {
			setExpanded([...expanded, id]);
		}
	};

	const sortTable = (sortVal) => {
		let cronsCopy = [...crons];
		cronsCopy.sort((a, b) => {
			if (sortVal === 'start' || sortVal === 'end') {
				if (sortAsc) {
					return new Date(a[sortVal]) - new Date(b[sortVal]);
				}
				return new Date(b[sortVal]) - new Date(a[sortVal]);
			}
			if (a[sortVal] < b[sortVal]) return sortAsc ? -1 : 1;
			if (a[sortVal] > b[sortVal]) return sortAsc ? 1 : -1;
			return 0;
		});
		setCrons(cronsCopy);
		setSortAsc(!sortAsc);
	};

	const findColor = (cron, nextDateForDay) => {
		if (cron.onHold) return '#B52731';
		const isActive = nextDateForDay.isBetween(cron.start, cron.end);
		if (!isActive) return '#B52731';
		if (cron.special) {
			const weekOfMonth = Math.ceil(nextDateForDay.date()/7);
			const week = 'week' + String(weekOfMonth);
			if (!cron.special.includes(week)){
				return '#cc777d';
			}
		}
		return darkMode ? '#fff' : '#000';
	}

	// 
	const today = moment().day();
	let dates = [];
	[0,1,2,3,4,5,6].forEach((day) => {
		let nextDateForDay;
		if (today <= day) { 
			nextDateForDay = moment().day(day);
		} else {
			nextDateForDay = moment().add(1, 'weeks').day(day);
		}
		dates.push(nextDateForDay);
	});
	return (
		<div className='AdminPage Crons'>
			<ToastContainer />
			<Modal
				isOpen={modalOpen}
				onRequestClose={toggleModal}>
				<div className='modal cron-modal'>
					<div className='modal-dialog'>
						<div className='modal-content'>
							<div className='modal-header'>
								<h5 className='modal-title'>Add Cron</h5>
								<span
									className='close-modal'
									onClick={toggleModal}>
									X
								</span>
							</div>
							<div className='modal-body'>
								<form onSubmit={saveCron}>
									<div className='form-group'>
										<label className='form-label'>Sport</label>
										<select
											name='sport'
											id='sportSelect'
											value={sport}
											onChange={(e) => setSport(e.target.value)}>
											<option value='NFL'>NFL</option>
											<option value='NBA'>NBA</option>
											<option value='MLB'>MLB</option>
											<option value='NHL'>NHL</option>
											<option value='NCAA'>NCAA</option>
										</select>
									</div>
									<div className='form-group'>
										<label className='form-label'>Account</label>
										<select
											name='account'
											id='accountSelect'
											value={account}
											onChange={(e) => setAccount(e.target.value)}>
											<option value='ftblstatpage'>ftblstatpage</option>
											<option value='cfbstatpage'>cfbstatpage</option>
											<option value='ballstatpage'>ballstatpage</option>
											<option value='cbbstatpage'>cbbstatpage</option>
											<option value='bsblstatpage'>bsblstatpage</option>
											<option value='icestatpage'>icestatpage</option>
											<option value='fantasyftbl'>fantasyftbl</option>
										</select>
									</div>
									<div className='form-group'>
										<label className='form-label'>Name</label>
										<div className='input-group'>
											<input
												type='text'
												className='form-control'
												value={name}
												onChange={(e) => {
													setName(e.target.value);
												}}
											/>
										</div>
									</div>
									<div className='form-group'>
										<label className='form-label'>Caption</label>
										<div className='input-group'>
											<input
												type='text'
												className='form-control'
												value={caption}
												onChange={(e) => {
													setCaption(e.target.value);
												}}
											/>
										</div>
									</div>
									<div className='form-group'>
										<label className='form-label'>Start Date</label>
										<DatePicker
											selected={start.toDate()}
											onChange={(date) => setStart(moment(date))}
										/>
									</div>
									<div className='form-group'>
										<label className='form-label'>End Date</label>
										<DatePicker
											selected={end.toDate()}
											onChange={(date) => setEnd(moment(date))}
										/>
									</div>
									<div className='form-group'>
										<label className='form-label'>{`Schedule ${cronstrue.toString(schedule, { throwExceptionOnParseError: false })}`}</label>
										<div className='input-group'>
											<input
												type='text'
												value={schedule}
												required
												className='form-control'
												onChange={(e) => {
													setSchedule(e.target.value);
												}}
											/>
										</div>
									</div>
									<div className='form-group'>
										<label className='form-label'>Email</label>
										<div className='input-group'>
											<input
												type='text'
												value={email}
												className='form-control'
												onChange={(e) => {
													setEmail(e.target.value);
												}}
											/>
										</div>
									</div>
									<div className='many-labels'>
										<span>Text</span>
										<span>Type</span>
										<span>Secondary</span>
										<span>Position</span>
									</div>
									<div className='form-group many-inputs'>
										<TextInput
											options={titles}
											onSelect={(val) => setImageText(val.replace('@', ''))}
											matchAny={true}
											type='text'
											spacer=''
											rows='1'
											cols='50'
											className='form-control'
											value={imageText}
											onChange={(val) => {
												setImageText(val);
											}}
										/>
										<TextInput
											options={['post', 'postimage', 'grid', 'gridimage', 'tier', 'bracket', 'scores', 'matchupimage', 'news', 'chart', 'highlightimage', 'lineupimage', 'comparison']}
											onSelect={(val) => setImageType(val.replace('@', ''))}
											matchAny={true}
											type='text'
											spacer=''
											rows='1'
											cols='50'
											className='form-control'
											value={imageType}
											onChange={(val) => {
												setImageType(val);
											}}
										/>
										<TextInput
											options={types}
											onSelect={(val) => setSecondaryType(val.replace('@', ''))}
											matchAny={true}
											type='text'
											spacer=''
											rows='1'
											cols='50'
											className='form-control'
											value={secondaryType}
											onChange={(val) => {
												setSecondaryType(val);
											}}
										/>
										<TextInput
											options={['QB', 'RB', 'WR', 'TE']}
											onSelect={(val) => setPosition(val.replace('@', ''))}
											matchAny={true}
											type='text'
											spacer=''
											rows='1'
											cols='50'
											className='form-control'
											value={position}
											onChange={(val) => {
												setPosition(val);
											}}
										/>
										<button
											className='addImage'
											onClick={addImage}>
											+
										</button>
									</div>
									{images.map((image, i) => {
										return (
											<div
												className='pending-images'
												key={i}>
												<span>{image.text}</span>
												<span>{image.type}</span>
												<span>{image.secondaryType}</span>
												<span>{image.posFilter}</span>
												<button
													className='removeImage'
													onClick={(e) => removeImage(e, image)}>
													X
												</button>
											</div>
										);
									})}
									<div
										className='form-group'
										style={{ marginTop: '10px' }}>
										<label className='form-label'>Next Caption</label>
										<div className='input-group'>
											<input
												type='text'
												value={nextCaption}
												className='form-control'
												onChange={(e) => {
													setNextCaption(e.target.value);
												}}
											/>
										</div>
									</div>
									<div
										className='form-group'
										style={{ marginTop: '10px' }}>
										<label className='form-label'>Next Minutes</label>
										<div className='input-group'>
											<input
												type='text'
												value={nextMinutes}
												className='form-control'
												onChange={(e) => {
													setNextMinutes(e.target.value);
												}}
											/>
										</div>
									</div>
									<div className='many-labels'>
										<span>Next Text</span>
										<span>Next Type</span>
										<span>Next Secondary</span>
										<span>Next Position</span>
									</div>
									<div className='form-group many-inputs'>
										<TextInput
											options={titles}
											onSelect={(val) => setImageText(val.replace('@', ''))}
											matchAny={true}
											type='text'
											spacer=''
											rows='1'
											cols='50'
											className='form-control'
											value={imageText}
											onChange={(val) => {
												setImageText(val);
											}}
										/>
										<TextInput
											options={['post', 'postimage', 'grid', 'gridimage', 'tier', 'bracket', 'scores', 'matchupimage', 'news', 'chart', 'highlightimage', 'lineupimage', 'comparison']}
											onSelect={(val) => setImageType(val.replace('@', ''))}
											matchAny={true}
											type='text'
											spacer=''
											rows='1'
											cols='50'
											className='form-control'
											value={imageType}
											onChange={(val) => {
												setImageType(val);
											}}
										/>
										<TextInput
											options={types}
											onSelect={(val) => setSecondaryType(val.replace('@', ''))}
											matchAny={true}
											type='text'
											spacer=''
											rows='1'
											cols='50'
											className='form-control'
											value={secondaryType}
											onChange={(val) => {
												setSecondaryType(val);
											}}
										/>
										<TextInput
											options={['QB', 'RB', 'WR', 'TE']}
											onSelect={(val) => setPosition(val.replace('@', ''))}
											matchAny={true}
											type='text'
											spacer=''
											rows='1'
											cols='50'
											className='form-control'
											value={position}
											onChange={(val) => {
												setPosition(val);
											}}
										/>
										<button
											className='addImage'
											onClick={(e) => addImage(e, true)}>
											+
										</button>
									</div>
									{nextImages.map((image, i) => {
										return (
											<div
												className='pending-images'
												key={i}>
												<span>{image.text}</span>
												<span>{image.type}</span>
												<span>{image.secondaryType}</span>
												<span>{image.posFilter}</span>
												<button
													className='removeImage'
													onClick={(e) => removeNextImage(e, image)}>
													X
												</button>
											</div>
										);
									})}
									<div className='form-group'>
										<label className='form-label'>Special (comma separated)</label>
										<div className='input-group'>
											<input
												type='text'
												value={special}
												className='form-control'
												onChange={(e) => {
													setSpecial(e.target.value);
												}}
											/>
										</div>
									</div>
									<div className='form-group same-line'>
										<label className='form-label'>On Hold</label>
										<div className='input-group'>
											<input
												type='checkbox'
												id='onHold'
												checked={onHold}
												onChange={() => setOnHold(!onHold)}
											/>
										</div>
										<label className='form-label'>Check Scores</label>
										<div className='input-group'>
											<input
												type='checkbox'
												id='checkScores'
												checked={checkScores}
												onChange={() => setCheckScores(!checkScores)}
											/>
										</div>
										<label className='form-label'>UTC</label>
										<div className='input-group'>
											<input
												type='checkbox'
												id='isUtc'
												checked={isUtc}
												onChange={() => setIsUtc(!isUtc)}
											/>
										</div>
									</div>
									<button
										type='submit'
										className='btn btn-primary btn-block'>
										Submit
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			<Modal
				isOpen={deleteOpen}
				onRequestClose={() => {
					setDeleteOpen(false);
				}}>
				<div className='modal'>
					<div className='modal-dialog'>
						<div className='modal-content'>
							<div className='modal-header'>
								<h5 className='modal-title'>Delete Cron</h5>
								<span
									className='close-modal'
									onClick={() => {
										set;
										setDeleteOpen(false);
									}}>
									X
								</span>
							</div>
							<div className='modal-body'>
								<p>Are you sure you want to delete this cron?</p>
							</div>
							<div className='modal-footer'>
								<button
									type='button'
									onClick={() => setDeleteOpen(false)}
									className='btn btn-primary secondary'
									data-dismiss='modal'>
									Cancel
								</button>
								<button
									type='submit'
									onClick={deleteCron}
									className='btn btn-primary'>
									Delete
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			<Modal
				isOpen={manuallyPostOpen}
				onRequestClose={() => {
					setManuallyPostOpen(false);
				}}>
				<div className='modal'>
					<div className='modal-dialog'>
						<div className='modal-content'>
							<div className='modal-header'>
								<h5 className='modal-title'>Post Manually</h5>
								<span
									className='close-modal'
									onClick={() => {
										setManuallyPostOpen(false);
									}}>
									X
								</span>
							</div>
							<div className='modal-body'>
								<p>Are you sure you want to post this manually?</p>
							</div>
							<div className='modal-footer'>
								<button
									type='button'
									onClick={() => setManuallyPostOpen(false)}
									className='btn btn-primary secondary'
									data-dismiss='modal'>
									Cancel
								</button>
								<button
									type='submit'
									onClick={manuallyPost}
									className='btn btn-primary'>
									Post
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			<div className='flexHeader'>
				<select
					name='accountFilter'
					id='accountFilter'
					value={accountFilter}
					onChange={(e) => {
						setAccountFilter(e.target.value);
					}}>
					<option value='all'>All</option>
					<option value='ftblstatpage'>ftblstatpage</option>
					<option value='cfbstatpage'>cfbstatpage</option>
					<option value='ballstatpage'>ballstatpage</option>
					<option value='cbbstatpage'>cbbstatpage</option>
					<option value='bsblstatpage'>bsblstatpage</option>
					<option value='icestatpage'>icestatpage</option>
					<option value='fantasyftbl'>fantasyftbl</option>
				</select>
				<h3>Crons</h3>
				<button
					className='newButton addCronBtn'
					onClick={toggleModal}>
					Add Cron
				</button>
			</div>
			<div className='main'>
				{crons.length > 0 && (
					<div className='table table-striped'>
						<div className='table-head' style={{gridTemplateColumns: 'repeat(8, 1fr)'}}>
							<span>Times</span>
							<span>Sunday {dates[0].format('M/D')}</span>
							<span>Monday {dates[1].format('M/D')}</span>
							<span>Tuesday {dates[2].format('M/D')}</span>
							<span>Wednesday {dates[3].format('M/D')}</span>
							<span>Thursday {dates[4].format('M/D')}</span>
							<span>Friday {dates[5].format('M/D')}</span>
							<span>Saturday {dates[6].format('M/D')}</span>
						</div>
						<div className='table-body'>
							{times.map((time, i) => {
								return (
									<span
										key={i}
										className='table-row'
										style={{gridTemplateColumns: 'repeat(8, 1fr)'}}>
										{days.map((day, j) => {
											const dayOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].indexOf(day);
											let nextDateForDay;
											if (today <= dayOfWeek) { 
												nextDateForDay = moment().day(dayOfWeek);
											} else {
												nextDateForDay = moment().add(1, 'weeks').day(dayOfWeek);
											}
											if (day === 'TIMES') {
												return <span key={j}>{time}</span>;
											} else {
												const foundCrons = findCrons(time, day);
												return (
													<span
														key={j}
														style={{ display: 'flex', flexDirection: 'column', background: today === dayOfWeek ? '#00bb0030' : '' }}>
														{foundCrons.map((cron, k) => {
															return (
																<span
																	key={k}
																	style={{ color: findColor(cron, nextDateForDay) }}>
																	{cron.name}
																</span>
															);
														})}
													</span>
												);
											}
										})}
									</span>
								);
							})}
						</div>
					</div>
				)}
				<div className='table table-striped'>
					<div className='table-head'>
						<span onClick={() => sortTable('sport')}>Sport</span>
						<span onClick={() => sortTable('account')}>Account</span>
						<span onClick={() => sortTable('start')}>Start</span>
						<span onClick={() => sortTable('end')}>End</span>
						<span onClick={() => sortTable('schedule')}>Schedule</span>
						<span onClick={() => sortTable('name')}>Name</span>
						<span>On Hold</span>
						<span>Email</span>
						<span>Special</span>
						<span>Actions</span>
					</div>
					<div className='table-body'>
						{crons
							.filter((cron) => accountFilter === 'all' || cron.account === accountFilter)
							.map((cron, i) => {
								return (
									<span
										key={i}
										className='expandable-row'>
										<span
											onClick={(e) => expandRow(e, cron._id)}
											className='first-row'>
											<span>{cron.sport}</span>
											<span>{cron.account}</span>
											<span>{moment(cron.start).format('M/D/YY')}</span>
											<span>{moment(cron.end).format('M/D/YY')}</span>
											<span>{cronstrue.toString(cron.schedule, { throwExceptionOnParseError: false })}</span>
											<span>{cron.name}</span>
											<span>
												<input
													type='checkbox'
													checked={cron.onHold}
													onChange={(e) => updateCron(e, cron)}
												/>
											</span>
											<span>{cron.email}</span>
											<span>{cron.special}</span>
											<span>
												<span
													className='postButton'
													onClick={(e) => {
														e.preventDefault();
														e.stopPropagation();
														setManuallyPostOpen(true);
														setPendingManualPost(cron);
													}}>
													<i className='fab fa-instagram fa-2x'></i>
												</span>
												<span
													className='editButton'
													onClick={(e) => {
														editCron(e, cron);
													}}>
													<i className='fas fa-pencil-alt'></i>
												</span>
												<span
													className='duplicateButton'
													onClick={(e) => {
														duplicateCron(e, cron);
													}}>
													<i className='fas fa-copy'></i>
												</span>
												<span
													className='deleteButton'
													onClick={() => {
														setDeleteOpen(true);
														setPendingDeleteID(cron._id);
													}}>
													<i className='fa fa-trash'></i>
												</span>
											</span>
										</span>
										{expanded.includes(cron._id) && (
											<span className='expanded-row'>
												<span>{cron.caption}</span>
												{cron.images && (
													<span className='imagesTD'>
														{cron.images.map((image, j) => {
															let retImage = image.text;
															if (image.type) retImage += ', Type: ' + image.type;
															if (image.secondaryType) retImage += ' (' + image.secondaryType + ')';
															if (image.posFilter) retImage += ', Pos: ' + image.posFilter;
															return <span key={j}>{retImage}</span>;
														})}
													</span>
												)}
												{cron.nextCaption && <span>{cron.nextCaption + ' (in ' + cron.nextMinutes + ' minutes)'}</span>}
												{cron.nextImages && (
													<span className='imagesTD'>
														{cron.nextImages.map((image, j) => {
															let retImage = image.text;
															if (image.type) retImage += ', Type: ' + image.type;
															if (image.secondaryType) retImage += ' (' + image.secondaryType + ')';
															if (image.posFilter) retImage += ', Pos: ' + image.posFilter;
															return <span key={j}>{retImage}</span>;
														})}
													</span>
												)}
											</span>
										)}
									</span>
								);
							})}
					</div>
				</div>
			</div>
		</div>
	);
}
