export const quick3 = {
	headerSize: 21,
	nameSize: 18,
	statSize: 18,
	logoOrder: -2,
	logoOpacity: 100,
	logoSize: 49,
	titleMarginTop: -1,
	headerSpacing: 0,
	nameMargin: 8,
	headerFont: 'uniform bold',
	nameFont: 'uniform reg',
	statFont: 'uniform bold',
	headerColor: '#ffffff',
	headerFontColor: '#f7f7f7',
	watermark: '',
	watermarkFont: 'exo',
	watermarkSize: 28,
	watermarkWidth: 80,
	statMargin: 2,
	watermarkColor: '#ffffff',
	nameShadow: 0,
	borderColor: '#000000',
	nameSpacing: 0,
	statSpacing: -2,
	statShadow: 0,
	type: 'post',
	logoPosition: 292,
	overlap: false,
	statMargin2: 0,
	lineHeight: 0.9,
	nameBackground: '#000000',
	statBackground: '#000000',
	nameColor: '#ffffff',
	statColor: '#ffffff',
	imageHorizontalNews: 0,
	imageVerticalNews: 0,
	imageZoomNews: 100,
	headerSizeNews: 20,
	headerFontNews: 'roboto condensed',
	headerMarginNews: 0,
	headerSpacingNews: 0,
	headerColorNews: '#ffffff',
	headerFontColorNews: '#000000',
	logoOpacityNews: 100,
	nameColorNews: '#ffffff',
	logoSizeNews: 500,
	orientationNews: 'leftright',
	borderRadius: 0,
	scoreMargin: 5,
	paneSplit: [50, 50],
	paneSplitNews: [50, 50],
	duplicate: false,
	logoShadow: 0,
	nameMargin2: 8,
	postWidth: 680,
	postHeight: 680,
	postWidthNews: 680,
	postHeightNews: 680,
	tierFont: 'roboto condensed',
	tierColor: '#000000',
	tierSize: 75,
	duplicateLogoSize: 500,
	duplicateLogoPosition: 0,
	duplicateLogoOpacity: 10,
	orientation: 'leftright',
	orientationMatchup: 'topbottom',
	paneSplitMatchup: [80, 20],
	gridMargin: 2,
	logoFlip: true,
	scoreMinHeight: 100,
	videoDirection: 'fromright',
	videoReverse: false,
	headerOutlineColor: '#6a2424',
	nameOutlineColor: '#ffffff',
	statOutlineColor: '#ffffff',
	headerOutlineSize: 0,
	nameOutlineSize: 0,
	statOutlineSize: 0,
	headerTransform: 'none',
	nameTransform: 'none',
	statTransform: 'none',
	nameShadowColor: '#000000',
	statShadowColor: '#000000',
	tierTitle: 'left',
	pyramid: false,
	newsStyle: {
		value: 'style-1',
		label: 'Style 1',
	},
	postBackground: null,
	titleHeight: 10,
	subtitleFontSize: 10,
	subtitleFont: 'uniform reg',
	subtitleFontColor: '#ffffff',
	subtitleMarginTop: -7,
	subtitleSpacing: 0,
	subtitleTransform: 'none',
	subtitleOutlineColor: '#ffffff',
	subtitleOutlineSize: 0,
	backgroundColor: 'single',
	postColumns: null,
	gridColumns: null,
	tierSplit: 8,
	fivelessBreak: 8,
	helmets: false,
	rankSize: 43,
	rankFont: 'uniform bold',
	rankFontColor: '#ffffff',
	rankMargin: 3,
	rankSpacing: -1,
	rankOutlineSize: 0,
	rankOutlineColor: '#000000',
	logoSizeGrid: 100,
	logoSizeTier: 100,
	pointsSize: 20,
	pointsFont: 'roboto condensed',
	pointsFontColor: '#ffffff',
	pointsMargin: 0,
	pointsSpacing: -1.2,
	pointsOutlineSize: 0,
	pointsOutlineColor: '#000000',
	pointsBackground: '#000000',
	playerHeight: 1,
	statLineHeight: 1,
	indexAxis: 'y',
	chartType: {
		value: 'bar',
		label: 'Bar Chart',
	},
	labelSize: 14,
	labelColor: '#000000',
	labelFont: 'roboto condensed',
	chartBorderColor: '#000000',
	chartBorderWidth: 0.5,
	chartLogoSize: 75,
	playerMargin: 0,
	lighterLogos: false,
	texture: null,
	textureSize: 5,
	textureOpacity: 19,
	listStyle: {
		value: 'style-2',
		label: 'Style 2',
	},
	rankBackground: '#000000',
	backgroundTexture: null,
	backgroundTextureSize: 2,
	backgroundTextureOpacity: 30,
	boxMarginTop: 4.5,
	boxMarginRight: 4.5,
	boxMarginBottom: 4.5,
	boxMarginLeft: 4.5,
	teamBackground: 'primary',
	actualHeaderColor: '#000000',
	rankWidth: 48,
	rankMargin2: 0,
	boxRankOutlineSize: 0,
	borderSize: 2,
	boxImageBackground: 'secondary',
	backgroundGradientDirectionDeg: '180deg',
	backgroundGradientStops: [
		{
			color: '#ffffff',
			percentage: 0,
		},
		{
			color: '#D50A0A',
			percentage: 100,
		},
	],
	gradientDirection: '90deg',
	gradientDirectionNews: '0deg',
	gradientType: 'linear-gradient',
	gradientStops: [
		{
			color: 'secondary',
			percentage: 0,
		},
		{
			color: 'mid',
			percentage: 50,
		},
		{
			color: 'primary',
			percentage: 100,
		},
	],
	gradientTypeNews: 'linear-gradient',
	gradientStopsNews: [
		{
			color: 'secondary',
			percentage: 0,
		},
		{
			color: 'mid',
			percentage: 50,
		},
		{
			color: 'primary',
			percentage: 100,
		},
	],
};
