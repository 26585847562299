import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement} from 'chart.js';
import {Bar, Scatter} from 'react-chartjs-2';
import {colord, extend} from 'colord';
import mixPlugin from 'colord/plugins/mix';
import useSettingsStore from '../../stores/settings.store';
import usePostStore from '../../stores/posts/base-post.store';
import useChartStore from '../../stores/posts/chart.store';
import useUserStore from '../../stores/user.store';
import useGlobalStore from '../../stores/global.store';
extend([mixPlugin]);

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement);

export default function ChartFormat(props) {
	const { settings } = useSettingsStore();
	const { name, stat, team } = usePostStore();
	const { xLabel, yLabel } = useChartStore();
	const { sport } = useUserStore();
	const { sizeMultiplier } = useGlobalStore();

	if (settings && settings.chartType?.value === 'bar') {
		let canvas = document.createElement('canvas');
		let ctx = canvas.getContext('2d');
		let gradients = [];
		team.forEach((team, i) => {
			let teamObj = props.getTeamObj(team.toUpperCase(), sport);
			if (teamObj) {
				if (settings.teamBackground === 'gradient') {
					let gradient = ctx.createLinearGradient(0, 0, 0, props.height);
					if (settings.indexAxis === 'y') {
						gradient = ctx.createLinearGradient(0, 0, props.width, 0);
					}
					settings.gradientStops.forEach((stop) => {
						let stopColor = props.findColor(stop.color, teamObj);
						if (stop.colorMix) {
							stopColor = colord(stopColor).mix(stop.color2, stop.colorMix / 100).toHex();
							colord(teamObj.primary).mix('#000000', 0.7).toHex();
						}
						gradient.addColorStop(String(stop.percentage / 100), stopColor);
					});
					gradients.push(gradient);
					// single color
				} else {
					gradients.push(props.findColor(settings.teamBackground, teamObj));
				}
			}
		});

		let options = {
			indexAxis: settings.indexAxis,
			responsive: true,
			aspectRatio: false,
			plugins: {
				legend: false,
				title: false,
				datalabels: {
					display: false,
				}
			},
			scales: {
				y: {
					ticks: {
						color: settings.labelColor,
						padding: 10,
						font: {
							size: Math.round(settings.labelSize * sizeMultiplier),
							weight: 700,
							family: settings.labelFont,
						},
					},
				},
				x: {
					ticks: {
						autoSkip: false,
						color: settings.labelColor,
						padding: 10,
						font: {
							size: Math.round(settings.labelSize * sizeMultiplier),
							weight: 700,
							family: settings.labelFont,
						},
					},
				},
			},
		};

		let data = {
			labels: name,
			datasets: [
				{
					label: '',
					data: stat,
					backgroundColor: gradients,
					borderColor: settings.chartBorderColor,
					borderWidth: settings.chartBorderWidth * sizeMultiplier,
				},
			],
		};

		return (
			<Bar
				options={options}
				data={data}
				style={{width: props.width, height: props.height}}
			/>
		);
	} else if (settings && settings.chartType?.value === 'scatter') {
		let options = {
			scales: {
				y: {
					beginAtZero: false,
					ticks: {
						display: false,
					},
					border: {
						color: settings.chartBorderColor,
						width: settings.chartBorderWidth,
					},
					position: 'center',
					offset: true,
				},
				x: {
					beginAtZero: false,
					ticks: {
						display: false,
					},
					title: {
						display: true,
						text: xLabel,
						color: settings.labelColor,
						align: 'end',
						font: {
							size: Math.round(settings.labelSize * sizeMultiplier),
							weight: 700,
							family: settings.labelFont,
							color: settings.labelColor
						},
					},
					border: {
						color: settings.chartBorderColor,
						width: settings.chartBorderWidth,
					},
					position: 'center',
					offset: true,
				},
			},
			responsive: true,
			aspectRatio: false,
			plugins: {
				legend: false,
				title: {
					display: true,
					text: yLabel,
					color: settings.labelColor,
					font: {
						size: Math.round(settings.labelSize * sizeMultiplier),
						weight: 700,
						family: settings.labelFont,
						color: settings.labelColor
					},
				},
				datalabels: {
					display: false,
				}
			},
		};

		let mappedData = [];
		stat.forEach((stat, i) => {
			mappedData.push({
				x: stat,
				y: name[i],
			});
		});

		let teamImages = [];
		team.forEach((team, i) => {
			let teamObj = props.getTeamObj(team.toUpperCase(), sport);
			if (teamObj) {
				let teamImage = new Image(settings.chartLogoSize * sizeMultiplier, settings.chartLogoSize * sizeMultiplier);
				teamImage.setAttribute('crossorigin', 'anonymous');
				teamImage.src = teamObj.logo.replace('url(', '').replace(')', '');
				teamImages.push(teamImage);
			}
		});

		let data = {
			datasets: [
				{
					label: '',
					data: mappedData,
					pointStyle: teamImages,
				},
			],
		};

		return (
			<Scatter
				options={options}
				data={data}
				style={{width: props.width, height: props.height}}
				redraw
			/>
		);
	}
	return null;
}
