import {colord, extend} from 'colord';
import mixPlugin from 'colord/plugins/mix';
import useSettingsStore from '../stores/settings.store';
extend([mixPlugin]);

export default function BoxPreview(props) {
	const { settings } = useSettingsStore();

	const findColor = (color, teamObj) => {
		if (!teamObj || (Object.keys(teamObj).length === 0 && teamObj.constructor === Object)) {
			if (color === 'none') return 'none';
			return colord(color).isValid() ? color : '#000000';
		}
		if (color === 'primary' || color === 'secondary') {
			return teamObj[color];
		} else if (color === 'primaryDarker') {
			return colord(teamObj.primary).darken(0.1).toHex()
		} else if (color === 'secondaryDarker') {
			return colord(teamObj.secondary).darken(0.1).toHex()
		} else if (color === 'brighter') {
			return colord(teamObj.primary).brightness() > colord(teamObj.secondary).brightness() ? teamObj.primary : teamObj.secondary;
		} else if (color === 'darker') {
			return colord(teamObj.secondary).brightness() > colord(teamObj.primary).brightness() ? teamObj.primary : teamObj.secondary;
		} else if (color === 'mid') {
			return colord(teamObj.primary).mix(teamObj.secondary).toHex();
		}
		return color;
	}

	const teamObj = props.teamObj;
	if (!teamObj || (Object.keys(teamObj).length === 0 && teamObj.constructor === Object) || !settings) {
		return;
	}
	let height = 36;
	let nameFont = height / 30 + 'em';
	let imageWidth = height / 25 + 'em';
	// box background
	let boxbackground;
	if (settings.teamBackground === 'gradient') {
		boxbackground = settings.gradientType === 'linear-gradient' ?
			`linear-gradient(${settings.gradientDirection}, ` :
			`radial-gradient(circle, `;
		settings.gradientStops.forEach((stop) => {
			let stopColor = findColor(stop.color, teamObj);
			if (stop.colorMix) {
				stopColor = colord(stopColor).mix(stop.color2, stop.colorMix / 100).toHex();
				colord(teamObj.primary).mix('#000000', 0.7).toHex();
			}
			boxbackground += `${stopColor} ${stop.percentage}%, `
		});
		boxbackground = boxbackground.slice(0, -2);
		boxbackground += ')';
	} else {
		boxbackground = findColor(settings.teamBackground);
	}
	// box image background
	let boxImageBack = 'none';
	if (settings.boxImageBackground !== 'none') {
		if (settings.boxImageBackground === 'primaryDarker') {
			boxImageBack = colord(teamObj.primary).darken(0.1).toHex();
		} else {
			boxImageBack = colord(settings.boxImageBackground).isValid() ? settings.boxImageBackground : teamObj[settings.boxImageBackground];
		}
	}
	return (
		<div className='boxPreview'>
			<span className={`boxwrap`}>
				{/* aspect ratio?? */}
				<div
					style={{height, background: boxbackground, width: '400px'}}
					className={'box drag-container'}>
					<div className={`boxname`}>
						<span style={{fontSize: nameFont}}>{teamObj.abbreviation}</span>
					</div>
					{settings.texture && <div className={'boxtexture ' + settings.texture}></div>}
					{settings.logoOpacity !== 0 && (
						<div
							style={{background: boxImageBack}}
							className={settings.overlap ? `boximagecontainer movelogo postyimg` : 'boximagecontainer postyimg'}>
							<div
								className={`boximage`}
								style={{backgroundImage: teamObj.logo, minWidth: imageWidth, height: height}}></div>
						</div>
					)}
					{settings.duplicate && (
						<div className={`boximagecontainer2`}>
							{teamObj?.duplicate ? (
								<div
									className={`boximage`}
									style={{backgroundImage: teamObj?.duplicate, minWidth: imageWidth, height: height}}></div>
							) : (
								<div
									className={`boximage`}
									style={{backgroundImage: teamObj.logo, minWidth: imageWidth, height: height}}></div>
							)}
						</div>
					)}
				</div>
			</span>
		</div>
	);
}
