import axios from 'axios';
import {useState} from 'react';
import Modal from 'react-modal';
import {toast} from 'react-toastify';
import useModalStore from '../../stores/modal.store';
import useUserStore from '../../stores/user.store';
import useGlobalStore from '../../stores/global.store';

export default function ProfileModal(props) {
	const [newName, setNewName] = useState('');
	const [newInstagram, setNewInstagram] = useState('');
	const [newEmail, setNewEmail] = useState('');
	const { toggleModal } = useModalStore();
	const {requestedChange} = useUserStore();
	const { debug } = useGlobalStore();

	const updateProfile = (e) => {
		e.preventDefault();
		try {
			if (!newName && !newEmail && !newInstagram) {
				toast.error('Nothing to change!', {autoClose: 3000});
			} else {
				let name, email, instagram;
				newName ? (name = newName) : (name = props.user.name);
				newEmail ? (email = newEmail) : (email = props.user.email);
				newInstagram ? (instagram = newInstagram) : (instagram = props.user.instagram);
				axios
					.put(`/users/updateProfile/${props.user._id}`, {
						newName: name,
						newEmail: email,
						newInstagram: instagram,
					})
					.then((response) => {
						toast.success('Updated profile successfully!', {autoClose: 3000});
						localStorage.setItem('token', response.data.data.token);
						props.updateUser(response.data.data.token);
					})
					.catch((error) => {
						if (debug) {
							console.log(error);
						}
						if (error.data.error === 'Email already exists') {
							toast.error('An account exists with this email already.  Please try a different one.');
						} else {
							toast.error('An unknown error occured.');
						}
					});
			}
		} catch (e) {
			if (debug) {
				console.log(e);
			}
			toast.error('Something went wrong.  Please try again later', {autoClose: 3000});
		}
	};
	return (
		<Modal
			isOpen={true}
			onRequestClose={() => toggleModal('profile')}>
			<div className='profile modal' onClick={(e) => props.closeModal(e, 'profile')}>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>Profile</h5>
							<span
								className='close-modal'
								onClick={() => toggleModal('profile')}>
								X
							</span>
						</div>
						<div className='modal-body'>
							<form onSubmit={updateProfile}>
								<div className='form-group'>
									<label className='form-label'>Name</label>
									<input
										type='text'
										required
										className='form-control'
										defaultValue={props.user.name}
										disabled={props.user.hasGoogleAuth}
										onChange={(e) => {
											setNewName(e.target.value);
										}}
									/>
								</div>

								<div className='form-group'>
									<label className='form-label'>Social Media Handle</label>
									<div className='input-group'>
										<div className='input-group-prepend'>
											<div className='input-group-text'>@</div>
										</div>
										<input
											type='text'
											required
											className='form-control'
											defaultValue={props.user.instagram}
											onChange={(e) => {
												setNewInstagram(e.target.value);
											}}
										/>
									</div>
								</div>

								<div className='form-group'>
									<label className='form-label'>Email Address</label>
									<input
										type='email'
										required
										className='form-control'
										defaultValue={props.user.email}
										disabled={props.user.hasGoogleAuth}
										onChange={(e) => {
											setNewEmail(e.target.value);
										}}
									/>
								</div>

								{props.user.isActive && (
									<span>
										<div className='form-group'>
											<label className='form-label'>Plan</label>
											<input
												disabled
												className='form-control'
												defaultValue={props.user.plan === 'all' ? 'All Sports' : `Single Sport (${props.user.sport})`}
											/>
										</div>
									</span>
								)}
								<button
									type='submit'
									className={'btn btn-primary btn-block' + ((!newName && !newEmail && !newInstagram) ? ' secondary' : '')}>
									Update Profile
								</button>
								{!props.user.hasGoogleAuth && (<button
									type='button'
									onClick={() => toggleModal('password')}
									className='btn btn-primary btn-block secondary'>
									Change Password
								</button>)}
								{requestedChange && <p className='requested-change'>Plan change requested. Please wait a few minutes, then log out and log back in.</p>}
								{props.user.isActive && props.user.plan === 'single' && !props.user.isAnnual && !props.user.stripeSub && !requestedChange && (
									<button
										type='button'
										onClick={() => toggleModal('change')}
										className='btn btn-primary btn-block secondary'>
										Upgrade Subscription
									</button>
								)}
								{props.user.isActive && props.user.plan === 'all' && !props.user.isAnnual && !props.user.stripeSub && !requestedChange && (
									<button
										type='button'
										onClick={() => toggleModal('change')}
										className='btn btn-primary btn-block secondary'>
										Downgrade Subscription
									</button>
								)}
								{props.user.isActive && props.user.stripeSub && (
									<button
										type='button'
										onClick={() => window.open('https://billing.stripe.com/p/login/28o6oE3Nx27t0GQ3cc', '_blank')}
										className='btn btn-primary btn-block secondary'>
										Manage Subscription
									</button>
								)}
								{props.user.isActive && (
									<button
										type='button'
										onClick={() => toggleModal('cancel')}
										className='btn btn-primary btn-block secondary'>
										Cancel Subscription
									</button>
								)}
							</form>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}
