import {useState} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';
import 'rc-slider/assets/index.css';
import { ChromePicker } from './editors/ChromePicker';
import useSettingsStore from '../stores/settings.store';
import usePostStore from '../stores/posts/base-post.store';
import useWithImageStore from '../stores/posts/with-image.store';
import useGlobalStore from '../stores/global.store';
import useUserStore from '../stores/user.store';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

export default function TeamEdit(props) {
	const [selectedColor, setSelectedColor] = useState('primary');
	const [selectedTeam, setSelectedTeam] = useState(1);
	const { settings } = useSettingsStore();
	const { team } = usePostStore();
	const { newsteam } = useWithImageStore();
	const { editTeam } = useGlobalStore();
	const { teamOverrides, setTeamOverrides, sport } = useUserStore();
	const { teamValue } = props;

	const saveOverride = (teamObj, s) => {
		let teamOverridesCopy = [...teamOverrides];
		let existingOverride = teamOverridesCopy.find((obj) => {
			return obj.abbreviation === teamObj.abbreviation && obj.sport === s;
		});
		if (!existingOverride) {
			return;
		}
		teamObj.sport = s;
		teamObj.email = props.user.email;
		teamObj.user_id = props.user._id;
		teamObj.isGlobal = true;
		if (teamObj._id) {
			delete teamObj._id;
		}
		axios.post(`/teamoverrides`, teamObj).then((response) => {
			if (response.status === 200) {
				toast.success('Team Override Saved!', {autoClose: 3000});
			}
			existingOverride._id = response.data._id;
			existingOverride.isGlobal = true;
			setTeamOverrides(teamOverridesCopy);
		});
	};

	const deleteOverride = (id) => {
		axios.delete(`/teamoverrides/${id}`).then((response) => {
			if (response.status === 200) {
				const filteredTeamOverrides = teamOverrides.filter((override) => override._id !== id);
				setTeamOverrides(filteredTeamOverrides);
				toast.error('Team Override Deleted!', {autoClose: 3000});
			}
		});
	};

	const teamLogoChange = (teamObj, sport, index) => {
		let teamOverridesCopy = [...teamOverrides];
		let existingOverride = teamOverridesCopy.find((obj) => {
			return obj.abbreviation === teamObj.abbreviation && obj.sport === sport;
		});
		if (existingOverride) {
			existingOverride.logo = existingOverride.logos[index];
			existingOverride.isGlobal = false;
			setTeamOverrides(teamOverridesCopy);
		} else {
			teamObj.logo = teamObj.logos[index];
			teamObj.sport = sport;
			teamObj.isGlobal = false;
			setTeamOverrides([...teamOverrides, teamObj]);
		}
	};

	const flipColors = (teamObj, sport) => {
		let teamOverridesCopy = [...teamOverrides];
		let existingOverride = teamOverridesCopy.find((obj) => {
			return obj.abbreviation === teamObj.abbreviation && obj.sport === sport;
		});
		if (existingOverride) {
			const oldPrimary = existingOverride.primary;
			const oldSecondary = existingOverride.secondary;
			existingOverride.primary = oldSecondary;
			existingOverride.secondary = oldPrimary;
			existingOverride.isGlobal = false;
			setTeamOverrides(teamOverridesCopy);
		} else {
			const oldPrimary = teamObj.primary;
			const oldSecondary = teamObj.secondary;
			teamObj.primary = oldSecondary;
			teamObj.secondary = oldPrimary;
			teamObj.sport = sport;
			teamObj.isGlobal = false;
			setTeamOverrides([...teamOverrides, teamObj]);
		}
	};

	const teamColorChange = (teamObj, s, color) => {
		let teamOverridesCopy = [...teamOverrides];
		let existingOverride = teamOverridesCopy.find((obj) => {
			return obj.abbreviation === teamObj.abbreviation && obj.sport === s;
		});
		if (existingOverride) {
			existingOverride[selectedColor] = color;
			existingOverride.isGlobal = false;
			setTeamOverrides(teamOverridesCopy);
		} else {
			teamObj[selectedColor] = color;
			teamObj.sport = s;
			teamObj.isGlobal = false;
			setTeamOverrides([...teamOverrides, teamObj]);
		}
	};

	const resetTeam = (existingOverride) => {
		let teamOverridesCopy = [...teamOverrides];
		const index = teamOverridesCopy.findIndex((x) => x === existingOverride);
		if (index > -1) {
			teamOverridesCopy.splice(index, 1);
		}
		setTeamOverrides(teamOverridesCopy);
	};

	let teamValues;
	if (props.fromDashboard) {
		teamValues = team[editTeam] ? props.getTeam(team[editTeam].replace('2', '')) : '';
		if (settings.type === 'news' || settings.type === 'highlightimage' || settings.type === 'lineupimage') {
			teamValues = newsteam ? props.getTeam(newsteam.replace('2', '')) : '';
		}
	} else {
		teamValues = teamValue;
	}
	let teamy = teamValues.team ? teamValues.team : null;
	let sporty = teamValues.sport ? teamValues.sport : sport;
	let team1 = teamValues.team1 ? teamValues.team1 : null;
	let team2 = teamValues.team2 ? teamValues.team2 : null;
	let sport1 = teamValues.sport1 ? teamValues.sport1 : null;
	let sport2 = teamValues.sport2 ? teamValues.sport2 : null;
	let teamObj;
	if (teamy) {
		teamObj = props.getTeamObj(teamy, sporty);
	}
	if (team1 && selectedTeam === 1) {
		teamObj = props.getTeamObj(team1, sport1);
		sporty = sport1;
	}
	let team2selected = false;
	if (team2 && selectedTeam === 2) {
		team2selected = true;
		teamObj = props.getTeamObj(team2, sport2);
		sporty = sport2;
	}
	if (!teamObj || (Object.keys(teamObj).length === 0 && teamObj.constructor === Object)) {
		return null;
	}
	let override = teamOverrides.filter((obj) => {
		return obj.abbreviation === teamObj.abbreviation && obj.sport === sporty;
	});
	let existingOverride;
	if (override && override.length > 1) {
		existingOverride = override.find((obj) => {
			return !obj.isGlobal;
		});
	} else if (override && override.length === 1) {
		existingOverride = override[0];
	}
	return (
		<div className='edit-individually'>
			<h5 className='edit-team-header'>
				{`Edit ${teamObj.full ? teamObj.full.toLowerCase() : teamObj.abbreviation}`}
				<div style={{backgroundImage: teamObj?.logo}}></div>
			</h5>
			{team1 && team2 && (
				<span>
					<h5>Select Team</h5>
					<span className='setting-radio'>
						<label>
							<input
								type='radio'
								name='teamradio'
								checked={selectedTeam === 1}
								value={1}
								onChange={() => setSelectedTeam(1)}
							/>
							{team1}
						</label>
						<label>
							<input
								type='radio'
								name='teamradio'
								checked={selectedTeam === 2}
								value={2}
								onChange={() => setSelectedTeam(2)}
							/>
							{team2}
						</label>
					</span>
				</span>
			)}
			{teamObj?.logos.length > 1 && (
				<span>
					<h5>Change Team Logo</h5>
					<span className='setting-radio'>
						{teamObj.logos.map((logo, i) => {
							return (
								<label key={i}>
									<input
										type='radio'
										name='logoradio'
										checked={teamObj?.logo === logo}
										value={i}
										onChange={() => teamLogoChange(teamObj, sporty, i)}
									/>
									{i + 1}
								</label>
							);
						})}
					</span>
				</span>
			)}
			{!team1 && (
				<span>
					<span className='flexRowHeader'>
						<h5>Adjust Team Colors</h5>
						<Tippy content={<span>Flip Colors</span>}>
							<button className='noButton'
								onClick={() => {
									flipColors(teamObj, sporty);
								}}>
								<span className='material-symbols-outlined'>
									flip
								</span>
							</button>
						</Tippy>
					</span>
					<span className='setting-radio' style={{marginBottom: '10px'}}>
						<label>
							<input
								type='radio'
								name='colorradio'
								checked={selectedColor === 'primary'}
								value='primary'
								onChange={() => setSelectedColor('primary')}
							/>
							Primary
						</label>
						<label>
							<input
								type='radio'
								name='colorradio'
								checked={selectedColor === 'secondary'}
								value='secondary'
								onChange={() => setSelectedColor('secondary')}
							/>
							Secondary
						</label>
					</span>
				</span>
			)}
			<ChromePicker
				color={teamObj?.[selectedColor]}
				onChange={(color) => teamColorChange(teamObj, sporty, color)}
			/>
			<span className='bottomPanel'>
				<button
					className='newButton'
					onClick={() => saveOverride(teamObj, sporty)}>
					Save Global Override
				</button>
				{existingOverride && existingOverride.isGlobal && (
					<button
						className='newButton'
						onClick={() => deleteOverride(existingOverride._id)}>
						Delete Global Override
					</button>
				)}
				{existingOverride && !existingOverride.isGlobal && (
					<button
						className='newButton resetTeam'
						onClick={() => resetTeam(existingOverride)}>
						Reset Team
					</button>
				)}
			</span>
		</div>
	);
}
