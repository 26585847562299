import React, { useEffect } from 'react';
import Header from './components/Header';
import Abbreviations from './pages/Abbreviations';
import PaypalSuccess from './pages/PaypalSuccess';
import Dashboard from './pages/Dashboard';
import Home from './pages/home/Home';
import Login from './pages/Login';
import Registration from './pages/Registration';
import NotFound from './pages/NotFound';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import GamePoll from './pages/admin/GamePoll';
import Legal from './pages/Legal';
import Crons from './pages/admin/Crons/Crons';
import Users from './pages/admin/Users';
import Stats from './pages/admin/Stats';
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import axios from 'axios';
import GameFinder from './pages/GameFinder';
import Admin from './pages/admin/Admin';
import StripeSuccess from './pages/StripeSuccess';
import HigherLower from './pages/admin/HigherLower';
import { jwtDecode } from 'jwt-decode';
import usePreferencesStore from './stores/preferences.store';
import Article from './pages/articles/Article';
const token = localStorage.getItem('token');
axios.defaults.headers.common['auth-token'] = token;

(() => {
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem('token');
			config.headers['auth-token'] = token;
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	axios.interceptors.response.use(
		(response) => {
			return response;
		},
		(error) => {
			if (error.response?.status === 401) {
				localStorage.removeItem('token');
				window.location.assign('/login');
			}
			return Promise.reject(error.response);
		}
	);
})();

function RequireAuth (): React.JSX.Element {
	const location = useLocation();
	try {
		const token = localStorage.getItem('token');
		if (token !== null) {
			const decoded = jwtDecode<any>(token);
			if (decoded.exp && decoded.exp > Date.now() / 1000) {
				return <Outlet />;
			}
		}
		return (
			<Navigate
				to='/login'
				state={{ from: location }}
			/>
		);
	} catch (e) {
		return (
			<Navigate
				to='/login'
				state={{ from: location }}
			/>
		);
	}
}

function RequireAdmin (): React.JSX.Element {
	const location = useLocation();
	try {
		const token = localStorage.getItem('token');
		if (token !== null) {
			const decoded = jwtDecode<any>(token);
			if (decoded.exp && decoded.exp > Date.now() / 1000 && decoded.user.is_admin === true) {
				return <Outlet />;
			}
		}
		return (
			<Navigate
				to='/login'
				state={{ from: location }}
			/>
		);
	} catch (e) {
		return (
			<Navigate
				to='/login'
				state={{ from: location }}
			/>
		);
	}
}

export default function RoutesPage (): React.JSX.Element {
	const { setDarkMode } = usePreferencesStore();

	useEffect(() => {
		if (localStorage.getItem('dark') !== null) {
			setDarkMode(localStorage.getItem('dark') === 'true');
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<BrowserRouter>
			<Header />
			<Routes>
				<Route
					path='/'
					element={<Home />}
				/>
				<Route
					path='/easiest-way-to-create-sports-graphics'
					element={<Article sport='sports'/>}
				/>
				<Route
					path='/easiest-way-to-create-nfl-graphics'
					element={<Article sport='NFL'/>}
				/>
				<Route
					path='/easiest-way-to-create-nba-graphics'
					element={<Article sport='NBA'/>}
				/>
				<Route
					path='/legal'
					element={<Legal />}
				/>
				<Route
					path='/success'
					element={<PaypalSuccess />}
				/>
				<Route
					path='/login'
					element={<Login />}
				/>
				<Route
					path='/register'
					element={<Registration />}
				/>
				<Route
					path='/forgot'
					element={<ForgotPassword />}
				/>
				<Route
					path='/reset/:token'
					element={<ResetPassword />}
				/>
				<Route
					path='/gamefinder'
					element={<GameFinder />}
				/>
				<Route element={<RequireAuth />}>
					<Route
						path='/abbreviations'
						element={<Abbreviations />}
					/>
					<Route
						path='/dashboard'
						element={
							<Dashboard />
						}
					/>
					<Route
						path='/subscribed'
						element={<StripeSuccess />}
					/>
				</Route>
				<Route element={<RequireAdmin />}>
					<Route
						path='/gamepoll'
						element={<GamePoll />}
					/>
					<Route
						path='/higher'
						element={<HigherLower />}
					/>
					<Route
						path='/admin'
						element={<Admin />}
					/>
					<Route
						path='/admin/crons'
						element={<Crons />}
					/>
					<Route
						path='/admin/users'
						element={<Users />}
					/>
					<Route
						path='/admin/stats'
						element={<Stats />}
					/>
				</Route>
				<Route
					path='*'
					element={<NotFound />}
				/>
			</Routes>
		</BrowserRouter>
	);
}
