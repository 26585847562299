import axios from 'axios';
import {useState} from 'react';
import Modal from 'react-modal';
import {toast} from 'react-toastify';
import Select from 'react-select';
import {settingsStylesDark} from '../../data/styles/settingsStylesDark';
import {settingsStyles} from '../../data/styles/settingsStyles';
import usePreferencesStore from '../../stores/preferences.store';
import useModalStore from '../../stores/modal.store';
import useUserStore from '../../stores/user.store';
import useGlobalStore from '../../stores/global.store';

export default function MoveFolderModal(props) {
	const [selectedFolder, selectFolder] = useState(null);
	const { darkMode } = usePreferencesStore();
	const { toggleModal } = useModalStore();
	const { folders } = useUserStore();
	const { savedPosts, setSavedPosts } = useUserStore();
	const { pendingMove, debug } = useGlobalStore();

	const editPost = () => {
		try {
			if (!selectedFolder) {
				return;
			}
			const ids = pendingMove.selected ?? [pendingMove.item._id];
			axios.put(`/folders/move`, {ids, folder: selectedFolder.value}).then((response) => {
				if (response.status === 200) {
					const updatedPosts = savedPosts.map((post) => {
						if (ids.includes(post._id)) {
							return {...post, folder: selectedFolder.value};
						}
						return post;
					});
					setSavedPosts(updatedPosts);
					toggleModal('move-folder');
					toast.success(pendingMove.selected ? 'Posts moved to folder!' : 'Post moved to folder!', {autoClose: 1500});
				}
			}).catch((e) => {
				toast.error('Failed to move one or more posts to a folder');
				if (debug) {
					console.log(e);
				}
			});
		} catch (e) {
			if (debug) {
				console.log(e);
			}
		}
	};

	const folderOptions = folders.map((folder) => {
		return {value: folder._id, label: folder.name};
	});
	return (
		<Modal
			isOpen={true}
			onRequestClose={() => toggleModal('move-folder')}
			shouldCloseOnOverlayClick={true}
			shouldCloseOnEsc={true}>
			<div
				className='modal'
				onClick={(e) => props.closeModal(e, 'move-folder')}>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>Move to Folder</h5>
							<span
								className='close-modal'
								onClick={() => toggleModal('move-folder')}>
								X
							</span>
						</div>
						<div className='modal-body allow-overflow'>
							<Select
								value={selectedFolder}
								onChange={(selectedOption) => {
									selectFolder(selectedOption);
								}}
								options={folderOptions}
								isSearchable={false}
								styles={darkMode ? settingsStylesDark : settingsStyles}
							/>
						</div>
						<div className='modal-footer'>
							<button
								type='button'
								onClick={() => toggleModal('move-folder')}
								className='btn btn-primary secondary'
								data-dismiss='modal'>
								Cancel
							</button>
							<button
								type='submit'
								onClick={editPost}
								className='btn btn-primary'>
								Move
							</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}
