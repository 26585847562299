import { create } from 'zustand';

interface PostStore {
    title: string;
    subtitle: string;
    thirdTitle: string;
    description: string;
    team: string[];
    name: string[];
    stat: string[];
    points: string[];
    ranks: string[];
    image: string[];
    teamsplits: any;
    floatingTexts: any;
    nameOverrides: any;
    statOverrides: any;
    nameHighlights: any;
    statHighlights: any;
    nflRanks: any;
    espnRanks: any;
    proRanks: any;
    opponents: any;
    oppRanks: any;
    combined: boolean;
    setTitle: (newValue: string) => void;
    setSubtitle: (newValue: string) => void;
    setThirdTitle: (newValue: string) => void;
    setDescription: (newValue: string) => void;
    setTeam: (newValue: string[]) => void;
    setName: (newValue: string[]) => void;
    setStat: (newValue: string[]) => void;
    setPoints: (newValue: string[]) => void;
    setRanks: (newValue: string[]) => void;
    setImage: (newValue: string[]) => void;
    setTeamSplits: (newValue: any) => void;
    setFloatingTexts: (newValue: any) => void;
    setNameOverrides: (newValue: any) => void;
    setStatOverrides: (newValue: any) => void;
    setNameHighlights: (newValue: any) => void;
    setStatHighlights: (newValue: any) => void;
    setNflRanks: (newValue: any) => void;
    setEspnRanks: (newValue: any) => void;
    setProRanks: (newValue: any) => void;
    setOpponents: (newValue: any) => void;
    setOppRanks: (newValue: any) => void;
    setCombined: (newValue: boolean) => void;
    reset: () => void;
}

const usePostStore = create<PostStore>((set) => ({
    title: '',
    subtitle: '',
    thirdTitle: '',
    description: '',
    team: [],
    name: [],
    stat: [],
    points: [],
    ranks: [],
    image: [],
    teamsplits: [],
    floatingTexts: [],
    nameOverrides: [],
    statOverrides: [],
    nameHighlights: [],
    statHighlights: [],
    nflRanks: [],
    espnRanks: [],
    proRanks: [],
    opponents: [],
    oppRanks: [],
    combined: false,
    setTitle: (newValue) => set({ title: newValue }),
    setSubtitle: (newValue) => set({ subtitle: newValue }),
    setThirdTitle: (newValue) => set({ thirdTitle: newValue }),
    setDescription: (newValue) => set({ description: newValue }),
    setTeam: (newValue) => set({ team: newValue }),
    setName: (newValue) => set({ name: newValue }),
    setStat: (newValue) => set({ stat: newValue }),
    setPoints: (newValue) => set({ points: newValue }),
    setRanks: (newValue) => set({ ranks: newValue }),
    setImage: (newValue) => set({ image: newValue }),
    setTeamSplits: (newValue) => set({ teamsplits: newValue }),
    setFloatingTexts: (newValue) => set({ floatingTexts: newValue }),
    setNameOverrides: (newValue) => set({ nameOverrides: newValue }),
    setStatOverrides: (newValue) => set({ statOverrides: newValue }),
    setNameHighlights: (newValue) => set({ nameHighlights: newValue }),
    setStatHighlights: (newValue) => set({ statHighlights: newValue }),
    setNflRanks: (newValue) => set({ nflRanks: newValue }),
    setEspnRanks: (newValue) => set({ espnRanks: newValue }),
    setProRanks: (newValue) => set({ proRanks: newValue }),
    setOpponents: (newValue) => set({ opponents: newValue }),
    setOppRanks: (newValue) => set({ oppRanks: newValue }),
    setCombined: (newValue) => set({ combined: newValue }),
    reset: () => set(() => ({
        title: '',
        subtitle: '',
        thirdTitle: '',
        description: '',
        team: [],
        name: [],
        stat: [],
        points: [],
        ranks: [],
        image: [],
        teamsplits: [],
        floatingTexts: [],
        nameOverrides: [],
        statOverrides: [],
        nameHighlights: [],
        statHighlights: [],
        nflRanks: [],
        espnRanks: [],
        proRanks: [],
        opponents: [],
        oppRanks: [],
        combined: false
    }))
}));

export default usePostStore;
