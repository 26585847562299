import { create } from 'zustand';

interface WithImageStore {
    newstitle: string;
    newssubtitle: string;
    newsimage: string;
    newsteam: string;
    newsimageheight: number;
    newsimagewidth: number;
    setNewsTitle: (newValue: string) => void;
    setNewsSubtitle: (newValue: string) => void;
    setNewsImage: (newValue: string) => void;
    setNewsTeam: (newValue: string) => void;
    setNewsImageHeight: (newValue: number) => void;
    setNewsImageWidth: (newValue: number) => void;
    reset: () => void;
}

const useWithImageStore = create<WithImageStore>((set) => ({
    newstitle: '',
    newssubtitle: '',
    newsteam: '',
    newsimage: '',
    newsimageheight: 800,
    newsimagewidth: 800,
    setNewsTitle: (newValue: string) => set({ newstitle: newValue }),
    setNewsSubtitle: (newValue: string) => set({ newssubtitle: newValue }),
    setNewsImage: (newValue: string) => set({ newsimage: newValue }),
    setNewsTeam: (newValue: string) => set({ newsteam: newValue }),
    setNewsImageHeight: (newValue: number) => set({ newsimageheight: newValue }),
    setNewsImageWidth: (newValue: number) => set({ newsimagewidth: newValue }),
    reset: () => set(() => ({
        newstitle: '',
        newssubtitle: '',
        newsteam: '',
        newsimage: '',
        newsimageheight: 800,
        newsimagewidth: 800
    }))
}));

export default useWithImageStore;
