import {useEffect, useState} from 'react';
import {nbaTeams, nflTeams} from '../../data/teams/variables';
import {ToastContainer, toast} from 'react-toastify';
import * as htmlToImage from 'html-to-image';
import AverageChart from '../../components/AverageChart';
import axios from 'axios';

export default function HigherLower(props) {
	const [data, setData] = useState();
	const [playerId, setPlayerId] = useState('');
	const [prop, setProp] = useState('');
	const [stat, setStat] = useState('fantasypts');
	const [sport, setSport] = useState('nfl');
	const [playerObjs, setPlayerObjs] = useState([]);
	const [allPlayerIds, setAllPlayerIds] = useState([]);

	useEffect(() => {
		axios.get(`/players/playerIds`).then((response) => {
			setAllPlayerIds(response.data);
		});
	}, []);

	useEffect(() => {
		if (sport && allPlayerIds.length) {
			const p = allPlayerIds
				.filter((a) => {
					if (sport === 'nfl') {
						return a.sport === sport && a.skillPosition;
					}
					return a.sport === sport;
				}).map((b) => {
					return {
						label: b.name,
						value: b.id,
					};
				});
			const objs = p.sort((a, b) => a.label.localeCompare(b.label));
			setPlayerObjs(objs);
			setPlayerId(objs[0].value);
		}
	}, [sport, allPlayerIds]);

	const download = async () => {
		toast.success('Downloading..', {autoClose: 1500});
		htmlToImage
			.toJpeg(document.getElementById('downloadMe'), {height: 1280, width: 720, quality: 1})
			.then((dataUrl) => {
				let link = document.createElement('a');
				link.download = `higherLower.jpeg`;
				link.href = dataUrl;
				link.click();
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getTeamObj = (team) => {
		team = team.toUpperCase();
		let teams = [];
		if (sport === 'nfl') teams = JSON.parse(JSON.stringify(nflTeams));
		else if (sport === 'nba') teams = JSON.parse(JSON.stringify(nbaTeams));
		let returnTeam = teams.find((obj) => {
			return obj.abbreviation === team || obj.full === team || obj.abbreviation2 === team || obj.full2 === team || obj.abbreviation3 === team;
		});
		if (returnTeam) {
			returnTeam.logo = returnTeam.logos[0];
		}
		return returnTeam;
	};

	const getPlayerStats = () => {
		if (!sport || !stat || !playerId) return;
		axios
			.get(`/stats/${sport}/player/${stat}/${playerId}`)
			.then((response) => {
				if (response.data) {
					setData(response.data);
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const getAverage = () => {
		const values = data.points.slice(0, 5);
		return Number(values.reduce((a, b) => a + b, 0) / values.length).toFixed(1);
	};

	const getOppRankColor = () => {
		let nonOrdinal = data.oppRank.replace(/[^0-9]/g, '');
		if (nonOrdinal < 10) {
			return {color: '#FF0000'};
		} else if (nonOrdinal > 22) {
			return {color: '#00FF00'};
		}
		return {color: '#ffffff'};
	};

	const teamObj = data ? getTeamObj(data.team) : null;
	return (
		<div className='HigherLower'>
			<ToastContainer />
			<select
				name='sport'
				id='sport'
				onChange={(e) => {
					if (e.target.value === 'nba') {
						setStat('points')
					} else if (e.target.value === 'nfl') {
						setStat('fantasypts')
					}
					setSport(e.target.value);
				}}>
				<option value='nfl'>NFL</option>
				<option value='nba'>NBA</option>
			</select>
			<select
				name='stat'
				id='stat'
				onChange={(e) => {
					setStat(e.target.value);
				}}>
				{sport === 'nfl' && <option value='fantasypts'>Fantasy Points</option>}
				{sport === 'nfl' && <option value='passyards'>Pass Yards</option>}
				{sport === 'nfl' && <option value='rushyards'>Rush Yards</option>}
				{sport === 'nfl' && <option value='recyards'>Rec Yards</option>}
				{sport === 'nba' && <option value='points'>Points</option>}
			</select>
			<select
				name='playerOptions'
				id='playerOptions'
				onChange={(e) => {
					setPlayerId(e.target.value);
				}}>
				{playerObjs.map((obj, i) => {
					return (
						<option
							key={i}
							value={obj.value}>
							{obj.label}
						</option>
					);
				})}
			</select>
			<textarea
				placeholder='Prop'
				rows='1'
				cols='45'
				id='prop'
				type='text'
				value={prop}
				onChange={(event) => {
					setProp(event.target.value);
				}}
			/>
			<button
				className='newButton'
				onClick={getPlayerStats}>
				Generate
			</button>
			{data && (
				<button
					className='newButton downloadImageBtn'
					onClick={download}>
					Download
				</button>
			)}
			{data && teamObj && (
				<div className='main'>
					<div style={{margin: '10px'}}>
						<div
							className='higherLower'
							id='downloadMe'>
							<div className='highertitle'>Higher or Lower</div>
							<div className='highersubtitle'>{data.player}</div>
							<div
								className='higherteam'
								style={{background: `linear-gradient(to bottom, #000000 0%, ${teamObj.primary} 50%, #000000 100%)`}}>
								<div
									className='higherimage'
									style={{backgroundImage: teamObj.logo}}></div>
								<div className='playerimagediv'>
									<img
										src={`https://a.espncdn.com/i/headshots/${sport}/players/full/${data.player_id}.png`}
										alt=''
										className='playerimage'></img>
								</div>
							</div>
							<div className='pollBorder'>
								{(stat === 'fantasypts' || stat === 'points') && <span className='propQuestion'>{`Higher or lower than ${prop} points?`}</span>}
								{stat === 'passyards'&& <span className='propQuestion'>{`Higher or lower than ${prop} passing yards?`}</span>}
								{stat === 'rushyards'&& <span className='propQuestion'>{`Higher or lower than ${prop} rushing yards?`}</span>}
								{stat === 'recyards'&& <span className='propQuestion'>{`Higher or lower than ${prop} receiving yards?`}</span>}
							</div>
							<div className='bottomhalf'>
								<div className='chartTitle'>Last 5 Games</div>
								<div className='chartTitle'>{`Average ${getAverage()} pts`}</div>
								<AverageChart
									teamObj={teamObj}
									labels={data.weeks.slice(0, 5).reverse()}
									data={data.points.slice(0, 5).reverse()}
									average={prop}
									prop={prop}
								/>
								{data.opponent && (
									<div className='chartFooter'>
										Opponent Rank:
										<span style={getOppRankColor()}> {data.oppRank}</span>
										{` (${data.opponent})`}
									</div>
								)}
							</div>
							<div className='higherFooter'>
							</div>
							{/* <div className='ad'>
								#ad
							</div> */}
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
