export const skaterStats = [
	{
	  label: "Goals",
	  value: "nhlplayerstats/offensive-goals",
	},
	{
	  label: "Assists",
	  value: "nhlplayerstats/offensive-assists",
	},
	{
	  label: "Points",
	  value: "nhlplayerstats/offensive-points",
	},
	{
	  label: "Power Play Goals",
	  value: "nhlplayerstats/offensive-powerplaygoals",
	},
	{
	  label: "Power Play Assists",
	  value: "nhlplayerstats/offensive-powerplayassists",
	},
	{
	  label: "Shots",
	  value: "nhlplayerstats/offensive-shotstotal",
	},
	{
	  label: "Shooting Percentage",
	  value: "nhlplayerstats/offensive-shootingpct",
	},
	{
	  label: "Game-Winning Goals",
	  value: "nhlplayerstats/offensive-gamewinninggoals",
	},
	{
	  label: "Faceoffs Won",
	  value: "nhlplayerstats/offensive-faceoffswon",
	},
	{
	  label: "Faceoffs Lost",
	  value: "nhlplayerstats/offensive-faceoffslost",
	},
	{
	  label: "Faceoff Win Percent",
	  value: "nhlplayerstats/offensive-faceoffpercent",
	},
	{
	  label: "Shootout Attempts",
	  value: "nhlplayerstats/offensive-shootoutattempts",
	},
	{
	  label: "Shootout Goals",
	  value: "nhlplayerstats/offensive-shootoutgoals",
	},
	{
	  label: "Shootout Shot Percentage",
	  value: "nhlplayerstats/offensive-shootoutshotpct",
	},
  ];

  export const goalieStats = [
	{
	  label: "Overtime Losses",
	  value: "nhlplayerstats/defensive-overtimelosses",
	},
	{
	  label: "Goals Against per Game",
	  value: "nhlplayerstats/defensive-avggoalsagainst",
	},
	{
	  label: "Shots Against",
	  value: "nhlplayerstats/defensive-shotsagainst",
	},
	{
	  label: "Goals Against",
	  value: "nhlplayerstats/defensive-goalsagainst",
	},
	{
	  label: "Saves",
	  value: "nhlplayerstats/defensive-saves",
	},
	{
	  label: "Save Percentage",
	  value: "nhlplayerstats/defensive-savepct",
	},
	{
	  label: "Shootout Shots Against",
	  value: "nhlplayerstats/defensive-shootoutshotsagainst",
	},
	{
	  label: "Shootout Saves",
	  value: "nhlplayerstats/defensive-shootoutsaves",
	},
	{
	  label: "Shootout Save Percentage",
	  value: "nhlplayerstats/defensive-shootoutsavepct",
	},
	{
	  label: "Shutouts",
	  value: "nhlplayerstats/defensive-shutouts",
	},
  ];