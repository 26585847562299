export const nhlFull = [
	'Anaheim Ducks',
	'Boston Bruins',
	'Buffalo Sabres',
	'Calgary Flames',
	'Carolina Hurricanes',
	'Chicago Blackhawks',
	'Colorado Avalanche',
	'Columbus Blue Jackets',
	'Dallas Stars',
	'Detroit Red Wings',
	'Edmonton Oilers',
	'Florida Panthers',
	'Los Angeles Kings',
	'Minnesota Wild',
	'Montreal Canadiens',
	'Nashville Predators',
	'New Jersey Devils',
	'New York Islanders',
	'New York Rangers',
	'Ottawa Senators',
	'Philadelphia Flyers',
	'Pittsburgh Penguins',
	'San Jose Sharks',
	'Seattle Kraken',
	'St Louis Blues',
	'Tampa Bay Lightning',
	'Toronto Maple Leafs',
	'Utah Hockey Club',
	'Vancouver Canucks',
	'Vegas Golden Knights',
	'Washington Capitals',
	'Winnipeg Jets',
];

export const nhlAbbrs = [
	'ANA',
	'BOS',
	'BUF',
	'CGY',
	'CAR',
	'CHI',
	'COL',
	'CBJ',
	'DAL',
	'DET',
	'EDM',
	'FLA',
	'LA',
	'MIN',
	'MTL',
	'NSH',
	'NJ',
	'NYI',
	'NYR',
	'OTT',
	'PHI',
	'PIT',
	'SJ',
	'SEA',
	'STL',
	'TB',
	'TOR',
	'UTAH',
	'VAN',
	'VGK',
	'WSH',
	'WPG',
	'NHL',
];

export const nflFull = [
	'Arizona Cardinals',
	'Atlanta Falcons',
	'Baltimore Ravens',
	'Buffalo Bills',
	'Carolina Panthers',
	'Chicago Bears',
	'Cincinnati Bengals',
	'Cleveland Browns',
	'Dallas Cowboys',
	'Denver Broncos',
	'Detroit Lions',
	'Green Bay Packers',
	'Houston Texans',
	'Indianapolis Colts',
	'Jacksonville Jaguars',
	'Kansas City Chiefs',
	'Las Vegas Raiders',
	'Los Angeles Chargers',
	'Los Angeles Rams',
	'Miami Dolphins',
	'Minnesota Vikings',
	'New England Patriots',
	'New Orleans Saints',
	'New York Giants',
	'New York Jets',
	'Philadelphia Eagles',
	'Pittsburgh Steelers',
	'San Francisco 49ers',
	'Seattle Seahawks',
	'Tampa Bay Buccaneers',
	'Tennessee Titans',
	'Washington Commanders',
];

export const nflAbbrs = [
	'ARI',
	'ATL',
	'BAL',
	'BUF',
	'CAR',
	'CHI',
	'CIN',
	'CLE',
	'DAL',
	'DEN',
	'DET',
	'GB',
	'HOU',
	'IND',
	'JAX',
	'KC',
	'LV',
	'LAC',
	'LAR',
	'MIA',
	'MIN',
	'NE',
	'NO',
	'NYG',
	'NYJ',
	'PHI',
	'PIT',
	'SF',
	'SEA',
	'TB',
	'TEN',
	'WAS',
	'NFL',
	'NFC',
	'AFC',
];

export const nbaFull = [
	'Atlanta Hawks',
	'Boston Celtics',
	'Brooklyn Nets',
	'Charlotte Hornets',
	'Chicago Bulls',
	'Cleveland Cavaliers',
	'Dallas Mavericks',
	'Denver Nuggets',
	'Detroit Pistons',
	'Golden State Warriors',
	'Houston Rockets',
	'Indiana Pacers',
	'Los Angeles Clippers',
	'Los Angeles Lakers',
	'Memphis Grizzlies',
	'Miami Heat',
	'Milwaukee Bucks',
	'Minnesota Timberwolves',
	'New Orleans Pelicans',
	'New York Knicks',
	'Oklahoma City Thunder',
	'Orlando Magic',
	'Philadelphia 76ers',
	'Phoenix Suns',
	'Portland Trail Blazers',
	'Sacramento Kings',
	'San Antonio Spurs',
	'Toronto Raptors',
	'Utah Jazz',
	'Washington Wizards',
];

export const nbaAbbrs = ['ATL', 'BOS', 'BRK', 'CHA', 'CHI', 'CLE', 'DAL', 'DEN', 'DET', 'GS', 'HOU', 'IND', 'LAC', 'LAL', 'MEM', 'MIA', 'MIL', 'MIN', 'NO', 'NY', 'OKC', 'ORL', 'PHI', 'PHX', 'POR', 'SAC', 'SA', 'TOR', 'UTA', 'WAS', 'NBA'];

export const mlbFull = [
	'Arizona Diamondbacks',
	'Atlanta Braves',
	'Baltimore Orioles',
	'Boston Red Sox',
	'Chicago White Sox',
	'Chicago Cubs',
	'Cincinnati Reds',
	'Cleveland Guardians',
	'Colorado Rockies',
	'Detroit Tigers',
	'Houston Astros',
	'Kansas City Royals',
	'Los Angeles Angels',
	'Los Angeles Dodgers',
	'Miami Marlins',
	'Milwaukee Brewers',
	'Minnesota Twins',
	'New York Yankees',
	'New York Mets',
	'Athletics',
	'Philadelphia Phillies',
	'Pittsburgh Pirates',
	'San Diego Padres',
	'San Francisco Giants',
	'Seattle Mariners',
	'St. Louis Cardinals',
	'Tampa Bay Rays',
	'Texas Rangers',
	'Toronto Blue Jays',
	'Washington Nationals',
];

export const mlbAbbrs = ['ARI', 'ATL', 'BAL', 'BOS', 'CHW', 'CHC', 'CIN', 'CLE', 'COL', 'DET', 'HOU', 'KC', 'LAA', 'LAD', 'MIA', 'MIL', 'MIN', 'NYY', 'NYM', 'OAK', 'PHI', 'PIT', 'SD', 'SF', 'SEA', 'STL', 'TB', 'TEX', 'TOR', 'WAS', 'MLB', 'AL', 'NL'];

export const f1Full = ['Red Bull Racing', 'Ferrari', 'Mercedes', 'Alpine', 'McLaren', 'Alfa Romeo', 'Haas F1 Team', 'AlphaTauri', 'Aston Martin', 'Williams'];

export const f1Abbrs = ['RED', 'FER', 'MER', 'ALPN', 'MCL', 'ALF', 'HAAS', 'ALPH', 'AST', 'WIL'];

export const wnbaFull = ['Atlanta Dream', 'Chicago Sky', 'Connecticut Sun', 'Dallas Wings', 'Indiana Fever', 'Las Vegas Aces', 'Los Angeles Sparks', 'Minnesota Lynx', 'New York Liberty', 'Phoenix Mercury', 'Seattle Storm', 'Washington Mystics'];

export const wnbaAbbrs = ['ATL', 'CHI', 'CONN', 'DAL', 'IND', 'LV', 'LA', 'MIN', 'NY', 'PHX', 'SEA', 'WSH'];

export const aflFull = [
	'Adelaide Crows',
	'Brisbane Lions',
	'Carlton Blues',
	'Collingwood Magpies',
	'Essendon Bombers',
	'Fremantle Dockers',
	'Gold Coast Suns',
	'Geelong Cats',
	'Greater Western Sydney Giants',
	'Hawthorn Hawks',
	'Melbourne Demons',
	'North Melbourne Kangaroos',
	'Port Adelaide Power',
	'Richmond Tigers',
	'St Kilda Saints',
	'Sydney Swans',
	'Western Bulldogs',
	'West Coast Eagles',
];

export const aflAbbrs = ['ADEL', 'BL', 'CARL', 'COLL', 'ESS', 'FRE', 'GCFC', 'GEEL', 'GWS', 'HAW', 'MELB', 'NMFC', 'PORT', 'RICH', 'STK', 'SYD', 'WB', 'WCE'];

export const ncaaFull = [
	'Abilene Christian',
	'Air Force',
	'Akron',
	'Alabama',
	'Alabama A&M',
	'Alabama State',
	'Albany',
	'Alcorn State',
	'American',
	'Appalachian State',
	'Arizona',
	'Arizona State',
	'Arkansas',
	'Arkansas State',
	'Arkansas-Pine Bluff',
	'Army',
	'Auburn',
	'Austin Peay',
	'Ball State',
	'Baylor',
	'Bellarmine',
	'Belmont',
	'Bethune-Cookman',
	'Binghamton',
	'Boise State',
	'Boston',
	'Boston College',
	'Bowling Green',
	'Bradley',
	'Brigham Young',
	'Brown',
	'Bryant',
	'Bucknell',
	'Buffalo',
	'Butler',
	'Cal',
	'Cal Poly',
	'California Baptist',
	'Campbell',
	'Canisius',
	'Central Arkansas',
	'Central Connecticut',
	'Central Michigan',
	'Charleston',
	'Charleston Southern',
	'Charlotte',
	'Chattanooga',
	'Chicago State',
	'Cincinnati',
	'Clemson',
	'Cleveland State',
	'Coastal Carolina',
	'Colgate',
	'Colorado',
	'Colorado State',
	'Columbia',
	'Coppin State',
	'Cornell',
	'Creighton',
	'CSU Bakersfield',
	'CSU Fullerton',
	'CSU Northridge',
	'Dartmouth',
	'Davidson',
	'Dayton',
	'Delaware',
	'Delaware State',
	'Denver',
	'DePaul',
	'Detroit Mercy',
	'Utah Tech',
	'Drake',
	'Drexel',
	'Duke',
	'Duquesne',
	'East Carolina',
	'East Tennessee State',
	'Eastern Illinois',
	'Eastern Kentucky',
	'Eastern Michigan',
	'Eastern Washington',
	'Elon',
	'Evansville',
	'Fairfield',
	'Fairleigh Dickinson',
	'Florida',
	'Florida A&M',
	'Florida Atlantic',
	'Florida Gulf Coast',
	'Florida International University',
	'Florida State',
	'Fordham',
	'Fresno State',
	'Furman',
	'Gardner-Webb',
	'George Mason',
	'George Washington',
	'Georgetown',
	'Georgia',
	'Georgia Southern',
	'Georgia State',
	'Georgia Tech',
	'Gonzaga',
	'Grambling State',
	'Grand Canyon',
	'Green Bay',
	'Hampton',
	'Hartford',
	'Harvard',
	'Hawaii',
	'High Point',
	'Hofstra',
	'Holy Cross',
	'Houston',
	'Houston Christian',
	'Howard',
	'Idaho',
	'Idaho State',
	'Illinois',
	'Illinois State',
	'Incarnate Word',
	'Indiana',
	'Indiana State',
	'Indiana University–Purdue University Indianapolis',
	'Iona',
	'Iowa',
	'Iowa State',
	'Jackson State',
	'Jacksonville',
	'Jacksonville State',
	'James Madison',
	'Kansas',
	'Kansas State',
	'Kennesaw State',
	'Kent State',
	'Kentucky',
	'La Salle',
	'Lafayette',
	'Lamar',
	'Lehigh',
	'Liberty',
	'Lipscomb',
	'Little Rock',
	'Long Beach State',
	'Long Island University',
	'Longwood',
	'Louisiana Lafayette',
	'Louisiana Monroe',
	'Louisiana State University',
	'Louisiana Tech',
	'Louisville',
	'Loyola (MD)',
	'Loyola Chicago',
	'Loyola Marymount',
	'Maine',
	'Manhattan',
	'Marist',
	'Marquette',
	'Marshall',
	'Maryland',
	'Maryland-Eastern Shore',
	'McNeese State',
	'Memphis',
	'Mercer',
	'Merrimack',
	'Miami',
	'Miami (OH)',
	'Michigan',
	'Michigan State',
	'Middle Tennessee',
	'Milwaukee',
	'Minnesota',
	'Mississippi State',
	'Mississippi Valley State',
	'Missouri',
	'Missouri State',
	'Monmouth',
	'Montana',
	'Montana State',
	'Morehead State',
	'Morgan State',
	"Mount St. Mary's",
	'Murray State',
	'Navy',
	'NC Central',
	'NC State',
	'Nebraska',
	'Nevada',
	'New Hampshire',
	'New Jersey Institute of Technology',
	'New Mexico',
	'New Mexico State',
	'New Orleans',
	'Niagara',
	'Nicholls State',
	'Norfolk State',
	'North Alabama',
	'North Carolina',
	'North Carolina A&T',
	'North Dakota',
	'North Dakota State',
	'North Florida',
	'North Texas',
	'Northeastern',
	'Northern Arizona',
	'Northern Colorado',
	'Northern Illinois',
	'Northern Iowa',
	'Northern Kentucky',
	'Northwestern',
	'Northwestern State',
	'Notre Dame',
	'Oakland',
	'Ohio',
	'Ohio State',
	'Oklahoma',
	'Oklahoma State',
	'Old Dominion',
	'Ole Miss',
	'Omaha',
	'Oral Roberts',
	'Oregon',
	'Oregon State',
	'Pacific',
	'Penn State',
	'Pepperdine',
	'Pittsburgh',
	'Portland',
	'Portland State',
	'Prairie View A&M',
	'Presbyterian',
	'Princeton',
	'Providence',
	'Purdue',
	'Purdue Fort Wayne',
	'Quinnipiac',
	'Radford',
	'Rhode Island',
	'Rice',
	'Richmond',
	'Rider',
	'Robert Morris (PA)',
	'Rutgers',
	'Sacramento State',
	'Sacred Heart',
	"Saint Joseph's",
	'Saint Louis',
	"Saint Mary's",
	"Saint Peter's",
	'Sam Houston State',
	'Samford',
	'San Diego',
	'San Diego State',
	'San Francisco',
	'San José State',
	'Santa Clara',
	'Seattle U',
	'Seton Hall',
	'Siena',
	'SIU Edwardsville',
	'South Alabama',
	'South Carolina',
	'South Carolina State',
	'South Carolina Upstate',
	'South Dakota',
	'South Dakota State',
	'South Florida',
	'Southeast Missouri',
	'Southeastern Louisiana',
	'Southern Illinois',
	'Southern Methodist University',
	'Southern Miss',
	'Southern University',
	'Southern Utah',
	'St. Bonaventure',
	'St. Francis (BKN)',
	'St. Francis (PA)',
	"St. John's",
	'Stanford',
	'Stephen F. Austin',
	'Stetson',
	'Stony Brook',
	'Syracuse',
	'Tarleton',
	'Temple',
	'Tennessee',
	'Tennessee State',
	'Tennessee Tech',
	'Tennessee-Martin',
	'Texas',
	'Texas A&M',
	'Texas A&M-CC',
	'Texas Christian',
	'Texas Southern',
	'Texas State',
	'Texas Tech',
	'The Citadel',
	'Toledo',
	'Towson',
	'Troy',
	'Tulane',
	'Tulsa',
	'UC Davis',
	'UC Irvine',
	'UC Riverside',
	'UC San Diego',
	'UC Santa Barbara',
	'UConn',
	'UM Kansas City',
	'UMass',
	'UMASS Lowell',
	'UNC Asheville',
	'UNC Greensboro',
	'UNC Wilmington',
	'University of Alabama at Birmingham',
	'University of California Los Angeles',
	'University of Central Florida',
	'University of Illinois at Chicago',
	'University of Maryland Baltimore County',
	'University of Nevada Las Vegas',
	'University of Pennsylvania',
	'University of Southern California',
	'University of Texas at El Paso',
	'University of Texas at San Antonio',
	'UT Arlington',
	'UT Rio Grande Valley',
	'Utah',
	'Utah State',
	'Utah Valley',
	'Valparaiso',
	'Vanderbilt',
	'Vermont',
	'Villanova',
	'Virginia',
	'Virginia Commonwealth',
	'Virginia Military',
	'Virginia Tech',
	'Wagner',
	'Wake Forest',
	'Washington',
	'Washington State',
	'Weber State',
	'West Virginia',
	'Western Carolina',
	'Western Illinois',
	'Western Kentucky',
	'Western Michigan',
	'Wichita State',
	'William & Mary',
	'Winthrop',
	'Wisconsin',
	'Wofford',
	'Wright State',
	'Wyoming',
	'Xavier',
	'Yale',
	'Youngstown State',
	'Lindenwood',
	'Queens University',
	'Southern Indiana',
	'Stonehill',
	'East Texas A&M',
	'Le Moyne',
	'West Georgia',
	'Mercyhurst'
];

export const ncaaAbbrs = [
	'ACU',
	'AFA',
	'AKR',
	'ALA',
	'AAMU',
	'ALST',
	'ALB',
	'ALCN',
	'AMER',
	'APP',
	'ARIZ',
	'ASU',
	'ARK',
	'ARST',
	'UAPB',
	'ARMY',
	'AUB',
	'APSU',
	'BALL',
	'BAY',
	'BELL',
	'BEL',
	'BCU',
	'BING',
	'BSU',
	'BU',
	'BC',
	'BGSU',
	'BRAD',
	'BYU',
	'BRWN',
	'BRY',
	'BUCK',
	'BUFF',
	'BUT',
	'CAL',
	'CP',
	'CBU',
	'CAM',
	'CAN',
	'UCA',
	'CCSU',
	'CMU',
	'COFC',
	'CHSO',
	'CHAR',
	'UTC',
	'CHIC',
	'CIN',
	'CLEM',
	'CLEV',
	'CCU',
	'COLG',
	'COLO',
	'CSU',
	'CLMB',
	'COPP',
	'COR',
	'CREI',
	'CSUB',
	'CSUF',
	'CSUN',
	'DART',
	'DAV',
	'DAY',
	'DEL',
	'DSU',
	'DEN',
	'DEP',
	'DET',
	'UTU',
	'DRKE',
	'DREX',
	'DUKE',
	'DUQ',
	'ECU',
	'ETSU',
	'EIU',
	'EKU',
	'EMU',
	'EWU',
	'ELON',
	'EVAN',
	'FAIR',
	'FDU',
	'FLA',
	'FAMU',
	'FAU',
	'FGCU',
	'FIU',
	'FSU',
	'FOR',
	'FRES',
	'FUR',
	'GWEB',
	'GMU',
	'GW',
	'GTWN',
	'UGA',
	'GASO',
	'GAST',
	'GT',
	'GONZ',
	'GRAM',
	'GCU',
	'GB',
	'HAMP',
	'HART',
	'HARV',
	'HAW',
	'HP',
	'HOF',
	'HC',
	'HOU',
	'HCU',
	'HOW',
	'IDHO',
	'IDST',
	'ILL',
	'ILST',
	'UIW',
	'IND',
	'INST',
	'IUPU',
	'IONA',
	'IOWA',
	'ISU',
	'JKST',
	'JAX',
	'JVST',
	'JMU',
	'KU',
	'KSU',
	'KENN',
	'KENT',
	'UK',
	'LAS',
	'LAF',
	'LAM',
	'LEH',
	'LIB',
	'LIP',
	'UALR',
	'LBSU',
	'LIU',
	'LONG',
	'ULL',
	'ULM',
	'LSU',
	'LT',
	'LOU',
	'L-MD',
	'LUC',
	'LMU',
	'ME',
	'MAN',
	'MRST',
	'MARQ',
	'MRSH',
	'MD',
	'UMES',
	'MCNS',
	'MEM',
	'MER',
	'MRMK',
	'MIA',
	'M-OH',
	'MICH',
	'MSU',
	'MTSU',
	'MILW',
	'MINN',
	'MSST',
	'MVSU',
	'MIZ',
	'MOST',
	'MONM',
	'MONT',
	'MTST',
	'MORE',
	'MORG',
	'MSM',
	'MUR',
	'NAVY',
	'NCCU',
	'NCST',
	'NEB',
	'NEV',
	'UNH',
	'NJIT',
	'UNM',
	'NMSU',
	'UNO',
	'NIAG',
	'NICH',
	'NORF',
	'UNA',
	'UNC',
	'NCAT',
	'UND',
	'NDSU',
	'UNF',
	'UNT',
	'NE',
	'NAU',
	'UNCO',
	'NIU',
	'UNI',
	'NKU',
	'NW',
	'NWST',
	'ND',
	'OAK',
	'OHIO',
	'OSU',
	'OU',
	'OKST',
	'ODU',
	'MISS',
	'OMA',
	'ORU',
	'ORE',
	'ORST',
	'PAC',
	'PSU',
	'PEPP',
	'PITT',
	'PORT',
	'PRST',
	'PV',
	'PRES',
	'PRIN',
	'PROV',
	'PUR',
	'PFW',
	'QUIN',
	'RAD',
	'URI',
	'RICE',
	'RICH',
	'RID',
	'RMU',
	'RUTG',
	'SAC',
	'SHU',
	'JOES',
	'SLU',
	'SMC',
	'SPU',
	'SHSU',
	'SAM',
	'USD',
	'SDSU',
	'SF',
	'SJSU',
	'SCU',
	'SEA',
	'HALL',
	'SIE',
	'SIUE',
	'USA',
	'SC',
	'SCST',
	'SCUP',
	'SDAK',
	'SDST',
	'USF',
	'SEMO',
	'SELA',
	'SIU',
	'SMU',
	'USM',
	'SOU',
	'SUU',
	'SBU',
	'SFBK',
	'SFPA',
	'SJU',
	'STAN',
	'SFA',
	'STET',
	'STBK',
	'SYR',
	'TAR',
	'TEM',
	'TENN',
	'TNST',
	'TNTC',
	'UTM',
	'TEX',
	'TAMU',
	'AMCC',
	'TCU',
	'TXSO',
	'TXST',
	'TTU',
	'CIT',
	'TOL',
	'TOW',
	'TROY',
	'TUL',
	'TLSA',
	'UCD',
	'UCI',
	'UCR',
	'UCSD',
	'UCSB',
	'CONN',
	'UMKC',
	'UMASS',
	'UML',
	'UNCA',
	'UNCG',
	'UNCW',
	'UAB',
	'UCLA',
	'UCF',
	'UIC',
	'UMBC',
	'UNLV',
	'PENN',
	'USC',
	'UTEP',
	'UTSA',
	'UTA',
	'UTRGV',
	'UTAH',
	'USU',
	'UVU',
	'VAL',
	'VAN',
	'UVM',
	'VILL',
	'UVA',
	'VCU',
	'VMI',
	'VT',
	'WAG',
	'WAKE',
	'WASH',
	'WSU',
	'WEB',
	'WVU',
	'WCU',
	'WIU',
	'WKU',
	'WMU',
	'WICH',
	'WM',
	'WIN',
	'WIS',
	'WOF',
	'WRST',
	'WYO',
	'XAV',
	'YALE',
	'YSU',
	'LIN',
	'QUOC',
	'USI',
	'STO',
	'ETAM',
	'LEM',
	'UWG',
	'MERC'
];

export const soccerFull = [
	'Ajax Amsterdam',
	'Alavés',
	'Arsenal',
	'Atalanta',
	'Athletic Club',
	'Atletico Madrid',
	'Atlanta United FC',
	'Austin FC',
	'FC Augsburg',
	'Aston Villa',
	'Barcelona',
	'Benevento',
	'Real Betis',
	'Brighton & Hove Albion',
	'Bologna',
	'Club Brugge',
	'Burnley',
	'Cádiz',
	'Cagliari',
	'Celta Vigo',
	'Chelsea',
	'Chicago Fire FC',
	'FC Cincinnati',
	'Columbus Crew SC',
	'FC Cologne',
	'Colorado Rapids',
	'Crotone',
	'Crystal Palace',
	'FC Dallas',
	'DC United',
	'Borussia Dortmund',
	'Arminia Bielefeld',
	'Eibar',
	'Eintracht Frankfurt',
	'Elche',
	'Everton',
	'Krasnodar',
	'FC Union Berlin',
	'Ferencvaros',
	'Fiorentina',
	'SC Freiburg',
	'Fulham',
	'Granada',
	'Genoa',
	'Getafe',
	'Hertha Berlin',
	'Tsg Hoffenheim',
	'Houston Dynamo FC',
	'Huesca',
	'Istanbul Basaksehir',
	'Internazionale',
	'Juventus',
	'Dynamo Kiev',
	'La Galaxy',
	'Los Angeles FC',
	'Lazio',
	'Leeds United',
	'Leicester City',
	'Rb Leipzig',
	'Levante',
	'Bayer Leverkusen',
	'Liverpool',
	'Lokomotiv Moscow',
	'Real Madrid',
	'Mainz',
	'Manchester United',
	'Marseille',
	'Inter Miami CF',
	'Midtjylland',
	'AC Milan',
	'Minnesota United FC',
	'Manchester City',
	'Borussia Monchengladbach',
	'CF Montréal',
	'Bayern Munich',
	'Napoli',
	'New England Revolution',
	'Newcastle United',
	'Nashville SC',
	'New York Red Bulls',
	'New York City FC',
	'Olympiakos',
	'Orlando City SC',
	'Osasuna',
	'Parma',
	'Philadelphia Union',
	'Porto',
	'Portland Timbers',
	'Paris Saint-Germain',
	'Stade Rennes',
	'AS Roma',
	'Real Salt Lake',
	'Real Sociedad',
	'Salzburg',
	'Sampdoria',
	'Sassuolo',
	'Schalke 04',
	'Seattle Sounders FC',
	'Sevilla FC',
	'Shakhtar Donetsk',
	'Sheffield United',
	'San Jose Earthquakes',
	'Sporting Kansas City',
	'Southampton',
	'Spezia',
	'Vfb Stuttgart',
	'Torino',
	'Toronto FC',
	'Tottenham Hotspur',
	'Udinese',
	'Valencia',
	'Real Valladolid',
	'Vancouver Whitecaps',
	'Hellas Verona',
	'Villarreal',
	'West Bromwich Albion',
	'Werder Bremen',
	'West Ham United',
	'Wolverhampton Wanderers',
	'Vfb Stuttgart',
	'Zenit St Petersburg',
	'Lesotho',
	'Cape Verde Islands',
	'Tanzania',
	'Burkina Faso',
	'Gabon',
	'Mauritania',
	'Zambia',
	'Chad',
	'Gambia',
	'Burundi',
	'Guinea',
	'Mozambique',
	'Malawi',
	'Namibia',
	'Congo',
	'Benin',
	'Rwanda',
	'Kenya',
	'Libya',
	'Bhutan',
	'Heerenveen',
	'Portland Thorns FC',
	'Rangers',
	'Senegal',
	'Botswana',
	'Jablonec',
	'El Salvador',
	'Curacao',
	'Honduras',
	'Nicaragua',
	'Maccabi Haifa',
	'Algeria',
	'Qatar',
	'Mazatlán FC',
	'Galatasaray',
	'IR Iran',
	'Chicago Red Stars',
	'Trinidad and Tobago',
	'Yemen',
	'AZ Alkmaar',
	'Egypt',
	'Sudan',
	'Tunisia',
	'Jordan',
	'Syria',
	'Peru',
	'Costa Rica',
	'Necaxa',
	'NEC Nijmegen',
	'RKC Waalwijk',
	'Canada',
	'Netherlands',
	'Jamaica',
	'Brunei Darussalam',
	'LASK Linz',
	'Nepal',
	'HJK Helsinki',
	'Slovan Bratislava',
	'Doncaster Rovers',
	'Stade de Reims',
	'Andorra',
	'Vitesse',
	'Sunderland',
	'Tigres UANL',
	'Sweden',
	'América',
	'Turks and Caicos Islands',
	'Guam',
	'Puebla',
	'Santos',
	'Bermuda',
	'US Virgin Islands',
	'Laos',
	'Australia',
	'Belize',
	'England',
	'Sri Lanka',
	'Tajikistan',
	'Lens',
	'Antigua and Barbuda',
	'Sierra Leone',
	'United Arab Emirates',
	'Bahamas',
	'Stoke City',
	'Preston North End',
	'Palestine',
	'Grenada',
	'Madagascar',
	'Maldives',
	'North Korea',
	'Kazakhstan',
	'Willem II',
	'Red Star Belgrade',
	'Charlton Athletic',
	'Maccabi Tel-Aviv',
	'Bodo/Glimt',
	'Pachuca',
	'Querétaro',
	'Cambodia',
	'Finland',
	'Panama',
	'Russia',
	'Ukraine',
	'Greece',
	'Celtic',
	'Pumas UNAM',
	'Barnsley',
	'Huddersfield Town',
	'Walsall',
	'FC Twente',
	'Fortuna Sittard',
	'Stevenage',
	'Ecuador',
	'Argentina',
	'South Korea',
	'Paraguay',
	'Brazil',
	'Colombia',
	'Slovenia',
	'NJ/NY Gotham FC',
	'Niger',
	'Leyton Orient',
	'Guadalajara',
	'South Sudan',
	'Morocco',
	'Austria',
	'Djibouti',
	'India',
	'Racing Louisville FC',
	'Faroe Islands',
	'Queens Park Rangers',
	'Hartlepool United',
	'Colchester United',
	'Northampton Town',
	'Forest Green Rovers',
	'FC Zorya Luhansk',
	'AS Monaco',
	'Lille',
	'Brest',
	'Bristol City',
	'Middlesbrough',
	'Rotherham United',
	'Hull City',
	'Mansfield Town',
	'Swansea City',
	'Bolton Wanderers',
	'FC Copenhagen',
	'Mauritius',
	'Nigeria',
	'Zimbabwe',
	'Uzbekistan',
	'Nottingham Forest',
	'Derby County',
	'Millwall',
	'Scunthorpe United',
	'Guyana',
	'Shrewsbury Town',
	'Croatia',
	'Germany',
	'PSV Eindhoven',
	'Braga',
	'Sparta Rotterdam',
	'PEC Zwolle',
	'Myanmar',
	'Heracles Almelo',
	'FC Groningen',
	'Bristol Rovers',
	'Brøndby',
	'Tranmere Rovers',
	'Ivory Coast',
	'South Africa',
	'Northern Ireland',
	'Accrington Stanley',
	'Israel',
	'Sheffield Wednesday',
	'Feyenoord Rotterdam',
	'Slavia Prague',
	'Kairat Almaty',
	'Cheltenham Town',
	'Cambridge United',
	'Ipswich Town',
	'VfL Wolfsburg',
	'Go Ahead Eagles',
	'Plymouth Argyle',
	'Thailand',
	'Orlando Pride',
	'Houston Dash',
	'Flora',
	'Harrogate Town',
	'Carlisle United',
	'Suriname',
	'Liberia',
	'Mali',
	'Sao Tome e Principe',
	'Servette',
	'BK Häcken',
	'WFC Kharkiv',
	'HB Køge',
	'Gillingham',
	'Puerto Rico',
	'Togo',
	'Dominica',
	'St. Vincent and the Grenadines',
	'Congo DR',
	'Angola',
	'China',
	'United States',
	'Chinese Taipei',
	'Portsmouth',
	'Rochdale',
	'Lincoln City',
	'British Virgin Islands',
	'Cuba',
	'Blackburn Rovers',
	'Coventry City',
	'Portugal',
	'Racing Genk',
	'Fenerbahce',
	'Kansas City Current',
	'Swindon Town',
	'Newport County',
	'Guinea-Bissau',
	'St. Kitts and Nevis',
	'Kuwait',
	'Philippines',
	'Dominican Republic',
	'Iraq',
	'Anguilla',
	'Cayman Islands',
	'Montserrat',
	'Romania',
	'Poland',
	'Haiti',
	'Afghanistan',
	'Uganda',
	'Bangladesh',
	'Cameroon',
	'FC Utrecht',
	'Rapid Vienna',
	'Timor-Leste',
	'Scotland',
	'Japan',
	'Saudi Arabia',
	'Belgium',
	'North Macedonia',
	'Lithuania',
	'Georgia',
	'Barrow',
	'Atlético San Luis',
	'Wigan Athletic',
	'Tijuana',
	'Atlas',
	'Eswatini',
	'Liechtenstein',
	'Bulgaria',
	'Kyrgyz Republic',
	'Belarus',
	'Turkey',
	'Central African Republic',
	'Ghana',
	'Mexico',
	'Peterborough United',
	'Metz',
	'St. Lucia',
	'Oldham Athletic',
	'Mongolia',
	'Troyes',
	'North Carolina Courage',
	'Washington Spirit',
	'Macau',
	'Moldova',
	'Seychelles',
	'Ethiopia',
	'Crewe Alexandra',
	'Port Vale',
	'Lyon',
	'Milton Keynes Dons',
	'KAA Gent',
	'Randers FC',
	'Cardiff City',
	'Comoros Islands',
	'Barbados',
	'Eritrea',
	'Vietnam',
	'Aruba',
	'Equatorial Guinea',
	'Reading',
	'Birmingham City',
	'Kosovo',
	'Wales',
	'Malaysia',
	'León',
	'AFC Wimbledon',
	'Dinamo Zagreb',
	'Sheriff Tiraspol',
	'Wycombe Wanderers',
	'Morecambe',
	'Exeter City',
	'Fleetwood Town',
	'Nantes',
	'Somalia',
	'FK Qarabag',
	'NS Mura',
	'SK Sturm Graz',
	'PAOK Salonika',
	'OL Reign',
	'Strasbourg',
	'Cruz Azul',
	'Anorthosis',
	'Monterrey',
	'Ludogorets Razgrad',
	'Bolivia',
	'Turkmenistan',
	'San Marino',
	'Bordeaux',
	'Montpellier',
	'Pakistan',
	'Partizan Belgrade',
	'Slovakia',
	'Omonia Nicosia',
	'Iceland',
	'Breidablik',
	'Bosnia and Herzegovina',
	'France',
	'Cyprus',
	'Estonia',
	'Italy',
	'Nice',
	'Angers',
	'Legia Warsaw',
	'Azerbaijan',
	'Republic of Ireland',
	'Saint-Etienne',
	'Sparta Prague',
	'Uruguay',
	'Venezuela',
	'Bradford City',
	'FC Juarez',
	'Clermont Foot',
	'CFR Cluj-Napoca',
	'Latvia',
	'CSKA Sofia',
	'Antwerp',
	'Lincoln Red Imps',
	'Crawley Town',
	'AFC Bournemouth',
	'Salford City',
	'FC Basel',
	'Benfica',
	'Singapore',
	'Bahrain',
	'Czech Republic',
	'Spartak Moscow',
	'Armenia',
	'Malta',
	'Gibraltar',
	'Albania',
	'Hong Kong',
	'Hungary',
	'Indonesia',
	'Lebanon',
	'Luxembourg',
	'Montenegro',
	'Oman',
	'Chile',
	'Denmark',
	'Spain',
	'Serbia',
	'Switzerland',
	'Norway',
	'Toluca',
	'Blackpool',
	'Alashkert FC',
	'SC Cambuur',
	'Lorient',
	'Luton Town',
	'Sutton United',
	'Burton Albion',
	'Oxford United',
	'Young Boys',
	'Sporting CP',
	'Malmo FF',
	'Besiktas',
	'Lecce',
	'Monza',
	'Frosinone',
	'Salernitana',
	'Empoli',
	'Brentford',
	'Girona',
	'Las Palmas',
	'Mallorca',
	'Almeria',
];

export const soccerAbbrs = [
	'AJA',
	'ALV',
	'ARS',
	'ATA',
	'ATH',
	'ATL',
	'ATLU',
	'ATX',
	'AUG',
	'AVL',
	'BAR',
	'BEN',
	'BET',
	'BHA',
	'BOL',
	'BRUG',
	'BUR',
	'CAD',
	'CAG',
	'CEL',
	'CHE',
	'CHI',
	'CIN',
	'CLB',
	'COL',
	'COLO',
	'CRO',
	'CRY',
	'DAL',
	'DC',
	'DOR',
	'DSC',
	'EIB',
	'EINF',
	'ELC',
	'EVE',
	'FCK',
	'FCUB',
	'FERE',
	'FIO',
	'FREI',
	'FUL',
	'GCF',
	'GEN',
	'GET',
	'HERT',
	'HOF',
	'HOU',
	'HUE',
	'IBFK',
	'INT',
	'JUV',
	'KIEV',
	'LA',
	'LAFC',
	'LAZ',
	'LEE',
	'LEI',
	'LEIP',
	'LEV',
	'LEVE',
	'LIV',
	'LMO',
	'MAD',
	'MAIN',
	'MAN',
	'MAR',
	'MIA',
	'MIDT',
	'MIL',
	'MIN',
	'MNC',
	'MON',
	'MTL',
	'MUN',
	'NAP',
	'NE',
	'NEW',
	'NSH',
	'NY',
	'NYC',
	'OLY',
	'ORL',
	'OSA',
	'PAR',
	'PHI',
	'POR',
	'PORT',
	'PSG',
	'RENN',
	'ROMA',
	'RSL',
	'RSO',
	'SALZ',
	'SAMP',
	'SAS',
	'SCHA',
	'SEA',
	'SEV',
	'SHK',
	'SHU',
	'SJ',
	'SKC',
	'SOU',
	'SPEZ',
	'STU',
	'TOR',
	'TORO',
	'TOT',
	'UDN',
	'VAL',
	'VALL',
	'VAN',
	'VER',
	'VILL',
	'WBA',
	'WER',
	'WHU',
	'WOL',
	'WOLF',
	'ZEN',
	'LES',
	'CPV',
	'TAN',
	'BKA',
	'GAB',
	'MTN',
	'ZAM',
	'CHAD',
	'GAM',
	'BDI',
	'GUI',
	'MOZ',
	'MWI',
	'NAM',
	'CGO',
	'BENN',
	'RWA',
	'KEN',
	'LBY',
	'BHU',
	'HEE',
	'PRT',
	'RAN',
	'SEN',
	'BOT',
	'JABL',
	'SLV',
	'CUR',
	'HON',
	'NCA',
	'MACC',
	'ALG',
	'QAT',
	'MAZ',
	'GAL',
	'IRN',
	'CHIC',
	'TRI',
	'YEM',
	'AZ',
	'EGY',
	'SUD',
	'TUN',
	'JOR',
	'SYR',
	'PER',
	'CRC',
	'NEX',
	'NEC',
	'RKC',
	'CAN',
	'NED',
	'JAM',
	'BRU',
	'LAS',
	'NEP',
	'HJKH',
	'SLOV',
	'DON',
	'REIM',
	'AND',
	'VIT',
	'SUN',
	'UANL',
	'SWE',
	'AME',
	'TCA',
	'GUA',
	'PUE',
	'SAN',
	'BER',
	'USVI',
	'LAO',
	'AUS',
	'BLZ',
	'ENG',
	'SRI',
	'TJK',
	'RCL',
	'ATG',
	'SLE',
	'UAE',
	'BAH',
	'STK',
	'PNE',
	'PLE',
	'GRN',
	'MDG',
	'MDV',
	'PRK',
	'KAZ',
	'WIL',
	'RSB',
	'CHA',
	'MTA',
	'BODO',
	'PAC',
	'QRT',
	'KH',
	'FIN',
	'PAN',
	'RUS',
	'UKR',
	'GRE',
	'CELT',
	'UNAM',
	'BARN',
	'HUD',
	'WAL',
	'TWE',
	'FOR',
	'STEV',
	'ECU',
	'ARG',
	'KOR',
	'PGY',
	'BRA',
	'CO',
	'SVN',
	'NJNY',
	'NIG',
	'LEY',
	'GDL',
	'SSD',
	'MA',
	'AUT',
	'DJI',
	'IND',
	'LOU',
	'FRO',
	'QPR',
	'HART',
	'CU',
	'NORT',
	'FGR',
	'FCZA',
	'ASM',
	'LILL',
	'BRES',
	'BRC',
	'MID',
	'ROT',
	'HUL',
	'MANS',
	'SWA',
	'BW',
	'COP',
	'MRI',
	'NGA',
	'ZIM',
	'UZB',
	'NOT',
	'DER',
	'MILL',
	'SCUN',
	'GUY',
	'SHR',
	'CRA',
	'GER',
	'PSV',
	'BRAG',
	'SPA',
	'PEC',
	'MYA',
	'HER',
	'GRO',
	'BRI',
	'BRO',
	'TRN',
	'CIV',
	'RSA',
	'NIR',
	'ACCR',
	'ISR',
	'SHW',
	'FEY',
	'SLP',
	'KAIR',
	'CHL',
	'CAMB',
	'IPS',
	'WOB',
	'GAE',
	'PLY',
	'THA',
	'ORLP',
	'HOUS',
	'FLO',
	'HAR',
	'CARL',
	'SUR',
	'LBR',
	'MLI',
	'STP',
	'SER',
	'BK',
	'KHA',
	'HBK',
	'GIL',
	'PUR',
	'TOG',
	'DMA',
	'VIN',
	'COD',
	'ANGL',
	'CHN',
	'USA',
	'TPE',
	'PO',
	'ROCH',
	'LCN',
	'BVI',
	'CUB',
	'BLK',
	'COV',
	'PT',
	'GENK',
	'FEN',
	'KC',
	'SWI',
	'NEWP',
	'GNB',
	'SKN',
	'KUW',
	'PHIL',
	'DOM',
	'IRQ',
	'AIA',
	'CAY',
	'MSR',
	'ROU',
	'POL',
	'HAI',
	'AFG',
	'UGA',
	'BAN',
	'CMR',
	'UTR',
	'VIE',
	'TLS',
	'SCO',
	'JPN',
	'KSA',
	'BEL',
	'MK',
	'LTU',
	'GEO',
	'BRW',
	'ASL',
	'WGA',
	'TIJ',
	'ATS',
	'SWZ',
	'LIE',
	'BUL',
	'KGZ',
	'BLR',
	'TUR',
	'CTA',
	'GHA',
	'MEX',
	'PET',
	'METZ',
	'LCA',
	'OLD',
	'MGL',
	'TRY',
	'NCC',
	'WSH',
	'MAC',
	'MDA',
	'SEY',
	'ETH',
	'CREW',
	'VALE',
	'LYON',
	'MKD',
	'GENT',
	'RAND',
	'CAR',
	'COM',
	'BRB',
	'ERI',
	'VT',
	'ARU',
	'EQG',
	'REA',
	'BIR',
	'KOS',
	'WLS',
	'MAS',
	'LEO',
	'WIM',
	'ZAG',
	'SHE',
	'WYC',
	'MOR',
	'EXE',
	'FLE',
	'NANT',
	'SOM',
	'FKQA',
	'NSM',
	'STG',
	'PAOK',
	'OLR',
	'STRA',
	'CAZ',
	'ANO',
	'MONY',
	'LUD',
	'BO',
	'TKM',
	'SMR',
	'BOR',
	'MONT',
	'PAK',
	'PART',
	'SVK',
	'OMON',
	'ISL',
	'BREI',
	'BIH',
	'FRA',
	'CYP',
	'EST',
	'ITA',
	'NICE',
	'ANG',
	'LEGI',
	'AZE',
	'IRL',
	'ASSE',
	'SPAR',
	'URU',
	'VNZ',
	'BRAD',
	'FCJ',
	'CLER',
	'CLUJ',
	'LVA',
	'CSKA',
	'ANT',
	'LRI',
	'CRAW',
	'BOU',
	'SALF',
	'BAS',
	'BENF',
	'SIN',
	'BHR',
	'CZE',
	'SPM',
	'ARM',
	'MLT',
	'GIB',
	'ALB',
	'HKG',
	'HUN',
	'IDN',
	'LIB',
	'LUX',
	'MNE',
	'OMA',
	'CL',
	'DEN',
	'ESPA',
	'SRB',
	'SUI',
	'NO',
	'TOL',
	'BLP',
	'ALA',
	'CAM',
	'LOR',
	'LUT',
	'SUT',
	'BRT',
	'OXF',
	'YB',
	'SCP',
	'MALM',
	'BES',
	'LEC',
	'MONZ',
	'FROS',
	'SAL',
	'EMP',
	'BRE',
	'GIR',
	'PALM',
	'MAL',
	'ALM',
];

export const ncaaAbbrsWConf = [
	{team: 'AAMU', conference: '26'},
	{team: 'ACU', conference: '30'},
	{team: 'AFA', conference: '44'},
	{team: 'AKR', conference: '14'},
	{team: 'ALA', conference: '23'},
	{team: 'ALB', conference: '1'},
	{team: 'ALCN', conference: '26'},
	{team: 'ALST', conference: '26'},
	{team: 'AMCC', conference: '25'},
	{team: 'AMER', conference: '22'},
	{team: 'APP', conference: '27'},
	{team: 'APSU', conference: '46'},
	{team: 'ARIZ', conference: '8'},
	{team: 'ARK', conference: '23'},
	{team: 'ARMY', conference: '22'},
	{team: 'ARMY', conference: '62'},
	{team: 'ARST', conference: '27'},
	{team: 'ASU', conference: '8'},
	{team: 'AUB', conference: '23'},
	{team: 'BALL', conference: '14'},
	{team: 'BAY', conference: '8'},
	{team: 'BC', conference: '2'},
	{team: 'BCU', conference: '26'},
	{team: 'BEL', conference: '18'},
	{team: 'BELL', conference: '46'},
	{team: 'BGSU', conference: '14'},
	{team: 'BING', conference: '1'},
	{team: 'BRAD', conference: '18'},
	{team: 'BRWN', conference: '12'},
	{team: 'BRY', conference: '1'},
	{team: 'BSU', conference: '44'},
	{team: 'BU', conference: '22'},
	{team: 'BUCK', conference: '22'},
	{team: 'BUFF', conference: '14'},
	{team: 'BUT', conference: '4'},
	{team: 'BYU', conference: '8'},
	{team: 'CAL', conference: '2'},
	{team: 'CAM', conference: '10'},
	{team: 'CAN', conference: '13'},
	{team: 'CBU', conference: '30'},
	{team: 'CCSU', conference: '19'},
	{team: 'CCU', conference: '27'},
	{team: 'CHAR', conference: '62'},
	{team: 'CHIC', conference: '19'},
	{team: 'CHSO', conference: '6'},
	{team: 'CIN', conference: '8'},
	{team: 'CIT', conference: '24'},
	{team: 'CLEM', conference: '2'},
	{team: 'CLEV', conference: '45'},
	{team: 'CLMB', conference: '12'},
	{team: 'CMU', conference: '14'},
	{team: 'COFC', conference: '10'},
	{team: 'COLG', conference: '22'},
	{team: 'COLO', conference: '8'},
	{team: 'CONN', conference: '4'},
	{team: 'CONN', conference: '100'},
	{team: 'COPP', conference: '16'},
	{team: 'COR', conference: '12'},
	{team: 'CP', conference: '9'},
	{team: 'CREI', conference: '4'},
	{team: 'CSU', conference: '44'},
	{team: 'CSUB', conference: '9'},
	{team: 'CSUF', conference: '9'},
	{team: 'CSUN', conference: '9'},
	{team: 'DART', conference: '12'},
	{team: 'DAV', conference: '3'},
	{team: 'DAY', conference: '3'},
	{team: 'DEL', conference: '10'},
	{team: 'DEN', conference: '49'},
	{team: 'DEP', conference: '4'},
	{team: 'DET', conference: '45'},
	{team: 'DREX', conference: '10'},
	{team: 'DRKE', conference: '18'},
	{team: 'DSU', conference: '16'},
	{team: 'DUKE', conference: '2'},
	{team: 'DUQ', conference: '3'},
	{team: 'UTU', conference: '30'},
	{team: 'ECU', conference: '62'},
	{team: 'EIU', conference: '20'},
	{team: 'EKU', conference: '46'},
	{team: 'ELON', conference: '10'},
	{team: 'EMU', conference: '14'},
	{team: 'ETSU', conference: '24'},
	{team: 'EVAN', conference: '18'},
	{team: 'EWU', conference: '5'},
	{team: 'FAIR', conference: '13'},
	{team: 'FAMU', conference: '26'},
	{team: 'FAU', conference: '62'},
	{team: 'FDU', conference: '19'},
	{team: 'FGCU', conference: '46'},
	{team: 'FIU', conference: '11'},
	{team: 'FLA', conference: '23'},
	{team: 'FOR', conference: '3'},
	{team: 'FRES', conference: '44'},
	{team: 'FSU', conference: '2'},
	{team: 'FUR', conference: '24'},
	{team: 'GASO', conference: '27'},
	{team: 'GAST', conference: '27'},
	{team: 'GB', conference: '45'},
	{team: 'GCU', conference: '30'},
	{team: 'GMU', conference: '3'},
	{team: 'GONZ', conference: '29'},
	{team: 'GRAM', conference: '26'},
	{team: 'GT', conference: '2'},
	{team: 'GTWN', conference: '4'},
	{team: 'GW', conference: '3'},
	{team: 'GWEB', conference: '6'},
	{team: 'HALL', conference: '4'},
	{team: 'HAMP', conference: '10'},
	{team: 'HARV', conference: '12'},
	{team: 'HAW', conference: '9'},
	{team: 'HAW', conference: '44'},
	{team: 'HCU', conference: '25'},
	{team: 'HC', conference: '22'},
	{team: 'HOF', conference: '10'},
	{team: 'HOU', conference: '8'},
	{team: 'HOW', conference: '16'},
	{team: 'HP', conference: '6'},
	{team: 'IDHO', conference: '5'},
	{team: 'IDST', conference: '5'},
	{team: 'ILL', conference: '7'},
	{team: 'ILST', conference: '18'},
	{team: 'IND', conference: '7'},
	{team: 'INST', conference: '18'},
	{team: 'IONA', conference: '13'},
	{team: 'IOWA', conference: '7'},
	{team: 'ISU', conference: '8'},
	{team: 'IUPU', conference: '45'},
	{team: 'JAX', conference: '46'},
	{team: 'JKST', conference: '26'},
	{team: 'JMU', conference: '27'},
	{team: 'JOES', conference: '3'},
	{team: 'JVST', conference: '11'},
	{team: 'KENN', conference: '11'},
	{team: 'KENT', conference: '14'},
	{team: 'KSU', conference: '8'},
	{team: 'KU', conference: '8'},
	{team: 'L-MD', conference: '22'},
	{team: 'LAF', conference: '22'},
	{team: 'LAM', conference: '25'},
	{team: 'LAS', conference: '3'},
	{team: 'LBSU', conference: '9'},
	{team: 'LEH', conference: '22'},
	{team: 'LIB', conference: '11'},
	{team: 'LIP', conference: '46'},
	{team: 'LIU', conference: '19'},
	{team: 'LMU', conference: '29'},
	{team: 'LONG', conference: '6'},
	{team: 'LOU', conference: '2'},
	{team: 'LSU', conference: '23'},
	{team: 'LT', conference: '11'},
	{team: 'LUC', conference: '3'},
	{team: 'M-OH', conference: '14'},
	{team: 'MAN', conference: '13'},
	{team: 'MARQ', conference: '4'},
	{team: 'MCNS', conference: '25'},
	{team: 'MD', conference: '7'},
	{team: 'ME', conference: '1'},
	{team: 'MEM', conference: '62'},
	{team: 'MER', conference: '24'},
	{team: 'MIA', conference: '2'},
	{team: 'MICH', conference: '7'},
	{team: 'MILW', conference: '45'},
	{team: 'MINN', conference: '7'},
	{team: 'MISS', conference: '23'},
	{team: 'MIZ', conference: '23'},
	{team: 'MONM', conference: '10'},
	{team: 'MONT', conference: '5'},
	{team: 'MORE', conference: '20'},
	{team: 'MORG', conference: '16'},
	{team: 'MOST', conference: '18'},
	{team: 'MRMK', conference: '13'},
	{team: 'MRSH', conference: '27'},
	{team: 'MRST', conference: '13'},
	{team: 'MSM', conference: '13'},
	{team: 'MSST', conference: '23'},
	{team: 'MSU', conference: '7'},
	{team: 'MTST', conference: '5'},
	{team: 'MTSU', conference: '11'},
	{team: 'MUR', conference: '18'},
	{team: 'MVSU', conference: '26'},
	{team: 'NAU', conference: '5'},
	{team: 'NAVY', conference: '22'},
	{team: 'NAVY', conference: '62'},
	{team: 'NCAT', conference: '10'},
	{team: 'NCCU', conference: '16'},
	{team: 'NCST', conference: '2'},
	{team: 'ND', conference: '2'},
	{team: 'ND', conference: '100'},
	{team: 'NDSU', conference: '49'},
	{team: 'NE', conference: '10'},
	{team: 'NEB', conference: '7'},
	{team: 'NEV', conference: '44'},
	{team: 'NIAG', conference: '13'},
	{team: 'NICH', conference: '25'},
	{team: 'NIU', conference: '14'},
	{team: 'NJIT', conference: '1'},
	{team: 'NKU', conference: '45'},
	{team: 'NMSU', conference: '11'},
	{team: 'NORF', conference: '16'},
	{team: 'NW', conference: '7'},
	{team: 'NWST', conference: '25'},
	{team: 'OAK', conference: '45'},
	{team: 'ODU', conference: '27'},
	{team: 'OHIO', conference: '14'},
	{team: 'OKST', conference: '8'},
	{team: 'OMA', conference: '49'},
	{team: 'ORE', conference: '7'},
	{team: 'ORST', conference: '21'},
	{team: 'ORU', conference: '49'},
	{team: 'OSU', conference: '7'},
	{team: 'OU', conference: '23'},
	{team: 'PAC', conference: '29'},
	{team: 'PENN', conference: '12'},
	{team: 'PEPP', conference: '29'},
	{team: 'PFW', conference: '45'},
	{team: 'PITT', conference: '2'},
	{team: 'PORT', conference: '29'},
	{team: 'PRES', conference: '6'},
	{team: 'PRIN', conference: '12'},
	{team: 'PROV', conference: '4'},
	{team: 'PRST', conference: '5'},
	{team: 'PSU', conference: '7'},
	{team: 'PUR', conference: '7'},
	{team: 'PV', conference: '26'},
	{team: 'QUIN', conference: '13'},
	{team: 'RAD', conference: '6'},
	{team: 'RICE', conference: '62'},
	{team: 'RICH', conference: '3'},
	{team: 'RID', conference: '13'},
	{team: 'RMU', conference: '45'},
	{team: 'RUTG', conference: '7'},
	{team: 'SAC', conference: '5'},
	{team: 'SAM', conference: '24'},
	{team: 'SBU', conference: '3'},
	{team: 'SC', conference: '23'},
	{team: 'SCST', conference: '16'},
	{team: 'SCU', conference: '29'},
	{team: 'SCUP', conference: '6'},
	{team: 'SDAK', conference: '49'},
	{team: 'SDST', conference: '49'},
	{team: 'SDSU', conference: '44'},
	{team: 'SEA', conference: '30'},
	{team: 'SELA', conference: '25'},
	{team: 'SEMO', conference: '20'},
	{team: 'SF', conference: '29'},
	{team: 'SFA', conference: '25'},
	{team: 'SFPA', conference: '19'},
	{team: 'SHSU', conference: '11'},
	{team: 'SHU', conference: '13'},
	{team: 'SIE', conference: '13'},
	{team: 'SIU', conference: '18'},
	{team: 'SIUE', conference: '20'},
	{team: 'SJSU', conference: '44'},
	{team: 'SJU', conference: '4'},
	{team: 'SLU', conference: '3'},
	{team: 'SMC', conference: '29'},
	{team: 'SMU', conference: '2'},
	{team: 'SOU', conference: '26'},
	{team: 'SPU', conference: '13'},
	{team: 'STAN', conference: '2'},
	{team: 'STBK', conference: '10'},
	{team: 'STET', conference: '46'},
	{team: 'STMN', conference: '49'},
	{team: 'SUU', conference: '30'},
	{team: 'SYR', conference: '2'},
	{team: 'TAMU', conference: '23'},
	{team: 'TAR', conference: '30'},
	{team: 'TCU', conference: '8'},
	{team: 'TEM', conference: '62'},
	{team: 'TENN', conference: '23'},
	{team: 'TEX', conference: '23'},
	{team: 'TLSA', conference: '62'},
	{team: 'TNST', conference: '20'},
	{team: 'TNTC', conference: '20'},
	{team: 'TOL', conference: '14'},
	{team: 'TOW', conference: '10'},
	{team: 'TROY', conference: '27'},
	{team: 'TTU', conference: '8'},
	{team: 'TUL', conference: '62'},
	{team: 'TXSO', conference: '26'},
	{team: 'TXST', conference: '27'},
	{team: 'UAB', conference: '62'},
	{team: 'UALR', conference: '20'},
	{team: 'UAPB', conference: '26'},
	{team: 'UCA', conference: '46'},
	{team: 'UCD', conference: '9'},
	{team: 'UCF', conference: '8'},
	{team: 'UCI', conference: '9'},
	{team: 'UCLA', conference: '7'},
	{team: 'UCR', conference: '9'},
	{team: 'UCSB', conference: '9'},
	{team: 'UCSD', conference: '9'},
	{team: 'UGA', conference: '23'},
	{team: 'UIC', conference: '18'},
	{team: 'UIW', conference: '25'},
	{team: 'UK', conference: '23'},
	{team: 'ULL', conference: '27'},
	{team: 'ULM', conference: '27'},
	{team: 'UMASS', conference: '3'},
	{team: 'UMASS', conference: '100'},
	{team: 'UMBC', conference: '1'},
	{team: 'UMES', conference: '16'},
	{team: 'UMKC', conference: '49'},
	{team: 'UML', conference: '1'},
	{team: 'UNA', conference: '46'},
	{team: 'UNC', conference: '2'},
	{team: 'UNCA', conference: '6'},
	{team: 'UNCG', conference: '24'},
	{team: 'UNCO', conference: '5'},
	{team: 'UNCW', conference: '10'},
	{team: 'UND', conference: '49'},
	{team: 'UNF', conference: '46'},
	{team: 'UNH', conference: '1'},
	{team: 'UNI', conference: '18'},
	{team: 'UNLV', conference: '44'},
	{team: 'UNM', conference: '44'},
	{team: 'UNO', conference: '25'},
	{team: 'UNT', conference: '62'},
	{team: 'URI', conference: '3'},
	{team: 'USA', conference: '27'},
	{team: 'USC', conference: '7'},
	{team: 'USD', conference: '29'},
	{team: 'USF', conference: '62'},
	{team: 'USM', conference: '27'},
	{team: 'USU', conference: '44'},
	{team: 'UTA', conference: '30'},
	{team: 'UTAH', conference: '8'},
	{team: 'UTC', conference: '24'},
	{team: 'UTEP', conference: '11'},
	{team: 'UTM', conference: '20'},
	{team: 'UTRGV', conference: '25'},
	{team: 'UTSA', conference: '62'},
	{team: 'UVA', conference: '2'},
	{team: 'UVM', conference: '1'},
	{team: 'UVU', conference: '30'},
	{team: 'VAL', conference: '18'},
	{team: 'VAN', conference: '23'},
	{team: 'VCU', conference: '3'},
	{team: 'VILL', conference: '4'},
	{team: 'VMI', conference: '24'},
	{team: 'VT', conference: '2'},
	{team: 'WAG', conference: '19'},
	{team: 'WAKE', conference: '2'},
	{team: 'WASH', conference: '7'},
	{team: 'WCU', conference: '24'},
	{team: 'WEB', conference: '5'},
	{team: 'WICH', conference: '62'},
	{team: 'WIN', conference: '6'},
	{team: 'WIS', conference: '7'},
	{team: 'WIU', conference: '20'},
	{team: 'WKU', conference: '11'},
	{team: 'WM', conference: '10'},
	{team: 'WMU', conference: '14'},
	{team: 'WOF', conference: '24'},
	{team: 'WRST', conference: '45'},
	{team: 'WSU', conference: '21'},
	{team: 'WVU', conference: '8'},
	{team: 'WYO', conference: '44'},
	{team: 'XAV', conference: '4'},
	{team: 'YALE', conference: '12'},
	{team: 'YSU', conference: '45'},
	{team: 'LIN', conference: '20'},
	{team: 'QUOC', conference: '46'},
	{team: 'USI', conference: '20'},
	{team: 'STO', conference: '19'},
	{team: 'ETAM', conference: '25'},
	{team: 'SEC', conference: '1000'},
	{team: 'BIGTEN', conference: '1000'},
	{team: 'BIGTWELVE', conference: '1000'},
	{team: 'ACC', conference: '1000'},
	{team: 'PACTWELVE', conference: '1000'},
	{team: 'BIGEAST', conference: '1000'},
	{team: 'AMERICAN', conference: '1000'},
	{team: 'C-USA', conference: '1000'},
	{team: 'MAC', conference: '1000'},
	{team: 'MWC', conference: '1000'},
	{team: 'BIGSKY', conference: '1000'},
	{team: 'MVC', conference: '1000'},
	{team: 'IVY', conference: '1000'},
	{team: 'MEAC', conference: '1000'},
	{team: 'NEC', conference: '1000'},
	{team: 'OVC', conference: '1000'},
	{team: 'PATRIOT', conference: '1000'},
	{team: 'SOUTHERN', conference: '1000'},
	{team: 'SOUTHLAND', conference: '1000'},
	{team: 'SWAC', conference: '1000'},
	{team: 'SUNBELT', conference: '1000'},
	{team: 'BIGSOUTH', conference: '1000'},
	{team: 'AMERICAEAST', conference: '1000'},
	{team: 'WCC', conference: '1000'},
	{team: 'MAAC', conference: '1000'},
	{team: 'HORIZON', conference: '1000'},
	{team: 'COASTAL', conference: '1000'},
	{team: 'BIGWEST', conference: '1000'},
	{team: 'ATLANTICTEN', conference: '1000'},
	{team: 'WAC', conference: '1000'},
	{team: 'ASUN', conference: '1000'},
	{team: 'LEM', conference: '19'},
	{team: 'UWG', conference: '46'},
	{team: 'MERC', conference: '19'},
];

export const soccerAbbrsWConf = [
	{team: 'ALM', conference: 'ESP.1'},
	{team: 'ALV', conference: 'ESP.1'},
	{team: 'ARS', conference: 'ENG.1'},
	{team: 'ATA', conference: 'ITA.1'},
	{team: 'ATH', conference: 'ESP.1'},
	{team: 'ATL', conference: 'ESP.1'},
	{team: 'ATLU', conference: 'USA.1'},
	{team: 'ATX', conference: 'USA.1'},
	{team: 'AUG', conference: 'GER.1'},
	{team: 'AVL', conference: 'ENG.1'},
	{team: 'BAR', conference: 'ESP.1'},
	{team: 'BET', conference: 'ESP.1'},
	{team: 'BHA', conference: 'ENG.1'},
	{team: 'BOC', conference: 'GER.1'},
	{team: 'BOL', conference: 'ITA.1'},
	{team: 'BRE', conference: 'ENG.1'},
	{team: 'BUR', conference: 'ENG.1'},
	{team: 'CAD', conference: 'ESP.1'},
	{team: 'CAG', conference: 'ITA.1'},
	{team: 'CEL', conference: 'ESP.1'},
	{team: 'CHE', conference: 'ENG.1'},
	{team: 'CHI', conference: 'USA.1'},
	{team: 'CIN', conference: 'USA.1'},
	{team: 'CLB', conference: 'USA.1'},
	{team: 'CLT', conference: 'USA.1'},
	{team: 'COL', conference: 'GER.1'},
	{team: 'COLO', conference: 'USA.1'},
	{team: 'CRY', conference: 'ENG.1'},
	{team: 'DAL', conference: 'USA.1'},
	{team: 'DC', conference: 'USA.1'},
	{team: 'DOR', conference: 'GER.1'},
	{team: 'DSC', conference: 'GER.1'},
	{team: 'EINF', conference: 'GER.1'},
	{team: 'ELC', conference: 'ESP.2'},
	{team: 'EMP', conference: 'ITA.1'},
	{team: 'ESP', conference: 'ESP.2'},
	{team: 'EVE', conference: 'ENG.1'},
	{team: 'FCUB', conference: 'GER.1'},
	{team: 'FIO', conference: 'ITA.1'},
	{team: 'FREI', conference: 'GER.1'},
	{team: 'FUR', conference: 'GER.1'},
	{team: 'GCF', conference: 'ESP.1'},
	{team: 'GEN', conference: 'ITA.1'},
	{team: 'GET', conference: 'ESP.1'},
	{team: 'GIR', conference: 'ESP.1'},
	{team: 'HERT', conference: 'GER.1'},
	{team: 'HOF', conference: 'GER.1'},
	{team: 'HOU', conference: 'USA.1'},
	{team: 'INT', conference: 'ITA.1'},
	{team: 'JUV', conference: 'ITA.1'},
	{team: 'LA', conference: 'USA.1'},
	{team: 'LAFC', conference: 'USA.1'},
	{team: 'LAZ', conference: 'ITA.1'},
	{team: 'LEC', conference: 'ITA.1'},
	{team: 'MONZ', conference: 'ITA.1'},
	{team: 'FROS', conference: 'ITA.1'},
	{team: 'LEE', conference: 'ENG.2'},
	{team: 'LEI', conference: 'ENG.2'},
	{team: 'LEIP', conference: 'GER.1'},
	{team: 'LEV', conference: 'ESP.2'},
	{team: 'LEVE', conference: 'GER.1'},
	{team: 'LIV', conference: 'ENG.1'},
	{team: 'MAD', conference: 'ESP.1'},
	{team: 'MAIN', conference: 'GER.1'},
	{team: 'MAL', conference: 'ESP.1'},
	{team: 'MAN', conference: 'ENG.1'},
	{team: 'MIA', conference: 'USA.1'},
	{team: 'MIL', conference: 'ITA.1'},
	{team: 'MIN', conference: 'USA.1'},
	{team: 'MNC', conference: 'ENG.1'},
	{team: 'MON', conference: 'GER.1'},
	{team: 'MTL', conference: 'USA.1'},
	{team: 'MUN', conference: 'GER.1'},
	{team: 'NAP', conference: 'ITA.1'},
	{team: 'NE', conference: 'USA.1'},
	{team: 'NEW', conference: 'ENG.1'},
	{team: 'NOR', conference: 'ENG.2'},
	{team: 'NSH', conference: 'USA.1'},
	{team: 'NY', conference: 'USA.1'},
	{team: 'NYC', conference: 'USA.1'},
	{team: 'ORL', conference: 'USA.1'},
	{team: 'OSA', conference: 'ESP.1'},
	{team: 'PALM', conference: 'ESP.1'},
	{team: 'PHI', conference: 'USA.1'},
	{team: 'PORT', conference: 'USA.1'},
	{team: 'RAY', conference: 'ESP.1'},
	{team: 'ROMA', conference: 'ITA.1'},
	{team: 'RSL', conference: 'USA.1'},
	{team: 'RSO', conference: 'ESP.1'},
	{team: 'SAL', conference: 'ITA.1'},
	{team: 'SAMP', conference: 'ITA.2'},
	{team: 'SAS', conference: 'ITA.1'},
	{team: 'SEA', conference: 'USA.1'},
	{team: 'SEV', conference: 'ESP.1'},
	{team: 'SJ', conference: 'USA.1'},
	{team: 'SKC', conference: 'USA.1'},
	{team: 'SOU', conference: 'ENG.2'},
	{team: 'SPEZ', conference: 'ITA.2'},
	{team: 'STU', conference: 'GER.1'},
	{team: 'TOR', conference: 'ITA.1'},
	{team: 'TORO', conference: 'USA.1'},
	{team: 'TOT', conference: 'ENG.1'},
	{team: 'UDN', conference: 'ITA.1'},
	{team: 'VAL', conference: 'ESP.1'},
	{team: 'VAN', conference: 'USA.1'},
	{team: 'VEN', conference: 'ITA.2'},
	{team: 'VER', conference: 'ITA.1'},
	{team: 'VILL', conference: 'ESP.1'},
	{team: 'WAT', conference: 'ENG.2'},
	{team: 'WHU', conference: 'ENG.1'},
	{team: 'WOL', conference: 'ENG.1'},
	{team: 'WOLF', conference: 'GER.1'},
	{team: 'ALG', conference: 'CAF.NATIONS'},
	{team: 'BKA', conference: 'CAF.NATIONS'},
	{team: 'CIV', conference: 'CAF.NATIONS'},
	{team: 'CMR', conference: 'CAF.NATIONS'},
	{team: 'COM', conference: 'CAF.NATIONS'},
	{team: 'CPV', conference: 'CAF.NATIONS'},
	{team: 'EGY', conference: 'CAF.NATIONS'},
	{team: 'EQG', conference: 'CAF.NATIONS'},
	{team: 'ETH', conference: 'CAF.NATIONS'},
	{team: 'GAB', conference: 'CAF.NATIONS'},
	{team: 'GAM', conference: 'CAF.NATIONS'},
	{team: 'GHA', conference: 'CAF.NATIONS'},
	{team: 'GNB', conference: 'CAF.NATIONS'},
	{team: 'GUI', conference: 'CAF.NATIONS'},
	{team: 'MA', conference: 'CAF.NATIONS'},
	{team: 'MLI', conference: 'CAF.NATIONS'},
	{team: 'MTN', conference: 'CAF.NATIONS'},
	{team: 'MWI', conference: 'CAF.NATIONS'},
	{team: 'NGA', conference: 'CAF.NATIONS'},
	{team: 'SEN', conference: 'CAF.NATIONS'},
	{team: 'SLE', conference: 'CAF.NATIONS'},
	{team: 'SUD', conference: 'CAF.NATIONS'},
	{team: 'TUN', conference: 'CAF.NATIONS'},
	{team: 'ZIM', conference: 'CAF.NATIONS'},
	{team: 'BARN', conference: 'ENG.2'},
	{team: 'BIR', conference: 'ENG.2'},
	{team: 'BLK', conference: 'ENG.2'},
	{team: 'BLP', conference: 'ENG.2'},
	{team: 'BOU', conference: 'ENG.1'},
	{team: 'BRC', conference: 'ENG.2'},
	{team: 'CAR', conference: 'ENG.2'},
	{team: 'COV', conference: 'ENG.2'},
	{team: 'DER', conference: 'ENG.2'},
	{team: 'FUL', conference: 'ENG.1'},
	{team: 'HUD', conference: 'ENG.2'},
	{team: 'HUL', conference: 'ENG.2'},
	{team: 'LUT', conference: 'ENG.1'},
	{team: 'MID', conference: 'ENG.2'},
	{team: 'MIL', conference: 'ENG.2'},
	{team: 'NOT', conference: 'ENG.1'},
	{team: 'PET', conference: 'ENG.2'},
	{team: 'PNE', conference: 'ENG.2'},
	{team: 'QPR', conference: 'ENG.2'},
	{team: 'REA', conference: 'ENG.2'},
	{team: 'SHU', conference: 'ENG.1'},
	{team: 'STK', conference: 'ENG.2'},
	{team: 'SWA', conference: 'ENG.2'},
	{team: 'WBA', conference: 'ENG.2'},
	{team: 'ACCR', conference: 'ENG.LEAGUE_CUP'},
	{team: 'ARS', conference: 'ENG.LEAGUE_CUP'},
	{team: 'AVL', conference: 'ENG.LEAGUE_CUP'},
	{team: 'BARN', conference: 'ENG.LEAGUE_CUP'},
	{team: 'BHA', conference: 'ENG.LEAGUE_CUP'},
	{team: 'BIR', conference: 'ENG.LEAGUE_CUP'},
	{team: 'BLK', conference: 'ENG.LEAGUE_CUP'},
	{team: 'BLP', conference: 'ENG.LEAGUE_CUP'},
	{team: 'BW', conference: 'ENG.LEAGUE_CUP'},
	{team: 'BOU', conference: 'ENG.LEAGUE_CUP'},
	{team: 'BRAD', conference: 'ENG.LEAGUE_CUP'},
	{team: 'BRC', conference: 'ENG.LEAGUE_CUP'},
	{team: 'BRE', conference: 'ENG.LEAGUE_CUP'},
	{team: 'BRI', conference: 'ENG.LEAGUE_CUP'},
	{team: 'BRT', conference: 'ENG.LEAGUE_CUP'},
	{team: 'BRW', conference: 'ENG.LEAGUE_CUP'},
	{team: 'BUR', conference: 'ENG.LEAGUE_CUP'},
	{team: 'CAMB', conference: 'ENG.LEAGUE_CUP'},
	{team: 'CAR', conference: 'ENG.LEAGUE_CUP'},
	{team: 'CARL', conference: 'ENG.LEAGUE_CUP'},
	{team: 'CHA', conference: 'ENG.LEAGUE_CUP'},
	{team: 'CHE', conference: 'ENG.LEAGUE_CUP'},
	{team: 'CHL', conference: 'ENG.LEAGUE_CUP'},
	{team: 'CU', conference: 'ENG.LEAGUE_CUP'},
	{team: 'COV', conference: 'ENG.LEAGUE_CUP'},
	{team: 'CRAW', conference: 'ENG.LEAGUE_CUP'},
	{team: 'CREW', conference: 'ENG.LEAGUE_CUP'},
	{team: 'CRY', conference: 'ENG.LEAGUE_CUP'},
	{team: 'DER', conference: 'ENG.LEAGUE_CUP'},
	{team: 'DON', conference: 'ENG.LEAGUE_CUP'},
	{team: 'EVE', conference: 'ENG.LEAGUE_CUP'},
	{team: 'EXE', conference: 'ENG.LEAGUE_CUP'},
	{team: 'FGR', conference: 'ENG.LEAGUE_CUP'},
	{team: 'FLE', conference: 'ENG.LEAGUE_CUP'},
	{team: 'FUL', conference: 'ENG.LEAGUE_CUP'},
	{team: 'GIL', conference: 'ENG.LEAGUE_CUP'},
	{team: 'HAR', conference: 'ENG.LEAGUE_CUP'},
	{team: 'HART', conference: 'ENG.LEAGUE_CUP'},
	{team: 'HUD', conference: 'ENG.LEAGUE_CUP'},
	{team: 'HUL', conference: 'ENG.LEAGUE_CUP'},
	{team: 'IPS', conference: 'ENG.LEAGUE_CUP'},
	{team: 'LCN', conference: 'ENG.LEAGUE_CUP'},
	{team: 'LEE', conference: 'ENG.LEAGUE_CUP'},
	{team: 'LEI', conference: 'ENG.LEAGUE_CUP'},
	{team: 'LEY', conference: 'ENG.LEAGUE_CUP'},
	{team: 'LIV', conference: 'ENG.LEAGUE_CUP'},
	{team: 'LUT', conference: 'ENG.LEAGUE_CUP'},
	{team: 'MAN', conference: 'ENG.LEAGUE_CUP'},
	{team: 'MANS', conference: 'ENG.LEAGUE_CUP'},
	{team: 'MID', conference: 'ENG.LEAGUE_CUP'},
	{team: 'MIL', conference: 'ENG.LEAGUE_CUP'},
	{team: 'MKD', conference: 'ENG.LEAGUE_CUP'},
	{team: 'MNC', conference: 'ENG.LEAGUE_CUP'},
	{team: 'MOR', conference: 'ENG.LEAGUE_CUP'},
	{team: 'NEW', conference: 'ENG.LEAGUE_CUP'},
	{team: 'NEWP', conference: 'ENG.LEAGUE_CUP'},
	{team: 'NOR', conference: 'ENG.LEAGUE_CUP'},
	{team: 'NORT', conference: 'ENG.LEAGUE_CUP'},
	{team: 'NOT', conference: 'ENG.LEAGUE_CUP'},
	{team: 'OLD', conference: 'ENG.LEAGUE_CUP'},
	{team: 'OXF', conference: 'ENG.LEAGUE_CUP'},
	{team: 'PET', conference: 'ENG.LEAGUE_CUP'},
	{team: 'PLY', conference: 'ENG.LEAGUE_CUP'},
	{team: 'PNE', conference: 'ENG.LEAGUE_CUP'},
	{team: 'PO', conference: 'ENG.LEAGUE_CUP'},
	{team: 'QPR', conference: 'ENG.LEAGUE_CUP'},
	{team: 'REA', conference: 'ENG.LEAGUE_CUP'},
	{team: 'ROCH', conference: 'ENG.LEAGUE_CUP'},
	{team: 'ROT', conference: 'ENG.LEAGUE_CUP'},
	{team: 'SALF', conference: 'ENG.LEAGUE_CUP'},
	{team: 'SCUN', conference: 'ENG.LEAGUE_CUP'},
	{team: 'SHR', conference: 'ENG.LEAGUE_CUP'},
	{team: 'SHU', conference: 'ENG.LEAGUE_CUP'},
	{team: 'SHW', conference: 'ENG.LEAGUE_CUP'},
	{team: 'SOU', conference: 'ENG.LEAGUE_CUP'},
	{team: 'STEV', conference: 'ENG.LEAGUE_CUP'},
	{team: 'STK', conference: 'ENG.LEAGUE_CUP'},
	{team: 'SUN', conference: 'ENG.LEAGUE_CUP'},
	{team: 'SUT', conference: 'ENG.LEAGUE_CUP'},
	{team: 'SWA', conference: 'ENG.LEAGUE_CUP'},
	{team: 'SWI', conference: 'ENG.LEAGUE_CUP'},
	{team: 'TOT', conference: 'ENG.LEAGUE_CUP'},
	{team: 'TRN', conference: 'ENG.LEAGUE_CUP'},
	{team: 'VALE', conference: 'ENG.LEAGUE_CUP'},
	{team: 'WAL', conference: 'ENG.LEAGUE_CUP'},
	{team: 'WAT', conference: 'ENG.LEAGUE_CUP'},
	{team: 'WBA', conference: 'ENG.LEAGUE_CUP'},
	{team: 'WGA', conference: 'ENG.LEAGUE_CUP'},
	{team: 'WHU', conference: 'ENG.LEAGUE_CUP'},
	{team: 'WIM', conference: 'ENG.LEAGUE_CUP'},
	{team: 'WOL', conference: 'ENG.LEAGUE_CUP'},
	{team: 'WYC', conference: 'ENG.LEAGUE_CUP'},
	{team: 'AFG', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'AUS', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'BAN', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'BHR', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'BHU', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'BRU', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'CAM', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'CHN', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'GUA', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'HKG', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'IDN', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'IND', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'IRN', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'IRQ', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'JOR', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'JPN', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'KGZ', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'KOR', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'KSA', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'KUW', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'LAO', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'LIB', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'MAC', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'MAS', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'MDV', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'MGL', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'MYA', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'NEP', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'OMA', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'PAK', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'PHIL', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'PLE', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'PRK', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'QAT', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'SIN', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'SRI', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'SYR', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'THA', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'TJK', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'TKM', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'TLS', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'TPE', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'UAE', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'UZB', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'VT', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'YEM', conference: 'FIFA.WORLDQ.AFC'},
	{team: 'ALG', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'ANGL', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'BDI', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'BENN', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'BKA', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'BOT', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'CGO', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'CHAD', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'CIV', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'CMR', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'COD', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'COM', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'CPV', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'CTA', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'DJI', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'EGY', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'EQG', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'ERI', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'ETH', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'GAB', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'GAM', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'GHA', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'GNB', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'GUI', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'KEN', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'LBR', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'LBY', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'LES', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'MA', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'MDG', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'MLI', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'MOZ', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'MRI', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'MTN', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'MWI', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'NAM', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'NGA', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'NIG', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'RSA', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'RWA', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'SEN', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'SEY', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'SLE', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'SOM', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'SSD', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'STP', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'SUD', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'SWZ', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'TAN', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'TOG', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'TUN', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'UGA', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'ZAM', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'ZIM', conference: 'FIFA.WORLDQ.CAF'},
	{team: 'AIA', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'ARU', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'ATG', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'BAH', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'BER', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'BLZ', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'BRB', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'BVI', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'CAN', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'CAY', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'CRC', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'CUB', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'CUR', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'DMA', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'DOM', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'GRN', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'GUA', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'GUY', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'HAI', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'HON', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'JAM', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'LCA', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'MEX', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'MSR', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'NCA', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'PAN', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'PUR', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'SKN', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'SLV', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'SUR', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'TCA', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'TRI', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'USA', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'USVI', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'VIN', conference: 'FIFA.WORLDQ.CONCACAF'},
	{team: 'ARG', conference: 'FIFA.WORLDQ.CONMEBOL'},
	{team: 'BO', conference: 'FIFA.WORLDQ.CONMEBOL'},
	{team: 'BRA', conference: 'FIFA.WORLDQ.CONMEBOL'},
	{team: 'CL', conference: 'FIFA.WORLDQ.CONMEBOL'},
	{team: 'CO', conference: 'FIFA.WORLDQ.CONMEBOL'},
	{team: 'ECU', conference: 'FIFA.WORLDQ.CONMEBOL'},
	{team: 'PGY', conference: 'FIFA.WORLDQ.CONMEBOL'},
	{team: 'PER', conference: 'FIFA.WORLDQ.CONMEBOL'},
	{team: 'URU', conference: 'FIFA.WORLDQ.CONMEBOL'},
	{team: 'VNZ', conference: 'FIFA.WORLDQ.CONMEBOL'},
	{team: 'ALB', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'AND', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'ARM', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'AUT', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'AZE', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'BEL', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'BIH', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'BLR', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'BUL', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'CRA', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'CYP', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'CZE', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'DEN', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'ENG', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'ESPA', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'EST', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'FIN', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'FRA', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'FRO', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'GEO', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'GER', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'GIB', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'GRE', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'HUN', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'IRL', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'ISL', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'ISR', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'ITA', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'KAZ', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'KOS', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'LIE', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'LTU', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'LUX', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'LVA', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'MDA', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'MK', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'MLT', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'MNE', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'NED', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'NIR', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'NO', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'POL', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'PT', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'ROU', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'RUS', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'SCO', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'SMR', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'SRB', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'SUI', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'SVK', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'SVN', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'SWE', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'TUR', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'UKR', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'WLS', conference: 'FIFA.WORLDQ.UEFA'},
	{team: 'ANG', conference: 'FRA.1'},
	{team: 'ASM', conference: 'FRA.1'},
	{team: 'ASSE', conference: 'FRA.1'},
	{team: 'BOR', conference: 'FRA.1'},
	{team: 'BRES', conference: 'FRA.1'},
	{team: 'CLER', conference: 'FRA.1'},
	{team: 'LILL', conference: 'FRA.1'},
	{team: 'LOR', conference: 'FRA.1'},
	{team: 'LYON', conference: 'FRA.1'},
	{team: 'MAR', conference: 'FRA.1'},
	{team: 'METZ', conference: 'FRA.1'},
	{team: 'MONT', conference: 'FRA.1'},
	{team: 'NANT', conference: 'FRA.1'},
	{team: 'NICE', conference: 'FRA.1'},
	{team: 'PSG', conference: 'FRA.1'},
	{team: 'RCL', conference: 'FRA.1'},
	{team: 'REIM', conference: 'FRA.1'},
	{team: 'RENN', conference: 'FRA.1'},
	{team: 'STRA', conference: 'FRA.1'},
	{team: 'TRY', conference: 'FRA.1'},
	{team: 'AME', conference: 'MEX.1'},
	{team: 'ASL', conference: 'MEX.1'},
	{team: 'ATS', conference: 'MEX.1'},
	{team: 'CAZ', conference: 'MEX.1'},
	{team: 'FCJ', conference: 'MEX.1'},
	{team: 'GDL', conference: 'MEX.1'},
	{team: 'LEO', conference: 'MEX.1'},
	{team: 'MAZ', conference: 'MEX.1'},
	{team: 'MONY', conference: 'MEX.1'},
	{team: 'NEX', conference: 'MEX.1'},
	{team: 'PAC', conference: 'MEX.1'},
	{team: 'PUE', conference: 'MEX.1'},
	{team: 'QRT', conference: 'MEX.1'},
	{team: 'SAN', conference: 'MEX.1'},
	{team: 'TIJ', conference: 'MEX.1'},
	{team: 'TOL', conference: 'MEX.1'},
	{team: 'UANL', conference: 'MEX.1'},
	{team: 'UNAM', conference: 'MEX.1'},
	{team: 'AJA', conference: 'NED.1'},
	{team: 'AZ', conference: 'NED.1'},
	{team: 'CAM', conference: 'NED.1'},
	{team: 'FEY', conference: 'NED.1'},
	{team: 'FOR', conference: 'NED.1'},
	{team: 'GAE', conference: 'NED.1'},
	{team: 'GRO', conference: 'NED.1'},
	{team: 'HEE', conference: 'NED.1'},
	{team: 'HER', conference: 'NED.1'},
	{team: 'NEC', conference: 'NED.1'},
	{team: 'PEC', conference: 'NED.1'},
	{team: 'PSV', conference: 'NED.1'},
	{team: 'RKC', conference: 'NED.1'},
	{team: 'SPA', conference: 'NED.1'},
	{team: 'TWE', conference: 'NED.1'},
	{team: 'UTR', conference: 'NED.1'},
	{team: 'VIT', conference: 'NED.1'},
	{team: 'WIL', conference: 'NED.1'},
	{team: 'ANT', conference: 'UEFA.EUROPA'},
	{team: 'ASM', conference: 'UEFA.EUROPA'},
	{team: 'ATA', conference: 'UEFA.EUROPA'},
	{team: 'BAR', conference: 'UEFA.EUROPA'},
	{team: 'BET', conference: 'UEFA.EUROPA'},
	{team: 'BRAG', conference: 'UEFA.EUROPA'},
	{team: 'BRO', conference: 'UEFA.EUROPA'},
	{team: 'CELT', conference: 'UEFA.EUROPA'},
	{team: 'DOR', conference: 'UEFA.EUROPA'},
	{team: 'EINF', conference: 'UEFA.EUROPA'},
	{team: 'FEN', conference: 'UEFA.EUROPA'},
	{team: 'FERE', conference: 'UEFA.EUROPA'},
	{team: 'GAL', conference: 'UEFA.EUROPA'},
	{team: 'GENK', conference: 'UEFA.EUROPA'},
	{team: 'LAZ', conference: 'UEFA.EUROPA'},
	{team: 'LEGI', conference: 'UEFA.EUROPA'},
	{team: 'LEI', conference: 'UEFA.EUROPA'},
	{team: 'LEIP', conference: 'UEFA.EUROPA'},
	{team: 'LEVE', conference: 'UEFA.EUROPA'},
	{team: 'LMO', conference: 'UEFA.EUROPA'},
	{team: 'LUD', conference: 'UEFA.EUROPA'},
	{team: 'LYON', conference: 'UEFA.EUROPA'},
	{team: 'MAR', conference: 'UEFA.EUROPA'},
	{team: 'MIDT', conference: 'UEFA.EUROPA'},
	{team: 'NAP', conference: 'UEFA.EUROPA'},
	{team: 'OLY', conference: 'UEFA.EUROPA'},
	{team: 'POR', conference: 'UEFA.EUROPA'},
	{team: 'PSV', conference: 'UEFA.EUROPA'},
	{team: 'RAN', conference: 'UEFA.EUROPA'},
	{team: 'RSB', conference: 'UEFA.EUROPA'},
	{team: 'RSO', conference: 'UEFA.EUROPA'},
	{team: 'SEV', conference: 'UEFA.EUROPA'},
	{team: 'SHE', conference: 'UEFA.EUROPA'},
	{team: 'SPAR', conference: 'UEFA.EUROPA'},
	{team: 'SPM', conference: 'UEFA.EUROPA'},
	{team: 'STG', conference: 'UEFA.EUROPA'},
	{team: 'VIE', conference: 'UEFA.EUROPA'},
	{team: 'WHU', conference: 'UEFA.EUROPA'},
	{team: 'ZAG', conference: 'UEFA.EUROPA'},
	{team: 'ZEN', conference: 'UEFA.EUROPA'},
	{team: 'ALA', conference: 'UEFA.EUROPA.CONF'},
	{team: 'ANO', conference: 'UEFA.EUROPA.CONF'},
	{team: 'AZ', conference: 'UEFA.EUROPA.CONF'},
	{team: 'BAS', conference: 'UEFA.EUROPA.CONF'},
	{team: 'BODO', conference: 'UEFA.EUROPA.CONF'},
	{team: 'CELT', conference: 'UEFA.EUROPA.CONF'},
	{team: 'CLUJ', conference: 'UEFA.EUROPA.CONF'},
	{team: 'COP', conference: 'UEFA.EUROPA.CONF'},
	{team: 'CSKA', conference: 'UEFA.EUROPA.CONF'},
	{team: 'FCUB', conference: 'UEFA.EUROPA.CONF'},
	{team: 'FCZA', conference: 'UEFA.EUROPA.CONF'},
	{team: 'FEN', conference: 'UEFA.EUROPA.CONF'},
	{team: 'FEY', conference: 'UEFA.EUROPA.CONF'},
	{team: 'FKQA', conference: 'UEFA.EUROPA.CONF'},
	{team: 'FLO', conference: 'UEFA.EUROPA.CONF'},
	{team: 'GENT', conference: 'UEFA.EUROPA.CONF'},
	{team: 'MACC', conference: 'UEFA.EUROPA.CONF'},
	{team: 'HJKH', conference: 'UEFA.EUROPA.CONF'},
	{team: 'JABL', conference: 'UEFA.EUROPA.CONF'},
	{team: 'KAIR', conference: 'UEFA.EUROPA.CONF'},
	{team: 'LAS', conference: 'UEFA.EUROPA.CONF'},
	{team: 'LEI', conference: 'UEFA.EUROPA.CONF'},
	{team: 'LRI', conference: 'UEFA.EUROPA.CONF'},
	{team: 'MAR', conference: 'UEFA.EUROPA.CONF'},
	{team: 'MIDT', conference: 'UEFA.EUROPA.CONF'},
	{team: 'MTA', conference: 'UEFA.EUROPA.CONF'},
	{team: 'NSM', conference: 'UEFA.EUROPA.CONF'},
	{team: 'OMON', conference: 'UEFA.EUROPA.CONF'},
	{team: 'PAOK', conference: 'UEFA.EUROPA.CONF'},
	{team: 'PART', conference: 'UEFA.EUROPA.CONF'},
	{team: 'PSV', conference: 'UEFA.EUROPA.CONF'},
	{team: 'RAND', conference: 'UEFA.EUROPA.CONF'},
	{team: 'RENN', conference: 'UEFA.EUROPA.CONF'},
	{team: 'ROMA', conference: 'UEFA.EUROPA.CONF'},
	{team: 'SLOV', conference: 'UEFA.EUROPA.CONF'},
	{team: 'SLP', conference: 'UEFA.EUROPA.CONF'},
	{team: 'SPAR', conference: 'UEFA.EUROPA.CONF'},
	{team: 'TOT', conference: 'UEFA.EUROPA.CONF'},
	{team: 'VIE', conference: 'UEFA.EUROPA.CONF'},
	{team: 'VIT', conference: 'UEFA.EUROPA.CONF'},
	{team: 'ALB', conference: 'UEFA.NATIONS'},
	{team: 'AND', conference: 'UEFA.NATIONS'},
	{team: 'ARM', conference: 'UEFA.NATIONS'},
	{team: 'AUT', conference: 'UEFA.NATIONS'},
	{team: 'AZE', conference: 'UEFA.NATIONS'},
	{team: 'BEL', conference: 'UEFA.NATIONS'},
	{team: 'BIH', conference: 'UEFA.NATIONS'},
	{team: 'BLR', conference: 'UEFA.NATIONS'},
	{team: 'BUL', conference: 'UEFA.NATIONS'},
	{team: 'CRA', conference: 'UEFA.NATIONS'},
	{team: 'CYP', conference: 'UEFA.NATIONS'},
	{team: 'CZE', conference: 'UEFA.NATIONS'},
	{team: 'DEN', conference: 'UEFA.NATIONS'},
	{team: 'ENG', conference: 'UEFA.NATIONS'},
	{team: 'ESPA', conference: 'UEFA.NATIONS'},
	{team: 'EST', conference: 'UEFA.NATIONS'},
	{team: 'FIN', conference: 'UEFA.NATIONS'},
	{team: 'FRA', conference: 'UEFA.NATIONS'},
	{team: 'FRO', conference: 'UEFA.NATIONS'},
	{team: 'GEO', conference: 'UEFA.NATIONS'},
	{team: 'GER', conference: 'UEFA.NATIONS'},
	{team: 'GIB', conference: 'UEFA.NATIONS'},
	{team: 'GRE', conference: 'UEFA.NATIONS'},
	{team: 'HUN', conference: 'UEFA.NATIONS'},
	{team: 'IRL', conference: 'UEFA.NATIONS'},
	{team: 'ISL', conference: 'UEFA.NATIONS'},
	{team: 'ISR', conference: 'UEFA.NATIONS'},
	{team: 'ITA', conference: 'UEFA.NATIONS'},
	{team: 'KAZ', conference: 'UEFA.NATIONS'},
	{team: 'KOS', conference: 'UEFA.NATIONS'},
	{team: 'LIE', conference: 'UEFA.NATIONS'},
	{team: 'LTU', conference: 'UEFA.NATIONS'},
	{team: 'LUX', conference: 'UEFA.NATIONS'},
	{team: 'LVA', conference: 'UEFA.NATIONS'},
	{team: 'MDA', conference: 'UEFA.NATIONS'},
	{team: 'MK', conference: 'UEFA.NATIONS'},
	{team: 'MLT', conference: 'UEFA.NATIONS'},
	{team: 'MNE', conference: 'UEFA.NATIONS'},
	{team: 'NED', conference: 'UEFA.NATIONS'},
	{team: 'NIR', conference: 'UEFA.NATIONS'},
	{team: 'NO', conference: 'UEFA.NATIONS'},
	{team: 'POL', conference: 'UEFA.NATIONS'},
	{team: 'PT', conference: 'UEFA.NATIONS'},
	{team: 'ROU', conference: 'UEFA.NATIONS'},
	{team: 'RUS', conference: 'UEFA.NATIONS'},
	{team: 'SCO', conference: 'UEFA.NATIONS'},
	{team: 'SMR', conference: 'UEFA.NATIONS'},
	{team: 'SRB', conference: 'UEFA.NATIONS'},
	{team: 'SUI', conference: 'UEFA.NATIONS'},
	{team: 'SVK', conference: 'UEFA.NATIONS'},
	{team: 'SVN', conference: 'UEFA.NATIONS'},
	{team: 'SWE', conference: 'UEFA.NATIONS'},
	{team: 'TUR', conference: 'UEFA.NATIONS'},
	{team: 'UKR', conference: 'UEFA.NATIONS'},
	{team: 'WLS', conference: 'UEFA.NATIONS'},
	{team: 'ARS', conference: 'UEFA.WCHAMPIONS'},
	{team: 'BAR', conference: 'UEFA.WCHAMPIONS'},
	{team: 'BENF', conference: 'UEFA.WCHAMPIONS'},
	{team: 'BK', conference: 'UEFA.WCHAMPIONS'},
	{team: 'BREI', conference: 'UEFA.WCHAMPIONS'},
	{team: 'CHE', conference: 'UEFA.WCHAMPIONS'},
	{team: 'HBK', conference: 'UEFA.WCHAMPIONS'},
	{team: 'HOF', conference: 'UEFA.WCHAMPIONS'},
	{team: 'JUV', conference: 'UEFA.WCHAMPIONS'},
	{team: 'KHA', conference: 'UEFA.WCHAMPIONS'},
	{team: 'LYON', conference: 'UEFA.WCHAMPIONS'},
	{team: 'MAD', conference: 'UEFA.WCHAMPIONS'},
	{team: 'MUN', conference: 'UEFA.WCHAMPIONS'},
	{team: 'PSG', conference: 'UEFA.WCHAMPIONS'},
	{team: 'SER', conference: 'UEFA.WCHAMPIONS'},
	{team: 'WOB', conference: 'UEFA.WCHAMPIONS'},
	{team: 'CHI', conference: 'USA.NWSL'},
	{team: 'HOUS', conference: 'USA.NWSL'},
	{team: 'KC', conference: 'USA.NWSL'},
	{team: 'LOU', conference: 'USA.NWSL'},
	{team: 'NCC', conference: 'USA.NWSL'},
	{team: 'NJNY', conference: 'USA.NWSL'},
	{team: 'OLR', conference: 'USA.NWSL'},
	{team: 'ORLP', conference: 'USA.NWSL'},
	{team: 'PRT', conference: 'USA.NWSL'},
	{team: 'WSH', conference: 'USA.NWSL'},
	{team: 'MIL', conference: 'UEFA.CHAMPIONS'},
	{team: 'AJA', conference: 'UEFA.CHAMPIONS'},
	{team: 'ATA', conference: 'UEFA.CHAMPIONS'},
	{team: 'ATL', conference: 'UEFA.CHAMPIONS'},
	{team: 'BAR', conference: 'UEFA.CHAMPIONS'},
	{team: 'MUN', conference: 'UEFA.CHAMPIONS'},
	{team: 'BENF', conference: 'UEFA.CHAMPIONS'},
	{team: 'BES', conference: 'UEFA.CHAMPIONS'},
	{team: 'DOR', conference: 'UEFA.CHAMPIONS'},
	{team: 'CHE', conference: 'UEFA.CHAMPIONS'},
	{team: 'BRUG', conference: 'UEFA.CHAMPIONS'},
	{team: 'KIEV', conference: 'UEFA.CHAMPIONS'},
	{team: 'POR', conference: 'UEFA.CHAMPIONS'},
	{team: 'SALZ', conference: 'UEFA.CHAMPIONS'},
	{team: 'INT', conference: 'UEFA.CHAMPIONS'},
	{team: 'JUV', conference: 'UEFA.CHAMPIONS'},
	{team: 'LILL', conference: 'UEFA.CHAMPIONS'},
	{team: 'LIV', conference: 'UEFA.CHAMPIONS'},
	{team: 'MALM', conference: 'UEFA.CHAMPIONS'},
	{team: 'MNC', conference: 'UEFA.CHAMPIONS'},
	{team: 'MAN', conference: 'UEFA.CHAMPIONS'},
	{team: 'PSG', conference: 'UEFA.CHAMPIONS'},
	{team: 'LEIP', conference: 'UEFA.CHAMPIONS'},
	{team: 'MAD', conference: 'UEFA.CHAMPIONS'},
	{team: 'SEV', conference: 'UEFA.CHAMPIONS'},
	{team: 'SHK', conference: 'UEFA.CHAMPIONS'},
	{team: 'SHE', conference: 'UEFA.CHAMPIONS'},
	{team: 'SCP', conference: 'UEFA.CHAMPIONS'},
	{team: 'WOB', conference: 'UEFA.CHAMPIONS'},
	{team: 'VILL', conference: 'UEFA.CHAMPIONS'},
	{team: 'YB', conference: 'UEFA.CHAMPIONS'},
	{team: 'ZEN', conference: 'UEFA.CHAMPIONS'},
	{team: 'ARG', conference: 'FIFA.WORLD'},
	{team: 'BEL', conference: 'FIFA.WORLD'},
	{team: 'BRA', conference: 'FIFA.WORLD'},
	{team: 'CMR', conference: 'FIFA.WORLD'},
	{team: 'CAN', conference: 'FIFA.WORLD'},
	{team: 'CRA', conference: 'FIFA.WORLD'},
	{team: 'DEN', conference: 'FIFA.WORLD'},
	{team: 'ECU', conference: 'FIFA.WORLD'},
	{team: 'ENG', conference: 'FIFA.WORLD'},
	{team: 'FRA', conference: 'FIFA.WORLD'},
	{team: 'GER', conference: 'FIFA.WORLD'},
	{team: 'GHA', conference: 'FIFA.WORLD'},
	{team: 'IRN', conference: 'FIFA.WORLD'},
	{team: 'JPN', conference: 'FIFA.WORLD'},
	{team: 'MEX', conference: 'FIFA.WORLD'},
	{team: 'MA', conference: 'FIFA.WORLD'},
	{team: 'NED', conference: 'FIFA.WORLD'},
	{team: 'POL', conference: 'FIFA.WORLD'},
	{team: 'PT', conference: 'FIFA.WORLD'},
	{team: 'QAT', conference: 'FIFA.WORLD'},
	{team: 'KSA', conference: 'FIFA.WORLD'},
	{team: 'SEN', conference: 'FIFA.WORLD'},
	{team: 'SRB', conference: 'FIFA.WORLD'},
	{team: 'KOR', conference: 'FIFA.WORLD'},
	{team: 'ESPA', conference: 'FIFA.WORLD'},
	{team: 'SUI', conference: 'FIFA.WORLD'},
	{team: 'TUN', conference: 'FIFA.WORLD'},
	{team: 'USA', conference: 'FIFA.WORLD'},
	{team: 'URU', conference: 'FIFA.WORLD'},
];
