import axios from 'axios';
import {useState} from 'react';
import Modal from 'react-modal';
import {toast} from 'react-toastify';
import {uploadJpeg} from '../../firebase/firebase';
import useModalStore from '../../stores/modal.store';
import useGlobalStore from '../../stores/global.store';

export default function LogoModal(props) {
	const [selectedLogo, setSelectedLogo] = useState(null);
	const { toggleModal } = useModalStore();
	const { debug } = useGlobalStore();

	const checkFileSize = (event) => {
		let file = event.target.files[0];
		if (file && file.size > 2097152) {
			toast.error('File is over 2 MB.  Please try a different file.');
			event.target.value = null;
			return false;
		}
		return true;
	};

	const logoSelected = (e) => {
		if (e.target.files[0] === undefined) {
			setSelectedLogo(null);
		} else if (checkFileSize(e)) {
			setSelectedLogo(e.target.files[0]);
		}
	};

	const logoSubmit = (e) => {
		e.preventDefault();
		if (selectedLogo) {
			const title = `/images/logo-${props.user._id}${selectedLogo.name}`;
			uploadJpeg(selectedLogo, title)
				.then((url) => {
					axios
						.put(`/users/addLogo/${props.user._id}`, {
							logo: url,
						})
						.then((response) => {
							toast.success('Saved logo successfully!', {autoClose: 3000});
							localStorage.setItem('token', response.data.data.token);
							props.updateUser(response.data.data.token);
							toggleModal('logo');
						})
						.catch((error) => {
							if (debug) {
								console.log(error);
							}
							toast.error('An unknown error occured.');
						});
				})
				.catch((error) => {
					toast.error('Upload failed. Please try again.');
					if (debug) {
						console.log(error);
					}
					setSelectedLogo(null);
				});
		}
	};

	return (
		<Modal
			isOpen={true}
			onRequestClose={() => toggleModal('logo')}
			shouldCloseOnOverlayClick={true}
			shouldCloseOnEsc={true}>
			<div className='modal'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>Upload Logo</h5>
							<span
								className='close-modal'
								onClick={() => toggleModal('logo')}>
								X
							</span>
						</div>
						<div className='modal-body'>
							<div className='file-box'>
								<form onSubmit={logoSubmit}>
									<span className='uploadInfo'>Upload a logo or watermark here.</span>
									<input
										type='file'
										className='file-input'
										accept='image/*'
										onChange={logoSelected}
									/>
									{selectedLogo && (
										<input
											id='upload-button'
											type='submit'
											value='Upload'
											className='upload-button newButton'
										/>
									)}
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}
